import { PLAN_STATUS, PLAN_VERSIONS } from './constants';
import { getIsTest } from './networkUtil';

export const getVolumeData = (inboundPackageProfileByInterval, interval, cycle) => {
  if (!inboundPackageProfileByInterval || !interval) {
    return null;
  }

  if (!inboundPackageProfileByInterval[interval]) {
    return null;
  }

  let volumeDataForCycle = inboundPackageProfileByInterval[interval]['packageVolumeByProcessFlow'];
  if (!volumeDataForCycle) {
    return null;
  }

  let key = cycle ? cycle : Object.keys(volumeDataForCycle)[0];
  if (!key || !volumeDataForCycle[key]) {
    return null;
  }

  return volumeDataForCycle[key]['packageVolumeBySize'];
};

export const getCycles = (opsClockInput) => {
  if (!opsClockInput['opsClockMap']) {
    return null;
  }
  const cycles = Object.keys(opsClockInput['opsClockMap']);
  if (cycles.length === 1) {
    return ['CYCLE_1'];
  } else if (cycles.length > 1) {
    return cycles;
  } else {
    return [];
  }
};

export const canShowDynamicPlan = (plan) => {
  return (
    (getIsTest()
      ? plan['planVersion'] === PLAN_VERSIONS.DYNAMIC_V2
      : plan['planVersion'] === PLAN_VERSIONS.DYNAMIC_V1 || plan['planVersion'] === PLAN_VERSIONS.DYNAMIC_V2) &&
    plan['status'] !== PLAN_STATUS.FAILED &&
    plan['status'] !== PLAN_STATUS.PENDING_CREATION &&
    plan['status'] !== PLAN_STATUS.CANCELLED
  );
};

export const getTotalVolume = (volumeValues) => {
  return Object.values(volumeValues).reduce((a, b) => a + b, 0);
};

export const isStablePlanTimeInShiftGroupWindow = (output, shiftGroup) => {
  if (!output || !shiftGroup) {
    return true;
  }
  let stablePlanTime = Date.parse(output['metadata']['STABLE_PLAN_TIME']);
  let timeWindowStart = Date.parse(shiftGroup['applicability']['timeWindow']['start']);
  let timeWindowEnd = Date.parse(shiftGroup['applicability']['timeWindow']['end']);
  return timeWindowStart <= stablePlanTime && stablePlanTime <= timeWindowEnd;
};

export const isDynamicPlanDataValid = (data) => {
  return data && data['input'] && data['output'];
};
