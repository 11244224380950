import React, { useState, useCallback } from 'react';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import Modal, { ModalFooter } from '@amzn/meridian/modal';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import exportLargeTokens from '@amzn/meridian-tokens/base/icon/export-large';
import chevronDownSmallTokens from '@amzn/meridian-tokens/base/icon/chevron-down-small';
import chevronRightSmallTokens from '@amzn/meridian-tokens/base/icon/chevron-right-small';
import Table, { TableRow, TableCell } from '@amzn/meridian/table';
import { displayTime } from '../../utils';

const DISPLAY_FIELDS = [
  { key: 'displayTime', label: '', isChildRow: false, hasChildRows: false },
  { key: 'availableAttendance', label: 'Total Available', isChildRow: false, hasChildRows: false },
  { key: 'assignedSum', label: 'Assigned', isChildRow: false, hasChildRows: true },
  { key: 'outbound', label: 'Outbound', isChildRow: true, hasChildRows: false },
  { key: 'sortation', label: 'Sortation', isChildRow: true, hasChildRows: false },
  { key: 'injection', label: 'Injection', isChildRow: true, hasChildRows: false },
  { key: 'idle', label: 'Idle', isChildRow: false, hasChildRows: false },
  { key: 'breakOrMove', label: 'Break/Move', isChildRow: false, hasChildRows: true },
  { key: 'toBreak', label: 'To Break', isChildRow: true, hasChildRows: false },
  { key: 'idleToProcess', label: 'Idle to Process', isChildRow: true, hasChildRows: false },
  { key: 'movePPToPP', label: 'Move PP to PP', isChildRow: true, hasChildRows: false },
  { key: 'onMoveOrBreak', label: 'On Move/Break', isChildRow: true, hasChildRows: false }
];

const isDifferentHeadcount = (current, previous) => {
  return (
    Object.keys(current)
      .filter((k) => k !== 'startDatetimeLocal')
      .filter((k) => current[k] !== previous[k]).length > 0
  );
};

const findDepartmentHeadcount = (quarterPivot, departmentName) => {
  return Object.keys(quarterPivot)
    .filter((k) => k.startsWith(departmentName))
    .map((k) => quarterPivot[k])
    .reduce((acc, curr) => acc + curr, 0);
};

const getProcessedPivotData = (shift, headcountAllocationPivot) => {
  const processedPivotData = [];
  headcountAllocationPivot
    .filter(({ start_datetime_local }) => start_datetime_local >= shift.start && start_datetime_local < shift.end)
    .forEach((pivot) => {
      const {
        start_datetime_local,
        available_headcount,
        assigned_sum,
        idle__nan__nan,
        to_break,
        on_move_or_break,
        idle_to_process,
        move_pp_to_pp
      } = pivot;
      const pivotData = {
        startDatetimeLocal: displayTime(start_datetime_local),
        availableAttendance: available_headcount,
        assignedSum: assigned_sum - idle__nan__nan,
        outbound: findDepartmentHeadcount(pivot, 'outbound'),
        sortation: findDepartmentHeadcount(pivot, 'sortation'),
        injection: findDepartmentHeadcount(pivot, 'injection'),
        idle: idle__nan__nan,
        breakOrMove: to_break + on_move_or_break + idle_to_process + move_pp_to_pp,
        toBreak: to_break,
        idleToProcess: idle_to_process,
        movePPToPP: move_pp_to_pp,
        onMoveOrBreak: on_move_or_break
      };
      if (
        processedPivotData.length === 0 ||
        isDifferentHeadcount(pivotData, processedPivotData[processedPivotData.length - 1])
      ) {
        processedPivotData.push(pivotData);
      }
    });
  processedPivotData.forEach((pivot, index) => {
    if (index < processedPivotData.length - 1) {
      pivot.displayTime = `${pivot.startDatetimeLocal} - ${processedPivotData[index + 1].startDatetimeLocal}`;
    } else {
      pivot.displayTime = `${pivot.startDatetimeLocal} - ${displayTime(shift.end)}`;
    }
  });
  return processedPivotData;
};

const ShiftHeadCountExpanded = ({ shift, headcountAllocationPivot }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isRowsExpanded, setIsRowsExpanded] = useState(false);

  const processedPivotData = getProcessedPivotData(shift, headcountAllocationPivot);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <Column>
      <Row alignmentHorizontal="end" widths="fit">
        <div style={{ cursor: 'pointer' }} onClick={() => setIsOpen(true)}>
          <Icon tokens={exportLargeTokens}></Icon>
        </div>
      </Row>

      <Modal title="Shift Headcount" open={isOpen} onClose={onClose} closeLabel="Close">
        <Row widths="fill" spacingInset="400">
          <Table headerRows={1} spacing={'small'}>
            {DISPLAY_FIELDS.filter(({ isChildRow }) => (isRowsExpanded ? true : !isChildRow)).map(
              ({ key, isChildRow, label, hasChildRows }) => (
                <TableRow key={key} backgroundColor={isChildRow ? 'secondary' : 'primary'}>
                  <TableCell alignmentVertical="top">
                    {hasChildRows && (
                      <Button type="icon" size="small" onClick={() => setIsRowsExpanded(!isRowsExpanded)}>
                        {isRowsExpanded ? (
                          <Icon tokens={chevronDownSmallTokens}>Collapse rows</Icon>
                        ) : (
                          <Icon tokens={chevronRightSmallTokens}>Expand rows</Icon>
                        )}
                      </Button>
                    )}
                  </TableCell>
                  <TableCell>{label}</TableCell>
                  {processedPivotData.map((pivot) => (
                    <TableCell key={`${pivot.displayTime}-${key}`}>{pivot[key]}</TableCell>
                  ))}
                </TableRow>
              )
            )}
          </Table>
        </Row>
        <ModalFooter>
          <Row alignmentHorizontal="end" widths="fit">
            <Button type="secondary" size="small" onClick={onClose}>
              Close
            </Button>
          </Row>
        </ModalFooter>
      </Modal>
    </Column>
  );
};
export default ShiftHeadCountExpanded;
