import React, { useEffect, useState } from 'react';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';
import Tab, { TabGroup } from '@amzn/meridian/tab';
import Button from '@amzn/meridian/button';
import Input from '@amzn/meridian/input';

import SubmitShiftPlan from './staticPlan/viewPlan/SubmitShiftPlan';
import VerifyShiftPlanAlert from './staticPlan/viewPlan/VerifyShiftPlanAlert';
import CreateShiftPlanAlert from './staticPlan/createPlan/CreateShiftPlanAlert';
import DynamicShiftPlan from './dynamicPlan/DynamicShiftPlan';

import { TEXTS, PLANS, DEFAULT_EXPERIMENT_MODE } from '../utils/constants';
import { getAvailableDates, getCurrentEpoch } from '../utils/helpers';
import { getIsTest } from '../utils/networkUtil';
import { logCustomMetrics } from '../utils/metricUtil';
import DatePicker from '@amzn/meridian/date-picker';
import Box from '@amzn/meridian/box';

const availableDates = getAvailableDates();

const ViewPlan = ({
  date,
  tab,
  setTab,
  changeDateHandler,
  callAPI,
  rerunPlan,
  getPlanAPI,
  planIdForGivenPlan,
  responseDataForGivenPlan,
  setResponseDataForGivenPlan,
  resetId,
  isDynamicShiftPlanEnabled,
  setAsyncError,
  updatePlanOutputAndStatus
}) => {
  const [experimentMode, setExperimentMode] = useState(DEFAULT_EXPERIMENT_MODE);

  const updateExperimentModeUrl = () => {
    var existingUrl = new URL(window.location);
    existingUrl.searchParams.set('experimentMode', experimentMode);
    const newUrl = existingUrl.toString();
    console.log('Appending experiment mode to URL => ' + newUrl);
    window.history.pushState({ path: newUrl }, '', newUrl);

    // Force re-render to re-issue API calls.
    changeDateHandler(date);
  };

  const setIsTest = () => {
    if (!window.location.toString().includes('isTest')) {
      const newUrl = window.location.toString().replace('stationCode', 'isTest=true&stationCode');
      window.history.pushState({ path: newUrl }, '', newUrl);

      // Force re-render to re-issue API calls.
      changeDateHandler(date);
    }
  };

  useEffect(() => {
    const currUrl = new URLSearchParams(window.location.search);
    const urlExperimentMode = currUrl.get('experimentMode');
    setExperimentMode(urlExperimentMode === null ? DEFAULT_EXPERIMENT_MODE : urlExperimentMode);

    if (urlExperimentMode === null) {
      var existingUrl = new URL(window.location);
      existingUrl.searchParams.set('experimentMode', experimentMode);
      const newUrl = existingUrl.toString();
      console.log('Appending experiment mode to URL => ' + newUrl);
      window.history.pushState({ path: newUrl }, '', newUrl);
    }
  }, [window.location.search]);

  return (
    <Column spacingInset="400" alignmentVertical="justify" alignmentHorizontal="left">
      <Text type={TEXTS.H1}>Shift Plan</Text>

      <Text type={TEXTS.T1} color="secondary">
        This page allows you to run and view the next shift plan and accept or override it
        <span onClick={() => setIsTest()}>.</span>
      </Text>

      {getIsTest() ? (
        <div>
          <Text color="error">Developer mode.</Text>
          <Input value={experimentMode} onChange={setExperimentMode} />
          <br />
          <Button onClick={updateExperimentModeUrl}>Submit Experiment Mode</Button>
        </div>
      ) : null}
      <Box type="fill" spacingInset="400" width="100%">
        <Column width={200}>
          <Text type={TEXTS.T1}>Out For Delivery Date</Text>
          <DatePicker
            value={date}
            onChange={changeDateHandler}
            monthsInView={1}
            size="medium"
            disabledDates={(d) => !availableDates.includes(d)}
          />
        </Column>
      </Box>
      <TabGroup
        value={tab}
        onChange={(a) => {
          setTab(a);
          logCustomMetrics('ShiftPlanTabChange', a.toString());
        }}
        type="default"
        fill="line"
      >
        <Tab value={PLANS.NEXT_DAY}>Next Day</Tab>
        <Tab value={PLANS.PRE_SHIFT}>Pre-Shift</Tab>
        {!!isDynamicShiftPlanEnabled && <Tab value={PLANS.DYNAMIC}>Dynamic</Tab>}
      </TabGroup>
      {tab !== PLANS.DYNAMIC ? (
        <React.Fragment>
          <VerifyShiftPlanAlert
            planId={planIdForGivenPlan[tab]}
            planType={tab}
            getPlanAPI={getPlanAPI}
            responseData={responseDataForGivenPlan[tab]}
          />
          <CreateShiftPlanAlert
            planId={planIdForGivenPlan[tab]}
            planType={tab}
            callAPI={callAPI}
            responseData={responseDataForGivenPlan[tab]}
            date={date}
          />
          <SubmitShiftPlan
            planType={tab}
            updatePlanOutputAndStatus={updatePlanOutputAndStatus}
            dataStorage={responseDataForGivenPlan}
            setDataStorage={setResponseDataForGivenPlan}
            rerunPlan={rerunPlan}
            key={resetId}
            getPlanAPI={getPlanAPI}
            ofdDate={date}
          />
        </React.Fragment>
      ) : (
        <DynamicShiftPlan date={date} currentTimeEpoch={getCurrentEpoch} setAsyncError={setAsyncError} />
      )}
    </Column>
  );
};

export default ViewPlan;
