import React, { useCallback, useState, useMemo } from 'react';
import Select, { SelectOption } from '@amzn/meridian/select';
import Tag from '@amzn/meridian/tag';
import Row from '@amzn/meridian/row';
import Button from '@amzn/meridian/button';
import { cx } from 'emotion';
import { removeHeight } from './styled';

export const SelectFilter = ({ options, value, onChange, labels }) => {
  const [filterQuery, setFilterQuery] = useState('');

  const filteredOptions = useMemo(() => {
    return [...options].filter((option) => option.toLowerCase().includes((filterQuery || '').toLowerCase())).sort();
  }, [filterQuery]);

  const onClickFilterTag = useCallback(
    (clickedOption) => {
      onChange(value.filter((option) => option !== clickedOption));
    },
    [value]
  );

  const onClearAllFilters = useCallback(() => {
    onChange([]);
  }, []);

  return (
    <React.Fragment>
      <Select
        searchQuery={filterQuery}
        onSearch={setFilterQuery}
        value={value}
        onChange={onChange}
        disabled={filteredOptions.length === 0}
        width="100%"
        size="small"
      >
        {filteredOptions.map((option, idx) => (
          <SelectOption key={`disallowed-option-${idx}`} value={option} label={labels ? labels[option] : option} />
        ))}
      </Select>
      <Row spacing="200" wrap="down" spacingInset="200">
        {value.map((item, idx) => (
          <Tag key={idx} onClose={() => onClickFilterTag(item)}>
            {labels ? labels[item] : item}
          </Tag>
        ))}
        {value.length > 0 && (
          <Button className={cx(removeHeight)} onClick={() => onClearAllFilters()} type="link">
            Clear all
          </Button>
        )}
      </Row>
    </React.Fragment>
  );
};
