import Box from '@amzn/meridian/box';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import Button from '@amzn/meridian/button';
import React from 'react';
import Column from '@amzn/meridian/column';
import Icon from '@amzn/meridian/icon';
import alertKnockoutTokens from '@amzn/meridian-tokens/base/icon/alert-informational-large';

import { TEXTS } from '../utils/constants';

const CustomAlert = ({
  title,
  subtitle,
  buttonTitle,
  buttonClickHandle,
  width,
  children,
  disabled,
  backgroundColor = 'secondary'
}) => {
  return (
    <Box
      backgroundColor={backgroundColor}
      type="fill"
      spacingInset={children ? '400' : '300'}
      width={width ? width : '100%'}
      className="alert-border"
    >
      <Column width="100%">
        <Row alignmentHorizontal="justify" width="100%">
          <Row spacingInset="200" alignmentVertical="top">
            <Icon tokens={alertKnockoutTokens} />
            <Column spacing="100">
              <Text>{title}</Text>
              <Text type={TEXTS.T4}>{<strong>{subtitle}</strong>}</Text>
            </Column>
          </Row>
          {!disabled && buttonTitle ? (
            <Button size="small" onClick={buttonClickHandle}>
              {buttonTitle}
            </Button>
          ) : null}
        </Row>
        {children}
      </Column>
    </Box>
  );
};

export default CustomAlert;
