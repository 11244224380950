import React, { useRef, useState } from 'react';
import Button from '@amzn/meridian/button';
import undoTokens from '@amzn/meridian-tokens/base/icon/undo';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import Table, { TableCell, TableRow } from '@amzn/meridian/table';
import Text from '@amzn/meridian/text';
import Link from '@amzn/meridian/link';
import Popover, { PopoverHeader } from '@amzn/meridian/popover';
import { SSD_OVERRIDE_REASON_CODES } from '../../../../utils/constants';
import Select, { SelectOption } from '@amzn/meridian/select';
import Input from '@amzn/meridian/input';
import { chainWalk } from '../../../../utils/helpers';
import Icon from '@amzn/meridian/icon';
import { getShiftSchedules, getTimelineForViewPlan } from '../../utils';

const USER_OVERRIDE_REASON_CODES = [
  SSD_OVERRIDE_REASON_CODES.EXCESSIVE_ATTRITION,
  SSD_OVERRIDE_REASON_CODES.PLANNED_EVENT
];

const VerifyHeadcounts = ({ currentUser, plan, setPlan, onGoBack, onGoNext, onCancel }) => {
  return (
    <Column width="100%">
      <Text type={'h400'}>Headcount</Text>
      <Text type={'b400'}>Verify or update the values below and submit.</Text>
      <HeadcountsComponent currentUser={currentUser} plan={plan} setPlan={setPlan} />
      <Row alignmentHorizontal="justify">
        <Button onClick={onGoBack} type="secondary">
          Back
        </Button>
        <Row>
          <Button onClick={onCancel} type="tertiary">
            Cancel
          </Button>
          <Button onClick={onGoNext}>Next</Button>
        </Row>
      </Row>
    </Column>
  );
};

const OverrideHeadcountPopover = ({ currentUser, plan, setPlan, headcount }) => {
  const linkRef = useRef();
  const [open, setOpen] = useState(false);
  const { scheduledHeadcount, onsiteHeadcount, availableHeadcount, expectedAttendanceHeadcount } = headcount || {};

  const overridingValue = chainWalk(() => headcount.overrides.overrideKeyValueMap.availableHeadcount, null);
  const reasonCode = chainWalk(() => headcount.overrides.reason, null);
  const minHeadcount = chainWalk(() => headcount.overrides.overrideKeyGuardrailsMap.availableHeadcount.minimum, 0);
  const maxHeadcount = chainWalk(
    () => headcount.overrides.overrideKeyGuardrailsMap.availableHeadcount.maximum,
    Number.POSITIVE_INFINITY
  );

  const initialValue = overridingValue != null ? overridingValue : availableHeadcount;
  const [newOverridingValue, setNewOverridingValue] = useState(initialValue);
  const [selectedOverrideReason, setSelectedOverrideReason] = useState(reasonCode);
  const attendancePercentage =
    scheduledHeadcount != null ? Math.round((newOverridingValue / scheduledHeadcount) * 100) : '';

  const onSubmit = () => {
    if (!headcount.overrides) {
      headcount.overrides = {};
    }
    if (!headcount.overrides.overrideKeyValueMap) {
      headcount.overrides.overrideKeyValueMap = {};
    }
    headcount.overrides.overrideKeyValueMap.availableHeadcount = newOverridingValue + '';
    headcount.overrides.reason = selectedOverrideReason;
    headcount.overrides.timestamp = parseInt(Date.now() / 1000);
    headcount.overrides.user = currentUser;
    setPlan({ ...plan });
    setOpen(false);
  };
  const onRevert = () => {
    if (headcount.overrides) {
      delete headcount.overrides.overrideKeyValueMap;
      delete headcount.overrides.users;
      delete headcount.overrides.reason;
    }
    setPlan({ ...plan });
  };

  const isValid = minHeadcount <= newOverridingValue && newOverridingValue <= maxHeadcount;
  const isDisabled = initialValue === newOverridingValue || !isValid || !selectedOverrideReason;
  const isRevertible = overridingValue !== null;

  return (
    <React.Fragment>
      {isRevertible && (
        <Button type={'link'} onClick={onRevert}>
          <Icon tokens={undoTokens} />
        </Button>
      )}
      <Link type="secondary" onClick={() => setOpen(true)} ref={linkRef}>
        {initialValue}
      </Link>
      <Popover anchorNode={linkRef.current} open={open} onClose={() => setOpen(false)} position="bottom">
        <PopoverHeader closeLabel="Close">Provide an override</PopoverHeader>
        <Column>
          <Row width="100%" alignmentHorizontal="justify">
            <Text>Scheduled HC</Text>
            <Text>{scheduledHeadcount}</Text>
          </Row>
          <Row width="100%" alignmentHorizontal="justify">
            <Text>Onsite HC</Text>
            <Text>{onsiteHeadcount}</Text>
          </Row>
          <Row width="100%" alignmentHorizontal="justify">
            <Text>Expected Attendance HC</Text>
            <Text alignment={'right'}>{expectedAttendanceHeadcount}</Text>
          </Row>
          <Row width="100%" alignmentHorizontal="justify">
            <Text>Available HC</Text>
            <Input
              width={200}
              value={newOverridingValue}
              onChange={setNewOverridingValue}
              error={!isValid}
              type="number"
              size="small"
              errorMessage={!isValid ? `Headcount value must be between ${minHeadcount} and ${maxHeadcount}` : ''}
            />
          </Row>
          <Row width="100%" widths="fill">
            <Text>Shift Half Attendance Rate</Text>
            <Text alignment={'right'}>{attendancePercentage}%</Text>
          </Row>
          <Select
            value={selectedOverrideReason}
            onChange={setSelectedOverrideReason}
            placeholder="Select a reason"
            size="small"
          >
            {USER_OVERRIDE_REASON_CODES.map(({ value, label }) => (
              <SelectOption key={value} value={value} label={label} />
            ))}
          </Select>
          <Button disabled={isDisabled} onClick={onSubmit}>
            Submit Override
          </Button>
        </Column>
      </Popover>
    </React.Fragment>
  );
};

const HeadcountsComponent = ({ currentUser, plan, setPlan }) => {
  const timeline = getTimelineForViewPlan(plan);
  const headcounts = chainWalk(() => plan.input.ssdShiftPlanInput.headcountList, []);
  const shiftSchedules = getShiftSchedules(timeline);
  const headcountRows = [];
  shiftSchedules.forEach((shiftSchedule) => {
    const firstHalfHeadcount = chainWalk(
      () =>
        headcounts.find((headcount) => headcount.shiftHalfStartTimestamp === shiftSchedule.firstHalf.startTimestamp),
      {}
    );
    const secondHalfHeadcount = chainWalk(
      () =>
        headcounts.find((headcount) => headcount.shiftHalfStartTimestamp === shiftSchedule.secondHalf.startTimestamp),
      {}
    );
    headcountRows.push(
      <TableRow key={shiftSchedule.label}>
        <TableCell>
          <Text type="h100">{shiftSchedule.label}</Text>
        </TableCell>
        <TableCell>{firstHalfHeadcount.scheduledHeadcount}</TableCell>
        <TableCell>
          <OverrideHeadcountPopover
            currentUser={currentUser}
            plan={plan}
            setPlan={setPlan}
            headcount={firstHalfHeadcount}
          />
        </TableCell>
        <TableCell>{secondHalfHeadcount.scheduledHeadcount}</TableCell>
        <TableCell>
          <OverrideHeadcountPopover
            currentUser={currentUser}
            plan={plan}
            setPlan={setPlan}
            headcount={secondHalfHeadcount}
          />
        </TableCell>
      </TableRow>
    );
  });

  return (
    <div style={{ overflowX: 'auto', maxWidth: '100%' }}>
      <Table headerRows={1} showDividers={true}>
        <TableRow>
          <TableCell>Window</TableCell>
          <TableCell>1H Scheduled</TableCell>
          <TableCell>1H Available</TableCell>
          <TableCell>2H Scheduled</TableCell>
          <TableCell>2H Available</TableCell>
        </TableRow>
        <TableRow backgroundColor="secondary">
          <TableCell>Active Shift</TableCell>
          <TableCell columnSpan={4}></TableCell>
        </TableRow>
        {headcountRows[0]}
        <TableRow backgroundColor="secondary">
          <TableCell>Upcoming Shifts</TableCell>
          <TableCell columnSpan={4}></TableCell>
        </TableRow>
        {headcountRows.slice(1)}
      </Table>
    </div>
  );
};

export default VerifyHeadcounts;
