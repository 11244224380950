import React from 'react';
import Column from '@amzn/meridian/column';

import CustomAlert from '../../commonComponents/CustomAlert';

const ErrorSection = ({ loadingMsg }) => {
  return (
    <Column className="loaderClass" alignmentHorizontal="center" alignmentVertical="center">
      <CustomAlert
        title={loadingMsg ? loadingMsg : 'Could not find Shift Allocation Plan for given station'}
        width="fit"
      />
    </Column>
  );
};

export default ErrorSection;
