import React, { useEffect, useState } from 'react';

import Checkbox from '@amzn/meridian/checkbox';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Select, { SelectOption } from '@amzn/meridian/select';
import Tag from '@amzn/meridian/tag';
import Text from '@amzn/meridian/text';
import { VictoryArea, VictoryChart, VictoryVoronoiContainer, VictoryAxis, VictoryLine, VictoryScatter } from 'victory';
import Legend, { LegendProvider } from '@amzn/meridian/legend';
import victoryLinePlugin from '@amzn/meridian/legend/plugin/victory-line';
import victoryTooltipPlugin from '@amzn/meridian/legend/plugin/victory-tooltip';
import useVictoryTheme from '@amzn/meridian/use-victory-theme';

import { chainWalk, parseEpochToHourMinute } from '../utils/helpers';
import { PROCESS_PATH_TO_ROLES } from './ShiftAdherence';

const GRAPH_THRESHOLD = 80;

const ShiftAdherenceGraph = ({ adherenceData }) => {
  const graphTheme = useVictoryTheme({ showIndependentGrid: false, lineStrokeWidth: 2, interpolation: 'monotoneX' });
  graphTheme.line.domainPadding.y = 0;
  graphTheme.scatter.domainPadding.y = 0;

  const [displayTimes, setDisplayTimes] = useState([]);
  const formatLabel = ({ datum }) => datum.value;

  const [selectedProcessPaths, setSelectedProcessPaths] = useState(['outbound', 'sortation']);
  const [selectedRoles, setSelectedRoles] = useState({
    outbound: ['pick2rebinpick', 'pick2rebinpack'],
    injection: [],
    sortation: ['induct', 'stow']
  });
  const [selectedRoleTags, setSelectedRoleTags] = useState([]);

  const [graphDatasets, setGraphDatasets] = useState([]);

  useEffect(() => {
    const currDate = new Date();

    const updatedSelectedRoleTags = [];
    Object.entries(selectedRoles).forEach(([processPathName, selectedRoleNames]) => {
      if (selectedProcessPaths.includes(processPathName)) {
        selectedRoleNames.forEach((selectedRoleName) => {
          updatedSelectedRoleTags.push(
            <Tag
              onClose={() => {
                const duplicatedSelectedRoles = JSON.parse(JSON.stringify(selectedRoles));
                duplicatedSelectedRoles[processPathName] = duplicatedSelectedRoles[processPathName].filter(
                  (processPathRoleName) => processPathRoleName !== selectedRoleName
                );
                setSelectedRoles(duplicatedSelectedRoles);
              }}
            >
              {PROCESS_PATH_TO_ROLES[processPathName][selectedRoleName]}
            </Tag>
          );
        });
      }
    });
    setSelectedRoleTags(updatedSelectedRoleTags);

    const stationTimeZone = chainWalk(() => adherenceData['stationAttrs']['timezone'], '');

    const updatedGraphDatasets = [
      {
        key: 'breaks',
        label: 'Break',
        symbol: 'diamond',
        color: 'black',
        data: []
      }
    ];
    Object.entries(selectedRoles).forEach(([processPathName, selectedRoleNames]) => {
      if (selectedProcessPaths.includes(processPathName)) {
        selectedRoleNames.forEach((selectedRoleName) => {
          updatedGraphDatasets.push({
            key: selectedRoleName,
            label: PROCESS_PATH_TO_ROLES[processPathName][selectedRoleName],
            data: []
          });
        });
      }
    });
    const updatedDisplayTimes = [];
    chainWalk(() => adherenceData['timeWindowHeadcountAdherences'], []).forEach((timeWindowAdherence) => {
      const displayTimeWindowStart = parseEpochToHourMinute(
        timeWindowAdherence['timeWindow']['startInSeconds'] * 1000,
        stationTimeZone
      );
      updatedDisplayTimes.push(displayTimeWindowStart);

      let isBreakInterval = false;
      chainWalk(() => timeWindowAdherence['breaks'], []).forEach((_) => {
        const breakDatasetDatum = {
          time: displayTimeWindowStart,
          value: 0,
          isBreak: true
        };
        updatedGraphDatasets
          .find((updatedGraphDataset) => updatedGraphDataset['key'] === 'breaks')
          ['data'].push(breakDatasetDatum);
        isBreakInterval = true;
      });

      // Skip adding data to updatedGraphDatasets if the timeWindow is in the future.
      if (timeWindowAdherence['timeWindow']['endInSeconds'] * 1000 > currDate.getTime()) {
        return;
      }

      timeWindowAdherence['roleHeadcountAdherences']
        .filter((roleAdherence) => {
          const roleProcessPath = roleAdherence['entityAttrs']['process'];
          return (
            selectedProcessPaths.includes(roleProcessPath) &&
            selectedRoles[roleProcessPath].includes(
              chainWalk(() => roleAdherence['entityAttrs']['name'].split('__')[2], roleAdherence['entityAttrs']['name'])
            )
          );
        })
        .forEach((roleAdherence) => {
          const roleName = chainWalk(
            () => roleAdherence['entityAttrs']['name'].split('__')[2],
            roleAdherence['entityAttrs']['name']
          );

          const datasetDatum = {
            time: displayTimeWindowStart,
            value: isBreakInterval ? null : roleAdherence['adherence'],
            isBreak: isBreakInterval
          };

          updatedGraphDatasets
            .find((updatedGraphDataset) => updatedGraphDataset['key'] === roleName)
            ['data'].push(datasetDatum);
        });
    });

    setDisplayTimes(updatedDisplayTimes);
    setGraphDatasets(updatedGraphDatasets);
  }, [selectedProcessPaths, selectedRoles]);

  function createProcessPathBox(processPathName, processPathRoles) {
    return (
      <Column width="fill" type="outline" spacingInset="400" spacing="300" alignmentVertical="justify">
        <Row alignmentHorizontal="justify" alignmentVertical="top">
          <Text type="h200">{processPathName.charAt(0).toUpperCase() + processPathName.slice(1)}</Text>
          <Checkbox
            checked={selectedProcessPaths.includes(processPathName)}
            onChange={(isProcessPathChecked) => {
              if (isProcessPathChecked) {
                setSelectedProcessPaths([...selectedProcessPaths, processPathName]);
              } else {
                setSelectedProcessPaths(selectedProcessPaths.filter((processPath) => processPath !== processPathName));
              }
            }}
          />
        </Row>
        <Row alignmentHorizontal="justify" widths={['50%', '50%']}>
          <Text
            type="d50"
            color={
              chainWalk(
                () =>
                  !!adherenceData['processPathHeadcountAdherences'].find(
                    (processPathAdherence) => processPathAdherence['entityAttrs']['name'] === processPathName
                  )['atRisk'],
                false
              )
                ? 'error'
                : 'primary'
            }
          >
            {chainWalk(
              () =>
                `${Math.round(
                  adherenceData['processPathHeadcountAdherences'].find(
                    (processPathAdherence) => processPathAdherence['entityAttrs']['name'] === processPathName
                  )['adherence']
                )}%`,
              ''
            )}
          </Text>
          <Select
            disabled={!selectedProcessPaths.includes(processPathName)}
            size="small"
            value={selectedRoles[processPathName]}
            onChange={(selectedProcessPathRoles) => {
              const duplicatedSelectedRoles = JSON.parse(JSON.stringify(selectedRoles));
              duplicatedSelectedRoles[processPathName] = Object.keys(PROCESS_PATH_TO_ROLES[processPathName]).filter(
                (processPathRole) => selectedProcessPathRoles.includes(processPathRole)
              );
              setSelectedRoles(duplicatedSelectedRoles);
            }}
            placeholder="Select a role..."
          >
            {Object.entries(processPathRoles).map(([roleName, roleLabel]) => {
              return <SelectOption value={roleName} label={roleLabel} />;
            })}
          </Select>
        </Row>
      </Column>
    );
  }

  return (
    <Column>
      <Row width="100%" widths={['12%', 'fill']} alignmentVertical="stretch">
        <Column
          height="fill"
          type="outline"
          spacingInset="400"
          spacing="300"
          alignmentVertical="justify"
          minWidth={150}
        >
          <Text type="h200">Total</Text>
          <Text
            type="d50"
            color={chainWalk(() => !!adherenceData['overallHeadcountAdherence']['atRisk'], false) ? 'error' : 'primary'}
          >
            {chainWalk(() => `${Math.round(adherenceData['overallHeadcountAdherence']['adherence'])}%`, '')}
          </Text>
        </Column>
        <Row widths="fill" height="100%">
          {Object.entries(PROCESS_PATH_TO_ROLES).map(([processPathName, processPathRoles]) => {
            return createProcessPathBox(processPathName, processPathRoles);
          })}
        </Row>
      </Row>

      <Row>{selectedRoleTags}</Row>

      <LegendProvider
        data={graphDatasets}
        plugins={[victoryLinePlugin({ theme: graphTheme }), victoryTooltipPlugin({ showCrosshair: true })]}
      >
        {({ getLineProps, getScatterProps, voronoiContainerProps }) => (
          <Column spacing="500" alignmentHorizontal="center">
            <VictoryChart
              theme={graphTheme}
              width={window.innerWidth}
              height={250}
              domainPadding={15}
              padding={{ top: 0, right: 15, bottom: 20, left: 55 }}
              containerComponent={
                <VictoryVoronoiContainer
                  labels={({ datum }) =>
                    chainWalk(() => !!datum['isBreak'], false) ? 'Break' : `${Math.round(datum.value)}%`
                  }
                  {...voronoiContainerProps}
                />
              }
            >
              <VictoryAxis tickValues={displayTimes} style={{ tickLabels: { fontSize: 14 } }} />
              <VictoryAxis
                dependentAxis
                tickValues={[20, 40, 60, 80, 100]}
                tickFormat={(t) => `${Math.round(t)}%`}
                domain={[0, 100]}
                style={{ tickLabels: { fontSize: 14 } }}
              />
              <VictoryArea
                data={[
                  { x: 0, y: 100, y0: GRAPH_THRESHOLD },
                  { x: 999, y: 100, y0: GRAPH_THRESHOLD }
                ]}
                style={{ data: { fill: '#edf8cf' } }}
              />
              {graphDatasets
                .filter((graphDataset) => graphDataset['key'] !== 'breaks')
                .map(({ data, key }) => (
                  <VictoryLine key={key} data={data} x="time" y="value" {...getLineProps(key)} />
                ))}
              {graphDatasets.map(({ data, key }) => (
                <VictoryScatter data={data} key={key} x="time" y="value" {...getScatterProps(key)} />
              ))}
            </VictoryChart>
            <Legend direction="horizontal" />
          </Column>
        )}
      </LegendProvider>
    </Column>
  );
};

export default ShiftAdherenceGraph;
