import React, { useState, useEffect } from 'react';

const AutoScroll = ({ containerRef, cadenceInSeconds = 1, scrollIncrement = 1 }) => {
  const [autoScrollInterval, setAutoScrollInterval] = useState(null);

  useEffect(() => {
    startAutoScroll();

    return () => {
      clearInterval(autoScrollInterval);
    };
  }, []);

  const startAutoScroll = () => {
    const interval = setInterval(() => {
      const tableContainer = containerRef.current;
      if (tableContainer) {
        const scrollLeft = tableContainer.scrollLeft;
        const clientWidth = tableContainer.clientWidth;
        const scrollWidth = tableContainer.scrollWidth;

        if (scrollLeft + clientWidth < scrollWidth) {
          tableContainer.scrollLeft += scrollIncrement;
        } else {
          tableContainer.scrollLeft = 0;
        }
      }
    }, cadenceInSeconds * 1000);
    setAutoScrollInterval(interval);
  };

  return null; // This component doesn't render any UI elements
};

export default AutoScroll;
