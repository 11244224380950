import { getRegion } from './helpers';
import { NODE_TYPE } from './constants';

/*
Verify Volume step (Step 1 of Shift Plan) Guardrails
*/

export const NEXT_DAY_PLANNED_VOLUME = {
  type: 'plannedVolume',
  alias: 'PLANNED'
};
export const LAST_DAY_ROLLOVER_VOLUME = {
  type: 'lastDayRolloverVolume',
  alias: 'SIDELINE'
};
export const PLANNED_ROLLOVER_VOLUME = {
  type: 'plannedRolloverVolume',
  alias: 'ROLLOVER'
};

export const VOLUME_GUARDRAILS = {
  NA: {
    PLANNED: {
      MIN_THRESHOLD_PERCENTAGE: 5,
      MAX_THRESHOLD_PERCENTAGE: 5 // additional threshold (+5%)
    },
    SIDELINE: {
      MIN_THRESHOLD_PERCENTAGE: 0,
      MAX_THRESHOLD_PERCENTAGE: 5
    },
    ROLLOVER: {
      MIN_THRESHOLD_PERCENTAGE: 0,
      MAX_THRESHOLD_PERCENTAGE: 5
    }
  },
  EU: {
    PLANNED: {
      MIN_THRESHOLD_PERCENTAGE: 100,
      MAX_THRESHOLD_PERCENTAGE: 25 // additional threshold (+25%)
    },
    SIDELINE: {
      MIN_THRESHOLD_PERCENTAGE: 0,
      MAX_THRESHOLD_PERCENTAGE: 25
    },
    ROLLOVER: {
      MIN_THRESHOLD_PERCENTAGE: 0,
      MAX_THRESHOLD_PERCENTAGE: 25
    }
  },
  DEFAULT: {
    PLANNED: {
      MIN_THRESHOLD_PERCENTAGE: 100,
      MAX_THRESHOLD_PERCENTAGE: 25
    },
    SIDELINE: {
      MIN_THRESHOLD_PERCENTAGE: 0,
      MAX_THRESHOLD_PERCENTAGE: 25
    },
    ROLLOVER: {
      MIN_THRESHOLD_PERCENTAGE: 0,
      MAX_THRESHOLD_PERCENTAGE: 25
    }
  }
};

/*
Verify Attendance step (Step 2 of Shift Plan) Guardrails
*/

export const ATTENDANCE_OVERRIDE_LIMIT = 50;
export const ATTENDANCE_LOWER_LIMIT = 0;
export const ATTENDANCE_UPPER_LIMIT = 100;

/*
Verify Rates step (Step 3 of Shift Plan) Guardrails
*/

export const RATES_GUARDRAILS = {
  NA: {
    RATES_MIN_THRESHOLD: 0.95,
    RATES_MAX_THRESHOLD: 1.05,
    RATES_THRESHOLD_PERCENTAGE: 5
  },
  EU: {
    RATES_MIN_THRESHOLD: 0.75,
    RATES_MAX_THRESHOLD: 1.25,
    RATES_THRESHOLD_PERCENTAGE: 25
  },
  DEFAULT: {
    RATES_MIN_THRESHOLD: 0.75,
    RATES_MAX_THRESHOLD: 1.25,
    RATES_THRESHOLD_PERCENTAGE: 25
  },
  RSR: {
    RATES_MIN_THRESHOLD: 0.6,
    RATES_MAX_THRESHOLD: 1.4,
    RATES_THRESHOLD_PERCENTAGE: 40
  }
};

export const getGuardrails = (guardrails, nodeType) => {
  const region = getRegion().toUpperCase();
  if (nodeType === NODE_TYPE.RSR) {
    return nodeType in guardrails ? guardrails[NODE_TYPE.RSR] : guardrails['DEFAULT'];
  }
  return region in guardrails ? guardrails[region] : guardrails['DEFAULT'];
};

export const getMaxAllowedVolumePercentage = (volumeType, nodeType) => {
  const volumeAlias =
    volumeType === NEXT_DAY_PLANNED_VOLUME.type
      ? NEXT_DAY_PLANNED_VOLUME.alias
      : LAST_DAY_ROLLOVER_VOLUME.type
      ? LAST_DAY_ROLLOVER_VOLUME.alias
      : PLANNED_ROLLOVER_VOLUME.alias;
  return getGuardrails(VOLUME_GUARDRAILS, nodeType)[volumeAlias].MAX_THRESHOLD_PERCENTAGE;
};
