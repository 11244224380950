import React, { useContext, useEffect, useState } from 'react';

import Box from '@amzn/meridian/box';
import Row from '@amzn/meridian/row';
import RadioButton from '@amzn/meridian/radio-button';
import Select, { SelectOption } from '@amzn/meridian/select';
import Text from '@amzn/meridian/text';
import TimeSelect from '@amzn/meridian/time-select';

import { DATE_TIME_FORMAT } from '../utils/constants';
import {
  chainWalk,
  generateDateIncrements,
  parseEpochToDateStringWithOptions,
  parseEpochToHourMinute
} from '../utils/helpers';
import apis from '../utils/apis';
import { GlobalStateContext } from '../global/context/GlobalStateContext';
import { getStationCode } from '../utils/networkUtil';

export const ViewModes = Object.freeze({
  SHIFT: 'Shift',
  TIME_RANGE: 'Time Range'
});

const FIFTEEN_MINUTES_MILLIS = 900000;

const ShiftAdherenceSelector = ({ adherenceData, setAdherenceData, availableShifts, stationTimeZone }) => {
  const { setAsyncError } = useContext(GlobalStateContext);

  const [displayLastUpdated, setDisplayLastUpdated] = useState('');

  const [viewSelector, setViewSelector] = useState(ViewModes.SHIFT);

  const [selectedShift, setSelectedShift] = useState('');

  const [selectedTimeSelectStart, setSelectedTimeSelectStart] = useState(undefined);
  const [timeSelectStartDisplayMap, setTimeSelectStartDisplayMap] = useState({});
  const [selectedTimeSelectEnd, setSelectedTimeSelectEnd] = useState(undefined);
  const [timeSelectEndDisplayMap, setTimeSelectEndDisplayMap] = useState({});

  useEffect(() => {
    if (Object.keys(availableShifts).length === 0 || stationTimeZone === '') {
      return;
    }

    const availableShiftLabels = Object.keys(availableShifts);
    const latestShiftLabel = availableShiftLabels[0];
    setSelectedShift(latestShiftLabel);

    const oldestShiftLabel = availableShiftLabels[availableShiftLabels.length - 1];
    const oldestTime = availableShifts[oldestShiftLabel]['start'];
    const latestTime = availableShifts[latestShiftLabel]['end'];

    // Generate epoch millis values for <TimeSelect />s.
    const startTimeDateRange = generateDateIncrements(
      oldestTime.getTime(),
      latestTime.getTime() - FIFTEEN_MINUTES_MILLIS,
      15 * 60 * 1000
    );
    const endTimeDateRange = generateDateIncrements(
      oldestTime.getTime() + FIFTEEN_MINUTES_MILLIS,
      latestTime.getTime(),
      15 * 60 * 1000
    );

    // Generate maps to maintain date precision in <TimeSelect />.
    const updatedTimeSelectStartDisplayMap = {};
    startTimeDateRange.forEach((timeRange) => {
      updatedTimeSelectStartDisplayMap[parseEpochToHourMinute(timeRange, stationTimeZone)] = timeRange;
    });
    setTimeSelectStartDisplayMap(updatedTimeSelectStartDisplayMap);

    const updatedTimeSelectEndDisplayMap = {};
    endTimeDateRange.forEach((timeRange) => {
      updatedTimeSelectEndDisplayMap[parseEpochToHourMinute(timeRange, stationTimeZone)] = timeRange;
    });
    setTimeSelectEndDisplayMap(updatedTimeSelectEndDisplayMap);

    // First call to Adherence API.
    updateAdherenceData({
      paramViewSelector: ViewModes.SHIFT,
      paramSelectedShift: latestShiftLabel
    });
  }, [availableShifts, stationTimeZone]);

  useEffect(() => {
    const lastUpdatedTime = parseEpochToDateStringWithOptions(
      chainWalk(() => adherenceData['planAttrs']['lastUpdatedAtInSeconds'], ''),
      DATE_TIME_FORMAT
    );
    const lastUpdatedBy = chainWalk(() => adherenceData['planAttrs']['lastUpdatedBy'], '');
    setDisplayLastUpdated(lastUpdatedBy !== '' ? `Last updated: ${lastUpdatedTime} (${lastUpdatedBy})` : '');
  }, [adherenceData]);

  useEffect(() => {
    if (selectedTimeSelectStart === undefined) {
      setSelectedTimeSelectStart(Object.keys(timeSelectStartDisplayMap)[0]);
    }
    if (selectedTimeSelectEnd === undefined) {
      const timeSelectEndDisplayKeys = Object.keys(timeSelectEndDisplayMap);
      setSelectedTimeSelectEnd(timeSelectEndDisplayKeys[timeSelectEndDisplayKeys.length - 1]);
    }
  }, [selectedTimeSelectStart, selectedTimeSelectEnd, timeSelectStartDisplayMap, timeSelectEndDisplayMap]);

  async function updateAdherenceData({
    paramViewSelector,
    paramSelectedShift,
    paramSelectedTimeSelectStart,
    paramSelectedTimeSelectEnd
  }) {
    const inputViewSelector = paramViewSelector === undefined ? viewSelector : paramViewSelector;
    const inputSelectedShift = paramSelectedShift === undefined ? selectedShift : paramSelectedShift;
    const inputSelectedTimeSelectStart =
      paramSelectedTimeSelectStart === undefined ? selectedTimeSelectStart : paramSelectedTimeSelectStart;
    const selectedTimeStartEpochMillis = timeSelectStartDisplayMap[inputSelectedTimeSelectStart];
    const inputParamSelectedTimeSelectEnd =
      paramSelectedTimeSelectEnd === undefined ? selectedTimeSelectEnd : paramSelectedTimeSelectEnd;
    const selectedTimeEndEpochMillis = timeSelectEndDisplayMap[inputParamSelectedTimeSelectEnd];

    let apiTimeRangeStartEpochSeconds = selectedTimeStartEpochMillis / 1000;
    let apiTimeRangeEndEpochSeconds = selectedTimeEndEpochMillis / 1000;

    if (inputViewSelector === ViewModes.SHIFT) {
      apiTimeRangeStartEpochSeconds = availableShifts[inputSelectedShift]['start'].getTime() / 1000;
      apiTimeRangeEndEpochSeconds = availableShifts[inputSelectedShift]['end'].getTime() / 1000;
    }

    setAdherenceData({});
    const updatedAdherenceData = await apis['GET_ADHERENCE'](
      {
        query: {
          nodeId: getStationCode(),
          timeRangeStartInSeconds: apiTimeRangeStartEpochSeconds,
          timeRangeEndInSeconds: apiTimeRangeEndEpochSeconds
        }
      },
      setAsyncError
    );
    setAdherenceData(updatedAdherenceData);
  }

  return (
    <Box type="fill" spacingInset="300">
      <Row alignmentHorizontal="justify" spacingInset="200 300 200 300">
        <Row spacing="500">
          <Row spacing="300">
            <RadioButton
              checked={viewSelector === ViewModes.SHIFT}
              onChange={(value) => {
                setViewSelector(value);
                updateAdherenceData({ paramViewSelector: value });
              }}
              value={ViewModes.SHIFT}
            >
              <Text type="b200">{ViewModes.SHIFT}</Text>
            </RadioButton>
            <Select
              value={selectedShift}
              onChange={(value) => {
                setSelectedShift(value);
                updateAdherenceData({ paramSelectedShift: value });
              }}
              minWidth={200}
              size="small"
              disabled={viewSelector !== ViewModes.SHIFT}
            >
              {Object.entries(availableShifts).map(([shiftLabel, _]) => (
                <SelectOption key={shiftLabel} value={shiftLabel} label={shiftLabel} />
              ))}
            </Select>
          </Row>

          <Row spacing="300">
            <RadioButton
              checked={viewSelector === ViewModes.TIME_RANGE}
              onChange={(value) => {
                setViewSelector(value);
                updateAdherenceData({ paramViewSelector: value });
              }}
              value={ViewModes.TIME_RANGE}
            >
              <Text type="b200">{ViewModes.TIME_RANGE}</Text>
            </RadioButton>
            <TimeSelect
              size="small"
              width={120}
              placeholder="--:--"
              format={{ hourCycle: 'h23', hour: '2-digit', minute: '2-digit' }}
              options={Object.keys(timeSelectStartDisplayMap)}
              value={selectedTimeSelectStart}
              onChange={(value) => {
                setSelectedTimeSelectStart(value);
                updateAdherenceData({ paramSelectedTimeSelectStart: value });
              }}
              disabled={viewSelector !== ViewModes.TIME_RANGE}
              disabledOptions={(option) => {
                const optionMillis = timeSelectStartDisplayMap[option];
                const selectedEndMillis = timeSelectEndDisplayMap[selectedTimeSelectEnd];

                return optionMillis >= selectedEndMillis;
              }}
            />
            <Text>-</Text>
            <TimeSelect
              size="small"
              width={120}
              placeholder="--:--"
              format={{ hourCycle: 'h23', hour: '2-digit', minute: '2-digit' }}
              options={Object.keys(timeSelectEndDisplayMap)}
              value={selectedTimeSelectEnd}
              onChange={(value) => {
                setSelectedTimeSelectEnd(value);
                updateAdherenceData({ paramSelectedTimeSelectEnd: value });
              }}
              disabled={viewSelector !== ViewModes.TIME_RANGE}
              disabledOptions={(option) => {
                const optionMillis = timeSelectEndDisplayMap[option];
                const selectedStartMillis = timeSelectStartDisplayMap[selectedTimeSelectStart];

                return optionMillis <= selectedStartMillis;
              }}
            />
          </Row>
        </Row>
        <Text type="b200">{displayLastUpdated}</Text>
      </Row>
    </Box>
  );
};

export default ShiftAdherenceSelector;
