import React from 'react';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import Icon from '@amzn/meridian/icon';
import Tooltip from '@amzn/meridian/tooltip';
import tooltipIcon from '@amzn/meridian-tokens/base/icon/info-knockout';

import { getTooltip, TEXTS } from './constants';
import { isEmpty, isValid } from './helpers';

const AddTooltip = ({ children: val, size, additionalText = '' }) => {
  if (isEmpty(val)) {
    return null;
  }
  const tooltipText = getTooltip[val];
  if (!isValid(tooltipText)) {
    return <Text type={size ? size : TEXTS.T2}>{val}</Text>;
  }

  let tooltipTitle = [tooltipText, ...additionalText];

  return (
    <Row alignmentHorizontal="left" widths="fit" spacing="300">
      <Text type={size ? size : TEXTS.T2}>{val}</Text>
      <Tooltip position="right" title={tooltipTitle}>
        <Icon tokens={tooltipIcon} />
      </Tooltip>
    </Row>
  );
};

export default AddTooltip;
