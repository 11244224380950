import React, { useMemo, useState } from 'react';

import Box from '@amzn/meridian/box';
import Column from '@amzn/meridian/column';
import Table, { TableActionBar, TableCell, TableRow } from '@amzn/meridian/table';
import Text from '@amzn/meridian/text';
import Row from '@amzn/meridian/row';
import { displayHc, displayThrowRate, displayTime, getBreaks, isBreakQuarter } from '../../utils';
import { chainWalk } from '../../../../utils/helpers';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import chevronDownSmallTokens from '@amzn/meridian-tokens/base/icon/chevron-down-small';
import chevronRightSmallTokens from '@amzn/meridian-tokens/base/icon/chevron-right-small';

export const SINGLES_ROLES = ['singlespick', 'singlespack'];

const shouldDisplayRoleRate = (department, role) => {
  return department.name !== 'injection' && role.category.toLowerCase() === 'demand';
};

const displayAvgExpectedRate = (department, breaks, role, quarter, depShiftAvgRate) => {
  if (isBreakQuarter(quarter, breaks)) {
    return 0;
  }
  let avgExpectedRate = 0;
  if (depShiftAvgRate) {
    depShiftAvgRate.forEach((sAvgRate) => {
      const rateData = sAvgRate.find(
        (r) =>
          r.process_path === department.name &&
          r.process_role === role.role.name &&
          quarter >= r.start_datetime_local &&
          quarter <= r.end_datetime_local
      );
      if (rateData) {
        avgExpectedRate = rateData.avg_expected_rate_per_hour;
      }
    });
  }
  return avgExpectedRate;
};

const populateRoleHcRate = (department, allRoleHc, roleHcRate) => {
  allRoleHc.forEach((roleHc) => {
    const prevHc = roleHcRate.find((r) => r.process_path === department.name && r.process_role === roleHc.process_role);

    if (prevHc) {
      prevHc.total_expected_rate_per_hour =
        parseFloat(roleHc ? roleHc.expected_rate_per_hour : 0) +
        parseFloat(prevHc ? prevHc.total_expected_rate_per_hour : 0);
      prevHc.end_datetime_local = roleHc.end_datetime_local;
    } else {
      const data = {
        process_path: department.name,
        process_role: roleHc.process_role,
        start_datetime_local: roleHc.start_datetime_local,
        end_datetime_local: roleHc.end_datetime_local,
        total_expected_rate_per_hour: parseFloat(roleHc ? roleHc.expected_rate_per_hour : 0)
      };
      roleHcRate.push(data);
    }
  });
};

const TimeWindowHeader = ({ quarters, index, quarter, shift, timeline }) => {
  const text =
    index === quarters.length - 1
      ? `${displayTime(quarter)} - ${displayTime(shift.end)}`
      : `${displayTime(quarter)} - ${displayTime(quarters[index + 1])}`;
  return (
    <Column>
      <Text type="h200">{isBreakQuarter(quarter, getBreaks(timeline)) ? 'Break' : ''}&nbsp;</Text>
      <Text type="h200">{text}</Text>
    </Column>
  );
};

const DepartmentTable = ({ roles, headcountAllocation, department, shift, timeline, singlesEnabled, allQuarters }) => {
  const breaks = getBreaks(timeline);
  const getDepartmentAvgExpectedRate = (department, breaks, allShiftRec) => {
    const quarters = department.quarters;
    let idx = 0;
    let shiftRecParts = [];
    let shiftRecRate = [];
    let roleHcRate = [];
    allQuarters.forEach((quarter, index) => {
      const allRoleHc = allShiftRec.filter(
        (r) => r.process_path === department.name && r.start_datetime_local === quarter
      );
      if (isBreakQuarter(quarter, breaks)) {
        if (idx !== 0) {
          shiftRecParts.push(idx);
          shiftRecRate.push(roleHcRate);
        }

        idx = 0;
        roleHcRate = [];
      } else {
        populateRoleHcRate(department, allRoleHc, roleHcRate);
        idx++;
      }
    });
    if (idx !== 0) {
      shiftRecParts.push(idx);
      shiftRecRate.push(roleHcRate);
    }

    shiftRecRate.forEach((recRate, index) => {
      const quarters = shiftRecParts[index];
      recRate.forEach((r) => {
        r['parts'] = quarters;
        r['avg_expected_rate_per_hour'] = parseFloat(r.total_expected_rate_per_hour) / quarters;
      });
    });

    return shiftRecRate;
  };

  const allShiftRecords = headcountAllocation.filter((rec) => {
    return rec.start_datetime_local >= shift.start && rec.start_datetime_local < shift.end;
  });

  const avgExpectedRate = useMemo(() => {
    return getDepartmentAvgExpectedRate(department, breaks, allShiftRecords);
  }, [department, breaks, allShiftRecords]);

  const roleList = roles.filter(
    (r) => r.role.departmentName === department.name && (singlesEnabled || SINGLES_ROLES.indexOf(r.role.name) < 0)
  );
  const [expandedRows, setExpandedRows] = useState([]);
  const updateExpandedRows = (row) => {
    const newExpandedRows = expandedRows.includes(row)
      ? expandedRows.filter((expandedRow) => expandedRow !== row)
      : [...expandedRows, row];
    setExpandedRows(newExpandedRows);
  };
  const roleRows = [];
  roleList.forEach((role) => {
    const rowIdentifier = role.role.name;
    roleRows.push(
      <TableRow key={`${role.role.name}-headcount`}>
        <TableCell width={50}>
          <Row>
            {shouldDisplayRoleRate(department, role) ? (
              <Button type="icon" size="small" onClick={() => updateExpandedRows(rowIdentifier)}>
                <Icon
                  tokens={expandedRows.includes(rowIdentifier) ? chevronDownSmallTokens : chevronRightSmallTokens}
                />
              </Button>
            ) : (
              <div />
            )}
          </Row>
        </TableCell>
        <TableCell>
          <Text type={'h100'}>{role.role.label}</Text>
        </TableCell>
        {department.quarters.map((quarter) => {
          const roleHc = headcountAllocation.find(
            (r) =>
              r.process_path === department.name &&
              r.process_role === role.role.name &&
              r.start_datetime_local === quarter
          );
          return (
            <TableCell key={`${role.role.name}-headcount-${quarter}`}>
              {displayHc(roleHc)}
              {displayThrowRate(roleHc)}
            </TableCell>
          );
        })}
      </TableRow>
    );
    if (expandedRows.includes(rowIdentifier) && shouldDisplayRoleRate(department, role)) {
      roleRows.push(
        <TableRow key={`${role.role.name}-rate`}>
          <TableCell />
          <TableCell>Expected Rate</TableCell>
          {department.quarters.map((quarter) => {
            return (
              <TableCell key={`${role.role.name}-rate-${quarter}`}>
                {chainWalk(
                  () => displayAvgExpectedRate(department, breaks, role, quarter, avgExpectedRate).toFixed(0),
                  ''
                )}
              </TableCell>
            );
          })}
        </TableRow>
      );
    }
  });

  return (
    <div className="horizontal-scroll" style={{ backgroundColor: '#fff' }}>
      <Table headerColumns={1} headerRows={1} showDividers={true} showStripes={false} spacing="small">
        <TableRow highlightOnHover={true}>
          <TableCell />
          <TableCell />
          {department['quarters'].map((val, index) => (
            <TableCell key={index}>
              <TimeWindowHeader
                timeline={timeline}
                quarters={department.quarters}
                shift={shift}
                index={index}
                quarter={val}
              />
            </TableCell>
          ))}
        </TableRow>
        <TableRow backgroundColor="secondary">
          <TableCell columnSpan={2}>
            <Text>{department.label} Totals</Text>
          </TableCell>
          {department.quarters.map((quarter) => {
            const departmentHc = roleList
              .map((role) =>
                chainWalk(
                  () =>
                    headcountAllocation.find(
                      (r) =>
                        r.process_path === department.name &&
                        r.process_role === role.role.name &&
                        r.start_datetime_local === quarter
                    ).total_hc,
                  0
                )
              )
              .reduce((departmentHc, roleHc) => departmentHc + roleHc, 0);
            return <TableCell key={`${department.name}-${quarter}`}>{departmentHc}</TableCell>;
          })}
        </TableRow>
        {roleRows}
      </Table>
    </div>
  );
};

const ShiftHeadcountRecommendationTable = ({
  shift,
  departments,
  roles,
  headcountAllocation,
  timeline,
  singlesEnabled,
  allQuarters
}) => {
  return (
    <Box type={'outline'}>
      <Column spacing="400" width="100%">
        <TableActionBar alignmentHorizontal="justify">
          <Text type="h300">Recommendation</Text>
        </TableActionBar>
        {departments.map((department) => (
          <DepartmentTable
            key={department.name}
            roles={roles}
            timeline={timeline}
            headcountAllocation={headcountAllocation}
            department={department}
            shift={shift}
            singlesEnabled={singlesEnabled}
            allQuarters={allQuarters}
          />
        ))}
      </Column>
    </Box>
  );
};

export default ShiftHeadcountRecommendationTable;
