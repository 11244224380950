import React, { useCallback, useMemo, useState } from 'react';
import Text from '@amzn/meridian/text';
import Row from '@amzn/meridian/row';
import { cx } from 'emotion';
import { greyPath, boldPath } from './styled';

export const PathCell = ({ paths, highlight, disallowed = [] }) => {
  return (
    <Row spacing="200" wrap="down">
      {paths.map((path, idx, arr) => {
        return (
          <Text
            key={idx}
            className={cx({ [boldPath]: highlight.includes(path), [greyPath]: disallowed.includes(path) })}
          >
            {path}
            {idx < arr.length - 1 && ','}
          </Text>
        );
      })}
    </Row>
  );
};
