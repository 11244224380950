import React, { useContext, useEffect, useState, useMemo } from 'react';
import ShiftRotationTable from './ShiftRotationTable';
import Column from '@amzn/meridian/column';
import { getAvailableDates, getDomain, parseEpochToDateStringWithOptions } from '../utils/helpers';
import { getStationCode } from '../utils/networkUtil';
import apis from '../utils/apis';
import { CALL_API_TIMER, DATE_TIME_FORMAT, PLAN_STATUS, PLAN_VERSIONS, PLANS, TEXTS } from '../utils/constants';
import LoadingView from '../handler/loadingHandler/LoadingView';
import { GlobalStateContext } from '../global/context/GlobalStateContext';
import Text from '@amzn/meridian/text';
import CustomAlert from '../commonComponents/CustomAlert';
import Box from '@amzn/meridian/box';
import Select, { SelectOption } from '@amzn/meridian/select';
import Row from '@amzn/meridian/row';
import DatePicker from '@amzn/meridian/date-picker';
import Button from '@amzn/meridian/button';
import { ALLOCATION_ALLOWED_STATION_CODES } from '../shiftAllocation/data';

const NUM_DAYS_AVAILABLE_PAST = 7;
const NUM_DAYS_AVAILABLE_FUTURE = 1;
const availableDates = getAvailableDates(NUM_DAYS_AVAILABLE_PAST, NUM_DAYS_AVAILABLE_FUTURE);

function ShiftRotation(props) {
  const { date, setDate, setAsyncError } = useContext(GlobalStateContext);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState('');
  const [isError, setIsError] = useState(false);
  const [selectedCycle, setSelectedCycle] = useState();

  useEffect(() => {
    getRotationPlan(date);
  }, [date]);

  const displayShiftRotationError = () => {
    setIsError(true);
    setIsLoading(false);
  };

  function onClickShiftAllocation() {
    props.history.push('/shift-allocation');
  }

  const getRotationPlan = async (date) => {
    setIsLoading(true);
    setIsError(false);
    setData(null);
    let allPlans = await apis['GET_ALL_PLANS'](
      {
        query: {
          date,
          nodeId: getStationCode()
        }
      },
      displayShiftRotationError
    );
    allPlans = (allPlans['shiftPlans'] || [])
      .filter((plan) => plan.planType === PLANS.PRE_SHIFT && plan.planVersion === PLAN_VERSIONS.LABOR_ALLOCATION_V1)
      .sort((a, b) => b.createdAt - a.createdAt);
    const approvedPlan = allPlans.filter((plan) => plan.status === PLAN_STATUS.APPROVED)[0];
    const pendingApprovalPlan = allPlans.filter((plan) => plan.status === PLAN_STATUS.PENDING_APPROVAL)[0];
    const currPlan = approvedPlan ? approvedPlan : pendingApprovalPlan;
    if (currPlan) {
      const res = await apis['GET_PLAN'](
        {
          query: {
            planId: currPlan.planId
          }
        },
        displayShiftRotationError
      );
      if (res && res.planId) {
        setData(res);
      }
    }
    setIsLoading(false);
  };

  const createRotationPlan = async (date) => {
    setLoadingMsg('Running shift rotation plan, this can take up to 5 minutes to run. Please wait and do not refresh.');
    setIsLoading(true);
    setIsError(false);
    setData(null);
    let newPlan = await apis['CREATE_PLAN'](
      {
        body: {
          nodeId: getStationCode(),
          ofdDate: date,
          planType: PLANS.PRE_SHIFT,
          planVersion: PLAN_VERSIONS.LABOR_ALLOCATION_V1
        }
      },
      displayShiftRotationError
    );
    let timeout = 10;
    let timer = setInterval(async () => {
      timeout--;
      let res = await apis['GET_PLAN'](
        {
          query: {
            planId: newPlan.planId
          }
        },
        displayShiftRotationError
      );
      if (res.status === PLAN_STATUS.PENDING_APPROVAL) {
        clearInterval(timer);
        setData(res);

        setIsLoading(false);
      } else if (res.status === PLAN_STATUS.FAILED) {
        var title =
          res.planId && res.errorMessage && res.errorCode
            ? `Error creating shift plan ${res.planId} \ Error Message: ${res.errorMessage} \ Error Code: ${res.errorCode}`
            : 'Error Message: Failed to create shift plan Error Code: JOB_ROTATION_EXCEPTION';
        setAsyncError(title);
      } else if (timeout <= 0) {
        clearInterval(timer);
        setAsyncError('Shift plan generation timed out. Please try again.');
        setIsLoading(false);
      }
    }, CALL_API_TIMER);
  };

  const formattedLastUpdatedAt = useMemo(() => {
    if (!data || !data.updatedAt) return '';
    return parseEpochToDateStringWithOptions(data.updatedAt, DATE_TIME_FORMAT);
  }, [data]);

  const availableCycles = useMemo(() => {
    if (!data || !data.output || !data.output.shiftPlan) return [];
    else {
      var allCyclesSet = new Set();
      data.output.cycleResourceAllocationOutputs.forEach(function (cycleResourceAllocationOutput) {
        if (cycleResourceAllocationOutput['cycleName'] !== undefined) {
          allCyclesSet.add(cycleResourceAllocationOutput['cycleName']);
        }
      });
      if (allCyclesSet.has('CYCLE_1')) setSelectedCycle('CYCLE_1');
      return Array.from(allCyclesSet);
    }
  }, [data]);

  if (isLoading) return <LoadingView loadingMsg={loadingMsg} />;

  return (
    <Column spacingInset="400">
      <Row alignmentHorizontal={'justify'}>
        <Text type={TEXTS.H1}>Shift Rotation</Text>
        {/* TODO: hiding this for UAT */}
        {/* gamma.us-east-1.m7AllowList = ("DAU5", "DWA6", "DLX5", "DLI5");
prod.us-east-1.m7AllowList = ("DAU5", "DWA6", "DLX5"); */}
        {(getDomain() !== 'prod' || ALLOCATION_ALLOWED_STATION_CODES.includes(getStationCode()))  && (
          <Button size="large" type="link" onClick={onClickShiftAllocation}>
            VLAB
          </Button>
        )}
      </Row>

      <Text type={TEXTS.T1} color="secondary">
        This table provides an overview of upcoming shift rotations and appropriate paths for each associate for all
        cycles
      </Text>
      <Box type="fill" spacingInset="400" width="100%">
        <Row>
          <Column width={200}>
            <Text type={TEXTS.T1}>Out For Delivery Date</Text>
            <DatePicker
              value={date}
              onChange={setDate}
              monthsInView={1}
              size="medium"
              disabledDates={(d) => !availableDates.includes(d)}
            />
          </Column>
          <Column width={200}>
            <Text type={TEXTS.T1}>Cycle</Text>
            <Select value={selectedCycle} onChange={setSelectedCycle} placeholder="Select cycle">
              {availableCycles.map((availableCycle) => (
                <SelectOption key={availableCycle} value={availableCycle} label={availableCycle} />
              ))}
            </Select>
          </Column>
        </Row>
      </Box>
      {!isError && !data && (
        <CustomAlert
          title="No Shift Rotation created. Run shift rotation to find suggested associate paths."
          buttonTitle="Run Shift Rotation"
          buttonClickHandle={() => createRotationPlan(date)}
        />
      )}
      {!isError && !!data && (
        <React.Fragment>
          <CustomAlert
            title="Shift rotation has been successfully run. To update the suggested associate paths, re-run Shift Rotation"
            buttonTitle="Re-run Shift Rotation"
            buttonClickHandle={() => createRotationPlan(date)}
          />
          <Text type="b200">Data last updated: {formattedLastUpdatedAt}</Text>
          <ShiftRotationTable
            data={
              data.input && data.input['resourceProcessPathAttributeListInput']
                ? data.input['resourceProcessPathAttributeListInput']
                : []
            }
            recommendations={
              data.output &&
              data.output['resourceAllocationOutput'] &&
              data.output['resourceAllocationOutput']['resourceRecommendedProcesses']
                ? data.output['resourceAllocationOutput']['resourceRecommendedProcesses']
                : []
            }
            cycleResourceAllocationOutputs={
              data.output && data.output.cycleResourceAllocationOutputs
                ? data.output.cycleResourceAllocationOutputs
                : []
            }
            selectedCycle={selectedCycle}
          />
        </React.Fragment>
      )}
      {isError && (
        <CustomAlert
          title="A pre-shift plan has not been run for the station at this date, please run a pre-shift plan before running labor allocation."
          disabled="true"
        />
      )}
    </Column>
  );
}
export default ShiftRotation;
