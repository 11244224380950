import React from 'react';
import MetricView from './MetricView';
import { SSD_TIMELINE_KEY } from '../../../../utils/constants';
import { displayTime, getFcCode, getShiftTimeline } from '../../utils';
import Heading from '@amzn/meridian/heading';
import Text from '@amzn/meridian/text';
import Link from '@amzn/meridian/link';
import Row from '@amzn/meridian/row';

const getRiskValueForKey = (scienceMetrics, cpt, key) => {
  const cptDate = cpt.date_time_local.split('T')[0];
  const cptMetric = Array.isArray(scienceMetrics)
    ? scienceMetrics.find(
        (m) =>
          m.metric.includes('total_volume_risk') &&
          m.metric.includes(cptDate) &&
          m.metric.includes(cpt.label.toLowerCase()) &&
          m.metric.includes(key)
      )
    : null;
  return cptMetric ? cptMetric.value : null;
};

const ShiftRisk = ({ scienceMetrics, timeline, shift, shifts }) => {
  const shiftTimeline = getShiftTimeline(shift, timeline);

  const currentShiftCpts = shiftTimeline.filter(({ key }) => key === SSD_TIMELINE_KEY.CPT);
  const nextShift = [...shifts]
    .sort((s1, s2) => s1.start.localeCompare(s2.start))
    .find((otherShift) => otherShift.start >= shift.end);
  const nextShiftTimeline = getShiftTimeline(nextShift, timeline);
  const nextShiftFirstCpt = nextShiftTimeline.find(({ key }) => key === SSD_TIMELINE_KEY.CPT);
  const cpts =
    currentShiftCpts.length > 0
      ? currentShiftCpts
      : currentShiftCpts.length === 0 && !!nextShiftFirstCpt
      ? [nextShiftFirstCpt]
      : [];
  const cptRiskPercentage = cpts
    .map((cpt) => ({ cpt, percentage: getRiskValueForKey(scienceMetrics, cpt, 'percent') }))
    .filter(({ percentage }) => percentage != null);
  return (
    <MetricView
      headerComponent={
        <Row width="100%" alignmentHorizontal="justify">
          <Heading level={2} type="h300">
            <Text>Plan Utilization</Text>
          </Heading>
          <Link
            href={`https://outboundflow-iad.amazon.com/${getFcCode()}/cora`}
            target="_blank"
            rel="noopener noreferrer"
          >
            CORA
          </Link>
        </Row>
      }
      values={cptRiskPercentage.map(({ cpt, percentage }) => {
        return {
          title: 'CPT ' + displayTime(cpt.date_time_local),
          subtext: `${percentage}%`,
          tooltipValues: [
            `Total volume: ${
              getRiskValueForKey(scienceMetrics, cpt, 'backlog_units') +
              getRiskValueForKey(scienceMetrics, cpt, 'pendingcharge_units')
            }`,
            `Total planned throughput: ${
              getRiskValueForKey(scienceMetrics, cpt, 'plannedthroughput_units') -
              getRiskValueForKey(scienceMetrics, cpt, 'plannedthroughput_removed')
            }`
          ]
        };
      })}
    />
  );
};

export default ShiftRisk;
