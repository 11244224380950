import React from 'react';
import MetricView from './MetricView';
import ShiftHeadcountExpanded from './ShiftHeadcountExpanded';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import Heading from '@amzn/meridian/heading';

const PROCESS_PATH_IDLE = 'idle';

const ShiftHeadcount = ({ shift, headcountAllocation, headcountAllocationPivot }) => {
  const idleHC = shift
    ? headcountAllocation.find(
        ({ start_datetime_local, process_path }) =>
          start_datetime_local >= shift.start && start_datetime_local < shift.end && process_path === PROCESS_PATH_IDLE
      )
    : false;
  return (
    <MetricView
      headerComponent={
        <Row width="100%" alignmentHorizontal="justify">
          <Heading level={2} type="h300">
            <Text>Headcount</Text>
          </Heading>
          <ShiftHeadcountExpanded shift={shift} headcountAllocationPivot={headcountAllocationPivot} />
        </Row>
      }
      values={[
        { title: 'Total', subtext: idleHC && idleHC.available_headcount },
        { title: 'Excess HC', subtext: idleHC && idleHC.total_hc }
      ]}
    />
  );
};

export default ShiftHeadcount;
