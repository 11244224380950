import React, { useCallback, useRef, useState } from 'react';
import Table, { TableRow, TableCell } from '@amzn/meridian/table';
import Popover from '@amzn/meridian/popover';
import Text from '@amzn/meridian/text';

import { convertToFixedDP, extractMainWorkGroup, parseTimeStampToDateString } from '../../../utils/helpers';

const ShiftTableMetricCell = ({ metricDisplay = null, metricValues, metricTexts }) => {
  const minimumMetricValue = Math.min(...metricValues);
  const maximumMetricValue = Math.max(...metricValues);

  const cellMetricText = metricDisplay
    ? metricDisplay
    : minimumMetricValue === maximumMetricValue
    ? maximumMetricValue
    : `${minimumMetricValue} - ${maximumMetricValue}`;

  const [isMetricPopoverOpen, setIsMetricPopoverOpen] = useState(false);
  const metricPopoverRef = useRef();

  return (
    <TableCell alignmentHorizontal="right">
      <span
        onMouseEnter={useCallback(() => setIsMetricPopoverOpen(minimumMetricValue !== maximumMetricValue && true), [])}
        onMouseLeave={useCallback(() => setIsMetricPopoverOpen(false), [])}
      >
        <Text className={minimumMetricValue === maximumMetricValue ? '' : 'menu-text-color'} ref={metricPopoverRef}>
          {cellMetricText}
        </Text>
      </span>
      <Popover anchorNode={metricPopoverRef.current} open={isMetricPopoverOpen} position="right" spacingInset="medium">
        {metricTexts.map((metricText) => (
          <Text>{metricText}</Text>
        ))}
      </Popover>
    </TableCell>
  );
};

const ShiftTable = ({ shiftGroupAvailabilities, isDay1NewHireLaborEnabled }) => {
  return (
    <div>
      <Table headerRows={1} showDividers={true} showStripes={false} spacing="small">
        <TableRow>
          <TableCell>Time</TableCell>
          <TableCell>Work Group</TableCell>
          <TableCell alignmentHorizontal="right">Attendance %</TableCell>
          <TableCell alignmentHorizontal="right">Expected Headcount</TableCell>
          <TableCell alignmentHorizontal="right">Expected Show Hours</TableCell>
          {isDay1NewHireLaborEnabled && <TableCell alignmentHorizontal="right">Day 1 New Hires</TableCell>}
        </TableRow>
        {shiftGroupAvailabilities
          .filter((shiftGroupAvailability) => {
            return (
              shiftGroupAvailability.isUnmatchedAssociate === undefined ||
              shiftGroupAvailability.isUnmatchedAssociate === false
            );
          })
          .sort((a, b) => new Date(a.applicability.timeWindow.start) - new Date(b.applicability.timeWindow.start))
          .map((val, index) => {
            if (
              val.cycleActivityLabourAvailabilityHeadCount === undefined ||
              Object.keys(val.cycleActivityLabourAvailabilityHeadCount).length === 0
            ) {
              return (
                <TableRow highlightOnHover={true} key={index}>
                  <TableCell>{`${parseTimeStampToDateString(
                    val.applicability.timeWindow.start
                  )} - ${parseTimeStampToDateString(val.applicability.timeWindow.end)}`}</TableCell>
                  <TableCell>{extractMainWorkGroup(val.workGroups)}</TableCell>
                  <TableCell alignmentHorizontal="right">
                    {convertToFixedDP(val.labourAvailabilityHeadCount.attendancePercentage * 100)}
                  </TableCell>
                  <TableCell alignmentHorizontal="right">
                    {convertToFixedDP(val.labourAvailabilityHeadCount.expectedHeadcount)}
                  </TableCell>
                  <TableCell alignmentHorizontal="right">
                    {convertToFixedDP(val.labourAvailabilityHeadCount.expectedShowHours)}
                  </TableCell>
                  {isDay1NewHireLaborEnabled && (
                    <TableCell alignmentHorizontal="right">
                      {convertToFixedDP(val.labourAvailabilityHeadCount.day1NewHireHeadcount)}
                    </TableCell>
                  )}
                </TableRow>
              );
            }

            const cycleActivityAttendancePercentages = [];
            const cycleActivityExpectedHeadcounts = [];
            const cycleActivityExpectedShowHours = [];

            const cycleActivityAttendancePercentagesText = [];
            const cycleActivityExpectedHeadcountsText = [];
            const cycleActivityExpectedShowHoursText = [];

            for (const [cycle, activityLabourAvailabilityHeadCount] of Object.entries(
              val.cycleActivityLabourAvailabilityHeadCount
            )) {
              // Display "sort" then "pick_stage".
              const activities = Object.keys(activityLabourAvailabilityHeadCount).sort().reverse();
              for (const activity of activities) {
                const labourAvailabilityHeadCount = activityLabourAvailabilityHeadCount[activity];
                const displayActivityName = activity
                  .split('_')
                  .map((activityName) => activityName.charAt(0).toUpperCase() + activityName.slice(1))
                  .join(' ');

                const cycleActivityAttendancePercentage = convertToFixedDP(
                  labourAvailabilityHeadCount.attendancePercentage * 100
                );
                cycleActivityAttendancePercentages.push(cycleActivityAttendancePercentage);
                cycleActivityAttendancePercentagesText.push(
                  `${cycle} ${displayActivityName} - ${cycleActivityAttendancePercentage}`
                );

                const cycleActivityExpectedHeadcount = convertToFixedDP(labourAvailabilityHeadCount.expectedHeadcount);
                cycleActivityExpectedHeadcounts.push(cycleActivityExpectedHeadcount);
                cycleActivityExpectedHeadcountsText.push(
                  `${cycle} ${displayActivityName} - ${cycleActivityExpectedHeadcount}`
                );

                const cycleActivityExpectedShowHour = convertToFixedDP(labourAvailabilityHeadCount.expectedShowHours);
                cycleActivityExpectedShowHours.push(cycleActivityExpectedShowHour);
                cycleActivityExpectedShowHoursText.push(
                  `${cycle} ${displayActivityName} - ${cycleActivityExpectedShowHour}`
                );
              }
            }

            const attendancePercentageCell = (
              <ShiftTableMetricCell
                metricValues={cycleActivityAttendancePercentages}
                metricTexts={cycleActivityAttendancePercentagesText}
              />
            );
            const expectedHeadcountCell = (
              <ShiftTableMetricCell
                metricValues={cycleActivityExpectedHeadcounts}
                metricTexts={cycleActivityExpectedHeadcountsText}
              />
            );
            const expectedShowHoursCell = (
              <ShiftTableMetricCell
                metricDisplay={Math.max(...cycleActivityExpectedShowHours)}
                metricValues={cycleActivityExpectedShowHours}
                metricTexts={cycleActivityExpectedShowHoursText}
              />
            );

            return (
              <TableRow highlightOnHover={true} key={index}>
                <TableCell>{`${parseTimeStampToDateString(
                  val.applicability.timeWindow.start
                )} - ${parseTimeStampToDateString(val.applicability.timeWindow.end)}`}</TableCell>
                <TableCell>{extractMainWorkGroup(val.workGroups)}</TableCell>
                {attendancePercentageCell}
                {expectedHeadcountCell}
                {expectedShowHoursCell}
                {isDay1NewHireLaborEnabled && (
                  <TableCell alignmentHorizontal="right">
                    {convertToFixedDP(val.labourAvailabilityHeadCount.day1NewHireHeadcount)}
                  </TableCell>
                )}
              </TableRow>
            );
          })}
      </Table>
    </div>
  );
};

export default ShiftTable;
