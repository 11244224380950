import { SHIFT_PLAN_SUGGESTED_INPUT } from './constants';
import { convertToFixedDP, sortList } from './helpers';

export const enrichResourceProductivityInput = (data) => {
  return data.map((item) => {
    const pph = {};
    for (const [key, value] of Object.entries(item.pph)) {
      pph[key] = {
        ...value,
        historicPph: convertToFixedDP(item.pph[key].historicPph),
        plannedPph: convertToFixedDP(item.pph[key].plannedPph),
        targetPph: convertToFixedDP(item.pph[key].targetPph)
      };
    }
    return {
      ...item,
      pph
    };
  });
};

export const enrichMultiCycleResourceProductivityInput = (data) => {
  if (data) {
    Object.keys(data)
      .sort()
      .forEach((key) => {
        const cycle = data[key];
        if (cycle && cycle.pph) {
          Object.keys(cycle.pph).forEach((type) => {
            cycle.pph[type].historicPph = convertToFixedDP(cycle.pph[type].historicPph);
            cycle.pph[type].plannedPph = convertToFixedDP(cycle.pph[type].plannedPph);
            cycle.pph[type].targetPph = convertToFixedDP(cycle.pph[type].targetPph);
          });
        }
      });
  }
  return data;
};

export const enrichShiftGroupAvailabilities = (data) => {
  return data.map((item) => ({
    ...item,
    compatibleProcesses: sortList(item.compatibleProcesses),
    employeeIds: sortList(item.employeeIds),
    workGroups: sortList(item.workGroups),
    labourAvailabilityHeadCount: {
      ...item.labourAvailabilityHeadCount,
      attendancePercentage: convertToFixedDP(item.labourAvailabilityHeadCount.attendancePercentage, 2),
      expectedHeadcount: convertToFixedDP(item.labourAvailabilityHeadCount.expectedHeadcount, 0),
      paidBreaks: convertToFixedDP(item.labourAvailabilityHeadCount.paidBreaks),
      rosteredHeadcount: convertToFixedDP(item.labourAvailabilityHeadCount.rosteredHeadcount),
      unpaidBreaks: convertToFixedDP(item.labourAvailabilityHeadCount.unpaidBreaks),
      expectedShowHours: convertToFixedDP(item.labourAvailabilityHeadCount.expectedShowHours, 2),
      expectedProductiveHours: convertToFixedDP(item.labourAvailabilityHeadCount.expectedProductiveHours, 2)
    }
  }));
};

// Accepts shift plan objects in string format
export const areSameShiftPlanObjects = (shiftPlan1, shiftPlan2, ignoreShiftPlanSuggestedInput) => {
  return (
    JSON.stringify(enrichShiftPlanObject(shiftPlan1, ignoreShiftPlanSuggestedInput)) ===
    JSON.stringify(enrichShiftPlanObject(shiftPlan2, ignoreShiftPlanSuggestedInput))
  );
};

const enrichShiftPlanObject = (shiftPlan, ignoreShiftPlanSuggestedInput) => {
  let shiftPlanJson = JSON.parse(shiftPlan);
  shiftPlanJson = {
    ...shiftPlanJson,
    input: {
      ...shiftPlanJson.input,
      resourceProductivityInput: {
        ...shiftPlanJson.input.resourceProductivityInput,
        resourceProductivityList: enrichResourceProductivityInput(
          shiftPlanJson.input.resourceProductivityInput.resourceProductivityList
        ),
        cycleResourceProductivities:
          shiftPlanJson.input.resourceProductivityInput.cycleResourceProductivities !== null &&
          shiftPlanJson.input.resourceProductivityInput.cycleResourceProductivities !== undefined
            ? enrichMultiCycleResourceProductivityInput(
                shiftPlanJson.input.resourceProductivityInput.cycleResourceProductivities
              )
            : shiftPlanJson.input.resourceProductivityInput.cycleResourceProductivities
      },
      labourAvailability: {
        ...shiftPlanJson.input.labourAvailability,
        shiftGroupAvailabilities: enrichShiftGroupAvailabilities(
          shiftPlanJson.input.labourAvailability.shiftGroupAvailabilities
        )
      }
    }
  };

  if (ignoreShiftPlanSuggestedInput && SHIFT_PLAN_SUGGESTED_INPUT in shiftPlanJson.input) {
    shiftPlanJson = {
      ...shiftPlanJson,
      input: Object.entries(shiftPlanJson.input)
        .filter(([key, value]) => key !== SHIFT_PLAN_SUGGESTED_INPUT)
        .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {})
    };
  }

  return shiftPlanJson;
};
