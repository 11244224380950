import React, { useState } from 'react';
import Button from '@amzn/meridian/button';
import Row from '@amzn/meridian/row';
import Expander from '@amzn/meridian/expander';
import Text from '@amzn/meridian/text';
import Column from '@amzn/meridian/column';
import Divider from '@amzn/meridian/divider';

import CycleSortTable from './CycleSortTable';
import CycleTimeWindowRatesSortTable from './CycleTimeWindowRatesSortTable';
import SortTable from './SortTable';
import OverrideReason from '../../commonComponents/OverrideReasons';

import { processMapping, TEXTS } from '../../../utils/constants';
import { areSameShiftPlanObjects, enrichMultiCycleResourceProductivityInput } from '../../../utils/shiftPlanHelper';

const getValidProcesses = (validProcesses, resourceProductivityList) => {
  let res = [];
  for (let i = 0; i < validProcesses.length; i++) {
    if (!resourceProductivityList[validProcesses[i]]) continue;
    if (resourceProductivityList[validProcesses[i]]['ratioBasedRule']) continue;
    res.push(validProcesses[i]);
  }
  return res;
};

const ControlledExpander = (props) => {
  const [open, setOpen] = useState(props.isOpen);
  return (
    <Expander open={open} onChange={setOpen} {...props}>
      {props.children}
    </Expander>
  );
};

const PathRatesMenu = ({
  data,
  errorCounter,
  updateButtonState,
  initialData,
  isUserAuthorisedForShiftPlanOverrides
}) => {
  let resourceProductivityList = data.input.resourceProductivityInput.resourceProductivityList[0].pph;
  return (
    <div>
      {['Sort', 'Pick Stage', 'UTR', 'Site Support'].map((processType, index) => {
        const validProcesses = getValidProcesses(processMapping[processType], resourceProductivityList);
        if (validProcesses.length === 0) return null;
        return (
          <ControlledExpander
            key={index}
            title={<Text alignment="left">{processType}</Text>}
            type="section"
            isOpen={index === 0}
          >
            <SortTable
              data={data}
              initialData={initialData}
              errorCounter={errorCounter}
              updateButtonState={updateButtonState}
              validProcesses={validProcesses}
              isUserAuthorisedForShiftPlanOverrides={isUserAuthorisedForShiftPlanOverrides}
            />
          </ControlledExpander>
        );
      })}
    </div>
  );
};

const CyclePathRatesMenu = ({
  data,
  cycle,
  errorCounter,
  updateButtonState,
  initialData,
  isUserAuthorisedForShiftPlanOverrides
}) => {
  // Temporary conditional for backwards compatibility until old plans phase out of availability.
  // This was introduced for cycle extensions, and the CycleSortTable.js should be removed
  // once ~2 weeks pass and old plans aren't available to be rendered in the UI.
  if (data.input.resourceProductivityInput.cycleResourceProductivities[cycle].timeWindowResourceProductivityList) {
    const cycleSortTables = [];
    for (
      let i = 0;
      i <
      data.input.resourceProductivityInput.cycleResourceProductivities[cycle].timeWindowResourceProductivityList.length;
      i++
    ) {
      let resourceProductivityList =
        data.input.resourceProductivityInput.cycleResourceProductivities[cycle].timeWindowResourceProductivityList[i]
          .pph;
      let timeWindowTag =
        data.input.resourceProductivityInput.cycleResourceProductivities[cycle].timeWindowResourceProductivityList[i]
          .applicability.identifier;
      if (timeWindowTag === 'ENTIRE_CYCLE') {
        timeWindowTag = cycle;
      } else if (timeWindowTag === 'SORT_0') {
        timeWindowTag = 'Sort 0';
      }
      cycleSortTables.push(
        <div>
          <Text type={TEXTS.H4}>{timeWindowTag}</Text>
          {['Sort', 'Pick Stage', 'UTR', 'Site Support'].map((processType, index) => {
            const validProcesses = getValidProcesses(processMapping[processType], resourceProductivityList);
            if (validProcesses.length === 0) return null;
            return (
              <ControlledExpander
                key={index}
                title={<Text alignment="left">{processType}</Text>}
                type="section"
                isOpen={index === 0}
              >
                <CycleTimeWindowRatesSortTable
                  data={data}
                  cycle={cycle}
                  timeWindowRateIterator={i}
                  initialData={initialData}
                  errorCounter={errorCounter}
                  updateButtonState={updateButtonState}
                  validProcesses={validProcesses}
                  isUserAuthorisedForShiftPlanOverrides={isUserAuthorisedForShiftPlanOverrides}
                />
              </ControlledExpander>
            );
          })}
          <br />
        </div>
      );
    }
    return cycleSortTables;
  } else {
    let resourceProductivityList = data.input.resourceProductivityInput.cycleResourceProductivities[cycle].pph;
    return (
      <div>
        {['Sort', 'Pick Stage', 'UTR', 'Site Support'].map((processType, index) => {
          const validProcesses = getValidProcesses(processMapping[processType], resourceProductivityList);
          if (validProcesses.length === 0) return null;
          return (
            <ControlledExpander
              key={index}
              title={<Text alignment="left">{processType}</Text>}
              type="section"
              isOpen={index === 0}
            >
              <CycleSortTable
                data={data}
                cycle={cycle}
                initialData={initialData}
                errorCounter={errorCounter}
                updateButtonState={updateButtonState}
                validProcesses={validProcesses}
                isUserAuthorisedForShiftPlanOverrides={isUserAuthorisedForShiftPlanOverrides}
              />
            </ControlledExpander>
          );
        })}
      </div>
    );
  }
};

const verifyRatesError = {
  count: 0
};

const VerifyRates = ({
  data,
  setData,
  callAPI,
  dataStorage,
  setScreen,
  isUserAuthorisedForShiftPlanOverrides,
  planVersion
}) => {
  const verifyRatesStorage = data.input.shiftPlanSuggestedInput
    ? JSON.stringify(data.input.shiftPlanSuggestedInput.resourceProductivityInput.cycleResourceProductivities)
    : JSON.stringify(JSON.parse(dataStorage).input.resourceProductivityInput.cycleResourceProductivities);

  const isDataSame = () => {
    const clonedCurrentCycleResourceProductivities = JSON.parse(
      JSON.stringify(data.input.resourceProductivityInput.cycleResourceProductivities)
    );
    const clonedOriginalCycleResourceProductivities = JSON.parse(verifyRatesStorage);

    return (
      JSON.stringify(enrichMultiCycleResourceProductivityInput(clonedCurrentCycleResourceProductivities)) ===
      JSON.stringify(enrichMultiCycleResourceProductivityInput(clonedOriginalCycleResourceProductivities))
    );
  };

  const overrideInitial = data.input.resourceProductivityInput.overrideBasis;
  const overrideOptions = ['Roster Defects', 'New Hires', 'Weather', 'SEV Events', 'Other'];
  const overrideExceptions = ['Other'];

  const [disableReset, setDisableReset] = useState(isDataSame());
  const [disableOverride, setDisableOverride] = useState(isDataSame());
  const [resetID, setResetID] = useState(Math.random());
  const [overrideReason, setOverrideReason] = useState(overrideInitial ? overrideInitial.reason : '');
  const [overrideReasonComment, setOverrideReasonComment] = useState(overrideInitial ? overrideInitial.comment : '');

  const onReset = () => {
    data.input.resourceProductivityInput.cycleResourceProductivities = JSON.parse(verifyRatesStorage);

    data.input.resourceProductivityInput.overrideBasis = null;
    setData(data);
    verifyRatesError.count = 0;
    setResetID(Math.random());
    setDisableReset(true);
    setDisableOverride(true);
    setOverrideReason('');
    setOverrideReasonComment('');
  };

  const updateOverrideReason = (reason) => {
    setOverrideReason(reason);
    let overrideInitial = data.input.resourceProductivityInput.overrideBasis;
    let comment = overrideInitial ? overrideInitial.comment : undefined;
    data.input.resourceProductivityInput.overrideBasis = {
      reason,
      comment
    };
  };

  const updateOverrideReasonComment = (comment) => {
    setOverrideReasonComment(comment);
    let overrideInitial = data.input.resourceProductivityInput.overrideBasis;
    let reason = overrideInitial ? overrideInitial.reason : undefined;
    data.input.resourceProductivityInput.overrideBasis = {
      reason,
      comment
    };
  };

  const updateButtonState = () => {
    let isSame = isDataSame();
    if (isSame || verifyRatesError.count) {
      data.input.resourceProductivityInput.overrideBasis = null;
      setOverrideReason('');
      setOverrideReasonComment('');
    }
    setDisableReset(!!(isSame && !verifyRatesError.count));
    setDisableOverride(!!(isSame || verifyRatesError.count));
  };

  const isSaveButtonDisabled = () => {
    if (verifyRatesError.count) return true;
    if (!disableOverride) {
      if (overrideReason === '') return true;
      else if (overrideExceptions.includes(overrideReason)) return !overrideReasonComment;
      else return false;
    }
    return false;
  };

  const onSave = async () => {
    setData(data);
    if (!areSameShiftPlanObjects(JSON.stringify(data), dataStorage, true)) {
      console.log('DATA is edited need to call API');
      callAPI(data.planType, { input: data.input, output: data.output }, () => setScreen(1));
    } else {
      console.log('DATA is same no need to call API');
      setScreen(1);
    }
  };

  let availableCycles = [];
  if (data['input']['resourceProductivityInput']['cycleResourceProductivities']) {
    availableCycles = [
      ...new Set(Object.keys(data['input']['resourceProductivityInput']['cycleResourceProductivities']))
    ];

    availableCycles.sort();

    if ('CYCLE_1' in availableCycles) {
      const cycle1Index = availableCycles.findIndex('CYCLE_1');
      availableCycles.unshift(availableCycles.split(cycle1Index, 1)[0]);
    }
  }

  return (
    <Column
      heights="fit"
      alignmentHorizontal="stretch"
      alignmentVertical="top"
      spacing="medium"
      width="100%"
      spacingInset="400"
    >
      <Text type={TEXTS.H2} alignment="left">
        Process Path Rates
      </Text>
      <Text type={TEXTS.T2} alignment="left">
        Verify or update the values below and save.
      </Text>

      {availableCycles && availableCycles.length > 0 ? (
        availableCycles.map((cycle, index) => {
          return (
            <ControlledExpander
              key={index}
              title={<Text alignment="left">{cycle}</Text>}
              type="section"
              isOpen={index === 0}
            >
              <CyclePathRatesMenu
                data={data}
                cycle={cycle}
                key={resetID}
                updateButtonState={updateButtonState}
                errorCounter={verifyRatesError}
                initialData={JSON.parse(dataStorage)}
                isUserAuthorisedForShiftPlanOverrides={isUserAuthorisedForShiftPlanOverrides}
              />
            </ControlledExpander>
          );
        })
      ) : (
        <PathRatesMenu
          data={data}
          key={resetID}
          updateButtonState={updateButtonState}
          errorCounter={verifyRatesError}
          initialData={JSON.parse(dataStorage)}
          isUserAuthorisedForShiftPlanOverrides={isUserAuthorisedForShiftPlanOverrides}
        />
      )}

      <Column alignmentHorizontal="right">
        <OverrideReason
          overrideReason={overrideReason}
          setOverrideReason={updateOverrideReason}
          overrideReasonComment={overrideReasonComment}
          setOverrideReasonComment={updateOverrideReasonComment}
          options={overrideOptions}
          exceptions={overrideExceptions}
          disable={disableOverride}
        />
      </Column>

      <Divider />

      <Column alignmentHorizontal="right">
        <Row wrap="down" spacingInset="medium none">
          <Button onClick={onReset} disabled={disableReset} type="secondary">
            Reset
          </Button>
          <Button onClick={onSave} disabled={isSaveButtonDisabled()}>
            Generate Plan
          </Button>
        </Row>
      </Column>
    </Column>
  );
};

export default VerifyRates;
