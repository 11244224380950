import React, { useState } from 'react';
import Table, { TableRow, TableCell } from '@amzn/meridian/table';
import Text from '@amzn/meridian/text';
import Icon from '@amzn/meridian/icon';
import Button from '@amzn/meridian/button';
import chevronDownSmallTokens from '@amzn/meridian-tokens/base/icon/chevron-down-small';
import chevronRightSmallTokens from '@amzn/meridian-tokens/base/icon/chevron-right-small';
import { SSD_VOLUME_PROCESS_TYPE } from '../../../../utils/constants';
import { chainWalk } from '../../../../utils/helpers';
import { getTimelineForViewPlan } from '../../utils';
import { Pick2RebinOverridePopover, SinglesOverridePopover } from './OutboundVolumeOverrides';
import {
  getSinglesVolume,
  getVnaVolume,
  getPick2RebinVolume,
  getSortableVolume,
  getTotalVolume
} from './OutboundVolumeUtils';
import { getSinglesEnabled } from './SinglesUtils';
import moment from 'moment';
import 'moment-timezone';

const findTotalVolume = (demand, cptDateTimeLocal, processPaths, roles, sources) => {
  return demand
    .filter(
      ({ cpt_local, process_path, process_role, volume_source }) =>
        cpt_local === cptDateTimeLocal &&
        (processPaths.length === 0 || processPaths.indexOf(process_path)) >= 0 &&
        (roles.length === 0 || roles.indexOf(process_role) >= 0) &&
        (sources.length === 0 ||
          sources.filter((sourceName) => chainWalk(() => volume_source.startsWith(sourceName), false)).length > 0)
    )
    .reduce((t, { volume }) => t + volume, 0);
};

const ViewOutboundVolumes = ({ currentUser, isEditable, plan, setPlan }) => {
  const timeline = getTimelineForViewPlan(plan);
  const singlesEnabled = getSinglesEnabled(plan);
  const outboundVolumes = chainWalk(() => plan.input.ssdShiftPlanInput.volume.outboundVolumeList, []);
  const demand = chainWalk(() => JSON.parse(plan.output.serializedOutput).transformedInputs.demand, []);
  const [collapsedRows, setCollapsedRows] = useState([]);
  const updateCollapsedRows = (row) => {
    const newCollapsedRows = collapsedRows.includes(row)
      ? collapsedRows.filter((collapsedRow) => collapsedRow !== row)
      : [...collapsedRows, row];
    setCollapsedRows(newCollapsedRows);
  };

  const cptToDisplayLabel = {};
  const cptToDateTimeLocal = {};
  timeline.forEach((timelineEvent) => {
    const timestamp = timelineEvent['timestamp'];
    const label = timelineEvent['label'];
    cptToDisplayLabel[timestamp] = `${label} (${timelineEvent['date_time_local'].split('T')[1].substring(0, 5)} CPT)`;
    cptToDateTimeLocal[timestamp] = timelineEvent.date_time_local.substring(0, 19);
  });

  const outboundVolumeTableRows = [];
  outboundVolumes.forEach((outboundVolume) => {
    const cpt = outboundVolume.cpt;
    if (!(cpt in cptToDisplayLabel)) {
      return;
    }
    const cptDateTimeLocal = cptToDateTimeLocal[cpt];
    let { vnaPredicatedCharge, vnaRodeoBacklog } = getVnaVolume(outboundVolume);
    let { singlesPredictedCharge, singlesPlanningCharge, singlesRodeoBacklog } = getSinglesVolume(
      outboundVolume,
      singlesEnabled
    );
    let {
      pickToRebinPredictedCharge,
      pickToRebinMaxPotentialCharge,
      pickToRebinPlanningCharge,
      pickToRebinRodeoBacklog
    } = getPick2RebinVolume(outboundVolume, singlesEnabled);
    let { sortablePredictedCharge, sortableMaxPotentialCharge, sortablePlanningCharge } = getSortableVolume(
      outboundVolume,
      singlesEnabled
    );
    let { totalRodeoBacklog, totalPlanningVolume } = getTotalVolume(outboundVolume, singlesEnabled);
    if (!isEditable) {
      vnaRodeoBacklog = findTotalVolume(demand, cptDateTimeLocal, ['outbound'], ['vnapick', 'vnapack'], ['RODEO']);
      pickToRebinRodeoBacklog = findTotalVolume(
        demand,
        cptDateTimeLocal,
        ['outbound'],
        ['pick2rebinpick', 'pick2rebinpack'],
        ['RODEO']
      );
      totalRodeoBacklog = vnaRodeoBacklog + pickToRebinRodeoBacklog;
      sortablePredictedCharge = findTotalVolume(
        demand,
        cptDateTimeLocal,
        ['outbound'],
        ['pick2rebinpick', 'pick2rebinpack'],
        ['AFT_CHARGE']
      );
      pickToRebinPredictedCharge = findTotalVolume(
        demand,
        cptDateTimeLocal,
        ['outbound'],
        ['pick2rebinpick', 'pick2rebinpack'],
        ['AFT_CHARGE']
      );
      vnaPredicatedCharge = findTotalVolume(
        demand,
        cptDateTimeLocal,
        ['outbound'],
        ['vnapick', 'vnapack'],
        ['AFT_CHARGE']
      );
      ({ sortableMaxPotentialCharge } = getSortableVolume(outboundVolume, singlesEnabled));
      sortablePlanningCharge = findTotalVolume(
        demand,
        cptDateTimeLocal,
        ['outbound'],
        ['pick2rebinpick', 'pick2rebinpack'],
        ['ATROPS', 'AFT_CHARGE', 'Override - PercentATROPS']
      );
      pickToRebinPlanningCharge = sortablePlanningCharge = findTotalVolume(
        demand,
        cptDateTimeLocal,
        ['outbound'],
        ['pick2rebinpick', 'pick2rebinpack'],
        ['ATROPS', 'AFT_CHARGE', 'Override - PercentATROPS']
      );
      totalPlanningVolume = totalRodeoBacklog + sortablePlanningCharge + vnaPredicatedCharge;
    }

    const rowIdentifier = cpt;
    const timezone = chainWalk(() => JSON.parse(plan.input.adminSettings).timezone, moment.tz.guess());
    const cptMoment = moment.unix(cpt).tz(timezone);
    outboundVolumeTableRows.push(
      <TableRow backgroundColor="secondary">
        <TableCell>
          <Button type="icon" size="small" onClick={() => updateCollapsedRows(rowIdentifier)}>
            <Icon tokens={collapsedRows.includes(rowIdentifier) ? chevronRightSmallTokens : chevronDownSmallTokens} />
          </Button>
        </TableCell>
        <TableCell>
          <Text type="h100">{cptToDisplayLabel[cpt]}</Text>
          <Text type="b200" color="secondary">
            {cptMoment.format('MM/DD/YYYY')}
          </Text>
        </TableCell>
        <TableCell>{Math.round(sortablePredictedCharge)}</TableCell>
        <TableCell>{Math.round(sortableMaxPotentialCharge)}</TableCell>
        <TableCell>{Math.round(sortablePlanningCharge)}</TableCell>
        <TableCell>{Math.round(vnaPredicatedCharge)}</TableCell>
        <TableCell>{Math.round(totalRodeoBacklog)}</TableCell>
        <TableCell>{Math.round(totalPlanningVolume)}</TableCell>
      </TableRow>
    );
    outboundVolumeTableRows.push(
      !collapsedRows.includes(rowIdentifier) && (
        <TableRow>
          <TableCell />
          <TableCell>{SSD_VOLUME_PROCESS_TYPE.PICK_2_REBIN.label}</TableCell>
          <TableCell>{Math.round(pickToRebinPredictedCharge)}</TableCell>
          <TableCell>{Math.round(pickToRebinMaxPotentialCharge)}</TableCell>
          <TableCell>
            <Pick2RebinOverridePopover
              currentUser={currentUser}
              plan={plan}
              setPlan={setPlan}
              isEditable={isEditable}
              pickToRebinPlanningCharge={Math.round(pickToRebinPlanningCharge)}
              outboundVolume={outboundVolume}
              singlesEnabled={singlesEnabled}
              volumeProcessType={SSD_VOLUME_PROCESS_TYPE.PICK_2_REBIN.value}
            />
          </TableCell>
          <TableCell>-</TableCell>
          <TableCell>{Math.round(pickToRebinRodeoBacklog)}</TableCell>
          <TableCell>{Math.round(pickToRebinPlanningCharge + pickToRebinRodeoBacklog)}</TableCell>
        </TableRow>
      )
    );

    if (singlesEnabled) {
      outboundVolumeTableRows.push(
        !collapsedRows.includes(rowIdentifier) && (
          <TableRow>
            <TableCell />
            <TableCell>{SSD_VOLUME_PROCESS_TYPE.SINGLES.label}</TableCell>
            <TableCell>{Math.round(singlesPredictedCharge)}</TableCell>
            <TableCell>{Math.round(singlesPlanningCharge)}</TableCell>
            <TableCell>
              <SinglesOverridePopover
                currentUser={currentUser}
                plan={plan}
                setPlan={setPlan}
                isEditable={isEditable}
                singlesPlanningCharge={Math.round(singlesPlanningCharge)}
                outboundVolume={outboundVolume}
                volumeProcessType={SSD_VOLUME_PROCESS_TYPE.SINGLES.value}
              />
            </TableCell>
            <TableCell>-</TableCell>
            <TableCell>{Math.round(singlesRodeoBacklog)}</TableCell>
            <TableCell>{Math.round(singlesPlanningCharge + singlesRodeoBacklog)}</TableCell>
          </TableRow>
        )
      );
    }

    outboundVolumeTableRows.push(
      !collapsedRows.includes(rowIdentifier) && (
        <TableRow>
          <TableCell />
          <TableCell>{SSD_VOLUME_PROCESS_TYPE.VNA.label}</TableCell>
          <TableCell>-</TableCell>
          <TableCell>-</TableCell>
          <TableCell>-</TableCell>
          <TableCell>{Math.round(vnaPredicatedCharge)}</TableCell>
          <TableCell>{Math.round(vnaRodeoBacklog)}</TableCell>
          <TableCell>{Math.round(vnaPredicatedCharge + vnaRodeoBacklog)}</TableCell>
        </TableRow>
      )
    );
  });

  return (
    <div style={{ overflowX: 'auto', maxWidth: '100%' }}>
      <Table headerRows={2} showDividers={true}>
        <TableRow>
          <TableCell></TableCell>
          <TableCell width={300} />
          <TableCell columnSpan={3}>
            <strong>Sortable</strong> (Pick2Rebin)
          </TableCell>
        </TableRow>
        <TableRow highlightOnHover={true}>
          <TableCell />
          <TableCell />
          <TableCell backgroundColor="secondary">Predicted Remaining Charge</TableCell>
          <TableCell backgroundColor="secondary">Max Potential Charge</TableCell>
          <TableCell backgroundColor="secondary">Planning Charge</TableCell>
          <TableCell>VNA Predicted Remaining Charge</TableCell>
          <TableCell>Rodeo Backlog</TableCell>
          <TableCell>Total Planning Volume</TableCell>
        </TableRow>
        <TableRow>
          <TableCell />
          <TableCell columnSpan={6}>
            <Text type="h100">Past Due Backlog</Text>
          </TableCell>
          <TableCell>
            <Text type="h100">-</Text>
          </TableCell>
        </TableRow>
        {outboundVolumeTableRows}
      </Table>
    </div>
  );
};

export default ViewOutboundVolumes;
