import Text from '@amzn/meridian/text';
import React, { useState, useContext, useMemo, useEffect } from 'react';
import { PLAN_VERSIONS, TEXTS } from '../utils/constants';
import Column from '@amzn/meridian/column';
import Box from '@amzn/meridian/box';
import Row from '@amzn/meridian/row';
import Button from '@amzn/meridian/button';
import { GlobalStateContext } from '../global/context/GlobalStateContext';
import DateRangePicker from '@amzn/meridian/date-range-picker';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import Input from '@amzn/meridian/input';
import Select, { SelectOption } from '@amzn/meridian/select';
import ShiftPlanHistoryTable from './ShiftPlanHistoryTable';
import apis from '../utils/apis';
import { getStationCode } from '../utils/networkUtil';
import { STATUS_MAP } from './StatusCell';

function ShiftPlanHistory() {
  const { date, setAsyncError } = useContext(GlobalStateContext);
  const formatIso = (date) => format(date, 'yyyy-MM-dd');
  const today = formatIso(new Date());
  const twoWeeksAgo = formatIso(addDays(new Date(), -14));
  const sevenDaysAgo = formatIso(addDays(new Date(), -7));
  const [dateRange, setDateRange] = useState([date, date]);
  const [planIdFilter, setPlanIdFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState([]);
  const [aliasFilter, setAliasFilter] = useState('');
  const [shiftPlansList, setShiftPlansList] = useState([]);
  const [stationTimeZone, setStationTimeZone] = useState('');

  let nodeId = getStationCode();

  const clearDateRange = () => {
    setDateRange([date, date]);
    setShiftPlansList([]);
  };

  const getShiftPlansList = async (startDate, endDate) => {
    if (endDate === '' || endDate === undefined) {
      endDate = startDate;
    }
    let allPlans = await apis['GET_ALL_PLANS_IN_RANGE'](
      {
        query: {
          startDate: startDate,
          endDate: endDate,
          nodeId: nodeId
        }
      },
      setAsyncError
    );
    let allStaticPlans = allPlans['shiftPlans']
      .sort((planA, planB) => planB.createdAt - planA.createdAt)
      .filter((plan) => plan.planVersion === PLAN_VERSIONS.STATIC_V1);

    if (allStaticPlans.length > 0) {
      let plan = await apis['GET_PLAN'](
        {
          query: {
            planId: allStaticPlans[0].planId
          }
        },
        setAsyncError
      );
      const adminSettings = JSON.parse(plan.input.adminSettings);
      setStationTimeZone(adminSettings.timezone);
    }
    setShiftPlansList(allStaticPlans);

    return null;
  };

  const filteredShiftPlansList = useMemo(() => {
    const filteredData = shiftPlansList.filter((plan) => {
      return (
        (planIdFilter !== '' ? plan.planId.toLowerCase().includes(planIdFilter.toLowerCase()) : true) &&
        (aliasFilter !== '' ? plan.lastUpdatedBy.toLowerCase().includes(aliasFilter.toLowerCase()) : true) &&
        (statusFilter.length > 0 ? statusFilter.includes(STATUS_MAP[plan.status]) : true)
      );
    });
    return filteredData;
  });

  return (
    <Column spacingInset="400" alignmentVertical="justify" alignmentHorizontal="left">
      <Text type={TEXTS.H1}>Shift Plan History</Text>
      <Column width={600} spacing="300">
        <Text type={TEXTS.H3}>Date Range</Text>
        <Row alignmentHorizontal="left" spacingInset="100" spacing="300">
          <DateRangePicker
            allowSameStartAndEnd={true}
            monthsInView={1}
            size="medium"
            value={dateRange}
            onChange={setDateRange}
            presets={[
              { label: 'Last 7 days', value: [sevenDaysAgo, today] },
              { label: 'Last 14 days', value: [twoWeeksAgo, today] }
            ]}
          />
          <Button
            disabled={false}
            type="primary"
            size="medium"
            onClick={() => getShiftPlansList(dateRange[0], dateRange[1])}
          >
            Show
          </Button>
          <Button disabled={false} type="tertiary" size="medium" onClick={() => clearDateRange()}>
            Clear
          </Button>
        </Row>
        <Box type="fill" spacingInset="450" width="980%">
          <Row width="100%">
            <Text type={TEXTS.T1}>Plan ID</Text>
            <Input
              placeholder={'ID...'}
              type="text"
              size="medium"
              width={300}
              value={planIdFilter}
              onChange={setPlanIdFilter}
            />
            <Text type={TEXTS.T1}>Created By</Text>
            <Input
              placeholder={'Alias...'}
              type="text"
              size="medium"
              value={aliasFilter}
              onChange={setAliasFilter}
              width={200}
            />
            <Text type={TEXTS.T1}>Status</Text>
            <Select
              width={200}
              value={statusFilter}
              onChange={setStatusFilter}
              placeholder="Select Status"
              size="medium"
            >
              <SelectOption value="Successful" label="Successful" />
              <SelectOption value="Failed" label="Failed" />
              <SelectOption value="Cancelled" label="Cancelled" />
              <SelectOption value="Pending Creation" label="Pending Creation" />
            </Select>
          </Row>
        </Box>
        <ShiftPlanHistoryTable
          shiftPlansList={filteredShiftPlansList}
          stationTimeZone={stationTimeZone}
        ></ShiftPlanHistoryTable>
      </Column>
    </Column>
  );
}
export default ShiftPlanHistory;
