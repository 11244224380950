import React from 'react';
import Button from '@amzn/meridian/button';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';
import ViewDispatchVolumes from '../../common/ssd/ViewDispatchVolumes';
const VerifyDispatchVolumes = ({ currentUser, plan, setPlan, onGoBack, onGoNext, onCancel }) => {
  return (
    <Column width="100%">
      <Text type={'h400'}>Dispatch Volume</Text>
      <Text type={'b400'}>Verify or update the values below and submit.</Text>
      <ViewDispatchVolumes currentUser={currentUser} isEditable={true} plan={plan} setPlan={setPlan} />
      <Row alignmentHorizontal="justify">
        <Button onClick={onGoBack} type="secondary">
          Back
        </Button>
        <Row>
          <Button onClick={onCancel} type="tertiary">
            Cancel
          </Button>
          <Button onClick={onGoNext}>Next</Button>
        </Row>
      </Row>
    </Column>
  );
};

export default VerifyDispatchVolumes;
