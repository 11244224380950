import React, { createContext, useReducer } from 'react';

import { GlobalStateReducer, initialState } from '../reducers/GlobalStateReducer';

export const GlobalStateContext = createContext();

const GlobalStateContextProvider = (props) => {
  const [globalState, dispatchGlobal] = useReducer(GlobalStateReducer, initialState);
  const setDate = (date) => {
    dispatchGlobal({
      type: 'SET_DATE',
      data: {
        date
      }
    });
  };
  const setAsyncError = (asyncError) => {
    dispatchGlobal({
      type: 'SET_ASYNC_ERROR',
      data: {
        asyncError
      }
    });
  };
  const restartApp = () => {
    dispatchGlobal({
      type: 'RESTART_APP'
    });
  };
  const value = {
    ...globalState,
    setDate,
    setAsyncError,
    restartApp
  };
  return <GlobalStateContext.Provider value={value}>{props.children}</GlobalStateContext.Provider>;
};

export default GlobalStateContextProvider;
