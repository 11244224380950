import React from 'react';
import Button from '@amzn/meridian/button';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import Icon from '@amzn/meridian/icon';
import Text from '@amzn/meridian/text';
import minusCircleTokens from '@amzn/meridian-tokens/base/icon/minus-circle';
import plusCircleTokens from '@amzn/meridian-tokens/base/icon/plus-circle';
import Input from '@amzn/meridian/input';
import Select, { SelectOption } from '@amzn/meridian/select';

import { chainWalk } from '../../../../utils/helpers';
import { getShiftSchedules, getTimelineForViewPlan } from '../../utils';
import { SSD_OVERRIDE_REASON_CODES } from '../../../../utils/constants';
import { SINGLES_ROLES, getSinglesEnabled } from '../../common/ssd/SinglesUtils';

const VerifyNewHires = ({ currentUser, plan, setPlan, onGoBack, onSubmit, onCancel }) => {
  const timeline = getTimelineForViewPlan(plan);
  const shiftSchedules = getShiftSchedules(timeline);
  const singlesEnabled = getSinglesEnabled(plan);
  const newHireRoles = chainWalk(
    () =>
      plan.input.ssdShiftPlanInput.newHiresList.filter(
        (role) => singlesEnabled || SINGLES_ROLES.indexOf(role.role.name) < 0
      ),
    []
  );

  const onAddHeadcountOverride = () => {
    const unoverriddenRoles = newHireRoles.filter(
      (role) => chainWalk(() => role.overrides.overrideKeyValueMap.headcount, null) == null
    );
    if (unoverriddenRoles.length <= 0) return false;
    const newRole = unoverriddenRoles[0];
    if (!newRole.overrides) {
      newRole.overrides = {};
    }
    if (!newRole.overrides.overrideKeyValueMap) {
      newRole.overrides.overrideKeyValueMap = {};
    }
    newRole.overrides.overrideKeyValueMap.headcount = '0';
    newRole.overrides.reason = SSD_OVERRIDE_REASON_CODES.NO_REASON_GIVEN.value;
    newRole.overrides.timestamp = parseInt(Date.now() / 1000);
    newRole.overrides.user = currentUser;
    setPlan({ ...plan });
  };

  const onDeleteHeadcountOverride = (role) => {
    if (role.overrides) {
      delete role.overrides.overrideKeyValueMap;
      delete role.overrides.users;
      delete role.overrides.reason;
    }
    setPlan({ ...plan });
  };
  const onChangeProcessRole = (prevRole, newRoleName) => {
    const newRole = newHireRoles.find((r) => r.role.name === newRoleName);
    if (newRole && prevRole) {
      if (!newRole.overrides) {
        newRole.overrides = {};
      }
      if (!newRole.overrides.overrideKeyValueMap) {
        newRole.overrides.overrideKeyValueMap = {};
      }
      newRole.overrides.overrideKeyValueMap.headcount = prevRole.overrides.overrideKeyValueMap.headcount;
      newRole.overrides.reason = SSD_OVERRIDE_REASON_CODES.NO_REASON_GIVEN.value;
      newRole.overrides.timestamp = parseInt(Date.now() / 1000);
      newRole.overrides.user = currentUser;
      delete prevRole.overrides.overrideKeyValueMap;
      delete prevRole.overrides.users;
      delete prevRole.overrides.reason;
      setPlan({ ...plan });
    }
  };
  const onChangeHeadcount = (role, newVal) => {
    if (!role.overrides) {
      role.overrides = {};
    }
    if (!role.overrides.overrideKeyValueMap) {
      role.overrides.overrideKeyValueMap = {};
    }
    role.overrides.overrideKeyValueMap.headcount = newVal + '';
    role.overrides.reason = SSD_OVERRIDE_REASON_CODES.NO_REASON_GIVEN.value;
    role.overrides.timestamp = parseInt(Date.now() / 1000);
    role.overrides.user = currentUser;
    setPlan({ ...plan });
  };

  return (
    <Column width="100%">
      <Text type={'h400'}>New Hires</Text>
      <Text type={'b400'}>Verify or update the values below and submit.</Text>
      <NewHiresComponent
        shiftSchedules={shiftSchedules}
        newHireRoles={newHireRoles}
        onAddHeadcountOverride={onAddHeadcountOverride}
        onDeleteHeadcountOverride={onDeleteHeadcountOverride}
        onChangeHeadcount={onChangeHeadcount}
        onChangeProcessRole={onChangeProcessRole}
      />
      <Row alignmentHorizontal="justify">
        <Button onClick={onGoBack} type="secondary">
          Back
        </Button>
        <Row>
          <Button onClick={onCancel} type="tertiary">
            Cancel
          </Button>
          <Button onClick={onSubmit}>Submit</Button>
        </Row>
      </Row>
    </Column>
  );
};

const NewHiresComponent = ({
  shiftSchedules,
  newHireRoles,
  onAddHeadcountOverride,
  onDeleteHeadcountOverride,
  onChangeProcessRole,
  onChangeHeadcount
}) => {
  if (!shiftSchedules[0]) {
    return <div />;
  }
  newHireRoles.forEach((role) => {
    role.shift = {
      shiftHalfStartTime: shiftSchedules[0].startTimestamp,
      shiftHalfEndTime: shiftSchedules[0].endTimestamp,
      name: shiftSchedules[0].label,
      shiftHalf: ''
    };
  });
  const overriddenRoles = newHireRoles.filter((role) => {
    return chainWalk(() => role.overrides.overrideKeyValueMap.headcount, null) != null;
  });
  return (
    <div style={{ maxWidth: '100%' }}>
      <Row>
        <Text type={'h100'}>{shiftSchedules[0].label}</Text>
        <div onClick={onAddHeadcountOverride}>
          <Text>
            <Icon tokens={plusCircleTokens} />
          </Text>
        </div>
      </Row>
      <div style={{ height: 1, marginTop: 30, marginBottom: 30, background: 'rgba(0,0,0,0.1)' }} />
      {overriddenRoles.map((role) => {
        const headcount = chainWalk(() => role.overrides.overrideKeyValueMap.headcount, 0);
        const minHc = chainWalk(() => role.overrides.overrideKeyGuardrailsMap.headcount.minimum, 0);
        const maxHc = chainWalk(() => role.overrides.overrideKeyGuardrailsMap.headcount.maximum, 0);
        const isValid = minHc <= headcount && headcount <= maxHc;
        return (
          <Column>
            <Row>
              <Input
                value={headcount}
                onChange={(val) => onChangeHeadcount(role, val)}
                label="New Hires(s) *"
                type="number"
                size="small"
                error={!isValid}
                errorMessage={!isValid ? `Headcount value must be between ${minHc} and ${maxHc}` : ''}
              />
              <Select
                value={role.role.name}
                onChange={(val) => onChangeProcessRole(role, val)}
                width={300}
                label="Process Path *"
                placeholder="Select a path"
                size="small"
              >
                {newHireRoles
                  .filter(
                    (r) =>
                      r.role.name === role.role.name ||
                      chainWalk(() => r.overrides.overrideKeyValueMap.headcount, null) == null
                  )
                  .map((r) => {
                    return <SelectOption key={`${r.role.roleName}`} value={r.role.name} label={r.role.label} />;
                  })}
              </Select>
              <div onClick={() => onDeleteHeadcountOverride(role)}>
                <Text>
                  <Icon tokens={minusCircleTokens} />
                </Text>
              </div>
            </Row>
          </Column>
        );
      })}
    </div>
  );
};

export default VerifyNewHires;
