import Text from '@amzn/meridian/text';
import { SHIFT_ALLOCATION_ID_SUFFIX, TEXTS, PLANS, PLAN_VERSIONS, LABOR_TRACKING_STATUS } from '../utils/constants';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import Tooltip from '@amzn/meridian/tooltip';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import DatePicker from '@amzn/meridian/date-picker';
import Select, { SelectOption } from '@amzn/meridian/select';
import { getAvailableDates, parseEpochToDateString } from '../utils/helpers';
import Tab, { TabGroup } from '@amzn/meridian/tab';
import Sheet from '@amzn/meridian/sheet';
import Button from '@amzn/meridian/button';
import AddTooltip from '../utils/AddTooltip';
import AvailableAssociates from './view/AvailableAssociates';
import LoadingView from '../handler/loadingHandler/LoadingView';
import CustomAlert from '../commonComponents/CustomAlert';
import { ProcessPathAllocation } from './view/ProcessPathAllocation';
import { DragDropContext } from 'react-beautiful-dnd';
import OnDragEndModal from './view/OnDragEndModal';
import { ShiftAllocationContext } from './context/ShiftAllocationContext';
import { isValidDragAndDrop } from '../utils/dragAndDropHelper';
import { ProcessViewLegend } from './view/ProcessViewLengend';
import { getStationCode } from '../utils/networkUtil';
import apis from '../utils/apis';
import {
  fixMarginAndWidth,
  fixStylesOnDragEnd,
  fixStylesOnDragUpdate,
  addStylesForStaffingIssues
} from '../utils/shiftAllocation';
import ErrorSection from './view/ErrorSection';
import { TIME_WINDOW_PHASES, prepareModelForBackend } from './data';
import { getPlanChangeViolations, renderShift } from './view/utils';
import {
  isDroppedInSingleOptionProcess,
  moveAssociateToSingleOptionProcess,
  moveAssociateToRecommendedSpot,
  isDroppedInBench,
  moveASsociateToBench,
  moveClockedOutAllocationsToBench
} from './view/dragAndDropUtils';

export const nodeId = getStationCode();
const NUM_DAYS_AVAILABLE_PAST = 0;
const NUM_DAYS_AVAILABLE_FUTURE = 1;
const availableDates = getAvailableDates(NUM_DAYS_AVAILABLE_PAST, NUM_DAYS_AVAILABLE_FUTURE);

function ShiftAllocation(props) {
  const [currentTab, setCurrentTab] = useState(TIME_WINDOW_PHASES.INDUCT_AND_STOW);
  const [isFCLMTrack, setIsFCLMTrack] = useState(false);
  const [isPlanSaved, setIsPlanSaved] = useState(false);
  const [isMissingPreShiftPlan, setIsMissingPreShiftPlan] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [openOnDragEndModal, setOpenOnDragEndModal] = useState(false);
  const [result, setResult] = useState();
  const [provided, setProvided] = useState();

  const onChangeTab = useCallback((selectedTab) => {
    setResult(null);
    setProvided(null);
    setCurrentTab(selectedTab);
  });

  const displayError = useCallback((errorMessage = null) => {
    if (errorMessage) {
      setErrorMsg(errorMessage);
    }
    setIsError(true);
    setIsLoading(false);
  }, []);

  const {
    shiftAllocationModel,
    shiftAllocationDispatch,
    availableAssociatesVisibility,
    setAvailableAssociatesVisibility,
    changePlanParameters,
    storedBoardAllocations,
    cachedResponse,
    setCurrPlan,
    alertMsg,
    isBoardUpdated,
    setIsBoardUpdated,
    date,
    setDate
  } = useContext(ShiftAllocationContext);

  const [selectedCycle, setSelectedCycle] = useState(null);
  const [selectedShift, setSelectedShift] = useState(null);
  const [selectedBoardAllocationKey, setSelectedBoardAllocationKey] = useState(null);

  const setFCLMTrackingEligibility = (status) => {
    return status === LABOR_TRACKING_STATUS.ELIGIBLE;
  }

  useEffect(() => {
    if (!selectedShift) {
      setSelectedShift(JSON.stringify(shiftAllocationModel.availableShifts[0]));
    }
    const parsedShift = selectedShift && JSON.parse(selectedShift);
    setIsFCLMTrack(setFCLMTrackingEligibility(shiftAllocationModel.laborTrackingStatus));
    if (
      parsedShift &&
      !shiftAllocationModel.availableShifts.find(
        (shiftOption) => shiftOption.start === parsedShift.start && shiftOption.end === parsedShift.end
      )
    ) {
      setSelectedShift(JSON.stringify(shiftAllocationModel.availableShifts[0]));
    }
    if (!selectedCycle || !shiftAllocationModel.availableCycles.includes(selectedCycle)) {
      setSelectedCycle(shiftAllocationModel.availableCycles[0]);
    }

    if (selectedShift && selectedCycle) {
      setSelectedBoardAllocationKey(shiftAllocationModel.boardAllocationKey);
    }
  }, [selectedShift, selectedCycle, shiftAllocationModel.availableCycles, shiftAllocationModel.availableShifts]);

  const onClickOpenAvailableAssociates = useCallback(() => {
    setAvailableAssociatesVisibility(true);
  });

  const handleAssociateViewButtonClick = () => {
    props.history.push('/shift-allocation-associate-view');
  };

  const onClickJobRotation = () => {
    props.history.push('/shift-rotation');
  };

  useEffect(() => {
    setTimeout(() => {
      fixMarginAndWidth();
      addStylesForStaffingIssues(shiftAllocationModel.processInfo);
    }, 1);
  }, [isLoading, currentTab, shiftAllocationModel['processInfo']]);

  useEffect(() => {
    changePlanParameters({ phase: currentTab, shift: selectedShift && JSON.parse(selectedShift) });
  }, [currentTab, selectedShift]);

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const onClickTrackInFCLM = async () => {
    //Add FCLM Track API call here
    let body = {
      planId: cachedResponse.planId
    };

    if (selectedBoardAllocationKey) {
      const [cycle, phase, start, end] = selectedBoardAllocationKey.split('.');
      body = {
        ...body,
        timeWindowAttributes: {
          cycle: cycle,
          phase: phase
        },
        shiftTimeWindow: {
          start: start,
          end: end
        }
      };
    }

    let trackIntoFCLM = await apis['LABOR_TRACKING'](
      {
        body: body
      },
      () => displayError('FCLM tracking failed, please try again')
    );
    setLoadingMsg('Tracking assignments into FCLM, this can take some time. Please wait and do not refresh.');
    setIsLoading(true);

    await sleep(2000);
    setIsLoading(false);

    setIsFCLMTrack(setFCLMTrackingEligibility(trackIntoFCLM.laborTrackingStatus));
  };

  const onClickRemoveClockOutAllocations = () => {
    if (moveClockedOutAllocationsToBench(shiftAllocationModel, shiftAllocationDispatch)) {
      setIsBoardUpdated(true);
    }
  };

  const onClickRerun = useCallback(async () => {
    setLoadingMsg('Rerunning the Shift Allocation Plan');
    setIsLoading(true);
    setIsMissingPreShiftPlan(false);

    const newPlan = await apis['CREATE_PLAN'](
      {
        body: {
          nodeId: getStationCode(),
          ofdDate: date,
          planType: PLANS.PRE_SHIFT,
          planVersion: PLAN_VERSIONS.LABOR_ALLOCATION_V2
        }
      },
      () => setIsMissingPreShiftPlan(true)
    );
    setIsLoading(false);
    setCurrPlan(newPlan.planId);
    setSelectedShift(null);
    setIsFCLMTrack(setFCLMTrackingEligibility(newPlan.laborTrackingStatus));
    setIsBoardUpdated(false);
  }, [setCurrPlan, date]);

  const onClickSavePlan = useCallback(async () => {
    const preparedModel = prepareModelForBackend(cachedResponse, {
      ...storedBoardAllocations,
      [shiftAllocationModel.boardAllocationKey]: {
        processInfo: shiftAllocationModel.processInfo,
        associateInfo: shiftAllocationModel.associateInfo
      }
    });

    setLoadingMsg('Updating the Shift Allocation Board');
    setIsLoading(true);
    setIsError(false);
    let newPlan = await apis['CREATE_PLAN'](
      {
        body: {
          nodeId: getStationCode(),
          ofdDate: date,
          planType: preparedModel.planType,
          planVersion: PLAN_VERSIONS.LABOR_ALLOCATION_V2,
          overridingPlanId: preparedModel.planId,
          input: preparedModel.input,
          outputOverride: preparedModel.output
        }
      },
      () => displayError('Shift Allocation Plan override failed, please try again')
    );
    setCurrPlan(newPlan.planId);
    setSelectedShift(null);
    setIsLoading(false);
    setIsBoardUpdated(false);
    setIsFCLMTrack(setFCLMTrackingEligibility(newPlan.laborTrackingStatus));
  }, [date, storedBoardAllocations, shiftAllocationModel, setCurrPlan]);

  const onDragEnd = (result, provided) => {
    console.log('on DragEnd result-> ', result);
    console.log('on DragEnd provided-> ', provided);

    if (isValidDragAndDrop(result, provided)) {
      const droppedInRecommended = result.destination.droppableId.includes(
          SHIFT_ALLOCATION_ID_SUFFIX.RECOMMENDED_SPOT_DROP
      );

      const singleProcessDrop = isDroppedInSingleOptionProcess(result, shiftAllocationModel['processInfo']);
      const droppedInBench = isDroppedInBench(result);
      if (droppedInBench) {
        moveASsociateToBench(result, provided, shiftAllocationDispatch);
        setIsBoardUpdated(true)
      } else if (
          getPlanChangeViolations(
              result,
              shiftAllocationModel['processInfo'],
              shiftAllocationModel['associateInfo'],
              null
          ).length > 0 ||
          (!singleProcessDrop && !droppedInRecommended)
      ) {
        setResult(result);
        setProvided(provided);
        setOpenOnDragEndModal(true);
      } else if (droppedInRecommended) {
        moveAssociateToRecommendedSpot(result, provided, shiftAllocationDispatch);
        setIsBoardUpdated(true)
      } else if (singleProcessDrop) {
        moveAssociateToSingleOptionProcess(result, singleProcessDrop, shiftAllocationDispatch);
        setIsBoardUpdated(true)
      }
    }
    fixStylesOnDragEnd(result, provided, shiftAllocationModel.processInfo);
  };

  const onDragUpdate = (update, provided) => {
    fixStylesOnDragUpdate(update, provided);
  };

  if (isLoading) return <LoadingView loadingMsg={loadingMsg} />;
  if (isError) return <ErrorSection loadingMsg={errorMsg} />;

  return (
    <Row alignmentVertical={'top'} width="100%" spacingInset={'400'} spacing={'200'}>
      <DragDropContext onDragUpdate={onDragUpdate} onDragEnd={onDragEnd}>
        <Column width={'100%'} height={'100%'} spacingInset="none">
          <Row alignmentHorizontal="justify">
            <Row alignmentHorizontal="start">
              <AddTooltip size={TEXTS.H1}>Labor Board</AddTooltip>
            </Row>
            <Row alignmentHorizontal="end" spacing={'200'} onClick>
              <Button size="small" type="tertiary" onClick={handleAssociateViewButtonClick}>
                Associate View
              </Button>
              <Button size="small" type="tertiary" onClick={onClickOpenAvailableAssociates}>
                Available Associates
              </Button>
              <Button size="small" type="link" onClick={onClickJobRotation}>
                Job Rotation
              </Button>
            </Row>
          </Row>

          <Row width="100%" widths={['fit', 'fit', 'fit', 'fit', 'fit', 'fit', 'fill']}>
            <Text type={TEXTS.T2} alignment="Left">
              Out For Delivery Date
            </Text>
            <DatePicker
              value={date}
              onChange={setDate}
              monthsInView={1}
              size="small"
              disabledDates={(d) => !availableDates.includes(d)}
            />
            <Text type={TEXTS.T2}>Cycle</Text>
            <Select value={selectedCycle} onChange={setSelectedCycle} placeholder="Cycle" size="small">
              {shiftAllocationModel.availableCycles.map((cycle) => (
                <SelectOption key={cycle} value={cycle} label={cycle} />
              ))}
            </Select>
            <Text type={TEXTS.T2}>Shift</Text>
            <Select value={selectedShift} onChange={setSelectedShift} placeholder="Shift" size="small">
              {shiftAllocationModel.availableShifts.map((shift, index) => (
                <SelectOption key={index} value={JSON.stringify(shift)} label={renderShift(shift)} />
              ))}
            </Select>
            <Row alignmentHorizontal="end">
              {cachedResponse ? (
                <Text type={TEXTS.T4} color="secondary">
                  Plan ID: {cachedResponse.planId}, Last updated:
                  {parseEpochToDateString(cachedResponse.updatedAt)}
                </Text>
              ) : (
                <Text></Text>
              )}
            </Row>
          </Row>

          {/* {!isFCLMTrack && (
            <Alert type="warning" size="medium">
              You have associates that have not yet been tracked. When all associates have clocked in, press the FCLM
              Track button
            </Alert>
          )}

          {isFCLMTrack && (
            <Alert type="success" size="medium">
              The Labor board has been successfully tracked
            </Alert>
          )} */}

          <Row alignmentHorizontal="justify" width="100%">
            <TabGroup value={currentTab} onChange={onChangeTab} fill="line">
              <Tab value={TIME_WINDOW_PHASES.INDUCT_AND_STOW}>
                <Text>Induct & Stow </Text>
              </Tab>

              <Tooltip position="top" title="Coming soon">
                <Tab disabled value={TIME_WINDOW_PHASES.PICK_AND_STAGE}>
                  <Text> Pick & Stage</Text>
                </Tab>
              </Tooltip>
            </TabGroup>
            <Row spacing="200">
              <Button size="small" type="tertiary" onClick={onClickRemoveClockOutAllocations}>
                Remove clocked OUT allocations
              </Button>
              <Button size="small" type="tertiary" onClick={onClickRerun}>
                Re-run
              </Button>
              <Button size="small" type="primary" onClick={onClickSavePlan} disabled={!isBoardUpdated}>
                Save
              </Button>
              <Button size="small" type="primary" onClick={onClickTrackInFCLM} disabled={!isFCLMTrack || isBoardUpdated}>
                FCLM Track
              </Button>
            </Row>
          </Row>
          {currentTab === TIME_WINDOW_PHASES.INDUCT_AND_STOW && (
            <div role="tabpanel" id="tabpanel-planets" aria-labelledby={TIME_WINDOW_PHASES.INDUCT_AND_STOW}>
              {/*<ShiftAllocationProcessPathRow />*/}
              {/*<ShiftAllocationProcessPathRow />*/}
              <ProcessViewLegend></ProcessViewLegend>
              <ProcessPathAllocation
                processInfo={shiftAllocationModel['processInfo']}
                associateInfo={shiftAllocationModel['associateInfo']}
                clusterDefinitions={shiftAllocationModel['clusterDefinitions']}
              />
            </div>
          )}
          {currentTab === TIME_WINDOW_PHASES.PICK_AND_STAGE && (
            <div role="tabpanel" id="tabpanel-planets" aria-labelledby={TIME_WINDOW_PHASES.PICK_AND_STAGE}>
              {/*<ShiftAllocationProcessPathRow />*/}
              {/*<ShiftAllocationProcessPathRow />*/}
              <ProcessViewLegend></ProcessViewLegend>
              <ProcessPathAllocation
                processInfo={shiftAllocationModel['processInfo']}
                associateInfo={shiftAllocationModel['associateInfo']}
                clusterDefinitions={shiftAllocationModel['clusterDefinitions']}
              />
            </div>
          )}
          <OnDragEndModal
            open={openOnDragEndModal}
            setOpen={setOpenOnDragEndModal}
            result={result}
            provided={provided}
            processInfo={shiftAllocationModel['processInfo']}
            associateInfo={shiftAllocationModel['associateInfo']}
            setIsBoardUpdated = {setIsBoardUpdated}
          />
          {isMissingPreShiftPlan && (
            <CustomAlert
              title="A pre-shift plan has not been run for the station at this date, please run a pre-shift plan before running labor allocation."
              disabled="true"
            />
          )}
          {alertMsg && <CustomAlert title={alertMsg} disabled="true" />}
        </Column>

        <Column height={'100%'} spacingInset={'none'} spacing="none" maxWidth={'25%'}>
          <Sheet type="push" side="end" open={availableAssociatesVisibility} spacingInset={'none'} spacing="none">
            <AvailableAssociates setOpen={setAvailableAssociatesVisibility} />
          </Sheet>
        </Column>
      </DragDropContext>
    </Row>
  );
}

export default ShiftAllocation;
