import React, { useState, useCallback } from 'react';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import Modal, { ModalFooter } from '@amzn/meridian/modal';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import exportLargeTokens from '@amzn/meridian-tokens/base/icon/export-large';
import Table, { TableRow, TableCell } from '@amzn/meridian/table';
import RadioButton from '@amzn/meridian/radio-button';
import { displayTime, displayDate } from '../../utils';

const VIEW = {
  TPH: 'TPH',
  TOTAL_UNITS: 'TOTAL_UNITS'
};

const OutboundPlanningThroughputExpanded = ({ plannedThroughput }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [view, setView] = useState(VIEW.TPH);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const getMultis = (tph) => {
    if (view === VIEW.TPH) {
      return Math.round(tph.planned_outbound_throughput_units_per_hour_pick2rebin_pack);
    } else if (view === VIEW.TOTAL_UNITS) {
      return Math.round(tph.planned_outbound_throughput_units_total_pick2rebin_pack);
    } else {
      return 'N/A';
    }
  };

  const getVnaAndSingles = (tph) => {
    if (view === VIEW.TPH) {
      return Math.round(
        tph.planned_outbound_throughput_units_per_hour_vna_pack +
          tph.planned_outbound_throughput_units_per_hour_singles_pack
      );
    } else if (view === VIEW.TOTAL_UNITS) {
      return Math.round(
        tph.planned_outbound_throughput_units_total_vna_pack + tph.planned_outbound_throughput_units_total_singles_pack
      );
    } else {
      return 'N/A';
    }
  };

  const getTotal = (tph) => {
    if (view === VIEW.TPH) {
      return Math.round(tph.planned_outbound_throughput_units_per_hour);
    } else if (view === VIEW.TOTAL_UNITS) {
      return Math.round(tph.planned_outbound_throughput_units_total);
    } else {
      return 'N/A';
    }
  };

  return (
    <Column>
      <Row alignmentHorizontal="end" widths="fit">
        <div style={{ cursor: 'pointer' }} onClick={() => setIsOpen(true)}>
          <Icon tokens={exportLargeTokens}></Icon>
        </div>
      </Row>

      <Modal title="Shift Headcount" open={isOpen} onClose={onClose} closeLabel="Close">
        <Row alignmentHorizontal="right">
          <RadioButton checked={view === VIEW.TPH} onChange={setView} value={VIEW.TPH} size="medium">
            TPH
          </RadioButton>
          <RadioButton checked={view === VIEW.TOTAL_UNITS} onChange={setView} value={VIEW.TOTAL_UNITS} size="medium">
            Total Units
          </RadioButton>
        </Row>
        <Row widths="fill" spacingInset="400">
          <Table headerRows={1} spacing={'small'}>
            <TableRow>
              <TableCell>Shift Half</TableCell>
              <TableCell>Start Time</TableCell>
              <TableCell>End Time</TableCell>
              <TableCell>{view === VIEW.TPH ? 'Multis TPH' : 'Multis Units'}</TableCell>
              <TableCell>{view === VIEW.TPH ? 'VNA & Singles TPH' : 'VNA & Singles Units'}</TableCell>
              <TableCell>{view === VIEW.TPH ? 'Total TPH' : 'Total Units'}</TableCell>
            </TableRow>
            {plannedThroughput
              .filter((tph) => tph.description.includes('Half'))
              .sort((a, b) => (a.start_time_local > b.start_time_local ? 1 : -1))
              .map((tph) => (
                <TableRow>
                  <TableCell>{tph.description}</TableCell>
                  <TableCell>
                    {displayDate(tph.start_time_local)} - {displayTime(tph.start_time_local)}
                  </TableCell>
                  <TableCell>
                    {displayDate(tph.end_time_local)} - {displayTime(tph.end_time_local)}
                  </TableCell>
                  <TableCell>{getMultis(tph)}</TableCell>
                  <TableCell>{getVnaAndSingles(tph)}</TableCell>
                  <TableCell>{getTotal(tph)}</TableCell>
                </TableRow>
              ))}
          </Table>
        </Row>
        <ModalFooter>
          <Row alignmentHorizontal="end" widths="fit">
            <Button type="secondary" size="small" onClick={onClose}>
              Close
            </Button>
          </Row>
        </ModalFooter>
      </Modal>
    </Column>
  );
};
export default OutboundPlanningThroughputExpanded;
