import React, { useState } from 'react';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';

import VerifyVolume from './VerifyVolume';
import VerifyRoster from './VerifyRoster';
import VerifyRates from './VerifyRates';
import LoadingView from '../../../handler/loadingHandler/LoadingView';

import { changeDateFormat, parseEpochToDateString, planTypeFormat } from '../../../utils/helpers';
import { TEXTS } from '../../../utils/constants';
import { getExperimentMode, getIsTest } from '../../../utils/networkUtil';

const Detail = ({ planId, createdAt, updatedAt, modifiedBy }) => (
  <Column alignmentVertical="justify" alignmentHorizontal="left">
    <Text type={TEXTS.H3}>Detail</Text>
    <Column>
      <Row>
        <Column>
          <Text type={TEXTS.T3} color="secondary">
            <div style={{ height: '40px' }}>Plan ID</div>
          </Text>
          <Text type={TEXTS.T3} color="secondary">
            Created
          </Text>
          <Text type={TEXTS.T3} color="secondary">
            Last Modified
          </Text>
          <Text type={TEXTS.T3} color="secondary">
            Modified by
          </Text>
        </Column>
        <Column>
          <Text type={TEXTS.H4}>
            <div style={{ maxWidth: '300px', height: '40px' }}>{planId}</div>
          </Text>
          <Text type={TEXTS.H4}>{createdAt}</Text>
          <Text type={TEXTS.H4}>{updatedAt}</Text>
          <Text type={TEXTS.H4}>{modifiedBy}</Text>
        </Column>
      </Row>
    </Column>
  </Column>
);

const TextWrapper = ({ step, thisStep, onClick, children }) => {
  return (
    <div
      style={{
        backgroundColor: step === thisStep ? '#e5f6fc' : '#f8fbfa',
        width: '100%',
        borderRadius: '3px',
        padding: '8px',
        cursor: step > thisStep ? 'pointer' : 'auto',
        opacity: step < thisStep ? '50%' : '100%'
      }}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

const PlanMenu = ({ step, setStep, planId, date, createdAt, updatedAt, modifiedBy, planType }) => {
  return (
    <Column width="30%" height="100vh" minWidth="300px" className="screen2-plan-menu" spacingInset="400">
      <Text type={TEXTS.H2}>{`${planType} Plan - OFD: ${date}`}</Text>
      <Text type={TEXTS.T2}>
        Verify all three steps, make any changes needed and save for the {planType.toLowerCase()} plan.
      </Text>
      {getIsTest() ? (
        <div>
          <Text color="error">Developer mode.</Text>
          <Text color="error">{getExperimentMode()}</Text>
        </div>
      ) : null}
      <Detail planId={planId} createdAt={createdAt} updatedAt={updatedAt} modifiedBy={modifiedBy} />
      <Text type={TEXTS.H4} color={step > 1 ? 'secondary' : 'primary'}>
        Step 1 {step > 1 ? ' - complete' : null}
      </Text>
      <TextWrapper
        thisStep={1}
        step={step}
        onClick={() => {
          if (step > 1) setStep(1);
        }}
      >
        <Text type={TEXTS.T2} className="menu-text-color">
          Verify Volume
        </Text>
      </TextWrapper>
      <Text type={TEXTS.H4} color={step > 2 ? 'secondary' : 'primary'}>
        Step 2 {step > 2 ? ' - complete' : null}
      </Text>
      <TextWrapper
        thisStep={2}
        step={step}
        onClick={() => {
          if (step > 2) setStep(2);
        }}
      >
        <Text type={TEXTS.T2} className="menu-text-color">
          Verify Roster and Attendance
        </Text>
      </TextWrapper>
      <Text type={TEXTS.H4} color={step > 3 ? 'secondary' : 'primary'}>
        Step 3 {step > 3 ? ' - complete' : null}
      </Text>
      <TextWrapper
        thisStep={3}
        step={step}
        onClick={() => {
          if (step > 3) setStep(3);
        }}
      >
        <Text type={TEXTS.T2} className="menu-text-color">
          Verify Rates
        </Text>
      </TextWrapper>
    </Column>
  );
};

const CreatePlan = ({
  data,
  callAPI,
  date,
  dataStorage,
  setData,
  setDataStorage,
  updatePlanOutputAndStatus,
  setScreen,
  isUserAuthorisedForShiftPlanOverrides,
  isFirstLoad,
  setIsFirstLoad
}) => {
  const [step, setStep] = useState(1);

  if (step === 1 && isFirstLoad) {
    data.input.packageProjectionInput = JSON.parse(dataStorage).input.packageProjectionInput;
    data.input.resourceProductivityInput = JSON.parse(dataStorage).input.resourceProductivityInput;
    data.input.labourAvailability = JSON.parse(dataStorage).input.labourAvailability;
    setIsFirstLoad(false);
  }

  return (
    <Row height="100%" width="100%">
      <PlanMenu
        step={step}
        setStep={setStep}
        planId={data.planId}
        date={changeDateFormat(date)}
        createdAt={parseEpochToDateString(data.createdAt)}
        updatedAt={parseEpochToDateString(data.updatedAt)}
        modifiedBy={data.lastUpdatedBy + '@'}
        planType={planTypeFormat(data.planType)}
      />
      {/*above column position is absolute, so we need to make space for that*/}
      <Column width="30%" height="100%" minWidth="300px" spacingInset="400" />
      <Column width="66%" height="100%" spacingInset="xxsmall" className="screen2-content">
        {step === 1 ? (
          <VerifyVolume
            data={data}
            setData={setData}
            dataStorage={dataStorage}
            setStep={setStep}
            dupData={JSON.stringify(data)}
            isUserAuthorisedForShiftPlanOverrides={isUserAuthorisedForShiftPlanOverrides}
          />
        ) : step === 2 ? (
          <VerifyRoster
            data={data}
            setData={setData}
            dataStorage={dataStorage}
            setStep={setStep}
            dupData={JSON.stringify(data)}
            isUserAuthorisedForShiftPlanOverrides={isUserAuthorisedForShiftPlanOverrides}
          />
        ) : step === 3 ? (
          <VerifyRates
            data={data}
            setData={setData}
            setDataStorage={setDataStorage}
            callAPI={callAPI}
            dataStorage={dataStorage}
            setScreen={setScreen}
            updatePlanOutputAndStatus={updatePlanOutputAndStatus}
            isUserAuthorisedForShiftPlanOverrides={isUserAuthorisedForShiftPlanOverrides}
            planVersion={data.planVersion}
          />
        ) : (
          <LoadingView />
        )}
      </Column>
    </Row>
  );
};

export default CreatePlan;
