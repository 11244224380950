import React, { useState, useCallback } from 'react';
import Table, { TableCell, TableRow } from '@amzn/meridian/table';
import Badge from '@amzn/meridian/badge';

import {
  chainWalk,
  isJSONEmpty,
  moveHardCodedElementsToBeginningOfArray,
  parseTimeStampToDateString,
  removeSecondsFromTimeStamp
} from '../../utils/helpers';
import {
  ALL_CYCLES,
  DYNAMIC_PLAN_DIFF,
  DYNAMIC_PLAN_RENAMED_ROLES,
  DYNAMIC_PLAN_SHOW_PLANNED_FLOW_FOR_PROCESSES,
  FIRST_DYNAMIC_PLAN_ROLES_TO_SHOW,
  PLAN_VERSIONS,
  processMapping,
  TEXTS
} from '../../utils/constants';
import Text from '@amzn/meridian/text';
import Row from '@amzn/meridian/row';
import Select, { SelectOption } from '@amzn/meridian/select';
import {
  processDynamicV1HeadCountTableData,
  processDynamicV2HeadCountTableData
} from '../../utils/processDynamicHeadCountTableData';

// Update role name display if in DYNAMIC_PLAN_RENAMED_ROLES
const getUpdatedRoleName = (role) => {
  return role in DYNAMIC_PLAN_RENAMED_ROLES ? DYNAMIC_PLAN_RENAMED_ROLES[role] : role;
};

const getPlannedFlowAndRateForProcess = (
  cycleEffectiveProcessRates,
  availableCycles,
  selectedCycle,
  processHeadcountMap,
  process
) => {
  let plannedFlow = NaN;
  let plannedRate = NaN;

  if (selectedCycle === ALL_CYCLES) {
    if (availableCycles.length !== 2)
      return {
        plannedFlow,
        plannedRate
      };
    selectedCycle = availableCycles[1];
  }

  plannedRate = chainWalk(
    () => parseFloat(cycleEffectiveProcessRates[selectedCycle][getUpdatedRoleName(process)]),
    NaN
  );
  if (isNaN(plannedRate))
    return {
      plannedRate,
      plannedFlow
    };
  const headcount = chainWalk(() => parseFloat(processHeadcountMap[process]), NaN);
  if (!isNaN(headcount) && DYNAMIC_PLAN_SHOW_PLANNED_FLOW_FOR_PROCESSES.includes(process))
    plannedFlow = headcount * plannedRate;
  return {
    plannedFlow,
    plannedRate
  };
};

// Return expandable sorted based on processMapping
const CustomExpandableRow = ({
  filteredRoles,
  columnHeadings,
  diff,
  listProcess,
  isPrevOutputPresent,
  cycleEffectiveProcessRates,
  selectedCycle,
  availableCycles
}) => {
  return filteredRoles.map((role, index) => {
    if (listProcess.includes(role)) {
      const { plannedFlow, plannedRate } = getPlannedFlowAndRateForProcess(
        cycleEffectiveProcessRates,
        availableCycles,
        selectedCycle,
        diff[`${DYNAMIC_PLAN_DIFF.UPDATED_HC} actual`],
        role
      );

      return (
        <TableRow key={index} highlightOnHover={true}>
          <TableCell>
            <Row>{getUpdatedRoleName(role)}</Row>
          </TableCell>
          {columnHeadings.map((column, i) => {
            let actualCount = chainWalk(() => diff[column][role], null);
            // If no diff changes (actualCount is null), show actual data at time.
            if (actualCount == null) {
              // If Previous Output was not originally present, do not show count.
              if (column === DYNAMIC_PLAN_DIFF.PREVIOUS_HC && !isPrevOutputPresent) {
                return <TableCell key={i} alignmentHorizontal={'right'} />;
              }
              return (
                <TableCell key={i} alignmentHorizontal={'right'}>
                  {diff[column + ' actual'][role]}
                </TableCell>
              );
            }
            // Keep Previous HC data font as normal
            if (column === DYNAMIC_PLAN_DIFF.PREVIOUS_HC) {
              return (
                <TableCell key={i} alignmentHorizontal={'right'}>
                  {actualCount}
                </TableCell>
              );
            }
            // Change Updated HC data font if a change is present.
            if (diff[DYNAMIC_PLAN_DIFF.PREVIOUS_HC][role] > diff[DYNAMIC_PLAN_DIFF.UPDATED_HC][role]) {
              return (
                <TableCell key={i} alignmentHorizontal={'right'}>
                  <div className="red-bold-text-color">{actualCount}</div>
                </TableCell>
              );
            } else {
              return (
                <TableCell key={i} alignmentHorizontal={'right'}>
                  <div className="green-bold-text-color">{actualCount}</div>
                </TableCell>
              );
            }
          })}
          <TableCell alignmentHorizontal={'right'}>{!isNaN(plannedFlow) ? Math.floor(plannedFlow) : ''}</TableCell>
          <TableCell alignmentHorizontal={'right'}>{!isNaN(plannedRate) ? Math.floor(plannedRate) : ''}</TableCell>
        </TableRow>
      );
    } else {
      return null;
    }
  });
};

const UncontrolledExpandableRow = ({ children, rowComponents }) => {
  const [open, setOpen] = useState(false);
  const onClick = useCallback(() => setOpen(!open), [open]);
  return (
    <TableRow open={open} onClick={onClick} rowComponents={rowComponents} highlightOnHover={true}>
      {children}
    </TableRow>
  );
};

const getTotalHeadCountForListProcessDuringShift = (
  filteredRoles,
  columnHeading,
  diff,
  listProcess,
  val,
  isPrevOutputPresent
) => {
  const totalHeadCountForListProcessString = `${columnHeading} ${val} TOTAL`;
  let count = diff[totalHeadCountForListProcessString];
  // Previous HC will have default text
  if (columnHeading === DYNAMIC_PLAN_DIFF.PREVIOUS_HC) {
    // If Previous Output was not originally present, do not show count.
    return isPrevOutputPresent ? count : '';
  }
  // If Updated HC data is less than Previous HC, show bold red text with badge
  if (diff[`${DYNAMIC_PLAN_DIFF.PREVIOUS_HC} ${val} TOTAL`] > diff[`${DYNAMIC_PLAN_DIFF.UPDATED_HC} ${val} TOTAL`]) {
    return (
      <div className="red-bold-text-color">
        {count}
        <div className="badge-spacing">
          <Badge />
        </div>
      </div>
    );
  } else if (
    diff[`${DYNAMIC_PLAN_DIFF.PREVIOUS_HC} ${val} TOTAL`] < diff[`${DYNAMIC_PLAN_DIFF.UPDATED_HC} ${val} TOTAL`]
  ) {
    // If Updated HC data is greater than Previous HC, show bold green text with badge
    return (
      <div className="green-bold-text-color">
        {count}
        <div className="badge-spacing">
          <Badge />
        </div>
      </div>
    );
  } else {
    // If values are same, check if any process were different internally.
    return diff[DYNAMIC_PLAN_DIFF.DIFF_HC][val] ? (
      <div>
        <b>{count}</b>
        <div className="badge-spacing">
          <Badge />
        </div>
      </div>
    ) : (
      count
    );
  }
};

export const getDynamicHeadCountDiff = (
  previousPlanVersion,
  previousOutput,
  previousOutputMetaDataKey,
  currentPlanVersion,
  currentOutput,
  currentOutputMetaDataKey,
  selectedCycle
) => {
  let tableData = {};
  let previousTimes =
    previousPlanVersion === PLAN_VERSIONS.DYNAMIC_V1
      ? chainWalk(() => processDynamicV1HeadCountTableData(previousOutput), [])
      : previousPlanVersion === PLAN_VERSIONS.DYNAMIC_V2
      ? chainWalk(() => processDynamicV2HeadCountTableData(previousOutput, selectedCycle), [])
      : [];

  let currentTimes =
    currentPlanVersion === PLAN_VERSIONS.DYNAMIC_V1
      ? chainWalk(() => processDynamicV1HeadCountTableData(currentOutput), [])
      : currentPlanVersion === PLAN_VERSIONS.DYNAMIC_V2
      ? chainWalk(() => processDynamicV2HeadCountTableData(currentOutput, selectedCycle), [])
      : [];

  previousOutputMetaDataKey = previousOutputMetaDataKey ? removeSecondsFromTimeStamp(previousOutputMetaDataKey) : '';
  currentOutputMetaDataKey = currentOutputMetaDataKey ? removeSecondsFromTimeStamp(currentOutputMetaDataKey) : '';
  console.log(previousOutputMetaDataKey);
  console.log(currentOutputMetaDataKey);

  // Meta day key will have stable plan id. If this is undefined or not in data, show first time stamp data as default.
  const latestTimeStampPrev =
    Object.keys(previousTimes).length > 0 && previousOutputMetaDataKey && previousTimes[previousOutputMetaDataKey]
      ? previousOutputMetaDataKey
      : Object.keys(previousTimes).length > 0
      ? Object.keys(previousTimes)[0]
      : '';

  const latestTimeStampCurr =
    Object.keys(currentTimes).length > 0 && currentOutputMetaDataKey && currentTimes[currentOutputMetaDataKey]
      ? currentOutputMetaDataKey
      : Object.keys(currentTimes).length > 0
      ? Object.keys(currentTimes)[0]
      : '';

  const roles = Object.keys(currentTimes).length > 0 ? Object.keys(currentTimes[latestTimeStampCurr]) : [];

  tableData[DYNAMIC_PLAN_DIFF.PREVIOUS_HC] = {};
  tableData[DYNAMIC_PLAN_DIFF.UPDATED_HC] = {};
  tableData[DYNAMIC_PLAN_DIFF.DIFF_HC] = {};
  tableData[`${DYNAMIC_PLAN_DIFF.PREVIOUS_HC} actual`] = {};
  tableData[`${DYNAMIC_PLAN_DIFF.UPDATED_HC} actual`] = {};

  roles.forEach((role) => {
    if (!previousTimes[latestTimeStampPrev][role]) {
      previousTimes[latestTimeStampPrev][role] = 0;
    }

    if (!currentTimes[latestTimeStampCurr][role]) {
      currentTimes[latestTimeStampCurr][role] = 0;
    }

    if (currentTimes[latestTimeStampCurr][role] !== previousTimes[latestTimeStampPrev][role]) {
      tableData[DYNAMIC_PLAN_DIFF.PREVIOUS_HC][role] = previousTimes[latestTimeStampPrev][role];
      tableData[DYNAMIC_PLAN_DIFF.UPDATED_HC][role] = currentTimes[latestTimeStampCurr][role];
    }
    tableData[`${DYNAMIC_PLAN_DIFF.PREVIOUS_HC} actual`][role] = previousTimes[latestTimeStampPrev][role];
    tableData[`${DYNAMIC_PLAN_DIFF.UPDATED_HC} actual`][role] = currentTimes[latestTimeStampCurr][role];

    Object.keys(processMapping).map((val) => {
      let previousHCValString = `${DYNAMIC_PLAN_DIFF.PREVIOUS_HC} ${val}`;
      let updatedHCValString = `${DYNAMIC_PLAN_DIFF.UPDATED_HC} ${val}`;
      if (!tableData[previousHCValString]) {
        tableData[previousHCValString] = {};
        tableData[updatedHCValString] = {};
      }
      if (processMapping[val].includes(role)) {
        tableData[previousHCValString][role] = previousTimes[latestTimeStampPrev][role];
        tableData[updatedHCValString][role] = currentTimes[latestTimeStampCurr][role];
      }
    });
  });
  // Calculates sum by mapping and returning HC data for each role in process path.
  Object.keys(processMapping).map((val) => {
    let previousHCValString = `${DYNAMIC_PLAN_DIFF.PREVIOUS_HC} ${val}`;
    let updatedHCValString = `${DYNAMIC_PLAN_DIFF.UPDATED_HC} ${val}`;
    let previousHCValTotalString = `${DYNAMIC_PLAN_DIFF.PREVIOUS_HC} ${val} TOTAL`;
    let updatedHCValTotalString = `${DYNAMIC_PLAN_DIFF.UPDATED_HC} ${val} TOTAL`;
    tableData[previousHCValTotalString] = chainWalk(
      () =>
        Object.keys(tableData[previousHCValString])
          .map((role) => tableData[previousHCValString][role])
          .reduce((a, b) => a + b, 0),
      0
    );
    tableData[updatedHCValTotalString] = chainWalk(
      () =>
        Object.keys(tableData[updatedHCValString])
          .map((role) => tableData[updatedHCValString][role])
          .reduce((a, b) => a + b, 0),
      0
    );
  });
  // Checks if every processMapping has at least one role where there is a delta.
  for (let i in Object.keys(processMapping)) {
    let val = Object.keys(processMapping)[i];
    tableData[DYNAMIC_PLAN_DIFF.DIFF_HC][val] = false;
    processMapping[val].forEach((role) => {
      if (Object.keys(tableData[DYNAMIC_PLAN_DIFF.UPDATED_HC]).includes(role)) {
        tableData[DYNAMIC_PLAN_DIFF.DIFF_HC][val] = true;
      }
    });
  }
  tableData[`${DYNAMIC_PLAN_DIFF.PREVIOUS_HC} timeStamp`] = latestTimeStampPrev;
  tableData[`${DYNAMIC_PLAN_DIFF.UPDATED_HC} timeStamp`] = latestTimeStampCurr;
  return [tableData, latestTimeStampPrev, latestTimeStampCurr, currentTimes];
};

const DiffDynamicHeadCountTable = ({ previousOutput, previousData, currentOutput, currentData, cycles }) => {
  let previousOutputMetaDataKey;
  let currentOutputMetaDataKey;
  let isPrevOutputPresent = true;

  const [selectedCycle, setSelectedCycle] = useState(ALL_CYCLES);

  let availableCycles = [];

  availableCycles = Array.from(cycles);
  availableCycles.sort();

  availableCycles.unshift(ALL_CYCLES);

  let previousPlanVersion = previousData['planVersion'];
  let currentPlanVersion = currentData['planVersion'];

  // If previous output does not have data. Put current output in diff but remove from table.
  if (!previousOutput && currentOutput) {
    previousOutput = { ...currentOutput };
    isPrevOutputPresent = false;
    previousPlanVersion = currentPlanVersion;
  }

  // If plan versions are different, default previous as current (will show no diff)
  if (previousPlanVersion !== currentPlanVersion) {
    previousOutput = { ...currentOutput };
    isPrevOutputPresent = false;
    previousPlanVersion = currentPlanVersion;
  }

  previousOutputMetaDataKey = chainWalk(() => previousOutput['metadata']['STABLE_PLAN_TIME'], '');
  currentOutputMetaDataKey = chainWalk(() => currentOutput['metadata']['STABLE_PLAN_TIME'], '');

  const [diff, latestTimeStampPrev, latestTimeStampCurr, currentTimes] = getDynamicHeadCountDiff(
    previousPlanVersion,
    previousOutput,
    previousOutputMetaDataKey,
    currentPlanVersion,
    currentOutput,
    currentOutputMetaDataKey,
    selectedCycle
  );
  if (isJSONEmpty(diff[DYNAMIC_PLAN_DIFF.UPDATED_HC])) {
    console.log('No delta of plan');
  }
  console.log(diff);

  console.log(currentTimes);
  // Determine if all headcount at role and time is zero
  const isPlannedHeadCountAtRoleAllZeroes = (headCountPlannerOutput, role) => {
    return Object.keys(headCountPlannerOutput).every(
      (time) => !headCountPlannerOutput[time][role] || headCountPlannerOutput[time][role] === 0
    );
  };

  // Relevant Data
  // Roles to map for
  const roles = Object.keys(currentTimes).length > 0 ? Object.keys(currentTimes[Object.keys(currentTimes)[0]]) : [];

  /* 1. Reorder processes with FIRST_DYNAMIC_PLAN_ROLES_TO_SHOW at beginning (if exist)
       2. Filter our processes not in diff (no delta) and have 0's for entire shift.
    */
  const filteredRoles = moveHardCodedElementsToBeginningOfArray(FIRST_DYNAMIC_PLAN_ROLES_TO_SHOW, roles).filter(
    (role) =>
      Object.keys(diff[DYNAMIC_PLAN_DIFF.UPDATED_HC]).includes(role) ||
      !isPlannedHeadCountAtRoleAllZeroes(currentTimes, role)
  );
  console.log(filteredRoles);

  console.log(latestTimeStampPrev);
  console.log(latestTimeStampCurr);
  const columnHeadings = [DYNAMIC_PLAN_DIFF.PREVIOUS_HC, DYNAMIC_PLAN_DIFF.UPDATED_HC];

  const targetHCTime = chainWalk(() => parseTimeStampToDateString(currentOutputMetaDataKey), '');

  const headerCells = columnHeadings.map((column, ind) => {
    return (
      <TableCell key={ind} alignmentHorizontal={'right'} alignmentVertical={'center'}>
        <Text type={TEXTS.H3}>
          {column === DYNAMIC_PLAN_DIFF.PREVIOUS_HC ? `Old HC Target` : `New HC Target ${targetHCTime}`}
        </Text>
      </TableCell>
    );
  });

  const canShowCycleFilter = availableCycles && availableCycles.length > 2;

  return (
    <div>
      <Table
        headerRows={1}
        headerColumns={1}
        showDividers={true}
        spacing="small"
        showStripes={true}
        fixHeaderRows={true}
        fixHeaderRowsFullWidth={true}
        rowComponents={[UncontrolledExpandableRow, TableRow]}
      >
        <Row width="100%" widths="fill">
          <Text type={TEXTS.H2}>Recommended Path Moves</Text>
          {canShowCycleFilter && (
            <Row alignmentHorizontal={'right'}>
              <Select
                value={selectedCycle}
                onChange={setSelectedCycle}
                placeholder="Select cycle"
                alignmentHorizontal="right"
              >
                {availableCycles.map((availableCycle) => (
                  <SelectOption key={availableCycle} value={availableCycle} label={availableCycle} />
                ))}
              </Select>
            </Row>
          )}
        </Row>
        <TableRow>
          <TableCell>
            <Text type={TEXTS.H2}>Path</Text>
          </TableCell>
          {headerCells}
          <TableCell alignmentHorizontal={'right'} alignmentVertical={'center'}>
            <Text type={TEXTS.H3}>Planned Flow</Text>
          </TableCell>
          <TableCell alignmentHorizontal={'right'} alignmentVertical={'center'}>
            <Text type={TEXTS.H3}>Planned Rate</Text>
          </TableCell>
        </TableRow>
        {Object.keys(processMapping).map((val, index) => (
          <UncontrolledExpandableRow key={index}>
            <TableCell>{val}</TableCell>
            <TableCell alignmentHorizontal={'right'}>
              {getTotalHeadCountForListProcessDuringShift(
                filteredRoles,
                DYNAMIC_PLAN_DIFF.PREVIOUS_HC,
                diff,
                processMapping[val],
                val,
                isPrevOutputPresent
              )}
            </TableCell>
            <TableCell alignmentHorizontal={'right'}>
              {getTotalHeadCountForListProcessDuringShift(
                filteredRoles,
                DYNAMIC_PLAN_DIFF.UPDATED_HC,
                diff,
                processMapping[val],
                val
              )}
            </TableCell>
            <TableCell alignmentHorizontal={'right'}> </TableCell>
            <TableCell alignmentHorizontal={'right'}> </TableCell>
            {CustomExpandableRow({
              filteredRoles,
              columnHeadings,
              diff,
              listProcess: processMapping[val],
              isPrevOutputPresent,
              cycleEffectiveProcessRates: chainWalk(
                () => currentData.input.resourceProductivityInput.cycleEffectiveProcessRates,
                {}
              ),
              selectedCycle,
              availableCycles
            })}
          </UncontrolledExpandableRow>
        ))}
      </Table>
    </div>
  );
};

export default DiffDynamicHeadCountTable;
/*
For debugging
<div style={{ marginBottom: '3vh'}}/>
<Box type="fill" spacingInset="400" width="100%">
    <pre style={{ whiteSpace: 'pre-wrap' }}>Diff between previous and current PDHCT</pre>
</Box>
<Box type="fill" spacingInset="400" width="100%">
    <pre style={{ whiteSpace: 'pre-wrap' }}>{JSON.stringify(diff, null, 2)}</pre>
</Box>

*/
