import React from 'react';

import CustomAlert from '../../../commonComponents/CustomAlert';

import { isDateOld, planTypeFormat } from '../../../utils/helpers';

const CreateShiftPlanAlert = ({ responseData, planId, planType, callAPI, date }) => {
  if (!responseData && !planId) {
    return (
      <CustomAlert
        title={`${planTypeFormat(planType)} Plan has not been run.`}
        buttonTitle={`Run ${planTypeFormat(planType)} Plan`}
        buttonClickHandle={() => {
          callAPI(planType);
        }}
        disabled={isDateOld(date)}
      />
    );
  } else return null;
};

export default CreateShiftPlanAlert;
