import React from 'react';
import MetricView from './MetricView';
import { displayTime } from '../../utils';
import { chainWalk } from '../../../../utils/helpers';
const PROCESS_PATH_OUTBOUND = 'outbound';

const aggregateDemandEntries = (demand) => {
  const aggregateVolumes = [];
  demand.forEach((volume) => {
    const aggregateVolume = aggregateVolumes.find(
      (aggregateVolume) =>
        aggregateVolume.volume_source === volume.volume_source && aggregateVolume.process_role === volume.process_role
    );
    if (aggregateVolume) {
      aggregateVolume.volume += volume.volume || 0;
    } else {
      aggregateVolumes.push({ ...volume });
    }
  });
  return aggregateVolumes;
};

const ShiftOutboundVolume = ({ shift, demand }) => {
  const cptList = shift ? [...new Set(chainWalk(() => demand.map(({ cpt_local }) => cpt_local), []))] : [];
  cptList.sort();
  const volumeByCpt = [];
  for (let i = 0; i < cptList.length; i++) {
    const cpt = cptList[i];
    if (cpt > shift.start) {
      const volumeList = demand.filter(
        ({ cpt_local, process_path }) => cpt_local === cpt && process_path === PROCESS_PATH_OUTBOUND
      );
      const total = volumeList.reduce((t, { volume }) => t + volume, 0);
      const aggregateVolumes = aggregateDemandEntries(volumeList);
      volumeByCpt.push({ cpt, total: total.toFixed(0), volumes: aggregateVolumes });
      if (cpt >= shift.end) {
        break;
      }
    }
  }
  return (
    <MetricView
      title={'OB Planned Volume (units)'}
      values={volumeByCpt.map(({ cpt, total, volumes }) => ({
        title: `CPT ${displayTime(cpt)}`,
        subtext: total,
        tooltipValues: volumes.map(
          (volume) => `${volume.volume_source} ${volume.process_role}: ${chainWalk(() => volume.volume.toFixed(0), '')}`
        )
      }))}
    />
  );
};
export default ShiftOutboundVolume;
