export const AVAILABLE_ASSOCIATES_VIEW_CONSTANTS = {
  PAGE_HEADER: 'Available Associates',
  DISALLOWED_PATH: 'Disallowed Path',
  TRAINED_PATH: 'Trained Path',
  SUGGESTED_PATH: 'Suggested Path',
  CLEAR_ALL_BUTTON: 'Clear all',
  SEARCH_PLACEHOLDER: 'Search Name',
  TAB_IN: 'In',
  TAB_OUT: 'Out'
};
