import { METRICS } from './constants';
import { publishClientSideMetrics, publishCustomMetrics } from '../handler/parentDataHandler';
import { getStationCode } from '../utils/networkUtil';

export const METRIC_PROCESS = 'SCC_SHIFTPLAN';

export const CALL_METHODS = {
  GET_ALL_PLANS: 'getAllPlans',
  CREATE_PLAN: 'createPlan',
  GET_PLAN: 'getPlan',
  UPDATE_PLAN_STATUS: 'updatePlanStatus',
  UPDATE_OUTPUT: 'updateOutput'
};

export const publishCount = (processName, metricName) => {
  const request = getMetricPublishRequest(processName, metricName, 1, METRICS.COUNT);
  publishClientSideMetrics(request);
};

export const publishTime = (processName, metricName, startTime) => {
  const metricValue = Date.now() - startTime;
  const request = getMetricPublishRequest(processName, metricName, metricValue, METRICS.TIME);
  publishClientSideMetrics(request);
};

const getMetricPublishRequest = (processName, metricName, metricValue, metricType) => {
  return {
    processName,
    metricName,
    metricValue,
    metricType
  };
};

export function logCustomMetrics(eventType, eventName) {
  let stationCode = getStationCode();
  publishCustomMetrics({
    eventType: eventType,
    eventName: eventName,
    stationCode: stationCode
  });
}
