import React, { useContext } from 'react';
import Alert from '@amzn/meridian/alert';
import Column from '@amzn/meridian/column';

import { GlobalStateContext } from '../../global/context/GlobalStateContext';

const ErrorPage = ({ errorTitle, errorMessage }) => {
  const { restartApp } = useContext(GlobalStateContext);
  return (
    <Column spacingInset="400">
      <Alert type="error" size="large" width="100%" title={errorTitle} onClose={restartApp}>
        {errorMessage.toString()}
      </Alert>
    </Column>
  );
};

export default ErrorPage;
