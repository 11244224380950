import React from 'react';
import Button from '@amzn/meridian/button';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import ViewOutboundVolumes from '../../common/ssd/ViewOutboundVolumes';
import Text from '@amzn/meridian/text';

const VerifyOutboundVolumes = ({ currentUser, plan, setPlan, onGoNext, onCancel }) => {
  return (
    <Column width="100%">
      <Text type={'h400'}>Outbound Volume</Text>
      <Text type={'b400'}>Verify or update the values below and submit.</Text>
      <ViewOutboundVolumes currentUser={currentUser} isEditable={true} plan={plan} setPlan={setPlan} />
      <Row alignmentHorizontal="justify">
        <Button disabled={true} type="secondary">
          Back
        </Button>
        <Row>
          <Button onClick={onCancel} type="tertiary">
            Cancel
          </Button>
          <Button onClick={onGoNext}>Next</Button>
        </Row>
      </Row>
    </Column>
  );
};

export default VerifyOutboundVolumes;
