import React, { useEffect, useState } from 'react';
import ShiftPlanMainMenu from '../shiftPlan/ShiftPlanMainMenu';
import GlobalStateContextProvider from './context/GlobalStateContext';
import ErrorBoundary from '../handler/errorHandler/ErrorBoundary';
import LoadingContextProvider from '../handler/loadingHandler/context/LoadingContext';
import { Route, Switch } from 'react-router-dom';
import ShiftRotation from '../shiftRotation/ShiftRotation';
import ShiftAdherence from '../shiftAdherence/ShiftAdherence';
import ShiftPlanHistory from '../shiftPlanHistory/ShiftPlanHistory';
import PermissionsContextProvider from '../permissions/PermissionsContext';
import { getNodeCapability } from '../handler/parentDataHandler';
import { NODE_TYPE, DARK_MODE } from '../utils/constants';
import { chainWalk } from '../utils/helpers';
import ShiftAllocationContainer from '../shiftAllocation/ShiftAllocationContainer';
import ShiftAllocationAssociateViewContainer from '../shiftAllocation/ShiftAllocationAssociatesViewContainer';
import blueDarkTokens from '@amzn/meridian-tokens/theme/blue-dark';
import blueLightTokens from '@amzn/meridian-tokens/theme/blue-light';
import Theme from '@amzn/meridian/theme';
import { getDarkModeStatus, setDarkModeStateListener } from '../handler/parentDataHandler';
import { AlchemyAnalytics } from '@amzn/alchemy-components/dist/utils/AlchemyAnalytics.es5.js';
import { getUserName } from '../resources/EmployeeResource';
import { getStationCode } from '../utils/networkUtil';
import ShiftAllocationContextProvider from '../shiftAllocation/context/ShiftAllocationContext';

const App = () => {
  const [isLoadingNodeCapability, setIsLoadingNodeCapability] = useState(true);
  const [isSsd, setIsSsd] = useState(false);
  const [currentDarkMode, setCurrentDarkMode] = useState(DARK_MODE.LIGHT);

  useEffect(() => {
    (async () => {
      setDarkModeStateListener(setCurrentDarkMode);
      getDarkModeStatus();

      const nodeCapability = await getNodeCapability();

      const extractedIsSsd = chainWalk(() => JSON.parse(nodeCapability['capability']) === NODE_TYPE.SSD, false);
      setIsSsd(extractedIsSsd);

      setIsLoadingNodeCapability(false);

      const visitorId = await getUserName();
      const analytics = new AlchemyAnalytics({
        apikey: 'ae299fdb-5276-403c-4b78-dd8b69d722d7',
        account: {
          id: 'SCC - AMZL'
        },
        visitor: {
          id: visitorId,
          stationCode: getStationCode()
        }
      });
    })();
  }, []);

  const shiftPlanComponent = isLoadingNodeCapability ? () => <div /> : () => <ShiftPlanMainMenu isSSD={isSsd} />;

  return (
    <Theme tokens={currentDarkMode === DARK_MODE.DARK ? blueDarkTokens : blueLightTokens}>
      <GlobalStateContextProvider>
        <ErrorBoundary>
          <LoadingContextProvider>
            <Switch>
              <PermissionsContextProvider>
                <Route path="/shiftplan" component={shiftPlanComponent} />
                <Route path="/shift-rotation" component={ShiftRotation} />
                <ShiftAllocationContextProvider>
                  <Route path="/shift-allocation" component={ShiftAllocationContainer} />
                  <Route path="/shift-allocation-associate-view" component={ShiftAllocationAssociateViewContainer} />
                </ShiftAllocationContextProvider>
                <Route path="/shift-adherence" component={ShiftAdherence} />
                <Route path="/shiftplan-history" component={ShiftPlanHistory} />
              </PermissionsContextProvider>
            </Switch>
          </LoadingContextProvider>
        </ErrorBoundary>
      </GlobalStateContextProvider>
    </Theme>
  );
};

export default App;
