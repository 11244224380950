import React from 'react';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import { TEXTS } from './constants';
import { isEmpty } from './helpers';

const AddText = ({ children: text, size = TEXTS.T3, color = 'secondary', alignment = 'left' }) => {
  if (isEmpty(text)) {
    return null;
  }
  return (
    <Row alignmentHorizontal="left" widths="fit" spacing="300">
      <Text type={size} color={color} htmlFor="my-input" alignment={alignment}>
        {text}
      </Text>
    </Row>
  );
};

export default AddText;
