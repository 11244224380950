import React from 'react';
import Table, { TableRow, TableCell } from '@amzn/meridian/table';
import { displayTime } from '../../utils';
import { chainWalk } from '../../../../utils/helpers';

const ViewHeadcount = ({ plan }) => {
  const headcountAllocationPivot = chainWalk(
    () => JSON.parse(plan.output.serializedOutput).headcountAllocationPivot,
    []
  );
  const headcountList = [];
  headcountAllocationPivot.forEach(({ start_datetime_local, available_headcount }) => {
    const headcountData = {
      start_datetime_local,
      available_headcount
    };
    if (
      headcountList.length === 0 ||
      headcountData.available_headcount !== headcountList[headcountList.length - 1].available_headcount
    ) {
      headcountList.push({
        start_datetime_local,
        available_headcount
      });
    }
  });

  return (
    <div style={{ overflowX: 'auto', maxWidth: '100%' }}>
      <Table headerRows={1} stickyHeaderColumn={true} stickyHeaderRow={true} headerColumns={1}>
        <TableRow>
          <TableCell width={120} />
          {headcountList.map((headcountData, index) => {
            const timeString =
              index < headcountList.length - 1
                ? `${displayTime(headcountData.start_datetime_local)} - ${displayTime(
                    headcountList[index + 1].start_datetime_local
                  )}`
                : `${displayTime(headcountData.start_datetime_local)} - ${displayTime(
                    headcountAllocationPivot[headcountAllocationPivot.length - 1].start_datetime_local
                  )}`;
            return <TableCell>{timeString}</TableCell>;
          })}
        </TableRow>
        <TableRow backgroundColor="secondary">
          <TableCell>Total Available</TableCell>
          {headcountList.map(({ available_headcount }) => (
            <TableCell>{Math.max(available_headcount, 0)}</TableCell>
          ))}
        </TableRow>
      </Table>
    </div>
  );
};

export default ViewHeadcount;
