import React, { useState } from 'react';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import Column from '@amzn/meridian/column';
import Input from '@amzn/meridian/input';
import Box from '@amzn/meridian/box';
import Alert from '@amzn/meridian/alert';
import Expander from '@amzn/meridian/expander';
import Icon from '@amzn/meridian/icon';
import Tooltip from '@amzn/meridian/tooltip';
import tooltipIcon from '@amzn/meridian-tokens/base/icon/info-knockout';

import AddTooltip from '../../../utils/AddTooltip';

import {
  chainWalk,
  convertToFixedDP,
  getWindowDurationInMin,
  isNumberInvalid,
  isNumberNotInRange,
  isValid
} from '../../../utils/helpers';
import { TEXTS } from '../../../utils/constants';
import { ATTENDANCE_LOWER_LIMIT, ATTENDANCE_OVERRIDE_LIMIT, ATTENDANCE_UPPER_LIMIT } from '../../../utils/guardrails';
import AddText from '../../../utils/AddText';

const PlannedInput = ({
  updateButtonState,
  errorCounter,
  labourAvailabilityHeadCount,
  defaultLabourAvailabilityHeadCount,
  initialAttendancePercentage,
  suggestedAttendancePercentage,
  attendancePercentage: value,
  setAttendancePercentage: setValue,
  isUserAuthorisedForShiftPlanOverrides: isUserAuthorisedForShiftPlanOverrides
}) => {
  const allowedDelta = convertToFixedDP(
    (ATTENDANCE_OVERRIDE_LIMIT *
      convertToFixedDP(
        isValid(suggestedAttendancePercentage) ? suggestedAttendancePercentage : initialAttendancePercentage
      )) /
      100
  );
  const minAllowed = Math.max(
    convertToFixedDP(
      isValid(suggestedAttendancePercentage) ? suggestedAttendancePercentage : initialAttendancePercentage
    ) - allowedDelta,
    ATTENDANCE_LOWER_LIMIT
  );
  const maxAllowed = Math.min(
    convertToFixedDP(
      isValid(suggestedAttendancePercentage) ? suggestedAttendancePercentage : initialAttendancePercentage
    ) + allowedDelta,
    ATTENDANCE_UPPER_LIMIT
  );

  const [isErr, setIsErr] = useState(false);
  const setText = (val) => {
    if (
      isNumberInvalid(val) ||
      isNumberNotInRange(val, minAllowed, maxAllowed, isUserAuthorisedForShiftPlanOverrides)
    ) {
      if (isErr === false) errorCounter.count++;
      setIsErr(true);
    } else {
      if (isErr === true) errorCounter.count--;
      setIsErr(false);
      labourAvailabilityHeadCount.attendancePercentage = convertToFixedDP(val / 100, 2);

      if (defaultLabourAvailabilityHeadCount) {
        defaultLabourAvailabilityHeadCount.attendancePercentage = labourAvailabilityHeadCount.attendancePercentage;
      }
    }
    updateButtonState();
    setValue(val);
  };
  return (
    <Column alignmentVertical="top" spacing="200" height="100%">
      <AddTooltip size={TEXTS.T3}>Attendance %</AddTooltip>
      {isValid(suggestedAttendancePercentage) ? (
        <AddText>{`Suggested: ${suggestedAttendancePercentage}`}</AddText>
      ) : null}
      <Column spacing="xxsmall">
        <Input
          value={value}
          onChange={setText}
          error={isErr}
          focus={isErr ? false : undefined}
          size="small"
          type="number"
          placeholder="Enter value..."
        />
        {isErr && !isUserAuthorisedForShiftPlanOverrides ? (
          <Text color="error" type={TEXTS.T4}>
            Needs to stay within +/- 50% of suggested value with range between 0 to 100
          </Text>
        ) : null}
      </Column>
    </Column>
  );
};

const RosteredHCInput = ({
  labourAvailabilityHeadCount,
  defaultLabourAvailabilityHeadCount,
  initialValue,
  suggestedRosteredHeadCount,
  updateButtonState,
  errorCounter,
  rosteredHeadCount: value,
  setRosteredHeadCount: setValue,
  isUserAuthorisedForShiftPlanOverrides: isUserAuthorisedForShiftPlanOverrides
}) => {
  const [isErr, setIsErr] = useState(false);
  const allowedDelta = convertToFixedDP(
    (ATTENDANCE_OVERRIDE_LIMIT *
      convertToFixedDP(isValid(suggestedRosteredHeadCount) ? suggestedRosteredHeadCount : initialValue)) /
      100
  );
  const minAllowed = Math.max(
    convertToFixedDP(isValid(suggestedRosteredHeadCount) ? suggestedRosteredHeadCount : initialValue) - allowedDelta,
    0
  );
  const maxAllowed =
    convertToFixedDP(isValid(suggestedRosteredHeadCount) ? suggestedRosteredHeadCount : initialValue) + allowedDelta;
  const setText = (val) => {
    if (
      isNumberInvalid(val) ||
      isNumberNotInRange(val, minAllowed, maxAllowed, isUserAuthorisedForShiftPlanOverrides)
    ) {
      if (isErr === false) errorCounter.count++;
      setIsErr(true);
    } else {
      if (isErr === true) errorCounter.count--;
      setIsErr(false);
      labourAvailabilityHeadCount.rosteredHeadcount = convertToFixedDP(val);

      if (defaultLabourAvailabilityHeadCount) {
        defaultLabourAvailabilityHeadCount.rosteredHeadcount = labourAvailabilityHeadCount.rosteredHeadcount;
      }
    }
    updateButtonState();
    setValue(val);
  };
  return (
    <Column alignmentVertical="top" spacing="200" height="100%">
      <AddTooltip size={TEXTS.T3}>Rostered HC</AddTooltip>
      {isValid(suggestedRosteredHeadCount) ? <AddText>{`Suggested: ${suggestedRosteredHeadCount}`}</AddText> : ''}
      <Column spacing="xxsmall">
        <Input
          value={value}
          onChange={setText}
          error={isErr}
          focus={isErr ? false : undefined}
          size="small"
          type="number"
          placeholder="Enter value..."
        />
        {isErr && !isUserAuthorisedForShiftPlanOverrides ? (
          <Text color="error" type={TEXTS.T4}>
            {`Needs to stay within +/- ${ATTENDANCE_OVERRIDE_LIMIT}% of suggested value`}
          </Text>
        ) : null}
      </Column>
    </Column>
  );
};

const NewHireHCInput = ({
  labourAvailabilityHeadCount,
  defaultLabourAvailabilityHeadCount,
  rosteredHeadCount,
  suggestedDay1NewHireHeadCount,
  updateButtonState,
  errorCounter,
  day1NewHireHeadCount: value,
  setDay1NewHireHeadCount: setValue,
  isUserAuthorisedForShiftPlanOverrides: isUserAuthorisedForShiftPlanOverrides
}) => {
  const [isErr, setIsErr] = useState(false);
  // Accept values from [0 to (50% of rosteredHeadcount)]
  const minAllowed = 0;
  const maxAllowed = Math.floor((ATTENDANCE_OVERRIDE_LIMIT / 100) * rosteredHeadCount);
  const setText = (val) => {
    if (
      isNumberInvalid(val) ||
      isNumberNotInRange(val, minAllowed, maxAllowed, isUserAuthorisedForShiftPlanOverrides)
    ) {
      if (isErr === false) errorCounter.count++;
      setIsErr(true);
    } else {
      if (isErr === true) errorCounter.count--;
      setIsErr(false);
      labourAvailabilityHeadCount.day1NewHireHeadcount = convertToFixedDP(val);

      if (defaultLabourAvailabilityHeadCount) {
        defaultLabourAvailabilityHeadCount.day1NewHireHeadcount = labourAvailabilityHeadCount.day1NewHireHeadcount;
      }
    }
    updateButtonState();
    setValue(val);
  };
  return (
    <Column alignmentVertical="top" spacing="200" height="100%">
      <AddTooltip size={TEXTS.T3}>Day 1 New Hire HC</AddTooltip>
      {isValid(suggestedDay1NewHireHeadCount) ? <AddText>{`Suggested: ${suggestedDay1NewHireHeadCount}`}</AddText> : ''}
      <Column spacing="xxsmall">
        <Input
          value={value}
          onChange={setText}
          error={isErr}
          focus={isErr ? false : undefined}
          size="small"
          type="number"
          placeholder="Enter value..."
        />
        {isErr && !isUserAuthorisedForShiftPlanOverrides ? (
          <Text color="error" type={TEXTS.T4}>
            {`Needs to be from ${minAllowed} to ${ATTENDANCE_OVERRIDE_LIMIT}% (${maxAllowed}) of Rostered Headcount`}
          </Text>
        ) : null}
      </Column>
    </Column>
  );
};

const AttendanceBox = ({
  updateButtonState,
  errorCounter,
  labourAvailabilityHeadCount,
  defaultLabourAvailabilityHeadCount,
  initialAttendancePercentage,
  suggestedAttendancePercentage,
  attendancePercentage,
  setAttendancePercentage,
  initialRosteredHeadCount,
  suggestedRosteredHeadCount,
  setRosteredHeadCount,
  rosteredHeadCount,
  isDay1NewHireLaborEnabled,
  setDay1NewHireHeadCount,
  suggestedDay1NewHireHeadCount,
  day1NewHireHeadCount,
  isUserAuthorisedForShiftPlanOverrides
}) => {
  return (
    <Box type="fill" spacingInset="400" height="100%">
      <Column height="100%" alignmentVertical="center" spacing="xxsmall">
        <Row alignmentHorizontal="justify" alignmentVertical="top" widths="fill" spacing="400">
          <PlannedInput
            labourAvailabilityHeadCount={labourAvailabilityHeadCount}
            defaultLabourAvailabilityHeadCount={defaultLabourAvailabilityHeadCount}
            initialAttendancePercentage={initialAttendancePercentage}
            suggestedAttendancePercentage={suggestedAttendancePercentage}
            attendancePercentage={attendancePercentage}
            setAttendancePercentage={setAttendancePercentage}
            updateButtonState={updateButtonState}
            errorCounter={errorCounter}
            isUserAuthorisedForShiftPlanOverrides={isUserAuthorisedForShiftPlanOverrides}
          />
          <RosteredHCInput
            labourAvailabilityHeadCount={labourAvailabilityHeadCount}
            defaultLabourAvailabilityHeadCount={defaultLabourAvailabilityHeadCount}
            initialValue={initialRosteredHeadCount}
            suggestedRosteredHeadCount={suggestedRosteredHeadCount}
            rosteredHeadCount={rosteredHeadCount}
            setRosteredHeadCount={setRosteredHeadCount}
            updateButtonState={updateButtonState}
            errorCounter={errorCounter}
            isUserAuthorisedForShiftPlanOverrides={isUserAuthorisedForShiftPlanOverrides}
          />
          {isDay1NewHireLaborEnabled && (
            <NewHireHCInput
              labourAvailabilityHeadCount={labourAvailabilityHeadCount}
              defaultLabourAvailabilityHeadCount={defaultLabourAvailabilityHeadCount}
              rosteredHeadCount={rosteredHeadCount}
              suggestedDay1NewHireHeadCount={suggestedDay1NewHireHeadCount}
              day1NewHireHeadCount={day1NewHireHeadCount}
              setDay1NewHireHeadCount={setDay1NewHireHeadCount}
              updateButtonState={updateButtonState}
              errorCounter={errorCounter}
              isUserAuthorisedForShiftPlanOverrides={isUserAuthorisedForShiftPlanOverrides}
            />
          )}
        </Row>
      </Column>
    </Box>
  );
};

const ContentDisplayBox = ({ heading, value, additionalText = '' }) => {
  return (
    <Box type="outline" spacingInset="400" height="100%">
      <Column height="100%" width="100%" spacingInset="200" spacing="xxsmall">
        <AddTooltip size={TEXTS.H4} additionalText={additionalText}>
          {heading}
        </AddTooltip>
        <Text type="d50">{value}</Text>
      </Column>
    </Box>
  );
};

const ShiftAttendanceLabourAvailabilityHeadCount = ({
  data,
  shiftGroupAvailability,
  labourAvailabilityHeadCount,
  defaultLabourAvailabilityHeadCount,
  dupLabourAvailabilityHeadCount,
  initialLabourAvailabilityHeadCount,
  suggestedLabourAvailabilityHeadCount,
  updateButtonState,
  errorCounter,
  isUserAuthorisedForShiftPlanOverrides
}) => {
  const [attendancePercentage, setAttendancePercentage] = useState(
    Math.round(
      convertToFixedDP(
        chainWalk(() => labourAvailabilityHeadCount.attendancePercentage, 0),
        2
      ) * 100
    )
  );
  const initialAttendancePercentage = Math.round(
    convertToFixedDP(
      chainWalk(() => dupLabourAvailabilityHeadCount.attendancePercentage, 0),
      2
    ) * 100
  );
  const suggestedAttendancePercentage = suggestedLabourAvailabilityHeadCount
    ? Math.round(
        convertToFixedDP(
          chainWalk(() => suggestedLabourAvailabilityHeadCount.attendancePercentage, 0),
          2
        ) * 100
      )
    : undefined;
  const [rosteredHeadCount, setRosteredHeadCount] = useState(
    convertToFixedDP(chainWalk(() => labourAvailabilityHeadCount.rosteredHeadcount, 0))
  );
  const initialRosteredHeadCount = convertToFixedDP(
    chainWalk(() => initialLabourAvailabilityHeadCount.rosteredHeadcount, 0)
  );
  const suggestedRosteredHeadCount = suggestedLabourAvailabilityHeadCount
    ? convertToFixedDP(chainWalk(() => suggestedLabourAvailabilityHeadCount.rosteredHeadcount, 0))
    : undefined;
  const isDay1NewHireLaborEnabled = chainWalk(() => data.input.labourAvailability.isDay1NewHireLaborEnabled, false);
  const [day1NewHireHeadCount, setDay1NewHireHeadCount] = useState(
    convertToFixedDP(chainWalk(() => labourAvailabilityHeadCount.day1NewHireHeadcount, 0))
  );
  const suggestedDay1NewHireHeadCount = suggestedLabourAvailabilityHeadCount
    ? convertToFixedDP(chainWalk(() => suggestedLabourAvailabilityHeadCount.day1NewHireHeadcount, 0))
    : undefined;
  const unpaidBreaks = convertToFixedDP(chainWalk(() => initialLabourAvailabilityHeadCount.unpaidBreaks, 0));
  const paidBreaks = convertToFixedDP(chainWalk(() => initialLabourAvailabilityHeadCount.paidBreaks, 0));
  const expectedHeadCount = convertToFixedDP(
    (rosteredHeadCount - day1NewHireHeadCount) * (attendancePercentage / 100.0),
    0
  );
  const durationInHours = chainWalk(
    () =>
      getWindowDurationInMin(
        shiftGroupAvailability.applicability.timeWindow.end,
        shiftGroupAvailability.applicability.timeWindow.start
      ) / 60.0,
    0
  );
  const expectedProductiveHours = convertToFixedDP(
    (durationInHours - unpaidBreaks / 60.0 - paidBreaks / 60.0) * expectedHeadCount
  );
  let breaksDisplayText = [];
  if (Array.isArray(shiftGroupAvailability['breaks'])) {
    for (let i = 0; i < shiftGroupAvailability['breaks'].length; i++) {
      const shiftGroupBreak = shiftGroupAvailability['breaks'][i];
      if ('breakType' in shiftGroupBreak && 'timeWindow' in shiftGroupBreak) {
        const shiftGroupBreakDuration = getWindowDurationInMin(
          shiftGroupBreak['timeWindow']['start'],
          shiftGroupBreak['timeWindow']['end']
        );
        breaksDisplayText.push(
          <br />,
          `Break ${i + 1} - ${shiftGroupBreak['breakType']} - ${shiftGroupBreakDuration}m`
        );
      }
    }
  }
  return (
    <Row alignmentHorizontal="justify" alignmentVertical="top" widths={['50%', '50%']} spacing="400">
      <AttendanceBox
        updateButtonState={updateButtonState}
        errorCounter={errorCounter}
        labourAvailabilityHeadCount={labourAvailabilityHeadCount}
        defaultLabourAvailabilityHeadCount={defaultLabourAvailabilityHeadCount}
        initialAttendancePercentage={initialAttendancePercentage}
        suggestedAttendancePercentage={suggestedAttendancePercentage}
        attendancePercentage={attendancePercentage}
        setAttendancePercentage={setAttendancePercentage}
        setRosteredHeadCount={setRosteredHeadCount}
        initialRosteredHeadCount={initialRosteredHeadCount}
        suggestedRosteredHeadCount={suggestedRosteredHeadCount}
        rosteredHeadCount={rosteredHeadCount}
        isDay1NewHireLaborEnabled={isDay1NewHireLaborEnabled}
        setDay1NewHireHeadCount={setDay1NewHireHeadCount}
        suggestedDay1NewHireHeadCount={suggestedDay1NewHireHeadCount}
        day1NewHireHeadCount={day1NewHireHeadCount}
        isUserAuthorisedForShiftPlanOverrides={isUserAuthorisedForShiftPlanOverrides}
      />
      <Row alignmentHorizontal="justify" widths="fill" spacing="400" height="100%">
        <ContentDisplayBox
          heading="Productive Hrs"
          value={expectedProductiveHours}
          additionalText={breaksDisplayText}
        />
        {isDay1NewHireLaborEnabled ? (
          <ContentDisplayBox
            heading="Expected HC + Day 1 New Hires"
            value={expectedHeadCount + ' + ' + day1NewHireHeadCount}
          />
        ) : (
          <ContentDisplayBox heading="Expected HC" value={expectedHeadCount} />
        )}
      </Row>
    </Row>
  );
};

const ShiftAttendanceMenu = ({
  data,
  initialData,
  dupData,
  index,
  shiftGroupAvailability,
  updateButtonState,
  errorCounter,
  isUserAuthorisedForShiftPlanOverrides
}) => {
  const labourAvailabilityHeadCounts = [];
  if (
    shiftGroupAvailability.cycleActivityLabourAvailabilityHeadCount === undefined ||
    Object.keys(shiftGroupAvailability.cycleActivityLabourAvailabilityHeadCount).length === 0
  ) {
    const initialLabourAvailabilityHeadCount =
      initialData.input.labourAvailability.shiftGroupAvailabilities[index].labourAvailabilityHeadCount;
    labourAvailabilityHeadCounts.push(
      <ShiftAttendanceLabourAvailabilityHeadCount
        data={data}
        shiftGroupAvailability={shiftGroupAvailability}
        labourAvailabilityHeadCount={shiftGroupAvailability.labourAvailabilityHeadCount}
        dupLabourAvailabilityHeadCount={
          dupData.input.labourAvailability.shiftGroupAvailabilities[index].labourAvailabilityHeadCount
        }
        initialLabourAvailabilityHeadCount={initialLabourAvailabilityHeadCount}
        suggestedLabourAvailabilityHeadCount={
          data.input.shiftPlanSuggestedInput
            ? data.input.shiftPlanSuggestedInput.labourAvailability.shiftGroupAvailabilities[index]
                .labourAvailabilityHeadCount
            : initialLabourAvailabilityHeadCount
        }
        updateButtonState={updateButtonState}
        errorCounter={errorCounter}
        isUserAuthorisedForShiftPlanOverrides={isUserAuthorisedForShiftPlanOverrides}
      />
    );
  }

  for (const [cycle, activityLabourAvailabilityHeadCount] of Object.entries(
    shiftGroupAvailability.cycleActivityLabourAvailabilityHeadCount
  )) {
    // Display "sort" then "pick_stage".
    const activities = Object.keys(activityLabourAvailabilityHeadCount).sort().reverse();
    for (const activity of activities) {
      const labourAvailabilityHeadCount = activityLabourAvailabilityHeadCount[activity];

      const displayActivityName = activity
        .split('_')
        .map((activityName) => activityName.charAt(0).toUpperCase() + activityName.slice(1))
        .join(' ');
      const displayName = `${cycle} - ${displayActivityName}`;
      const [open, setOpen] = useState(false);

      const initialLabourAvailabilityHeadCount =
        initialData.input.labourAvailability.shiftGroupAvailabilities[index].cycleActivityLabourAvailabilityHeadCount[
          cycle
        ][activity];

      const attendancePercentage = Math.round(
        convertToFixedDP(
          chainWalk(() => labourAvailabilityHeadCount.attendancePercentage, 0),
          2
        ) * 100
      );
      const rosteredHeadCount = convertToFixedDP(chainWalk(() => labourAvailabilityHeadCount.rosteredHeadcount, 0));
      const day1NewHireHeadCount = convertToFixedDP(
        chainWalk(() => labourAvailabilityHeadCount.day1NewHireHeadcount, 0)
      );
      const unpaidBreaks = convertToFixedDP(chainWalk(() => initialLabourAvailabilityHeadCount.unpaidBreaks, 0));
      const expectedHeadCount = convertToFixedDP(
        (rosteredHeadCount - day1NewHireHeadCount) * (attendancePercentage / 100.0),
        0
      );
      const durationInHours = chainWalk(
        () =>
          getWindowDurationInMin(
            shiftGroupAvailability.applicability.timeWindow.end,
            shiftGroupAvailability.applicability.timeWindow.start
          ) / 60.0,
        0
      );
      const expectedShowHours = convertToFixedDP((durationInHours - unpaidBreaks / 60.0) * expectedHeadCount);

      const suggestedLabourAvailabilityHeadCount = data.input.shiftPlanSuggestedInput
        ? data.input.shiftPlanSuggestedInput.labourAvailability.shiftGroupAvailabilities[index]
            .cycleActivityLabourAvailabilityHeadCount[cycle][activity]
        : undefined;

      let defaultLabourAvailabilityHeadCount = shiftGroupAvailability.labourAvailabilityHeadCount;

      labourAvailabilityHeadCounts.push(
        <Expander
          title={
            <Row alignmentHorizontal="justify" alignmentVertical="center">
              <Text alignment="left">{displayName}</Text>
              <Row>
                <Text alignment="right">{`${expectedHeadCount} HC / ${expectedShowHours} Show Hours`}</Text>
                <Tooltip position="right" title="Total paid hours to AA’s excluding lunch but including break times">
                  <Icon tokens={tooltipIcon} />
                </Tooltip>
              </Row>
            </Row>
          }
          type="section"
          open={open}
          onChange={setOpen}
        >
          <ShiftAttendanceLabourAvailabilityHeadCount
            data={data}
            shiftGroupAvailability={shiftGroupAvailability}
            labourAvailabilityHeadCount={labourAvailabilityHeadCount}
            defaultLabourAvailabilityHeadCount={defaultLabourAvailabilityHeadCount}
            dupLabourAvailabilityHeadCount={
              dupData.input.labourAvailability.shiftGroupAvailabilities[index].cycleActivityLabourAvailabilityHeadCount[
                cycle
              ][activity]
            }
            initialLabourAvailabilityHeadCount={initialLabourAvailabilityHeadCount}
            suggestedLabourAvailabilityHeadCount={suggestedLabourAvailabilityHeadCount}
            updateButtonState={updateButtonState}
            errorCounter={errorCounter}
            isUserAuthorisedForShiftPlanOverrides={isUserAuthorisedForShiftPlanOverrides}
          />
        </Expander>
      );
    }
  }

  return (
    <Column alignmentVertical="justify" heights="fill" width="100%" spacing="400">
      <Alert type="informational" size="medium">
        <Row>
          <Text type={TEXTS.H4}>Work Groups:</Text>
          {shiftGroupAvailability.workGroups.map((workGroup, index) => {
            return (index ? ', ' : '') + workGroup;
          })}
        </Row>
      </Alert>
      {labourAvailabilityHeadCounts}
    </Column>
  );
};

export default ShiftAttendanceMenu;
