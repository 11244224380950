import React, { useRef, useState } from 'react';
import Text from '@amzn/meridian/text';
import Icon from '@amzn/meridian/icon';
import Button from '@amzn/meridian/button';
import undoTokens from '@amzn/meridian-tokens/base/icon/undo';
import Link from '@amzn/meridian/link';
import Popover, { PopoverHeader } from '@amzn/meridian/popover';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Select, { SelectOption } from '@amzn/meridian/select';
import { SSD_OVERRIDE_REASON_CODES } from '../../../../utils/constants';
import Input from '@amzn/meridian/input';
import { chainWalk } from '../../../../utils/helpers';
import {
  getSinglesOverrideGuardrails,
  getPick2RebinVolume,
  getSinglesVolume,
  getPick2RebinOverridePlanningCharge
} from './OutboundVolumeUtils';
import alertTokens from '@amzn/meridian-tokens/base/icon/alert';

const USER_OVERRIDE_REASON_CODES = [
  SSD_OVERRIDE_REASON_CODES.DEMAND_LIGHTNESS,
  SSD_OVERRIDE_REASON_CODES.OVERCHARGING,
  SSD_OVERRIDE_REASON_CODES.SEV_ADDITIONAL_CAP_DOWN,
  SSD_OVERRIDE_REASON_CODES.WEATHER,
  SSD_OVERRIDE_REASON_CODES.INJECTION_REDUCED_CANCELLED
];

const SINGLES_OVERRIDE_ERROR =
  'Singles overrides must be summited before Pick2Rebin overrides. ' +
  'Please remove the active Pick2Rebin override for this window and submit a Singles Override before any Pick2Rebin overrides.';

export const Pick2RebinOverridePopover = ({
  currentUser,
  isEditable,
  pickToRebinPlanningCharge,
  outboundVolume,
  singlesEnabled,
  plan,
  setPlan,
  volumeProcessType
}) => {
  const linkRef = useRef();
  const [open, setOpen] = useState(false);
  const atropsOverrideCapacityPercentage = chainWalk(
    () => outboundVolume.overrides.pick2rebin.overrideKeyValueMap.atropsOverrideCapacityPercentage,
    null
  );
  const reasonCode = chainWalk(() => outboundVolume.overrides.reason, null);
  const [newAtropsOverrideCapacityPercentage, setNewAtropsOverrideCapacityPercentage] = useState(
    atropsOverrideCapacityPercentage
  );
  const [selectedReasonCode, setSelectedReasonCode] = useState(reasonCode);
  const isValid = 0 <= newAtropsOverrideCapacityPercentage && newAtropsOverrideCapacityPercentage <= 105;
  const isDisabled = !isValid || !selectedReasonCode;
  const isRevertible = atropsOverrideCapacityPercentage !== null;

  const onSubmit = () => {
    if (!outboundVolume.overrides) {
      outboundVolume.overrides = {};
    }

    outboundVolume.overrides.pick2rebin = {};
    const pick2rebinOverride = outboundVolume.overrides.pick2rebin;
    pick2rebinOverride.overrideKeyGuardrailsMap = {};
    pick2rebinOverride.overrideKeyValueMap = {};
    pick2rebinOverride.overrideKeyValueMap.atropsOverrideCapacityPercentage = newAtropsOverrideCapacityPercentage + '';
    pick2rebinOverride.overrideKeyValueMap.atropsOverrideCapacityValue =
      getPick2RebinVolume(outboundVolume, singlesEnabled).pickToRebinPlanningCharge + '';
    pick2rebinOverride.overrideKeyValueMap.volumeProcessType = volumeProcessType;
    pick2rebinOverride.reason = selectedReasonCode;
    pick2rebinOverride.timestamp = parseInt(Date.now() / 1000);
    pick2rebinOverride.user = currentUser;
    pickToRebinPlanningCharge = setOpen(false);
    setPlan({ ...plan });
  };
  const onRevert = () => {
    if (outboundVolume.overrides.pick2rebin) {
      delete outboundVolume.overrides.pick2rebin.overrideKeyValueMap;
      delete outboundVolume.overrides.pick2rebin.user;
      delete outboundVolume.overrides.pick2rebin.reason;
    }
    setPlan({ ...plan });
  };

  if (!isEditable) return <Text>{pickToRebinPlanningCharge}</Text>;
  return (
    <React.Fragment>
      {isRevertible && (
        <Button type={'link'} onClick={onRevert}>
          <Icon tokens={undoTokens} />
        </Button>
      )}
      <Link type={'secondary'} onClick={() => setOpen(true)} ref={linkRef}>
        {pickToRebinPlanningCharge}
      </Link>
      <Popover anchorNode={linkRef.current} open={open} onClose={() => setOpen(false)} position="bottom">
        <PopoverHeader closeLabel="Close">Provide an override</PopoverHeader>
        <Column>
          <Row width="100%" alignmentHorizontal="justify">
            <Text>ATROPS Capacity:</Text>
            <Text>{outboundVolume.atropsCapacity}</Text>
          </Row>
          <Row width="100%" alignmentHorizontal="justify">
            <Text type={'h100'}>Override Capacity %:</Text>
            <Input
              width={100}
              value={newAtropsOverrideCapacityPercentage}
              onChange={setNewAtropsOverrideCapacityPercentage}
              type="number"
              suffix="%"
              size="small"
              error={!isValid}
              errorMessage={!isValid ? 'Value must be between 0% and 105%' : ''}
            />
          </Row>
          {newAtropsOverrideCapacityPercentage != null && (
            <Row width="100%" alignmentHorizontal="justify">
              <Text type={'h100'}>Override Capacity: {parseFloat(newAtropsOverrideCapacityPercentage || 0)}</Text>
              <Text>
                {Math.round(
                  (parseFloat(newAtropsOverrideCapacityPercentage || 0) * outboundVolume['atropsCapacity']) / 100
                )}
              </Text>
            </Row>
          )}
          <Row width="100%" alignmentHorizontal="justify">
            <Text>ATROPS Scheduled:</Text>
            <Text>{outboundVolume.atropsScheduled}</Text>
          </Row>
          <Row width="100%" alignmentHorizontal="justify">
            <Text>VNA Predicted Charge:</Text>
            <Text>{outboundVolume.vnaPredictedCharge}</Text>
          </Row>
          {singlesEnabled && (
            <Row width="100%" alignmentHorizontal="justify">
              <Text>Singles Planning Charge:</Text>
              <Text>{getSinglesVolume(outboundVolume, singlesEnabled).singlesPlanningCharge}</Text>
            </Row>
          )}
          <Row width="100%" alignmentHorizontal="justify">
            <Text type={'h100'}>Planning Charge:</Text>
            <Text>
              {getPick2RebinOverridePlanningCharge(outboundVolume, newAtropsOverrideCapacityPercentage, singlesEnabled)}
            </Text>
          </Row>
          <Select
            value={selectedReasonCode}
            onChange={setSelectedReasonCode}
            placeholder="Select a reason"
            size="small"
          >
            {USER_OVERRIDE_REASON_CODES.map(({ value, label }) => (
              <SelectOption key={value} value={value} label={label} />
            ))}
          </Select>
          <Button disabled={isDisabled} onClick={onSubmit}>
            Submit Override
          </Button>
        </Column>
      </Popover>
    </React.Fragment>
  );
};

export const SinglesOverridePopover = ({
  currentUser,
  isEditable,
  singlesPlanningCharge,
  outboundVolume,
  plan,
  setPlan,
  volumeProcessType
}) => {
  const linkRef = useRef();
  const [open, setOpen] = useState(false);
  const { cpt } = outboundVolume;
  const reasonCode = chainWalk(() => outboundVolume.overrides.reason, null);

  const atropsOverrideCapacityPercentage = chainWalk(
    () => outboundVolume.overrides.pick2rebin.overrideKeyValueMap.atropsOverrideCapacityPercentage,
    null
  );

  const [singlesPlanningChargeOverride, setSinglesPlanningChargeOverride] = useState(singlesPlanningCharge);
  const [selectedReasonCode, setSelectedReasonCode] = useState(reasonCode);
  const { minVolume, maxVolume } = getSinglesOverrideGuardrails(outboundVolume);

  const isValid = minVolume <= singlesPlanningChargeOverride && singlesPlanningChargeOverride <= maxVolume;
  const isDisabled = !isValid || !selectedReasonCode || atropsOverrideCapacityPercentage != null;
  const isRevertible =
    chainWalk(() => outboundVolume.overrides.singles.overrideKeyValueMap.singlesPlanningCharge, null) != null;
  const onSubmit = () => {
    if (!outboundVolume.overrides) {
      outboundVolume.overrides = {};
    }

    outboundVolume.overrides.singles = {};
    const singlesOverride = outboundVolume.overrides.singles;
    singlesOverride.overrideKeyValueMap = {};
    singlesOverride.overrideKeyValueMap.singlesPlanningCharge = parseFloat(singlesPlanningChargeOverride) + '';
    singlesOverride.overrideKeyValueMap.volumeProcessType = volumeProcessType;
    singlesOverride.reason = selectedReasonCode;
    singlesOverride.timestamp = parseInt(Date.now() / 1000);
    singlesOverride.user = currentUser;
    setOpen(false);
    setPlan({ ...plan });
  };
  const onRevert = () => {
    if (outboundVolume.overrides.singles) {
      delete outboundVolume.overrides.singles;
    }
    setPlan({ ...plan });
  };

  if (!isEditable) return <Text>{singlesPlanningCharge}</Text>;
  return (
    <React.Fragment>
      {isRevertible && (
        <Button type={'link'} onClick={onRevert}>
          <Icon tokens={undoTokens} />
        </Button>
      )}
      <Link type={'secondary'} onClick={() => setOpen(true)} ref={linkRef}>
        {singlesPlanningCharge}
      </Link>
      <Popover anchorNode={linkRef.current} open={open} onClose={() => setOpen(false)} position="bottom">
        <PopoverHeader closeLabel="Close">Provide an override</PopoverHeader>
        <Column>
          <Row width="100%" alignmentHorizontal="justify">
            <Text type={'h100'}>Singles Planning Charge</Text>
            <Input
              width={100}
              value={singlesPlanningChargeOverride}
              onChange={setSinglesPlanningChargeOverride}
              type="number"
              size="small"
              error={!isValid}
              errorMessage={!isValid ? `Value must be between ${minVolume} and ${maxVolume}` : ''}
            />
          </Row>
          <Select
            value={selectedReasonCode}
            onChange={setSelectedReasonCode}
            placeholder="Select a reason"
            size="small"
          >
            {USER_OVERRIDE_REASON_CODES.map(({ value, label }) => (
              <SelectOption key={value} value={value} label={label} />
            ))}
          </Select>
          {atropsOverrideCapacityPercentage != null && (
            <Row>
              <Text color="error">
                <Icon tokens={alertTokens} />
              </Text>
              <Text color="error">{SINGLES_OVERRIDE_ERROR}</Text>
            </Row>
          )}
          <Button disabled={isDisabled} onClick={onSubmit}>
            Submit Override
          </Button>
        </Column>
      </Popover>
    </React.Fragment>
  );
};
