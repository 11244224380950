import { css } from 'emotion';

export const highRisk = css`
  color: #cc0c39;
`;

export const medRisk = css`
  color: #dcb700;
`;

export const greyPath = css`
  color: #bbc0c1;
`;

export const boldPath = css`
  font-weight: bold;
`;

export const tableRowBottomBorder = css`
  border-bottom: 1px solid #bbc0c1;
`;

export const removeHeight = css`
  height: auto;
`;

export const RISK_RANK_CLASS = {
  0: '',
  1: '',
  2: medRisk,
  3: highRisk
};
