import React, { useEffect, useState } from 'react';
import Text from '@amzn/meridian/text';
import Button from '@amzn/meridian/button';
import Box from '@amzn/meridian/box';
import Row from '@amzn/meridian/row';
import apis from '../../utils/apis';
import Select, { SelectOption } from '@amzn/meridian/select';

import LoadingView from '../../handler/loadingHandler/LoadingView';

import { getIsTest, getStationCode } from '../../utils/networkUtil';

import {
  chainWalk,
  convertTimeStampToEpoch,
  getCurrentEpoch,
  isNumberInvalid,
  parseEpochToDateString,
  parseEpochToDateStringWithOptions
} from '../../utils/helpers';
import {
  DATE_TIME_FORMAT,
  DEFAULT_OVERRIDE_REASON,
  DYNAMIC_PLAN_REJECT_REASON_CODES,
  M5_RELOAD_PAGE_TIME_OUT,
  PLAN_STATUS,
  PLAN_VERSIONS,
  TEXTS
} from '../../utils/constants';

import DynamicShiftPlanAlert from './DynamicShiftPlanAlert';
import DiffDynamicHeadCountTable from './DiffDynamicHeadCountTable';
import Divider from '@amzn/meridian/divider';
import DynamicPlanInputOverride from './DynamicPlanInputOverride';
import Column from '@amzn/meridian/column';
import Checkbox from '@amzn/meridian/checkbox';
import Textarea from '@amzn/meridian/textarea';
import DynamicHeadcountTable from './DynamicHeadcountTable';
import AdditionalRecommendedVTOTable from '../staticPlan/viewPlan/AdditionalRecommendedVTOTable';
import UpdatedRecommendedVTOTable from './UpdatedRecommendedVTOTable';
import PlannedVolumeAndRolloverTable from './PlannedVolumeAndRolloverTable';
import VTOVETRecommendation from '../staticPlan/viewPlan/VTOVETRecommendation';
import Alert from '@amzn/meridian/alert';
import {
  canShowDynamicPlan,
  getCycles,
  getTotalVolume,
  getVolumeData,
  isDynamicPlanDataValid,
  isStablePlanTimeInShiftGroupWindow
} from '../../utils/dynamicShiftPlanHelper';
import DynamicPlanVolumeOverride from './DynamicPlanVolumeOverride';

const DynamicShiftPlanMenu = ({ date, currentTimeEpoch, setAsyncError }) => {
  const [currentTime, setCurrentTime] = useState(currentTimeEpoch);
  const [plans, setPlans] = useState([]);
  const [rejectChoice, setRejectChoice] = useState();
  const [choice, setChoice] = useState();
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');
  const [screen, setScreen] = useState(1);
  const [errMsg, setErrMsg] = useState('');
  const [data, setData] = useState('');
  const [labourOpportunities, setLabourOpportunities] = useState([]);
  const [additionalLabourOpportunities, setAdditionalLabourOpportunities] = useState([]);
  const [packageValue, setPackageValue] = useState('');
  const [headcountValue, setHeadcountValue] = useState('');
  const [isPlanError, setIsPlanError] = useState(false);
  const [activeAislesValue, setActiveAislesValue] = useState('');
  const [activeLinesValue, setActiveLinesValue] = useState('');
  const [rejectChecked, setRejectChecked] = useState(false);
  const [prevData, setPrevData] = useState('');
  const [prevOutput, setPrevOutput] = useState('');
  const [warnings, setWarnings] = useState('');
  const [laborMoveReasons, setLaborMoveReasons] = useState('');
  const [cycles, setCycles] = useState([]);
  const [volumeValues, setVolumeValues] = useState('');
  const [volumeValuesBackup, setVolumeValuesBackup] = useState('');

  const VIEWTAB = {
    PARAM_VIEW: 'PARAM_VIEW',
    JSON_VIEW: 'JSON_VIEW'
  };

  const FUNCTIONS = {
    APPROVE: 'Approve',
    UPDATE: 'Update and re-run',
    REJECT: 'Reject'
  };

  const REJECT_REASON_CHARACTER_LIMIT = 100;

  const [currentTab, setCurrentTab] = useState(VIEWTAB.PARAM_VIEW);

  const plansList = plans
    .filter((plan) => plan['planVersion'] === PLAN_VERSIONS.DYNAMIC_V1)
    .map((plan) => (
      <SelectOption
        key={plan['planId']}
        value={plan}
        label={`${plan['planId']} | ${parseEpochToDateString(plan['createdAt'])} | ${plan['status']}`}
      />
    ));

  const isEmpty = (x) => {
    return (
      x && // 👈 null and undefined check
      Object.keys(x).length === 0 &&
      Object.getPrototypeOf(x) === Object.prototype
    );
  };

  let nodeId = getStationCode();
  let planType = 'INTRA_DAY';
  let planVersion = PLAN_VERSIONS.DYNAMIC_V2;

  useEffect(() => {
    fetchAllPlans();
    console.log(plansList);
    const interval = setInterval(() => {
      setErrMsg('Refresh page');
      fetchAllPlans();
    }, M5_RELOAD_PAGE_TIME_OUT);
    return () => clearInterval(interval);
  }, []);

  async function fetchAllPlans() {
    // Fetching all plans
    setErrMsg('Fetching all plans. Please wait...');
    setScreen(100);
    let d = await apis['GET_ALL_PLANS'](
      {
        query: {
          date: date,
          nodeId
        }
      },
      setAsyncError
    );

    // Sorting plans by creation time to retrieve latest plan
    let plans = d['shiftPlans'];
    let sortedPlans = Object.keys(plans)
      .sort(function (a, b) {
        return plans[b]['createdAt'] - plans[a]['createdAt'];
      })
      .map((key) => plans[key]);

    console.log(sortedPlans);
    setPlans(sortedPlans);
    // Shows most recent dynamic plan on UI if one exists
    let latestDynamicPlan = sortedPlans.find((plan) => canShowDynamicPlan(plan));
    if (latestDynamicPlan) {
      setChoice(latestDynamicPlan);
      await fetchPlan(latestDynamicPlan);
    }
    // Get previous dynamic plan before latest (if one exists)
    let previousDynamicPlan = sortedPlans.find(
      (plan) =>
        canShowDynamicPlan(plan) &&
        plan !== latestDynamicPlan &&
        plan['planVersion'] === latestDynamicPlan['planVersion']
    );
    if (previousDynamicPlan) {
      console.log('found previous dynamic plan');
      await fetchPreviousPlan(previousDynamicPlan);
    }
    setCurrentTime(getCurrentEpoch);
    setScreen(1);
  }

  async function fetchPlan(plan) {
    setErrMsg('Fetching plan. Please wait...');

    if (plan === undefined) {
      console.log('No plan selected...');
      setScreen(1);
      return;
    }

    let res = await apis['GET_PLAN'](
      {
        query: {
          planId: plan['planId']
        }
      },
      setAsyncError,
      true
    );

    // If no data was returned, show page but without any data populated.
    if (!res) {
      console.log('Cannot fetch plan');
      console.log(plan['planId']);
      setIsPlanError(true);
      setScreen(1);
      return;
    }

    console.log(res);

    if (isDynamicPlanDataValid(res)) {
      loadDynamicPlanData(res);
    } else {
      setData('');
    }
  }

  async function fetchPreviousPlan(plan) {
    setErrMsg('Fetching plan. Please wait...');

    if (plan === undefined) {
      console.log('No plan selected...');
      setScreen(1);
      return;
    }

    let res = await apis['GET_PLAN'](
      {
        query: {
          planId: plan['planId']
        }
      },
      setAsyncError,
      true
    );

    // If no data was returned, do not load previous plan info.
    if (!res) {
      console.log('Cannot fetch plan');
      console.log(plan['planId']);
      setScreen(1);
      return;
    }
    console.log(res);
    setPrevData(res);
    setPrevOutput(res['output'] ? res['output'] : '');
  }

  function buildInput(input, hasDataChanged) {
    if (isEmpty(input)) {
      return undefined;
    }

    if (hasDataChanged) {
      return input;
    }

    // if input exists and hasn't changed, build new input with just mechanicalConstraintsInput
    let newInput = {};
    newInput['mechanicalConstraintsInput'] = input['mechanicalConstraintsInput'];
    return newInput;
  }

  async function createDynamicPlan(input) {
    const hasDataChanged = data && data['input'] ? JSON.stringify(data['input']) !== JSON.stringify(input) : true;
    console.log(input);
    setErrMsg('Creating dynamic plan. Please wait...');
    setScreen(100);
    setRejectChoice('');
    setRejectChecked(false);
    let dynamicPlan;
    // Calling CREATE_PLAN api for given date with predefined plan type and version
    try {
      dynamicPlan = await apis['CREATE_PLAN'](
        {
          body: {
            nodeId: nodeId,
            ofdDate: input['date'] ? input['date'] : date,
            planType,
            planVersion,
            input: buildInput(input, hasDataChanged),
            output: undefined
          }
        },
        setAsyncError
      );
      console.log(dynamicPlan);
    } catch (e) {
      console.log(e.toString());
      setScreen(1);
      alert('Unable to create dynamic plan given input :(');
    }

    // Continuously fetch plan until created in 5 second intervals
    let approved = false;
    let res;
    let count = 0;
    while (!approved && count < 60) {
      console.log('fetching data again...');
      res = await apis['GET_PLAN'](
        {
          query: {
            planId: dynamicPlan['planId']
          }
        },
        setAsyncError
      );
      approved = res['status'] !== 'PENDING_CREATION';
      console.log(res);
      await new Promise((r) => setTimeout(r, 5000));
      count++;
    }
    console.log(res);

    // Set current data/output as previous data/output
    let oldOutput = output;
    setPrevOutput(oldOutput ? oldOutput : '');
    let prevData = data ? { ...data } : '';
    if (prevData) {
      setPrevData({
        ...prevData
      });
    }

    loadDynamicPlanData(res);

    setCurrentTime(getCurrentEpoch);
    setScreen(1);
  }

  const loadDynamicPlanData = (res) => {
    // Update textboxs with input & output with new plan
    setInput(res['input'] ? res['input'] : '');
    setOutput(res['output'] ? res['output'] : '');
    // Update VTO/VET tables
    setData(res);

    setLabourOpportunities(chainWalk(() => res['input']['labourAvailability']['inputLabourOpportunities'], []));
    setAdditionalLabourOpportunities(chainWalk(() => res['output']['labourOpportunities'], []));
    setLaborMoveReasons(chainWalk(() => res['output']['laborMoveReasons'], ''));
    setHeadcountValue(chainWalk(() => getTotalRosteredHeadCount(res['input'], res['output']), ''));

    setActiveLinesValue(chainWalk(() => getActiveInductLines(res['input']), ''));
    setActiveAislesValue(chainWalk(() => getActiveBufferAisles(res['input']), ''));

    let cycles = chainWalk(() => getCycles(res['input']['opsclockInput']), []);
    setCycles(cycles);
    let volumeValues = chainWalk(() => getTotalInboundPackageVolumeCounts(res['input'], cycles), '');
    setVolumeValues(volumeValues);
    setVolumeValuesBackup(JSON.stringify(volumeValues));
    setPackageValue(chainWalk(() => getTotalVolume(volumeValues), ''));

    setWarnings(chainWalk(() => res['output']['warnings'], ''));
    setIsPlanError(false);
  };

  const onSubmit = async () => {
    let json = { ...input };
    let newJsonInput = {};

    // Remove inboundPackageProfileByInterval values if null
    if (json['inboundPackageProfile'] && json['inboundPackageProfile']['inboundPackageProfileByInterval']) {
      let inboundPackageProfileByInterval = json['inboundPackageProfile']['inboundPackageProfileByInterval'];
      for (let i in inboundPackageProfileByInterval) {
        let data = getVolumeData(inboundPackageProfileByInterval, i);
        if (data == null) {
          delete json['inboundPackageProfile']['inboundPackageProfileByInterval'][i];
        }
      }
    }

    // Build new JSON input for any overrides that are found.
    updateJSONWithParameters(json, newJsonInput);
    // Sending user json to request data from SPMS and updating textbox
    await createDynamicPlan(newJsonInput);
  };

  const onFetch = async () => {
    setErrMsg('Fetching input for plan');
    setScreen(100);

    await fetchPlan(choice);

    setScreen(1);
  };

  // Gets total inbound package volume counts from plan input by cycle.
  const getTotalInboundPackageVolumeCounts = (jsonInput, cycles) => {
    let inboundPackageProfileByInterval = chainWalk(
      () => jsonInput['inboundPackageProfile']['inboundPackageProfileByInterval'],
      null
    );
    if (inboundPackageProfileByInterval == null) {
      return;
    }

    let volumeValues = {};
    for (let interval in inboundPackageProfileByInterval) {
      cycles.forEach((cycle) => {
        if (volumeValues[cycle] == null) {
          volumeValues[cycle] = 0;
        }
        let volumeSize = getVolumeData(inboundPackageProfileByInterval, interval, cycle);
        if (!!volumeSize) {
          volumeValues[cycle] += Object.values(volumeSize)[0];
        }
      });
    }
    return volumeValues;
  };

  // Gets total rostered head count from plan input.
  // TODO: Add activeHeadcount feild in labourAvailabilityHeadCount to represent headcount from myTime
  const getTotalRosteredHeadCount = (jsonInput, jsonOutput) => {
    let shiftGroupAvailabilities = chainWalk(() => jsonInput['labourAvailability']['shiftGroupAvailabilities'], null);
    if (shiftGroupAvailabilities == null || shiftGroupAvailabilities.length === 0) {
      return;
    }

    let count = 0;
    for (let i in shiftGroupAvailabilities) {
      let shiftGroup = shiftGroupAvailabilities[i];
      if (!isStablePlanTimeInShiftGroupWindow(jsonOutput, shiftGroup)) {
        continue;
      }
      let rosteredHeadCount = chainWalk(
        //Stop Gap for pilot, reading expectedHeadcount which is set to myTime HeadCount if available
        () => shiftGroup['labourAvailabilityHeadCount']['expectedHeadcount'],
        0
      );
      count += rosteredHeadCount;
    }
    return count;
  };

  const getActiveInductLines = (jsonInput) => {
    return chainWalk(() => jsonInput['mechanicalConstraintsInput']['mechanicalConstraintsMap']['INDUCT_LINES'], '');
  };

  const getActiveBufferAisles = (jsonInput) => {
    return chainWalk(() => jsonInput['mechanicalConstraintsInput']['mechanicalConstraintsMap']['BUFFER_AISLES'], '');
  };

  // Returns an updated JSON input with the parameters.
  const updateJSONWithParameters = (oldJsonInput, newJsonInput) => {
    updateVolumes(oldJsonInput, newJsonInput);
    updateHeadCount(oldJsonInput, newJsonInput);
    updateInductLines(oldJsonInput, newJsonInput);
    updateBufferAisles(oldJsonInput, newJsonInput);
  };

  // Current Package and HeadCount values if they exist.
  const currentHeadCount = chainWalk(() => getTotalRosteredHeadCount(data['input'], data['output']), '');
  const currentActiveInductLines = chainWalk(() => getActiveInductLines(data['input'], ''));
  const currentActiveBufferAisles = chainWalk(() => getActiveBufferAisles(data['input'], ''));

  const updateVolumes = (oldJsonInput, newJsonInput, cycle) => {
    if (!oldJsonInput || !oldJsonInput['inboundPackageProfile']) {
      return;
    }
    if (
      oldJsonInput['inboundPackageProfile'] != null &&
      oldJsonInput['inboundPackageProfile']['overrideBasis'] != null
    ) {
      newJsonInput['inboundPackageProfile'] = oldJsonInput['inboundPackageProfile'];
    }

    if (!volumeValues || !volumeValuesBackup || JSON.stringify(volumeValues) === volumeValuesBackup) {
      return;
    }

    cycles.forEach((cycle) => {
      updateVolumeCountByCycle(oldJsonInput, newJsonInput, cycle);
    });

    newJsonInput['inboundPackageProfile'] = oldJsonInput['inboundPackageProfile'];
    newJsonInput['inboundPackageProfile']['overrideBasis'] = {};
    newJsonInput['inboundPackageProfile']['overrideBasis']['reason'] = rejectChoice
      ? rejectChoice
      : DEFAULT_OVERRIDE_REASON;
  };

  // Changes the max value to the new package count value if a new value is entered. All other values become 0.
  const updateVolumeCountByCycle = (oldJsonInput, newJsonInput, cycle) => {
    if (!volumeValues || !volumeValues[cycle] || !volumeValuesBackup) {
      return;
    }

    let newPackageCount = volumeValues[cycle];
    if (newPackageCount === JSON.parse(volumeValuesBackup)[cycle] || newPackageCount === 0) {
      return;
    }

    let maxIndex = 0;
    let max = -1;
    let volumeData;
    let key;
    let inboundPackageProfileByInterval = chainWalk(
      () => oldJsonInput['inboundPackageProfile']['inboundPackageProfileByInterval'],
      null
    );
    if (inboundPackageProfileByInterval == null) {
      return;
    }
    for (let i in inboundPackageProfileByInterval) {
      volumeData = getVolumeData(inboundPackageProfileByInterval, i, cycle);
      if (Object.values(volumeData)[0] > max) {
        max = Object.values(volumeData)[0];
        maxIndex = i;
      }
      key = Object.keys(volumeData)[0];
      volumeData[key] = 0;
    }

    volumeData = getVolumeData(inboundPackageProfileByInterval, maxIndex, cycle);
    key = Object.keys(volumeData)[0];
    volumeData[key] = newPackageCount;
  };

  // Changes the max value to the new head count value if a new value is entered. All other values become 0.
  const updateHeadCount = (oldJsonInput, newJsonInput) => {
    if (currentHeadCount === 0 || headcountValue === '') {
      return;
    }

    let newHeadCount = parseInt(headcountValue);
    if (newHeadCount === currentHeadCount || newHeadCount === 0) {
      return;
    }

    let maxIndex = 0;
    let max = -1;
    for (let i in oldJsonInput['labourAvailability']['shiftGroupAvailabilities']) {
      let shiftGroupArray = oldJsonInput['labourAvailability']['shiftGroupAvailabilities'][i];
      if (!isStablePlanTimeInShiftGroupWindow(output, shiftGroupArray)) {
        continue;
      }

      if (shiftGroupArray['labourAvailabilityHeadCount']['rosteredHeadcount'] > max) {
        max = shiftGroupArray['labourAvailabilityHeadCount']['rosteredHeadcount'];
        maxIndex = i;
      }
    }

    for (let i in oldJsonInput['labourAvailability']['shiftGroupAvailabilities']) {
      let shiftGroupArray = oldJsonInput['labourAvailability']['shiftGroupAvailabilities'][i];
      if (!isStablePlanTimeInShiftGroupWindow(output, shiftGroupArray)) {
        continue;
      }
      shiftGroupArray['labourAvailabilityHeadCount']['rosteredHeadcount'] = i === maxIndex ? newHeadCount : 0;
      shiftGroupArray['labourAvailabilityHeadCount']['expectedHeadcount'] = i === maxIndex ? newHeadCount : 0;
    }
    newJsonInput['labourAvailability'] = oldJsonInput['labourAvailability'];
  };

  const updatePlanStatus = async (status, planId, rejectPlanReason, rejectReasonComment) => {
    setErrMsg('Updating plan status. Please wait a few seconds');
    setScreen(100);
    let rejectReason = rejectPlanReason
      ? rejectPlanReason === 'Other'
        ? rejectReasonComment
        : rejectPlanReason
      : undefined;
    let res = await apis['UPDATE_PLAN_STATUS'](
      {
        body: {
          planId,
          status,
          rejectReason
        }
      },
      setAsyncError,
      true
    );
    // Call did not return response. Show plan error.
    if (!res) {
      setIsPlanError(true);
      setScreen(1);
      return;
    }
    console.log('Updating plan status was successful');
    let updatedData = { ...data };
    updatedData['status'] = status;
    setData({
      ...updatedData
    });
    setScreen(1);
  };
  const updateInductLines = (oldJsonInput, newJsonInput) => {
    if (currentActiveInductLines === '') {
      return;
    }

    let newActiveLines;
    if (activeLinesValue === '') {
      newActiveLines = parseInt(currentActiveInductLines);
    } else {
      if (isNumberInvalid(activeLinesValue)) {
        return;
      }
      newActiveLines = parseInt(activeLinesValue);
    }

    if (newActiveLines === currentActiveInductLines || newActiveLines === 0) {
      return;
    }

    let shiftGroupMap = oldJsonInput['mechanicalConstraintsInput']['mechanicalConstraintsMap'];
    shiftGroupMap['INDUCT_LINES'] = newActiveLines;

    newJsonInput['mechanicalConstraintsInput'] = {};
    newJsonInput['mechanicalConstraintsInput']['mechanicalConstraintsMap'] = shiftGroupMap;
  };

  const updateBufferAisles = (oldJsonInput, newJsonInput) => {
    if (currentActiveBufferAisles === '') {
      return;
    }

    let newActiveAisles;
    if (activeAislesValue === '') {
      newActiveAisles = parseInt(currentActiveBufferAisles);
    } else {
      if (isNumberInvalid(activeAislesValue)) {
        return;
      }

      newActiveAisles = parseInt(activeAislesValue);
    }

    if (newActiveAisles === currentActiveBufferAisles || newActiveAisles === 0) {
      return;
    }

    let shiftGroupMap = oldJsonInput['mechanicalConstraintsInput']['mechanicalConstraintsMap'];
    shiftGroupMap['BUFFER_AISLES'] = newActiveAisles;

    if (!newJsonInput['mechanicalConstraintsInput']) {
      newJsonInput['mechanicalConstraintsInput'] = {};
      newJsonInput['mechanicalConstraintsInput']['mechanicalConstraintsMap'] = {};

      let activeLines = chainWalk(() => parseInt(currentActiveInductLines), null);
      if (!!activeLines) {
        newJsonInput['mechanicalConstraintsInput']['mechanicalConstraintsMap']['INDUCT_LINES'] = activeLines;
      }
    }
    newJsonInput['mechanicalConstraintsInput']['mechanicalConstraintsMap']['BUFFER_AISLES'] = newActiveAisles;
  };

  const isPlanApproved = chainWalk(() => data['status'] === PLAN_STATUS.APPROVED, false);

  const isPlanRejected = chainWalk(() => data['status'] === PLAN_STATUS.REJECTED, false);

  const isPlanPendingApproval = chainWalk(() => data['status'] === PLAN_STATUS.PENDING_APPROVAL, false);

  const isPlanBeingOverridden =
    currentHeadCount != headcountValue ||
    JSON.stringify(volumeValues) !== volumeValuesBackup ||
    currentActiveInductLines != activeLinesValue;

  const [rejectReason, setRejectReason] = useState('');
  const isRejectReasonValid =
    (!!rejectChoice && rejectChoice !== 'Other') ||
    (!!rejectReason && rejectReason.length !== 0 && rejectReason.length <= REJECT_REASON_CHARACTER_LIMIT);

  const getButtonName = React.useMemo(() => {
    if (isPlanBeingOverridden || isPlanRejected || !isPlanPendingApproval) {
      return FUNCTIONS.UPDATE;
    }
    if (rejectChecked) {
      return FUNCTIONS.REJECT;
    }
    return FUNCTIONS.APPROVE;
  }, [isPlanApproved, isPlanRejected, isPlanPendingApproval, isPlanBeingOverridden, rejectChecked]);

  /* Button will either
    1. Approve Plan
    2. Create new plan with overrides
    3. Reject Plan
  */
  const runButtonFunctionByName = async (name) => {
    switch (name) {
      case FUNCTIONS.APPROVE:
        await updatePlanStatus(PLAN_STATUS.APPROVED, data['planId']);
        break;
      case FUNCTIONS.UPDATE:
        await onSubmit();
        break;
      case FUNCTIONS.REJECT:
        await updatePlanStatus(PLAN_STATUS.REJECTED, data['planId'], rejectChoice, rejectReason);
        break;
      default:
        break;
    }
  };

  // Show data plan was created or current time if no plan data present.
  const getDataLastGeneratedString = React.useMemo(() => {
    let timeUpdated = chainWalk(
      () =>
        data && parseEpochToDateString(data['createdAt'])
          ? parseEpochToDateStringWithOptions(data['createdAt'], DATE_TIME_FORMAT)
          : '',
      ''
    );
    if (!timeUpdated) {
      timeUpdated = '' + parseEpochToDateStringWithOptions(currentTime, DATE_TIME_FORMAT);
    }
    return 'Data last generated at ' + timeUpdated;
  }, [data, currentTime]);

  // Toggles the Button. If a plan is approved or rejected, toggle it for overriding a plan.
  // If pending approval, leave button toggled to approve.
  // If rejecting, disable button until user selects a reason. This will enable it.
  const isButtonDisabled =
    isPlanApproved || isPlanRejected
      ? !isPlanBeingOverridden
      : (!isPlanPendingApproval && !isPlanBeingOverridden) || (rejectChecked && !isRejectReasonValid);

  // We hide the rejection when a plan is accepted (or in any state that is not pending approval) or rejected.
  const hideReject = !isPlanPendingApproval || isPlanRejected;

  return (
    <div style={{ height: '100%', width: '100%' }}>
      {screen === 1 ? (
        <div style={{ maxWidth: '95vw' }}>
          <div style={{ marginBottom: '1em' }}>
            {isPlanError && (
              <Alert type="error" size="medium" width="100%">
                Unable to perform action. Please refresh page.
              </Alert>
            )}
            <DynamicShiftPlanAlert
              data={data}
              isPlanPendingApproval={isPlanPendingApproval}
              isPlanApproved={isPlanApproved}
              isPlanRejected={isPlanRejected}
              laborMoveReasons={laborMoveReasons}
              currentTime={currentTime}
              runDynamicShiftPlan={onSubmit}
            >
              <div style={{ marginBottom: '3em' }} />
              <Row
                height="100%"
                width="100%"
                widths="fill"
                spacing="400"
                wrap="down"
                alignmentHorizontal="justify"
                alignmentVertical="stretch"
              >
                <Column>
                  {output ? (
                    <Box type="outline" spacingInset="400">
                      <DiffDynamicHeadCountTable
                        previousOutput={prevOutput}
                        previousData={prevData}
                        currentOutput={output}
                        currentData={data}
                        cycles={cycles}
                      />
                    </Box>
                  ) : null}
                </Column>
                <Column>
                  {output ? (
                    <Box type="outline" spacingInset="400">
                      <PlannedVolumeAndRolloverTable warnings={warnings} plannedVolume={packageValue} />
                    </Box>
                  ) : null}
                  {output ? (
                    <Box type="outline" spacingInset="400">
                      <UpdatedRecommendedVTOTable labourOpportunities={additionalLabourOpportunities} />
                    </Box>
                  ) : null}
                </Column>
              </Row>
              <div style={{ marginBottom: '3em' }} />
              <Divider />
              <Text>
                <b>Expected line hauls or headcount change? Update manually below.</b> (This will re-run the dynamic
                shift plan.)
              </Text>
              <Row width="100%" widths="fill" alignmentVertical="stretch">
                <DynamicPlanVolumeOverride
                  name={'Inbound Package Volume'}
                  cycles={cycles}
                  totalVolume={packageValue}
                  volumeValues={volumeValues}
                  setVolumeValues={setVolumeValues}
                  volumeValuesBackup={volumeValuesBackup}
                />
                <DynamicPlanInputOverride
                  name={'Available Headcount'}
                  currentInputValue={currentHeadCount}
                  inputOverride={headcountValue}
                  setInputOverride={setHeadcountValue}
                />
                <DynamicPlanInputOverride
                  name={'Total Induct Lines'}
                  currentInputValue={currentActiveInductLines}
                  inputOverride={activeLinesValue}
                  setInputOverride={setActiveLinesValue}
                />
              </Row>
              <Divider />
              <Row widths={!hideReject ? ['fit', 'fit', 'fill', 'fill'] : ['fill']}>
                {!hideReject && (
                  <Checkbox checked={rejectChecked} onChange={setRejectChecked} disabled={isPlanRejected}>
                    <Text type={TEXTS.H3}>Reject suggested shift plan for this reason.</Text>
                  </Checkbox>
                )}
                {!hideReject && (
                  <Select
                    value={rejectChoice}
                    onChange={setRejectChoice}
                    placeholder="Select a reason"
                    disabled={isPlanRejected || !rejectChecked}
                  >
                    {DYNAMIC_PLAN_REJECT_REASON_CODES.map((val) => (
                      <SelectOption key={val} value={val} label={val} />
                    ))}
                  </Select>
                )}
                {!hideReject && !!rejectChoice && rejectChoice === 'Other' && (
                  <Textarea
                    value={rejectReason}
                    onChange={setRejectReason}
                    error={!isRejectReasonValid}
                    disabled={!rejectChecked}
                    placeholder="Enter reject reason here..."
                    width={'30vh'}
                    rows={1}
                  />
                )}
                <Row alignmentHorizontal={'right'}>
                  <Button onClick={() => runButtonFunctionByName(getButtonName)} disabled={isButtonDisabled}>
                    {getButtonName}
                  </Button>
                </Row>
              </Row>
            </DynamicShiftPlanAlert>
          </div>
          <Text>{getDataLastGeneratedString}</Text>
          <div style={{ marginBottom: '2em' }} />
        </div>
      ) : (
        <LoadingView loadingMsg={errMsg} />
      )}
    </div>
  );
};

export default DynamicShiftPlanMenu;

/*
For Debugging purposes
<Text>CURRENT </Text>
{output ?
<DynamicHeadcountTable output={output} data={data} />: null}
<div style={{marginBottom: '1em'}} />
<Text>OLD </Text>
{output ?
<DynamicHeadcountTable output={prevOutput ? prevOutput: output} data={prevData} />: null}
*/
