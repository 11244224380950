import {
  getTcpRoles,
  getUserLoginFromParent,
  getUserNameFromParent,
  getTCPRolesWithRootStations
} from '../handler/parentDataHandler';
import { AMAZON_SUFFIX, TIMEOUT_STRING } from '../utils/constants';

export async function getEmployeeTCPRoles() {
  let response = await getTcpRoles();
  let parsedResponse = [];
  try {
    if (response != null && response.roles != null) {
      parsedResponse = JSON.parse(response.roles);
    }
  } catch (e) {
    console.error(e);
  }
  return parsedResponse;
}

export async function getUserName() {
  let response = await getUserLoginFromParent();
  if (response === TIMEOUT_STRING) {
    response = await getUserNameFromParent();
  }
  let userName = JSON.parse(response.name);
  if (!userName.endsWith(AMAZON_SUFFIX)) {
    userName += AMAZON_SUFFIX;
  }
  return userName;
}

export async function getEmployeeTCPRolesWithRootStations() {
  let response = await getTCPRolesWithRootStations();
  let parsedResponse = {};
  try {
    if (response && response.rolesWithRootStations) {
      parsedResponse = JSON.parse(response.rolesWithRootStations);
    }
  } catch (e) {
    console.error(e);
  }
  return parsedResponse;
}
