import React, { useCallback, useEffect, useState } from 'react';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';

import VerifyOutboundVolumes from './ssd/VerifyOutboundVolumes';
import VerifyDispatchVolumes from './ssd/VerifyDispatchVolumes';
import VerifyHeadcounts from './ssd/VerifyHeadcounts';
import VerifyRates from './ssd/VerifyRates';
import VerifyNewHires from './ssd/VerifyNewHires';
import ProgressTracker, { ProgressTrackerStep } from '@amzn/meridian/progress-tracker';
import Text from '@amzn/meridian/text';
import { getUserLoginFromParent } from '../../../handler/parentDataHandler';
import { chainWalk } from '../../../utils/helpers';

const STEPS = {
  OUTBOUND_VOLUMES: {
    value: 'OUTBOUND_VOLUMES',
    name: 'Outbound Volumes',
    stepNum: 1
  },
  DISPATCH_VOLUMES: {
    value: 'DISPATCH_VOLUMES',
    name: 'Dispatch Volumes',
    stepNum: 2
  },
  HEADCOUNTS: {
    value: 'HEADCOUNTS',
    name: 'Headcounts',
    stepNum: 3
  },
  RATES: {
    value: 'RATES',
    stepNum: 4,
    name: 'Rates'
  },
  NEW_HIRES: {
    value: 'NEW_HIRES',
    name: 'New Hires',
    stepNum: 5
  }
};

const CreatePlanSSD = ({ plan, setPlan, overridePlan, abortPlan }) => {
  const [currentStepNum, setCurrentStepNum] = useState(1);
  const [currentUser, setCurrentUser] = useState('');
  const onGoNext = useCallback(() => {
    setCurrentStepNum(Math.min(currentStepNum + 1, Object.keys(STEPS).length));
  }, [currentStepNum]);
  const onGoBack = useCallback(() => {
    setCurrentStepNum(Math.max(currentStepNum - 1, 1));
  }, [currentStepNum]);
  useEffect(() => {
    (async () => {
      const response = await getUserLoginFromParent();
      setCurrentUser(chainWalk(() => response.name, ''));
    })();
  }, []);
  return (
    <Column height="100%" width="100%" spacingInset="400" spacing="450">
      <Text type={'h600'}>Shift-level Plan</Text>
      <Row width="100%" alignmentHorizontal="center">
        <ProgressTracker type="theme" direction="row">
          {Object.keys(STEPS)
            .map((key) => STEPS[key])
            .map((step) => {
              return (
                <ProgressTrackerStep
                  key={step.value}
                  type={currentStepNum === step.stepNum ? 'present' : currentStepNum < step.stepNum ? 'future' : 'past'}
                  label={step.name}
                  showLabel={true}
                  alert="none"
                />
              );
            })}
        </ProgressTracker>
      </Row>
      <Row>
        {currentStepNum === STEPS.OUTBOUND_VOLUMES.stepNum ? (
          <VerifyOutboundVolumes
            currentUser={currentUser}
            plan={plan}
            setPlan={setPlan}
            onGoNext={onGoNext}
            onCancel={abortPlan}
          />
        ) : currentStepNum === STEPS.DISPATCH_VOLUMES.stepNum ? (
          <VerifyDispatchVolumes
            currentUser={currentUser}
            plan={plan}
            setPlan={setPlan}
            onGoBack={onGoBack}
            onGoNext={onGoNext}
            onCancel={abortPlan}
          />
        ) : currentStepNum === STEPS.HEADCOUNTS.stepNum ? (
          <VerifyHeadcounts
            currentUser={currentUser}
            plan={plan}
            setPlan={setPlan}
            onGoBack={onGoBack}
            onGoNext={onGoNext}
            onCancel={abortPlan}
          />
        ) : currentStepNum === STEPS.RATES.stepNum ? (
          <VerifyRates
            currentUser={currentUser}
            plan={plan}
            setPlan={setPlan}
            onGoBack={onGoBack}
            onGoNext={onGoNext}
            onCancel={abortPlan}
          />
        ) : (
          <VerifyNewHires
            currentUser={currentUser}
            plan={plan}
            setPlan={setPlan}
            onGoBack={onGoBack}
            onSubmit={() => overridePlan(plan.planId, plan.input)}
            onCancel={abortPlan}
          />
        )}
      </Row>
    </Column>
  );
};

export default CreatePlanSSD;
