import React from 'react';
import Text from '@amzn/meridian/text';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import userTokens from '@amzn/meridian-tokens/base/icon/user';
import Icon from '@amzn/meridian/icon';

const MAX_HEIGHT_FOR_PLACEHOLDER = 10;

export const EmptyEmployeeIcon = ({ header = null, containerType, droppableId, placeHolder }) => {
  return (
    <Column spacing={'none'} width={'60px'} id={`${containerType}-${droppableId}`}>
      <div id={'placeholder-for-cluster'} style={{ maxHeight: MAX_HEIGHT_FOR_PLACEHOLDER }}>
        {placeHolder}
      </div>
      <Row spacing={'none'} alignmentHorizontal={'center'} width={'fit'}>
        {header && (
          <Text transform={'capitalize'} type="B100" truncate={'true'}>
            {header}
          </Text>
        )}
      </Row>
      <Row spacing={'none'}>
        <div className={'labor-board-employee-icon-empty-div'}>
          <Text>
            <Icon tokens={userTokens} className={'labor-board-employee-icon-empty'} />
          </Text>
        </div>
      </Row>
      {/* empty space to align with non-empty icons */}
      <Row spacing={'none'} alignmentHorizontal={'center'} width={'fit'}>
        <Text transform={'capitalize'} type="b100" truncate={'true'}>
          &nbsp;
        </Text>
      </Row>
      <div id={'placeholder-for-cluster'} style={{ maxHeight: MAX_HEIGHT_FOR_PLACEHOLDER }}>
        {placeHolder}
      </div>
    </Column>
  );
};
