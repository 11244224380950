import React from 'react';
import Row from '@amzn/meridian/row';
import Loader from '@amzn/meridian/loader';

const LoaderView = () => {
  return (
    <div>
      <Row alignmentHorizontal="center">
        <Loader type="circular" size="large" />
      </Row>
    </div>
  );
};

export default LoaderView;
