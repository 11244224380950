import React from 'react';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';
import Select, { SelectOption } from '@amzn/meridian/select';
import Input from '@amzn/meridian/input';

import { TEXTS } from '../../utils/constants';

const OverrideReason = ({
  overrideReason,
  setOverrideReason,
  overrideReasonComment,
  setOverrideReasonComment,
  options,
  exceptions,
  disable
}) => {
  const setOverrideReasonText = (val) => {
    if (!exceptions.includes(val)) setOverrideReasonComment('');
    setOverrideReason(val);
  };

  return (
    <Column alignmentHorizontal="stretch" alignmentVertical="justify">
      <Column width="200px" spacing="100">
        <Text tag="label" type={TEXTS.T2} color="primary">
          Override Reason
        </Text>
        <Select
          value={overrideReason}
          onChange={setOverrideReasonText}
          error={!overrideReason}
          disabled={disable}
          placeholder="Select Reason"
          size="small"
        >
          {options.map((option, index) => (
            <SelectOption key={index} value={option} label={option} />
          ))}
        </Select>
      </Column>
      <Column width="200px" spacing="100">
        <Text tag="label" type={TEXTS.T2} color="primary">
          Specify Reason
        </Text>
        <Input
          value={overrideReasonComment}
          onChange={setOverrideReasonComment}
          error={exceptions.includes(overrideReason) && !overrideReasonComment}
          type="text"
          placeholder="Details..."
          disabled={!overrideReason}
          size="small"
        />
      </Column>
    </Column>
  );
};

export default OverrideReason;
