import React from 'react';

import MetricView from './MetricView';
import { SSD_NULL_VRID, SSD_TRAILER_VOLUME_SOURCE_TYPE } from '../../../../utils/constants';
import { chainWalk } from '../../../../utils/helpers';
const PROCESS_PATH_INJECTION = 'injection';
const PROCESS_PATH_SORTATION = 'sortation';

const processTrailerData = (trailerData) => {
  const volumeSourceInfo = chainWalk(() => trailerData.volume_source.split('|'), ['', '', '']);
  const source = volumeSourceInfo[0];
  const trailerId = volumeSourceInfo[1];
  const cet = volumeSourceInfo[2];
  return {
    source: source,
    trailerId: trailerId === SSD_NULL_VRID ? 'No VRID' : trailerId,
    cet,
    start: trailerData.start_datetime_local,
    volume: chainWalk(() => trailerData.volume.toFixed(0), 0),
    label: source === SSD_TRAILER_VOLUME_SOURCE_TYPE.SNOP ? 'S&OP' : ''
  };
};

const ShiftSortInjectionVolume = ({ shift, demand }) => {
  const trailersVolume = [];
  const pendingSort = {
    volumeTotal: 0,
    volumes: []
  };
  const cptList = [];
  shift &&
    demand &&
    demand.forEach(({ cpt_local }) => {
      if (!cptList.includes(cpt_local)) {
        cptList.push(cpt_local);
      }
    });
  cptList.sort();
  for (let i = 0; i < cptList.length; i++) {
    const cpt = cptList[i];
    if (cpt > shift.start) {
      const trailerList = demand.filter(
        ({ cpt_local, process_path }) => cpt_local === cpt && process_path === PROCESS_PATH_INJECTION
      );
      trailerList.forEach((trailerData) => {
        const formattedTrailer = processTrailerData(trailerData);
        trailersVolume.push(formattedTrailer);
      });

      const sortVolumeList = demand.filter(
        ({ cpt_local, process_path }) => cpt_local === cpt && process_path === PROCESS_PATH_SORTATION
      );
      pendingSort.volumeTotal = sortVolumeList.reduce((t, { volume }) => t + volume, pendingSort.volumeTotal);
      pendingSort.volumes = pendingSort.volumes.concat(sortVolumeList);
      if (cpt >= shift.end) {
        break;
      }
    }
  }

  return (
    <MetricView
      title={'Injection & Sortation Volume (pkgs)'}
      values={[
        ...trailersVolume.map((v) => ({ title: v.cet, helperText: v.trailerId, subtext: v.volume })),
        {
          title: 'Pending Sortation',
          subtext: pendingSort.volumeTotal,
          tooltipValues: pendingSort.volumes.map(
            (volume) => `${volume.volume_source} : ${chainWalk(() => volume.volume.toFixed(0), '')}`
          )
        }
      ]}
    />
  );
};

export default ShiftSortInjectionVolume;
