import React from 'react';
import Table, { TableRow, TableCell } from '@amzn/meridian/table';
import { TEXTS } from '../../utils/constants';
import Text from '@amzn/meridian/text';
import { isJSONEmpty } from '../../utils/helpers';

const PlannedVolumeAndRolloverTable = ({ plannedVolume, warnings }) => {
  // Default value if no warnings are found
  if (!warnings || isJSONEmpty(warnings)) {
    warnings = { ROLLOVER_VOLUME: 0 };
  }

  return (
    <Table headerRows={1} showDividers={true} showStripes={false} spacing="small">
      <TableRow>
        <TableCell>
          <Text type={TEXTS.H2}>Cycle</Text>
        </TableCell>
        <TableCell alignmentHorizontal="right">
          <Text type={TEXTS.H2}>Total Planned Volume</Text>
        </TableCell>
        <TableCell alignmentHorizontal="right">
          <Text type={TEXTS.H2}>Expected Rollover</Text>
        </TableCell>
      </TableRow>
      {Object.keys(warnings).map((val, index) => {
        return (
          <TableRow highlightOnHover={true} key={index}>
            <TableCell>All Cycles</TableCell>
            <TableCell alignmentHorizontal="right">{plannedVolume}</TableCell>
            <TableCell alignmentHorizontal="right">
              {warnings[val] === 0 || warnings[val] === '0' ? (
                warnings[val]
              ) : (
                <div className="red-bold-text-color">{warnings[val]}</div>
              )}
            </TableCell>
          </TableRow>
        );
      })}
    </Table>
  );
};

export default PlannedVolumeAndRolloverTable;
