import React, { useRef, useState } from 'react';
import Table, { TableRow, TableCell } from '@amzn/meridian/table';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Icon from '@amzn/meridian/icon';
import Text from '@amzn/meridian/text';
import Button from '@amzn/meridian/button';
import undoTokens from '@amzn/meridian-tokens/base/icon/undo';
import chevronDownSmallTokens from '@amzn/meridian-tokens/base/icon/chevron-down-small';
import chevronRightSmallTokens from '@amzn/meridian-tokens/base/icon/chevron-right-small';
import { chainWalk } from '../../../../utils/helpers';
import Link from '@amzn/meridian/link';
import Popover, { PopoverHeader } from '@amzn/meridian/popover';
import Select, { SelectOption } from '@amzn/meridian/select';
import Input from '@amzn/meridian/input';
import Divider from '@amzn/meridian/divider';
import {
  SSD_FIRST_SHIFT_HALF_INDICATOR,
  SSD_SECOND_SHIFT_HALF_INDICATOR,
  SSD_OVERRIDE_REASON_CODES
} from '../../../../utils/constants';
import { displayTime, getShiftHalves, getTimelineForViewPlan } from '../../utils';
import { SINGLES_ROLES, getSinglesEnabled } from './SinglesUtils';

const DEPARTMENT_NAMES = {
  OUTBOUND: 'outbound',
  SORTATION: 'sortation'
};

const RATE_UNITS_LABEL = {
  [DEPARTMENT_NAMES.OUTBOUND]: '(units)',
  [DEPARTMENT_NAMES.SORTATION]: '(pkgs)'
};

const SUPPORTED_DEPARTMENT_NAMES = [DEPARTMENT_NAMES.OUTBOUND, DEPARTMENT_NAMES.SORTATION];

const RATE = {
  EER_LOWER: 'EER_LOWER',
  EER_UPPER: 'EER_UPPER',
  MSR: 'MSR',
  PPA_SHIFT_DAY_LAST_WEEK: 'PPA_SHIFT_DAY_LAST_WEEK',
  PPA_T7_DAYS: 'PPA_T7_DAYS'
};

const USER_OVERRIDE_REASON_CODES = [
  SSD_OVERRIDE_REASON_CODES.SITE_UNDER_PERFORMING_PPA,
  SSD_OVERRIDE_REASON_CODES.SITE_OVER_PERFORMING_PPA,
  SSD_OVERRIDE_REASON_CODES.NEW_SITE,
  SSD_OVERRIDE_REASON_CODES.OI_REQUEST
];

const getShiftHalfDisplayTime = (timeline, shiftHalfStartTime) => {
  const shiftHalf = getShiftHalves(timeline).find(({ startTimestamp }) => startTimestamp === shiftHalfStartTime);
  return chainWalk(
    () => `(${displayTime(shiftHalf.startDateTimeLocal)}-${displayTime(shiftHalf.endDateTimeLocal)})`,
    ''
  );
};

const getExpectedRatesRange = (mergedRateMap, roleName) => {
  const rateMap = (mergedRateMap && mergedRateMap[roleName] && mergedRateMap[roleName].rateMap) || {};
  if (!rateMap) {
    return '';
  }
  const eerLower = rateMap[RATE.EER_LOWER];
  const eerUpper = rateMap[RATE.EER_UPPER];
  if (eerLower && eerUpper) {
    return `${eerLower} - ${eerUpper}`;
  }
  return '';
};

const getShiftHalfStartDatetimeLocal = (timeline, shiftHalfStartTime) => {
  const shiftHalf = getShiftHalves(timeline).find(({ startTimestamp }) => startTimestamp === shiftHalfStartTime);
  return chainWalk(() => shiftHalf.startDateTimeLocal, '');
};

const OverridePopover = ({ currentUser, isEditable, plan, setPlan, rate }) => {
  const msrRate = chainWalk(() => rate.rateMap[RATE.MSR], null);
  const rateFromTrailing7DaysPPA = chainWalk(() => rate.rateMap[RATE.PPA_T7_DAYS], null);
  const rateFromSameDayLastWeekPPA = chainWalk(() => rate.rateMap[RATE.PPA_SHIFT_DAY_LAST_WEEK], null);

  const linkRef = useRef();
  const [open, setOpen] = useState(false);

  const overridingValue = chainWalk(() => rate.overrides.overrideKeyValueMap.rate, null);
  const reasonCode = chainWalk(() => rate.overrides.reason, null);
  const minRate = chainWalk(() => rate.overrides.overrideKeyGuardrailsMap.rate.minimum, 0);
  const maxRate = chainWalk(() => rate.overrides.overrideKeyGuardrailsMap.rate.maximum, Number.POSITIVE_INFINITY);

  const initialValue = overridingValue != null ? overridingValue : msrRate;
  const [newOverridingValue, setNewOverridingValue] = useState(initialValue);
  const [selectedReasonCode, setSelectedReasonCode] = useState(reasonCode);

  const onSubmit = () => {
    if (!rate.overrides) {
      rate.overrides = {};
    }
    if (!rate.overrides.overrideKeyValueMap) {
      rate.overrides.overrideKeyValueMap = {};
    }
    rate.overrides.overrideKeyValueMap.rate = newOverridingValue + '';
    rate.overrides.reason = selectedReasonCode;
    rate.overrides.timestamp = parseInt(Date.now() / 1000);
    rate.overrides.user = currentUser;
    setPlan({ ...plan });
    setOpen(false);
  };
  const onRevert = () => {
    if (rate.overrides) {
      delete rate.overrides.overrideKeyValueMap;
      delete rate.overrides.users;
      delete rate.overrides.reason;
    }
    setPlan({ ...plan });
  };

  const isValid = minRate <= newOverridingValue && newOverridingValue <= maxRate;
  const isDisabled = initialValue === newOverridingValue || !isValid || !selectedReasonCode;
  const isRevertible = overridingValue !== null;
  if (!isEditable) {
    return <Text>{initialValue}</Text>;
  }
  return (
    <React.Fragment>
      {isRevertible && (
        <Button type="link" onClick={onRevert}>
          <Icon tokens={undoTokens} />
        </Button>
      )}
      <Link type="secondary" onClick={() => setOpen(true)} ref={linkRef}>
        {initialValue}
      </Link>
      <Popover anchorNode={linkRef.current} open={open} onClose={() => setOpen(false)} position="bottom">
        <PopoverHeader closeLabel="Close">Provide an override</PopoverHeader>
        <Column>
          <Row width="100%" alignmentHorizontal="justify">
            <Text>MSR</Text>
            <Text>{msrRate}</Text>
          </Row>
          {(rateFromTrailing7DaysPPA != null || rateFromSameDayLastWeekPPA != null) && (
            <Text type={'h100'}>PPA historical shift half data</Text>
          )}
          {rateFromTrailing7DaysPPA != null && (
            <Row width="100%" alignmentHorizontal="justify">
              <Text>Trailing 7 Days</Text>
              <Text>{rateFromTrailing7DaysPPA}</Text>
            </Row>
          )}
          {rateFromSameDayLastWeekPPA != null && (
            <Row width="100%" alignmentHorizontal="justify">
              <Text>Same Day, Last Week</Text>
              <Text>{rateFromSameDayLastWeekPPA}</Text>
            </Row>
          )}
          <Row width="100%" alignmentHorizontal="justify">
            <Text>Rate Override</Text>
            <Input
              value={newOverridingValue}
              onChange={setNewOverridingValue}
              type="number"
              size="small"
              error={!isValid}
              errorMessage={!isValid ? `Rate value must be between ${minRate} and ${maxRate}` : ''}
            />
          </Row>
          <Select
            value={selectedReasonCode}
            onChange={setSelectedReasonCode}
            placeholder="Select a reason"
            size="small"
          >
            {USER_OVERRIDE_REASON_CODES.map(({ value, label }) => (
              <SelectOption key={value} value={value} label={label} />
            ))}
          </Select>
          <Button disabled={isDisabled} onClick={onSubmit}>
            Submit Override
          </Button>
        </Column>
      </Popover>
    </React.Fragment>
  );
};

const ViewRates = ({ currentUser, isEditable, plan, setPlan }) => {
  const { departments, roleDetailsList: roles, rateDetailsList: rates } = plan.input.ssdShiftPlanInput;
  const timeline = getTimelineForViewPlan(plan);
  const ratesFromTransformedInputs = chainWalk(
    () => JSON.parse(plan.output.serializedOutput).transformedInputs.rates,
    []
  );
  const singlesEnabled = getSinglesEnabled(plan);
  const [collapsedRows, setCollapsedRows] = useState([]);

  const updateCollapsedRow = (row) => {
    const newCollapsedRows = collapsedRows.includes(row)
      ? collapsedRows.filter((collapsedRow) => collapsedRow !== row)
      : [...collapsedRows, row];
    setCollapsedRows(newCollapsedRows);
  };

  const ratesByDepartmentShiftAndRole = {};
  rates.forEach((rate) => {
    const { role, shift, rateMap = {} } = rate;
    const { departmentName, name: roleName } = role;
    if (!SUPPORTED_DEPARTMENT_NAMES.includes(departmentName)) {
      return;
    }

    if (!singlesEnabled && SINGLES_ROLES.indexOf(roleName) >= 0) {
      return;
    }

    const { name: shiftName, shiftHalf, shiftHalfStartTime, shiftHalfEndTime } = shift;
    if (!ratesByDepartmentShiftAndRole[departmentName]) {
      ratesByDepartmentShiftAndRole[departmentName] = {};
    }
    if (!ratesByDepartmentShiftAndRole[departmentName][shiftName]) {
      ratesByDepartmentShiftAndRole[departmentName][shiftName] = {};
    }
    if (!ratesByDepartmentShiftAndRole[departmentName][shiftName][shiftHalf]) {
      ratesByDepartmentShiftAndRole[departmentName][shiftName][shiftHalf] = {
        shiftHalfStartTime,
        shiftHalfEndTime,
        rates: {}
      };
    }
    ratesByDepartmentShiftAndRole[departmentName][shiftName][shiftHalf].rates[roleName] = rate;
    const shiftHalfStartDatetimeLocal = getShiftHalfStartDatetimeLocal(timeline, shiftHalfStartTime);
    const rateFromTransformedInputs = ratesFromTransformedInputs.find(
      ({ process_path, process_role, start_datetime_local, rate_type }) =>
        process_path === departmentName &&
        process_role === roleName &&
        start_datetime_local === shiftHalfStartDatetimeLocal.toString().substring(0, 19) &&
        rate_type === RATE.MSR
    );
    const msrRate = chainWalk(() => rateFromTransformedInputs.predicted_rate, null);
    if (msrRate != null) {
      rateMap[RATE.MSR] = msrRate;
    }
  });
  const departmentNames = Object.keys(ratesByDepartmentShiftAndRole);
  return (
    <div style={{ overflowX: 'auto', maxWidth: '100%' }}>
      <Column spacing="600">
        {departmentNames.map((departmentName) => {
          const shiftNames = Object.keys(ratesByDepartmentShiftAndRole[departmentName]).sort(
            (shiftName1, shiftName2) => {
              const shift1 = ratesByDepartmentShiftAndRole[departmentName][shiftName1];
              const shift2 = ratesByDepartmentShiftAndRole[departmentName][shiftName2];
              return (
                Math.min(
                  chainWalk(() => shift1[SSD_FIRST_SHIFT_HALF_INDICATOR].shiftHalfStartTime, Number.POSITIVE_INFINITY),
                  chainWalk(() => shift1[SSD_SECOND_SHIFT_HALF_INDICATOR].shiftHalfStartTime, Number.POSITIVE_INFINITY)
                ) -
                Math.min(
                  chainWalk(() => shift2[SSD_FIRST_SHIFT_HALF_INDICATOR].shiftHalfStartTime, Number.POSITIVE_INFINITY),
                  chainWalk(() => shift2[SSD_SECOND_SHIFT_HALF_INDICATOR].shiftHalfStartTime, Number.POSITIVE_INFINITY)
                )
              );
            }
          );
          const departmentRoleNames = new Set();
          shiftNames.forEach((shiftName) => {
            [
              ...chainWalk(
                () =>
                  Object.keys(
                    ratesByDepartmentShiftAndRole[departmentName][shiftName][SSD_FIRST_SHIFT_HALF_INDICATOR].rates
                  ),
                []
              ),
              ...chainWalk(
                () =>
                  Object.keys(
                    ratesByDepartmentShiftAndRole[departmentName][shiftName][SSD_SECOND_SHIFT_HALF_INDICATOR].rates
                  ),
                []
              )
            ].forEach((roleName) => departmentRoleNames.add(roleName));
          });
          const sortedDepartmentRoleNames = [...departmentRoleNames].sort((roleName1, roleName2) => {
            return (
              chainWalk(() => roles.find((role) => role.role.name === roleName1).order, Number.POSITIVE_INFINITY) -
              chainWalk(() => roles.find((role) => role.role.name === roleName2).order, Number.POSITIVE_INFINITY)
            );
          });
          return (
            <Table>
              <Divider />
              <Table
                key={departmentName}
                headerRows={1}
                showDividers={true}
                headerColumns={2}
                stickyHeaderColumn={true}
                spacing="small"
              >
                <TableRow>
                  <TableCell width={100} />
                  <TableCell width={100} />
                  {[...sortedDepartmentRoleNames].map((roleName) => {
                    return (
                      <TableCell key={roleName} alignmentHorizontal={'center'} width={220}>
                        {chainWalk(() => roles.find((role) => role.role.name === roleName).role.label, roleName)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </Table>

              <Table
                key={departmentName}
                headerRows={isEditable ? 2 : 1}
                showDividers={true}
                headerColumns={2}
                stickyHeaderColumn={true}
                spacing="small"
              >
                <TableRow>
                  <TableCell columnSpan={sortedDepartmentRoleNames.length}>
                    {chainWalk(() => departments.find((dep) => dep.name === departmentName).label, departmentName)}{' '}
                    {RATE_UNITS_LABEL[departmentName]}
                  </TableCell>
                  {isEditable ? (
                    <TableCell columnSpan={6}>
                      <div className="rate-container">
                        <div className="msr-rate">MSR Rate</div>
                        <div className="expected-range">Expected Rate Range</div>
                      </div>
                    </TableCell>
                  ) : (
                    <TableCell columnSpan={6} />
                  )}
                </TableRow>
                {isEditable ? (
                  <TableRow>
                    {Array.from({ length: sortedDepartmentRoleNames.length + 2 }).map((_, index) => (
                      <TableCell key={index} />
                    ))}
                  </TableRow>
                ) : null}
                {shiftNames.map((shiftName) => {
                  const rows = [];
                  const rowIdentifier = `${departmentName}-${shiftName}`;
                  const firstHalfStartTimestamp = chainWalk(
                    () =>
                      ratesByDepartmentShiftAndRole[departmentName][shiftName][SSD_FIRST_SHIFT_HALF_INDICATOR]
                        .shiftHalfStartTime,
                    null
                  );
                  const firstHalfRateMap = chainWalk(
                    () =>
                      ratesByDepartmentShiftAndRole[departmentName][shiftName][SSD_FIRST_SHIFT_HALF_INDICATOR].rates,
                    {}
                  );
                  const secondHalfStartTimestamp = chainWalk(
                    () =>
                      ratesByDepartmentShiftAndRole[departmentName][shiftName][SSD_SECOND_SHIFT_HALF_INDICATOR]
                        .shiftHalfStartTime,
                    null
                  );
                  const secondHalfRateMap = chainWalk(
                    () =>
                      ratesByDepartmentShiftAndRole[departmentName][shiftName][SSD_SECOND_SHIFT_HALF_INDICATOR].rates,
                    {}
                  );
                  const mergedRateMap = {};
                  sortedDepartmentRoleNames.forEach((roleName) => {
                    const firstHalfRoleRate = firstHalfRateMap[roleName];
                    const secondHalfRoleRate = secondHalfRateMap[roleName];
                    if (firstHalfRoleRate == null && secondHalfRoleRate == null) {
                      mergedRateMap[roleName] = null;
                    }
                    const firstHalfRoleMsrRate = chainWalk(() => firstHalfRoleRate.rateMap[RATE.MSR], 0);
                    const secondHalfRoleMsrRate = chainWalk(() => secondHalfRoleRate.rateMap[RATE.MSR], 0);
                    if (firstHalfRoleMsrRate > secondHalfRoleMsrRate) {
                      mergedRateMap[roleName] = firstHalfRoleRate;
                    } else {
                      mergedRateMap[roleName] = secondHalfRoleRate;
                    }
                  });
                  rows.push(
                    <TableRow backgroundColor="secondary">
                      <TableCell width={50}>
                        <Button type="icon" size="small" onClick={() => updateCollapsedRow(rowIdentifier)}>
                          <Icon
                            tokens={
                              !collapsedRows.includes(rowIdentifier) ? chevronDownSmallTokens : chevronRightSmallTokens
                            }
                          />
                        </Button>
                      </TableCell>
                      <TableCell width={200}>
                        <Row spacing="200">
                          <Text type="h100">{shiftName}</Text>
                        </Row>
                      </TableCell>
                      {[...sortedDepartmentRoleNames].map((roleName) => (
                        <TableCell alignmentHorizontal="center" key={roleName}>
                          <div className="centered-text">
                            <Text type="h100">
                              {chainWalk(() => mergedRateMap[roleName].rateMap[RATE.MSR] != null, false) &&
                                Math.round(mergedRateMap[roleName].rateMap[RATE.MSR])}
                            </Text>
                          </div>
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                  rows.push(
                    Object.keys(firstHalfRateMap).length > 0 && !collapsedRows.includes(rowIdentifier) && (
                      <TableRow>
                        <TableCell />
                        <TableCell alignmentHorizontal="start">
                          <Text>1H {getShiftHalfDisplayTime(timeline, firstHalfStartTimestamp)}</Text>
                        </TableCell>
                        {[...sortedDepartmentRoleNames].map((roleName) => (
                          <TableCell alignmentHorizontal="center" key={`1H-${roleName}`}>
                            <div>
                              <OverridePopover
                                currentUser={currentUser}
                                isEditable={isEditable}
                                plan={plan}
                                setPlan={setPlan}
                                rate={firstHalfRateMap[roleName]}
                              />
                              {isEditable ? (
                                <Text type={'b200'} color="secondary">
                                  {getExpectedRatesRange(mergedRateMap, roleName)}
                                </Text>
                              ) : (
                                <Text />
                              )}
                            </div>
                          </TableCell>
                        ))}
                      </TableRow>
                    )
                  );
                  rows.push(
                    Object.keys(secondHalfRateMap).length > 0 && !collapsedRows.includes(rowIdentifier) && (
                      <TableRow>
                        <TableCell />
                        <TableCell alignmentHorizontal="start">
                          <Text>2H {getShiftHalfDisplayTime(timeline, secondHalfStartTimestamp)}</Text>
                        </TableCell>
                        {[...sortedDepartmentRoleNames].map((roleName) => (
                          <TableCell alignmentHorizontal="center" key={`2H-${roleName}`}>
                            <div>
                              <OverridePopover
                                currentUser={currentUser}
                                isEditable={isEditable}
                                plan={plan}
                                setPlan={setPlan}
                                rate={secondHalfRateMap[roleName]}
                              />
                              {isEditable ? (
                                <Text type={'b200'} color="secondary">
                                  {getExpectedRatesRange(mergedRateMap, roleName)}
                                </Text>
                              ) : (
                                <Text />
                              )}
                            </div>
                          </TableCell>
                        ))}
                      </TableRow>
                    )
                  );
                  return rows;
                })}
              </Table>
            </Table>
          );
        })}
      </Column>
    </div>
  );
};

export default ViewRates;
