import React, { createContext, useEffect, useState } from 'react';
import { getEmployeeTCPRolesWithRootStations } from '../resources/EmployeeResource';
import { SETTINGSV2_PERMISSIONS_REGION, SCC_SHIFTPLAN_MANAGER, ROOT_STATIONS } from '../utils/constants';
import Loader from '../loader/Loader';
import { getRegion } from '../utils/helpers';

export const PermissionsContext = createContext();

const isValidTcpRole = (tcpRoles) => {
  if (tcpRoles) {
    return SCC_SHIFTPLAN_MANAGER.some((manager) => tcpRoles.some((role) => role.includes(manager)));
  }
  return false;
};

const isValidRootStation = (tcpStations, region) => {
  if (tcpStations && ROOT_STATIONS[region]) {
    return ROOT_STATIONS[region].some((rootStation) => tcpStations.includes(rootStation));
  }
  return false;
};

const isValidUserForShiftPlanInputsBlankOverrides = (tcpRolesWithRootStations, region) => {
  if (tcpRolesWithRootStations && region) {
    region = region.toUpperCase();
    let stations = Object.keys(tcpRolesWithRootStations);
    if (isValidRootStation(stations, region)) {
      let rootStations = ROOT_STATIONS[region];
      const uniqueRoles = new Set();
      rootStations.forEach((station) => {
        const roles = tcpRolesWithRootStations[station];
        if (roles) {
          roles.forEach((role) => {
            uniqueRoles.add(role);
          });
        }
      });
      if (uniqueRoles) {
        return isValidTcpRole([...uniqueRoles]);
      }
    }
    return false;
  }
  return false;
};

const PermissionsContextProvider = ({ children }) => {
  const [isUserAuthorisedForShiftPlanOverrides, setIsUserAuthorisedForShiftPlanOverrides] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    (async () => {
      const region = getRegion();

      const tcpRolesWithRootStationsResponse = await getEmployeeTCPRolesWithRootStations();

      let isAuthorized = isValidUserForShiftPlanInputsBlankOverrides(tcpRolesWithRootStationsResponse, region);

      isAuthorized = isAuthorized || !SETTINGSV2_PERMISSIONS_REGION.includes(region);

      setIsUserAuthorisedForShiftPlanOverrides(isAuthorized);

      setIsLoading(false);
    })();
  }, []);
  if (isLoading) return <Loader />;
  return (
    <PermissionsContext.Provider value={{ isUserAuthorisedForShiftPlanOverrides }}>
      {children}
    </PermissionsContext.Provider>
  );
};

export default PermissionsContextProvider;
