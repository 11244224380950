import React, { useContext, useState } from 'react';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';
import Table, { TableRow, TableCell } from '@amzn/meridian/table';
import Button from '@amzn/meridian/button';
import { TEXTS, DATE_TIME_FORMAT_NUMERIC, PLAN_VERSIONS } from '../utils/constants';
import Icon from '@amzn/meridian/icon';
import copyTokens from '@amzn/meridian-tokens/base/icon/copy';
import { changeDateFormat, parseEpochToDateStringWithTimeZone } from '../utils/helpers';
import { StatusCell } from './StatusCell';
import Row from '@amzn/meridian/row';
import Modal, { ModalFooter } from '@amzn/meridian/modal';
import { PLAN_STATUS } from '../utils/constants';
import apis from '../utils/apis';
import { GlobalStateContext } from '../global/context/GlobalStateContext';
import ViewPlanOverview from '../shiftPlan/staticPlan/viewPlan/ssd/ViewPlanOverview';
import Divider from '@amzn/meridian/divider';
import ShiftAdherence from '../shiftAdherence/ShiftAdherence';

const ShiftPlanHistoryTable = ({ shiftPlansList, stationTimeZone }) => {
  const { setAsyncError } = useContext(GlobalStateContext);
  const [openView, setOpenView] = useState(false);
  const [openAdherence, setOpenAdherence] = useState(false);
  const [shiftPlanData, setShiftPlanData] = useState({});
  const [planStart, setPlanStart] = useState('');
  const onClickViewPlanButton = (planId, planStart) => {
    getShiftPlan(planId);
    setPlanStart(planStart);
  };

  const onClickAdherenceButton = (planId) => {
    setOpenAdherence(true);
  };

  const getShiftPlan = async (planId) => {
    let plan = await apis['GET_PLAN'](
      {
        query: {
          planId: planId
        }
      },
      setAsyncError
    );
    setShiftPlanData(plan);
    setOpenView(true);
    return null;
  };

  const onClickViewPlanFooterButton = () => setOpenView(false);

  const onClickAdherenceFooterButton = () => setOpenAdherence(false);

  const onCloseView = () => {
    setOpenView(false);
  };

  const onCloseAdherence = () => {
    setOpenAdherence(false);
  };

  const copyToClipboard = (planId) => {
    navigator.clipboard.writeText(planId);
  };

  const isPlanViewDisabled = (status) => {
    if (
      status === PLAN_STATUS.PENDING_CREATION ||
      status === PLAN_STATUS.REJECTED ||
      status === PLAN_STATUS.FAILED ||
      status === PLAN_STATUS.CANCELLED
    ) {
      return true;
    }
    return false;
  };

  return (
    <Column spacingInset="200" width={'250%'}>
      <Table headerRows={1} showStripes={false} showDividers={true} spacing={'small'}>
        <TableRow highlightOnHover={true}>
          <TableCell width="2%">
            <Text type={TEXTS.H2}>Plan ID</Text>
          </TableCell>
          <TableCell width="1%"></TableCell>
          <TableCell width="4%">
            <Text type={TEXTS.H2}>Plan Start</Text>
          </TableCell>
          <TableCell width="4%">
            <Text type={TEXTS.H2}>Creation Date/Time</Text>
          </TableCell>
          <TableCell width="2%">
            <Text type={TEXTS.H2}>Creation By</Text>
          </TableCell>
          <TableCell width="2%">
            <Text type={TEXTS.H2}>Status</Text>
          </TableCell>
          <TableCell width="3%"></TableCell>
          <TableCell width="3%"></TableCell>
        </TableRow>
        {shiftPlansList.map((row, idx) => {
          return (
            <TableRow key={idx} highlightOnHover={true}>
              <TableCell>
                <Row>
                  <Text type={TEXTS.T2} className={'plan-id'} text-overflow={'ellipsis'} truncate={true}>
                    {row.planId}
                  </Text>
                </Row>
              </TableCell>
              <TableCell>
                <Text alignment={'justified'}>
                  <Button type="link" onClick={() => copyToClipboard(row.planId)} size="medium">
                    <Icon tokens={copyTokens}>Copy</Icon>
                  </Button>
                </Text>
              </TableCell>
              <TableCell>
                <Text type={TEXTS.T2} className={'plan-start'}>
                  {row.shiftPlanHorizonStartTime != null
                    ? parseEpochToDateStringWithTimeZone(row.shiftPlanHorizonStartTime, stationTimeZone)
                    : changeDateFormat(row.ofdDate)}
                </Text>
              </TableCell>
              <TableCell>
                <Text type={TEXTS.T2} className={'created-at'}>
                  {parseEpochToDateStringWithTimeZone(row.createdAt, stationTimeZone)}
                </Text>
              </TableCell>
              <TableCell>
                <Text type={TEXTS.T2} className={'created-by'}>
                  {row.lastUpdatedBy}
                </Text>
              </TableCell>
              <TableCell>
                <StatusCell status={row.status}></StatusCell>
              </TableCell>
              <TableCell>
                <Button
                  size="small"
                  type="tertiary"
                  onClick={() => onClickViewPlanButton(row.planId, row.ofdDate)}
                  disabled={isPlanViewDisabled(row.status)}
                >
                  View Plan
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  size="small"
                  type="tertiary"
                  onClick={() => onClickAdherenceButton(row.planId)}
                  disabled={isPlanViewDisabled(row.status)}
                >
                  Plan Adherence
                </Button>
              </TableCell>
            </TableRow>
          );
        })}
      </Table>
      <Modal
        title="Plan Review"
        open={openView}
        onClose={onCloseView}
        scrollContainer="viewport"
        closeLabel="Close"
        aria-describedby="view-plan-overview"
      >
        <Column spacingInset="400" alignmentVertical="start" alignmentHorizontal="left">
          <Text type={TEXTS.H2}>
            {'Plan Started, '}
            {planStart}
          </Text>
          <Divider />
        </Column>
        <Column width="100%" spacingInset="400 400 400 400" alignmentVertical="start" alignmentHorizontal="left">
          <ViewPlanOverview plan={shiftPlanData} />
        </Column>
        <ModalFooter>
          <Row alignmentHorizontal="end" widths="fit">
            <Button type="secondary" size="small" onClick={onClickViewPlanFooterButton}>
              Close
            </Button>
          </Row>
        </ModalFooter>
      </Modal>
      <Modal
        title="Plan Adherence"
        open={openAdherence}
        onClose={onCloseAdherence}
        scrollContainer="viewport"
        closeLabel="Close"
        aria-describedby="view-plan-adherence"
      >
        <Column spacingInset="500" alignmentVertical="justify" alignmentHorizontal="left">
          <ShiftAdherence />
        </Column>
        <ModalFooter>
          <Row alignmentHorizontal="end" widths="fit">
            <Button type="secondary" size="small" onClick={onClickAdherenceFooterButton}>
              Close
            </Button>
          </Row>
        </ModalFooter>
      </Modal>
    </Column>
  );
};
export default ShiftPlanHistoryTable;
