import { ANONYMOUS_ASSOCIATE_NAME, JP } from '../utils/constants';
import { alphabeticalSort, chainWalk, getRegion } from '../utils/helpers';

export function nameFormatter(name, region) {
  if (name) {
    const splitted = name.split(',');
    if (splitted.length > 1) {
      if (region === JP) {
        return `${splitted[1]} ${splitted[0]}`;
      }
      return `${splitted[1]} ${splitted[0].charAt(0).toUpperCase()}.`;
    } else {
      return name;
    }
  } else {
    return null;
  }
}

export function processData(data, recommendations, cycleResourceAllocationOutputs, selectedCycle) {
  const allPaths = new Set();
  const disallowedPathOptions = new Set();
  const trainedPathOptions = new Set();
  const recommendedPathOptions = new Set();

  const thisCycleRecommendation = chainWalk(
    () =>
      cycleResourceAllocationOutputs.filter((cycleResourceAllocationOutput) => {
        if (selectedCycle === cycleResourceAllocationOutput.cycleName) {
          return cycleResourceAllocationOutput;
        }
      })[0],
    null
  );

  if (thisCycleRecommendation != null) {
    recommendations = thisCycleRecommendation.resourceRecommendedProcesses;
  }

  const thisCycleAvailableAssociates = chainWalk(() => thisCycleRecommendation.availableResources, []).map(
    (associate) => {
      return associate.resourceId;
    }
  );

  const dataForCycle = data.filter((item) => {
    if (thisCycleRecommendation != null) {
      return new Set(thisCycleAvailableAssociates).has(item.resource.resourceId);
    }
    return true;
  });

  const dataWithoutAnonymous = dataForCycle.filter((item) => {
    return item.resource.resourceName !== ANONYMOUS_ASSOCIATE_NAME;
  });

  const processedAssociates = dataWithoutAnonymous.map((item) => {
    const paths = Object.keys(item.processPathAttributeMap).sort();
    const recommendation = recommendations.find((rec) => rec.resource.resourceId === item.resource.resourceId);

    if (recommendation) {
      recommendedPathOptions.add(recommendation.recommendedProcess);
    }

    const disallowedPaths = [];
    const trainedPaths = [];

    paths.forEach((path) => {
      allPaths.add(path);
      if (item.processPathAttributeMap[path].eligibility) {
        if (item.processPathAttributeMap[path].eligibility.ineligibleCriteria) {
          if (
            item.processPathAttributeMap[path].eligibility.ineligibleCriteria.filter(
              (c) => c.reason !== 'TRAINING_INCOMPLETE'
            ).length > 0
          ) {
            disallowedPaths.push(path);
            disallowedPathOptions.add(path);
          }
        }

        if (
          item.processPathAttributeMap[path].eligibility.trainingProfile &&
          item.processPathAttributeMap[path].eligibility.trainingProfile.completedTraining
        ) {
          trainedPaths.push(path);
          trainedPathOptions.add(path);
        }
      }
    });

    return {
      name: nameFormatter(item.resource.resourceName, getRegion()),
      id: item.resource.resourceId,
      disallowedPaths,
      riskRanking: item.riskRanking,
      trainedPaths,
      recommendedPath: recommendation && recommendation.recommendedProcess
    };
  });

  return {
    disallowedPathOptions,
    trainedPathOptions,
    recommendedPathOptions,
    allPaths: [...allPaths].sort(),
    processedAssociates: processedAssociates.sort(alphabeticalSort('name'))
  };
}

export const RISK_RANK_VALUES = ['0', '1', '2', '3'];

export const RISK_RANK_OPTIONS = ['None', 'Low', 'Med', 'High'];
