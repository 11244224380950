import Tag from '@amzn/meridian/tag';
import React from 'react';
import { PLAN_STATUS } from '../utils/constants';

export const STATUS_MAP = {
  APPROVED: 'Successful',
  CLOSED: 'Successful',
  PENDING_APPROVAL: 'Successful',
  FAILED: 'Failed',
  CANCELLED: 'Cancelled',
  PENDING_CREATION: 'Pending Creation'
};

export const StatusCell = ({ status }) => {
  let statusType = 'theme';
  console.log(status);
  if (status === PLAN_STATUS.APPROVED || status === PLAN_STATUS.CLOSED || status === PLAN_STATUS.PENDING_APPROVAL) {
    statusType = 'success';
  } else if (
    status === PLAN_STATUS.PENDING_CREATION ||
    status === PLAN_STATUS.REJECTED ||
    status === PLAN_STATUS.CANCELLED
  ) {
    statusType = 'neutral';
  } else if (status === PLAN_STATUS.FAILED) {
    statusType = 'error';
  }
  return (
    <Tag className={'status'} type={statusType} tag-font-size={18}>
      {STATUS_MAP[status]}
    </Tag>
  );
};
