import Table, { TableActionBar, TableCell, TableRow } from '@amzn/meridian/table';
import React from 'react';
import Text from '@amzn/meridian/text';

import { TEXTS } from '../../utils/constants';
import { parseTimeStampToDateStringWith12Hr } from '../../utils/helpers';

const UpdatedRecommendedVTOTable = ({ labourOpportunities }) => {
  return (
    <Table headerRows={1} showDividers={true} showStripes={false} spacing="small">
      <Text type={TEXTS.H2}>Updated Recommended VTO/VET</Text>
      <TableRow>
        <TableCell>
          <Text type={TEXTS.H2}>Time</Text>
        </TableCell>
        <TableCell>
          <Text type={TEXTS.H2}>VET</Text>
        </TableCell>
        <TableCell alignmentHorizontal="right">
          <Text type={TEXTS.H2}>VTO</Text>
        </TableCell>
      </TableRow>
      {labourOpportunities &&
        labourOpportunities.map((labourOpportunity, index) => {
          return (
            <TableRow highlightOnHover={true} key={index}>
              <TableCell>
                {parseTimeStampToDateStringWith12Hr(labourOpportunity.applicability.timeWindow.start) +
                  ' - ' +
                  parseTimeStampToDateStringWith12Hr(labourOpportunity.applicability.timeWindow.end)}
              </TableCell>
              <TableCell>{labourOpportunity.opportunityType === 'VET' ? labourOpportunity.headcount : 0}</TableCell>
              <TableCell alignmentHorizontal="right">
                {labourOpportunity.opportunityType === 'VTO' ? labourOpportunity.headcount : 0}
              </TableCell>
            </TableRow>
          );
        })}
    </Table>
  );
};

export default UpdatedRecommendedVTOTable;
