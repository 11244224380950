import React from 'react';
import MetricView from './MetricView';
import Text from '@amzn/meridian/text';
import Row from '@amzn/meridian/row';
import Tag from '@amzn/meridian/tag';
import Heading from '@amzn/meridian/heading';
import { displayTime, getFcCode } from '../../utils';
import Link from '@amzn/meridian/link';
import OutboundPlanningThroughputExpanded from './OutboundPlanningThroughputExpanded';

const ShiftPlannedThroughput = ({ singlesEnabled, shift, plannedThroughput }) => {
  const shiftPlannedThroughput = shift
    ? plannedThroughput.filter(({ start_time_local, end_time_local, description = '' }) => {
        return start_time_local >= shift.start && end_time_local <= shift.end && description.includes('Half');
      })
    : [];
  return (
    <MetricView
      headerComponent={
        <Row width="100%" alignmentHorizontal="justify">
          <Heading level={2} type="h300" alignment="left">
            <Text>Outbound Planning Throughput (units/hr)</Text>
          </Heading>
          <Row>
            <Text type="b300">
              <Tag type={singlesEnabled ? 'success' : 'error'}>{`Singles Processing: ${
                singlesEnabled ? 'On' : 'Off'
              }`}</Tag>
            </Text>
            <Link
              href={`https://throughput-iad.iad.proxy.amazon.com/${getFcCode()}/lagrange/`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Lagrange
            </Link>
            <OutboundPlanningThroughputExpanded plannedThroughput={plannedThroughput} />
          </Row>
        </Row>
      }
      title={'Outbound Planning Throughput (units/hr)'}
      values={shiftPlannedThroughput.map(
        ({
          start_time_local,
          end_time_local,
          planned_outbound_throughput_units_per_hour,
          planned_outbound_throughput_units_total
        }) => {
          return {
            title: `${displayTime(start_time_local)}-${displayTime(end_time_local)}`,
            subtext: Math.round(planned_outbound_throughput_units_per_hour),
            tooltipValues: [`Total Planned Throughput: ${Math.round(planned_outbound_throughput_units_total)}`]
          };
        }
      )}
    />
  );
};

export default ShiftPlannedThroughput;
