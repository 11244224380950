import React, { useRef, useState } from 'react';
import Table, { TableRow, TableCell } from '@amzn/meridian/table';
import Text from '@amzn/meridian/text';
import { chainWalk } from '../../../../utils/helpers';
import Link from '@amzn/meridian/link';
import Popover, { PopoverHeader } from '@amzn/meridian/popover';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Select, { SelectOption } from '@amzn/meridian/select';
import Checkbox from '@amzn/meridian/checkbox';
import {
  SSD_NULL_VRID,
  SSD_OVERRIDE_REASON_CODES,
  SSD_TIMELINE_KEY,
  SSD_TRAILER_STATUS
} from '../../../../utils/constants';
import Input from '@amzn/meridian/input';
import Button from '@amzn/meridian/button';
import undoTokens from '@amzn/meridian-tokens/base/icon/undo';
import { displayDate, displayTime, getTimelineForViewPlan } from '../../utils';
import Icon from '@amzn/meridian/icon';
import moment from 'moment';
import 'moment-timezone';
const PRE_PLAN_CET = 'CETs Prior to Start of Plan';
const PLANNING_HORIZON_CET = 'Planning Horizon CETs';

const OVERRIDE_REASON_CODES = [
  SSD_OVERRIDE_REASON_CODES.WEATHER,
  SSD_OVERRIDE_REASON_CODES.LSE_SEV,
  SSD_OVERRIDE_REASON_CODES.OTHER
];

const getCetDisplayText = (cet) => {
  const dateTimeLocal = chainWalk(() => cet.dateTimeLocal.toString(), null)
    ? new Date(cet.dateTimeLocal.toString().substring(0, 16) + 'Z')
    : null;
  if (!dateTimeLocal) {
    return null;
  }
  return `${('0' + dateTimeLocal.getUTCHours()).slice(-2)}:${('0' + dateTimeLocal.getUTCMinutes()).slice(
    -2
  )} ${chainWalk(() => cet.type.toString().toUpperCase(), '')}`;
};

const getDispatchVolumeData = (plan) => {
  const transformedInputs = chainWalk(() => JSON.parse(plan.output.serializedOutput).transformedInputs, {});
  const { demand = [], timezone = null } = transformedInputs;
  const rtsVolume = chainWalk(() => plan.input.ssdShiftPlanInput.volume.rtsVolumeList[0], {});
  if (timezone) {
    const rtsVolumeFromTransformedInput = demand.find(
      ({ volume_source, cpt_local }) =>
        volume_source === 'RTS' && moment.tz(cpt_local, timezone).unix() === rtsVolume.cpt
    );
    if (rtsVolumeFromTransformedInput) {
      rtsVolume.volume = rtsVolumeFromTransformedInput.volume;
    }
  }
  const injectionVolumes = chainWalk(() => plan.input.ssdShiftPlanInput.volume.injectionVolumeList, []);
  const injectionVolumesFromTransformedInputs = demand.filter(({ process_path }) => process_path === 'injection');
  const timeline = getTimelineForViewPlan(plan);
  const planCets = timeline.filter(({ key }) => key === SSD_TIMELINE_KEY.CET);
  const planCetToInjectionVolumesMap = planCets
    .map((cet) => {
      const cetInjectionVolumes = injectionVolumes.filter(
        (injectionVolume) => injectionVolume.cet.timestamp === cet.timestamp
      );
      const transformedCet = {
        timestamp: cet.timestamp,
        key: cet.key,
        label: cet.label,
        dateTimeLocal: chainWalk(() => cet.date_time_local.toString().substring(0, 16), '')
      };
      if (cetInjectionVolumes.length === 0) {
        cetInjectionVolumes.push({
          cet: transformedCet,
          volume: null,
          trailerStatus: null,
          linehaulId: SSD_NULL_VRID,
          plannedTimeLocal: null,
          gpsTime: null,
          actualTime: null
        });
      }
      return {
        cet: transformedCet,
        volumes: cetInjectionVolumes
      };
    })
    .reduce((map, cetInjectionVolumes) => {
      map[cetInjectionVolumes.cet.timestamp] = cetInjectionVolumes;
      return map;
    }, {});
  const firstCetInPlan = planCets[0];
  const injectionVolumesBeforeFirstCet = injectionVolumes.filter(
    (injectionVolume) => injectionVolume.cet.timestamp < firstCetInPlan.timestamp
  );
  const prePlanCetToInjectionVolumesMap = {};
  injectionVolumesBeforeFirstCet.forEach((injectionVolume) => {
    if (
      chainWalk(() => injectionVolume.volume, Number.NEGATIVE_INFINITY) < 100 &&
      chainWalk(() => injectionVolume.overrides.overrideKeyValueMap.volume, null) == null
    ) {
      return;
    }
    if (!prePlanCetToInjectionVolumesMap[injectionVolume.cet.timestamp]) {
      prePlanCetToInjectionVolumesMap[injectionVolume.cet.timestamp] = {
        cet: {
          ...injectionVolume.cet
        },
        volumes: []
      };
    }
    prePlanCetToInjectionVolumesMap[injectionVolume.cet.timestamp].volumes.push(injectionVolume);
  });
  [...Object.entries(prePlanCetToInjectionVolumesMap), ...Object.entries(planCetToInjectionVolumesMap)]
    .flatMap(([_, injectionVolumes]) => injectionVolumes.volumes || [])
    .forEach((injectionVolume) => {
      const injectionVolumeFromTransformedInput = injectionVolumesFromTransformedInputs
        .map((injectionVolumeFromTransformedInput) => {
          const volumeSourceInfo = chainWalk(
            () => injectionVolumeFromTransformedInput.volume_source.split('|'),
            ['', '', '']
          );
          const linehaulId = volumeSourceInfo[1];
          return {
            ...injectionVolumeFromTransformedInput,
            linehaulId
          };
        })
        .find(
          ({ start_datetime_local, linehaulId }) =>
            chainWalk(() => start_datetime_local.toString().substring(0, 16), null) ===
              chainWalk(() => injectionVolume.cet.dateTimeLocal, '') && linehaulId === injectionVolume.linehaulId
        );
      if (injectionVolumeFromTransformedInput && injectionVolumeFromTransformedInput.volume != null) {
        injectionVolume.volume = Math.round(injectionVolumeFromTransformedInput.volume);
      }
    });

  return {
    prePlanCetToInjectionVolumesMap,
    planCetToInjectionVolumesMap,
    rtsVolume
  };
};

const OverrideRTSPopover = ({ currentUser, plan, setPlan, isEditable, rtsVolume }) => {
  const linkRef = useRef();
  const [open, setOpen] = useState(false);
  const overridingValue = chainWalk(() => rtsVolume.overrides.overrideKeyValueMap.volume, null);
  const reasonCode = chainWalk(() => rtsVolume.overrides.reason, null);
  const minVolume = chainWalk(() => rtsVolume.overrides.overrideKeyGuardrailsMap.volume.minimum, 0);
  const maxVolume = chainWalk(() => rtsVolume.overrides.overrideKeyGuardrailsMap.volume.maximum, Number.MAX_VALUE);
  const initialValue = overridingValue != null ? overridingValue : rtsVolume.volume;
  const [newOverridingValue, setNewOverridingValue] = useState(initialValue);
  const [selectedReasonCode, setSelectedReasonCode] = useState(reasonCode);
  const onSubmitOverride = () => {
    if (!rtsVolume.overrides) {
      rtsVolume.overrides = {};
    }
    if (!rtsVolume.overrides.overrideKeyValueMap) {
      rtsVolume.overrides.overrideKeyValueMap = {};
    }
    rtsVolume.overrides.overrideKeyValueMap.volume = newOverridingValue + '';
    rtsVolume.overrides.reason = selectedReasonCode;
    rtsVolume.overrides.timestamp = parseInt(Date.now() / 1000);
    rtsVolume.overrides.user = currentUser;
    setPlan({ ...plan });
    setOpen(false);
  };
  const onRevert = () => {
    if (rtsVolume.overrides) {
      delete rtsVolume.overrides.overrideKeyValueMap;
      delete rtsVolume.overrides.users;
      delete rtsVolume.overrides.reason;
      delete rtsVolume.overrides.timestamp;
    }
    setPlan({ ...plan });
  };

  const isValid = minVolume <= newOverridingValue && newOverridingValue <= maxVolume;
  const isDisabled = initialValue === newOverridingValue || !isValid || !selectedReasonCode;
  const isRevertible = overridingValue !== null;
  if (!isEditable) return <Text>{initialValue}</Text>;
  return (
    <React.Fragment>
      {isRevertible && (
        <Button type={'link'} onClick={onRevert}>
          <Icon tokens={undoTokens} />
        </Button>
      )}
      <Link type={'secondary'} onClick={() => setOpen(true)} ref={linkRef}>
        {initialValue}
      </Link>
      <Popover anchorNode={linkRef.current} open={open} onClose={() => setOpen(false)} position="bottom">
        <PopoverHeader closeLabel="Close">Provide an override</PopoverHeader>
        <Column>
          <Row width="100%" alignmentHorizontal="justify">
            <Text type={'h100'}>RTS Override Volume:</Text>
            <Input
              value={newOverridingValue}
              onChange={setNewOverridingValue}
              type="number"
              size="small"
              error={!isValid}
              errorMessage={!isValid ? `Volume must be between ${minVolume} and ${maxVolume}` : ''}
            />
          </Row>
          <Select
            value={selectedReasonCode}
            onChange={setSelectedReasonCode}
            placeholder="Select a reason"
            size="small"
          >
            {OVERRIDE_REASON_CODES.map(({ value, label }) => (
              <SelectOption key={value} value={value} label={label} />
            ))}
          </Select>
          <Button disabled={isDisabled} onClick={onSubmitOverride}>
            Submit Override
          </Button>
        </Column>
      </Popover>
    </React.Fragment>
  );
};

const OverrideTrailerStatusPopover = ({ currentUser, isEditable, injectionVolume, plan, setPlan }) => {
  const { cet, trailerStatus, linehaulId, plannedTimeLocal } = injectionVolume;
  const linkRef = useRef();
  const [open, setOpen] = useState(false);
  const overridingValue = chainWalk(() => injectionVolume.overrides.overrideKeyValueMap.volume, null);
  if (!isEditable) {
    return <Text>{overridingValue != null ? overridingValue : injectionVolume.volume}</Text>;
  }
  const [checked, setChecked] = useState(overridingValue === '0');
  const linehaulIdDisplayText = linehaulId === SSD_NULL_VRID || !linehaulId ? 'Pending' : linehaulId;
  const trailerStatusDisplayText = !trailerStatus
    ? 'Pending'
    : chainWalk(() => SSD_TRAILER_STATUS[trailerStatus].label, '-');
  const plannedTimeDisplayText = !plannedTimeLocal ? 'Pending' : `${displayTime(plannedTimeLocal)} Arrival`;
  const cetDisplayText = getCetDisplayText(cet);
  const volumeDisplayText =
    overridingValue === '0' && linehaulId === SSD_NULL_VRID
      ? 'CET has been cancelled'
      : overridingValue === '0' && trailerStatus === SSD_TRAILER_STATUS.PENDING_DEPART.value
      ? 'Trailer has been cancelled'
      : 'Trailer has not departed';
  const onSubmit = () => {
    if (!injectionVolume.overrides) {
      injectionVolume.overrides = {};
    }
    if (!injectionVolume.overrides.overrideKeyValueMap) {
      injectionVolume.overrides.overrideKeyValueMap = {};
    }
    if (checked) {
      injectionVolume.overrides.overrideKeyValueMap.volume = '0';
      injectionVolume.overrides.reason = SSD_OVERRIDE_REASON_CODES.NO_REASON_GIVEN.value;
      injectionVolume.overrides.timestamp = parseInt(Date.now() / 1000);
      injectionVolume.overrides.user = currentUser;
    } else {
      delete injectionVolume.overrides.overrideKeyValueMap;
      delete injectionVolume.overrides.user;
      delete injectionVolume.overrides.timestamp;
      delete injectionVolume.overrides.reason;
    }
    const injectionVolumes = chainWalk(() => plan.input.ssdShiftPlanInput.volume.injectionVolumeList, []);
    if (
      injectionVolumes.findIndex(
        ({ cet: { label, timestamp } }) =>
          injectionVolume.cet.label === label && injectionVolume.cet.timestamp === timestamp
      ) < 0
    ) {
      injectionVolumes.push(injectionVolume);
    }
    setPlan({ ...plan });
    setOpen(false);
  };
  const onRevert = () => {
    if (injectionVolume.overrides) {
      delete injectionVolume.overrides.overrideKeyValueMap;
      delete injectionVolume.overrides.user;
      delete injectionVolume.overrides.timestamp;
      delete injectionVolume.overrides.reason;
    }
    setPlan({ ...plan });
  };
  const disabled = (checked ? 0 : null) === overridingValue;
  const isRevertible = overridingValue !== null;
  if (!isEditable) return <Text>{volumeDisplayText}</Text>;
  return (
    <React.Fragment>
      {isRevertible && (
        <Button type={'link'} onClick={onRevert}>
          <Icon tokens={undoTokens} />
        </Button>
      )}
      <Link type={'secondary'} onClick={() => setOpen(true)} ref={linkRef} aria-haspopup="dialog" aria-expanded="false">
        {volumeDisplayText}
      </Link>
      <Popover anchorNode={linkRef.current} open={open} onClose={() => setOpen(false)} position="bottom">
        <PopoverHeader closeLabel="Close">Provide an override</PopoverHeader>
        <Column>
          <Row width="100%" alignmentHorizontal="justify">
            <Text>VRID:</Text>
            <Text>{linehaulIdDisplayText}</Text>
          </Row>
          <Row width="100%" alignmentHorizontal="justify">
            <Text>Trailer Status:</Text>
            <Text>{trailerStatusDisplayText}</Text>
          </Row>
          <Row width="100%" alignmentHorizontal="justify">
            <Text>Planned Time:</Text>
            <Text>{plannedTimeDisplayText}</Text>
          </Row>
          <Row width="100%" alignmentHorizontal="justify">
            <Text>CET:</Text>
            <Text>{cetDisplayText}</Text>
          </Row>
          <Row width="100%" alignmentHorizontal="justify">
            <Text>Volume:</Text>
            <Text>Data not available. S&OP will be used in planning.</Text>
          </Row>
          <Row width="100%" alignmentHorizontal="justify">
            <Checkbox checked={checked} onChange={setChecked}>
              Planned volume has been cancelled
            </Checkbox>
          </Row>
          <Button disabled={disabled} onClick={onSubmit}>
            Submit Override
          </Button>
        </Column>
      </Popover>
    </React.Fragment>
  );
};

const OverrideTrailerVolumePopover = ({ currentUser, isEditable, injectionVolume, plan, setPlan }) => {
  const { cet, linehaulId, plannedTimeLocal, actualTime } = injectionVolume;
  const linkRef = useRef();
  const [open, setOpen] = useState(false);
  const overridingValue = chainWalk(() => injectionVolume.overrides.overrideKeyValueMap.volume, null);
  const initialValue = overridingValue != null ? overridingValue : injectionVolume.volume;
  const [newOverridingValue, setNewOverridingValue] = useState(initialValue);
  const onSubmitOverride = () => {
    if (!injectionVolume.overrides) {
      injectionVolume.overrides = {};
    }
    if (!injectionVolume.overrides.overrideKeyValueMap) {
      injectionVolume.overrides.overrideKeyValueMap = {};
    }
    injectionVolume.overrides.overrideKeyValueMap.volume = newOverridingValue + '';
    injectionVolume.overrides.reason = SSD_OVERRIDE_REASON_CODES.NO_REASON_GIVEN.value;
    injectionVolume.overrides.timestamp = parseInt(Date.now() / 1000);
    injectionVolume.overrides.user = currentUser;
    setPlan({ ...plan });
    setOpen(false);
  };
  const onRevert = () => {
    if (injectionVolume.overrides) {
      delete injectionVolume.overrides.overrideKeyValueMap;
      delete injectionVolume.overrides.users;
      delete injectionVolume.overrides.reason;
      delete injectionVolume.overrides.timestamp;
    }
    setPlan({ ...plan });
  };

  const isDisabled = initialValue === newOverridingValue;
  const isRevertible = overridingValue !== null;
  if (!isEditable) return <Text>{initialValue}</Text>;
  return (
    <React.Fragment>
      {isRevertible && (
        <Button type={'link'} onClick={onRevert}>
          <Icon tokens={undoTokens} />
        </Button>
      )}
      <Link type={'secondary'} onClick={() => setOpen(true)} ref={linkRef} aria-haspopup="dialog" aria-expanded="false">
        {initialValue}
      </Link>
      <Popover anchorNode={linkRef.current} open={open} onClose={() => setOpen(false)} position="bottom">
        <PopoverHeader closeLabel="Close">Provide an override</PopoverHeader>
        <Column>
          <Row width="100%" alignmentHorizontal="justify">
            <Text>VRID:</Text>
            <Text>{linehaulId}</Text>
          </Row>
          <Row width="100%" alignmentHorizontal="justify">
            <Text>Trailer Status:</Text>
            <Text>{SSD_TRAILER_STATUS.ARRIVED.label}</Text>
          </Row>
          {!!plannedTimeLocal && (
            <Row width="100%" alignmentHorizontal="justify">
              <Text>Planned Time:</Text>
              <Text>
                {displayTime(plannedTimeLocal)}, {displayDate(plannedTimeLocal)}
              </Text>
            </Row>
          )}
          {!!actualTime && (
            <Row width="100%" alignmentHorizontal="justify">
              <Text>Arrival Time:</Text>
              <Text>
                {displayTime(actualTime)}, {displayDate(actualTime)}
              </Text>
            </Row>
          )}
          <Row width="100%" alignmentHorizontal="justify">
            <Text>CET:</Text>
            <Text>{getCetDisplayText(cet)}</Text>
          </Row>
          <Row width="100%" alignmentHorizontal="justify">
            <Text type={'h100'}>Actual Volume:</Text>
            <Input value={newOverridingValue} onChange={setNewOverridingValue} type="number" size="small" />
          </Row>
          <Button disabled={isDisabled} onClick={onSubmitOverride}>
            Submit Override
          </Button>
        </Column>
      </Popover>
    </React.Fragment>
  );
};

const OverrideTrailer = ({ currentUser, isEditable, injectionVolume, plan, setPlan }) => {
  const { volume, trailerStatus, linehaulId } = injectionVolume;
  if (linehaulId === SSD_NULL_VRID || trailerStatus === SSD_TRAILER_STATUS.PENDING_DEPART.value) {
    return (
      <OverrideTrailerStatusPopover
        currentUser={currentUser}
        isEditable={isEditable}
        injectionVolume={injectionVolume}
        plan={plan}
        setPlan={setPlan}
      />
    );
  } else if (trailerStatus === SSD_TRAILER_STATUS.ARRIVED.value) {
    return (
      <OverrideTrailerVolumePopover
        currentUser={currentUser}
        isEditable={isEditable}
        injectionVolume={injectionVolume}
        plan={plan}
        setPlan={setPlan}
      />
    );
  } else {
    return <Text>{volume}</Text>;
  }
};

const ViewDispatchVolumes = ({ currentUser, isEditable, plan, setPlan }) => {
  const { prePlanCetToInjectionVolumesMap, planCetToInjectionVolumesMap, rtsVolume } = getDispatchVolumeData(plan);
  const injectionRows = [];
  const sortedPrePlanCets = Object.keys(prePlanCetToInjectionVolumesMap).sort();
  const sortedPlanCets = Object.keys(planCetToInjectionVolumesMap).sort();
  let cets = [];
  if (sortedPrePlanCets.length > 0) {
    cets = [PRE_PLAN_CET, ...sortedPrePlanCets];
  }
  cets = [...cets, PLANNING_HORIZON_CET, ...sortedPlanCets];

  cets.forEach((cetTimestamp) => {
    if ([PRE_PLAN_CET, PLANNING_HORIZON_CET].includes(cetTimestamp)) {
      return injectionRows.push(
        <TableRow>
          <TableCell columnSpan={6}>{cetTimestamp}</TableCell>
        </TableRow>
      );
    }
    const injectionVolumes =
      planCetToInjectionVolumesMap[cetTimestamp] || prePlanCetToInjectionVolumesMap[cetTimestamp];
    if (!injectionVolumes) return;
    chainWalk(() => injectionVolumes.volumes, []).forEach((injectionVolume) => {
      const deliveryWindow = chainWalk(() => injectionVolume.cet.label, '-');
      const dateTimeLocal = chainWalk(() => injectionVolume.cet.dateTimeLocal, null)
        ? new Date(injectionVolume.cet.dateTimeLocal)
        : null;
      let dateString = '';
      let cetDisplayText = '';
      if (dateTimeLocal) {
        dateString = ` ${
          dateTimeLocal.getUTCMonth() + 1
        }/${dateTimeLocal.getUTCDate()}/${dateTimeLocal.getUTCFullYear()}`;
        cetDisplayText = getCetDisplayText(injectionVolume.cet);
      }

      const { linehaulId, trailerStatus, plannedTimeLocal, gpsTime, actualTime } = injectionVolume;
      const linehaulIdDisplayText = linehaulId === SSD_NULL_VRID || !linehaulId ? '-' : linehaulId;
      const trailerStatusDisplayText =
        linehaulId === SSD_NULL_VRID
          ? '-'
          : trailerStatus === SSD_TRAILER_STATUS.PENDING_DEPART.value
          ? !!plannedTimeLocal &&
            `${SSD_TRAILER_STATUS.PENDING_DEPART.label} (Planned ${displayTime(plannedTimeLocal)} Arrival)`
          : trailerStatus === SSD_TRAILER_STATUS.IN_TRANSIT.value
          ? !!gpsTime && `${SSD_TRAILER_STATUS.IN_TRANSIT.label} (Expected ${displayTime(gpsTime)} Arrival)`
          : trailerStatus === SSD_TRAILER_STATUS.ARRIVED.value
          ? !!actualTime && `${SSD_TRAILER_STATUS.ARRIVED.label} (Arrived at ${displayTime(actualTime)})`
          : '';

      injectionRows.push(
        <TableRow>
          <TableCell />
          <TableCell>
            <Text type="h100">{`${deliveryWindow}${dateString}`}</Text>
          </TableCell>
          <TableCell>{cetDisplayText}</TableCell>
          <TableCell>{linehaulIdDisplayText}</TableCell>
          <TableCell>{trailerStatusDisplayText}</TableCell>
          <TableCell>
            <OverrideTrailer
              currentUser={currentUser}
              plan={plan}
              setPlan={setPlan}
              isEditable={isEditable}
              injectionVolume={injectionVolume}
            />
          </TableCell>
        </TableRow>
      );
    });
  });
  return (
    <div style={{ overflowX: 'auto', maxWidth: '100%' }}>
      <Table headerRows={1} showDividers={true}>
        <TableRow>
          <TableCell />
          <TableCell>Window</TableCell>
          <TableCell>CET</TableCell>
          <TableCell>VRID</TableCell>
          <TableCell>Trailer Status</TableCell>
          <TableCell>Volume</TableCell>
        </TableRow>

        <TableRow backgroundColor="secondary">
          <TableCell columnSpan={5}>
            <Text type="h100">RTS</Text>
          </TableCell>
          <TableCell>
            <OverrideRTSPopover
              currentUser={currentUser}
              plan={plan}
              setPlan={setPlan}
              isEditable={isEditable}
              rtsVolume={rtsVolume}
            />
          </TableCell>
        </TableRow>

        <TableRow backgroundColor="secondary">
          <TableCell columnSpan={5}>
            <Text type="h100">Injection</Text>
          </TableCell>
          <TableCell>-</TableCell>
        </TableRow>
        {injectionRows}
      </Table>
    </div>
  );
};

export default ViewDispatchVolumes;
