import React, { useCallback, useState, useMemo } from 'react';
import Table, { TableActionBar, TableCell, TableRow } from '@amzn/meridian/table';
import Text from '@amzn/meridian/text';

import { TEXTS } from '../../../utils/constants';
import { parseTimeStampToDateString } from '../../../utils/helpers';
import { isAfter, isBefore } from 'date-fns';

const VTOVETRecommendation = ({ labourOpportunities }) => {
  const [sortColumn, setSortColumn] = useState('timeFrame');
  const [sortDirection, setSortDirection] = useState('ascending');
  const onSort = useCallback(({ sortColumn, sortDirection }) => {
    setSortDirection(sortDirection);
    setSortColumn(sortColumn);
  }, []);

  const formatTimeWindow = useCallback((timeWindow) => {
    if (!timeWindow) {
      return '';
    }
    return parseTimeStampToDateString(timeWindow.start) + ' - ' + parseTimeStampToDateString(timeWindow.end);
  }, []);

  const formatSignUpWindow = useCallback((timeWindow) => {
    if (!timeWindow) {
      return '';
    }
    const start = new Date(timeWindow.start);
    const end = new Date(timeWindow.end);
    const now = new Date();
    if (isBefore(now, start) || isAfter(now, end)) {
      return 'Closed';
    }
    return <Text className="green-bold-text-color">{formatTimeWindow(timeWindow)}</Text>;
  }, []);

  const visibleData = useMemo(() => {
    return labourOpportunities
      .map((labourOpportunity) => {
        return {
          ...labourOpportunity,
          timeFrame: labourOpportunity.applicability.timeWindow
        };
      })
      .sort((a, b) => {
        const directionMultiplier = 'ascending' === sortDirection ? 1 : -1;
        if (sortColumn === 'opportunityType') {
          return directionMultiplier * a[sortColumn].localeCompare(b[sortColumn]);
        } else {
          if (!a[sortColumn]) {
            if (!b[sortColumn]) {
              return 0;
            }
            return -1;
          }
          if (!b[sortColumn]) {
            return 1;
          }
          const aStart = new Date(a[sortColumn].start);
          const bStart = new Date(b[sortColumn].start);

          let comparison = aStart > bStart ? 1 : -1;

          // we use the string representation to compare equality
          if (a[sortColumn].start === b[sortColumn].start) {
            comparison = new Date(a[sortColumn].end) > new Date(b[sortColumn].end) ? 1 : -1;
          }

          return directionMultiplier * comparison;
        }
      });
  }, [labourOpportunities, sortColumn, sortDirection]);

  return (
    <Table
      headerRows={1}
      showDividers={true}
      layout="fixed"
      spacing="small"
      sortColumn={sortColumn}
      sortDirection={sortDirection}
      onSort={onSort}>
      <TableActionBar>
        <Text type={TEXTS.H4}>Active VTO/VET Opportunities</Text>
      </TableActionBar>
      <TableRow highlightOnHover={true}>
        <TableCell sortColumn="opportunityType">{}</TableCell>
        <TableCell sortColumn="timeFrame">Time Frame</TableCell>
        <TableCell sortColumn="signUpWindow">Signup Window</TableCell>
        <TableCell alignmentHorizontal="right">Submitted</TableCell>
        <TableCell alignmentHorizontal="right">Accepted</TableCell>
      </TableRow>
      {visibleData.map((labourOpportunity, index) => {
        return (
          <TableRow highlightOnHover={true} key={index}>
            <TableCell>{labourOpportunity.opportunityType}</TableCell>
            <TableCell>{formatTimeWindow(labourOpportunity.timeFrame)}</TableCell>
            <TableCell>{formatSignUpWindow(labourOpportunity.signUpWindow)}</TableCell>
            <TableCell alignmentHorizontal="right">{labourOpportunity.submitted}</TableCell>
            <TableCell alignmentHorizontal="right">{labourOpportunity.accepted}</TableCell>
          </TableRow>
        );
      })}
    </Table>
  );
};

export default VTOVETRecommendation;
