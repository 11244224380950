import React, { useState } from 'react';
import Box from '@amzn/meridian/box';
import Text from '@amzn/meridian/text';
import Input from '@amzn/meridian/input';
import Column from '@amzn/meridian/column';
import Icon from '@amzn/meridian/icon';
import tooltipIcon from '@amzn/meridian-tokens/base/icon/info-knockout';
import errWarningIcon from '@amzn/meridian-tokens/base/icon/alert-error-large';
import Row from '@amzn/meridian/row';
import Tooltip from '@amzn/meridian/tooltip';

import AddText from '../../../utils/AddText';
import AddTooltip from '../../../utils/AddTooltip';
import {
  convertToFixedDP,
  isNumberInvalid,
  isNumberNotInRange,
  planTypeFormat,
  isSSDCycle,
  chainWalk,
  parseTimeStampToDateString
} from '../../../utils/helpers';
import { TEXTS } from '../../../utils/constants';
import {
  getGuardrails,
  getMaxAllowedVolumePercentage,
  LAST_DAY_ROLLOVER_VOLUME,
  NEXT_DAY_PLANNED_VOLUME,
  PLANNED_ROLLOVER_VOLUME,
  VOLUME_GUARDRAILS
} from '../../../utils/guardrails';

let currentNextDayPlannedVolume = {};

const isSSDCycleAndZeroVolume = (cycleName, volume, type) => {
  return isSSDCycle(cycleName) && type === NEXT_DAY_PLANNED_VOLUME.type && volume === 0;
};

const VolumeContent = ({
  type,
  currentValue,
  title,
  prefix,
  tooltipText,
  data,
  index,
  errorCounter,
  updateButtonState,
  minAllowed,
  maxAllowed,
  initialValue,
  suggestedValue,
  isUserAuthorisedForShiftPlanOverrides,
  cycleName,
  isNDPSuggestedVolumeZero
}) => {
  const [val, setVal] = useState(currentValue);

  const VOLUME_OVERRIDE_LIMIT = getGuardrails(VOLUME_GUARDRAILS, data.nodeType);

  if (type === NEXT_DAY_PLANNED_VOLUME.type && !(cycleName in currentNextDayPlannedVolume)) {
    currentNextDayPlannedVolume[cycleName] = currentValue;
  }

  const [isErr, setIsErr] = useState(false);

  maxAllowed =
    type !== NEXT_DAY_PLANNED_VOLUME.type && cycleName in currentNextDayPlannedVolume
      ? convertToFixedDP(
          (getMaxAllowedVolumePercentage(type, data.nodeType) *
            convertToFixedDP(currentNextDayPlannedVolume[cycleName])) /
            100
        )
      : maxAllowed;

  const setText = (value) => {
    if (
      !isSSDCycleAndZeroVolume(cycleName, initialValue, type) &&
      (isNumberInvalid(value) ||
        isNumberNotInRange(value, minAllowed, maxAllowed, isUserAuthorisedForShiftPlanOverrides))
    ) {
      if (isErr === false) errorCounter.count++;
      setIsErr(true);
    } else {
      if (isErr === true) errorCounter.count--;
      setIsErr(false);
      data.input.packageProjectionInput.packageProjectionList[index].volume[type] = convertToFixedDP(value);
    }
    updateButtonState();
    setVal(value);
    currentNextDayPlannedVolume[cycleName] =
      type === NEXT_DAY_PLANNED_VOLUME.type ? value : currentNextDayPlannedVolume[cycleName];
  };
  return (
    <Column spacing="200">
      <Row alignmentHorizontal="left" widths="fit" spacing="200">
        <Text tag="label" type={TEXTS.T3} color="primary" htmlFor="my-input" alignment="left">
          {title}
        </Text>
        <Tooltip position="right" title={tooltipText}>
          <Icon tokens={tooltipIcon} />
        </Tooltip>
      </Row>
      {!isNumberInvalid(suggestedValue) ? <AddText>{`Suggested value: ${suggestedValue}`}</AddText> : null}
      <Row>
        <Input
          value={val}
          type="number"
          placeholder="Enter value..."
          onChange={setText}
          size="small"
          error={isErr}
          prefix={prefix}
          focus={isErr ? false : undefined}
          width="220px"
        />
        {isNDPSuggestedVolumeZero &&
        isErr &&
        !isSSDCycleAndZeroVolume(cycleName, initialValue, type) &&
        !isUserAuthorisedForShiftPlanOverrides ? (
          <div>
            <Row>
              <Icon tokens={errWarningIcon} />
              <Text color="error" type={TEXTS.T3}>
                {type === NEXT_DAY_PLANNED_VOLUME.type && !isNumberInvalid(suggestedValue)
                  ? VOLUME_OVERRIDE_LIMIT[NEXT_DAY_PLANNED_VOLUME.alias].MIN_THRESHOLD_PERCENTAGE === 100
                    ? `0 to +${
                        VOLUME_OVERRIDE_LIMIT[NEXT_DAY_PLANNED_VOLUME.alias].MAX_THRESHOLD_PERCENTAGE
                      }% max of suggested value`
                    : `Needs to stay within -${
                        VOLUME_OVERRIDE_LIMIT[NEXT_DAY_PLANNED_VOLUME.alias].MIN_THRESHOLD_PERCENTAGE
                      }% to +${
                        VOLUME_OVERRIDE_LIMIT[NEXT_DAY_PLANNED_VOLUME.alias].MAX_THRESHOLD_PERCENTAGE
                      }% max of suggested value`
                  : type === NEXT_DAY_PLANNED_VOLUME.type
                  ? `Needs to stay within -${
                      VOLUME_OVERRIDE_LIMIT[NEXT_DAY_PLANNED_VOLUME.alias].MIN_THRESHOLD_PERCENTAGE
                    }% to +${VOLUME_OVERRIDE_LIMIT[NEXT_DAY_PLANNED_VOLUME.alias].MAX_THRESHOLD_PERCENTAGE}% max`
                  : type === LAST_DAY_ROLLOVER_VOLUME.type
                  ? `Needs to stay within ${
                      VOLUME_OVERRIDE_LIMIT[LAST_DAY_ROLLOVER_VOLUME.alias].MAX_THRESHOLD_PERCENTAGE
                    }% of Next Day Shift Planned Volume`
                  : `Needs to stay within ${
                      VOLUME_OVERRIDE_LIMIT[PLANNED_ROLLOVER_VOLUME.alias].MAX_THRESHOLD_PERCENTAGE
                    }% of Next Day Shift Planned Volume`}
              </Text>
            </Row>
          </div>
        ) : null}
      </Row>
      {isNDPSuggestedVolumeZero ? (
        !isSSDCycleAndZeroVolume(cycleName, initialValue, type) && !isUserAuthorisedForShiftPlanOverrides ? (
          <Text type={TEXTS.T3} color="secondary" alignment="left">
            {type === NEXT_DAY_PLANNED_VOLUME.type && !isNumberInvalid(suggestedValue)
              ? VOLUME_OVERRIDE_LIMIT[NEXT_DAY_PLANNED_VOLUME.alias].MIN_THRESHOLD_PERCENTAGE === 100
                ? `0 to +${
                    VOLUME_OVERRIDE_LIMIT[NEXT_DAY_PLANNED_VOLUME.alias].MAX_THRESHOLD_PERCENTAGE
                  }% max of Next Day Shift Planned Suggested Volume`
                : `-${VOLUME_OVERRIDE_LIMIT[NEXT_DAY_PLANNED_VOLUME.alias].MIN_THRESHOLD_PERCENTAGE}% to +${
                    VOLUME_OVERRIDE_LIMIT[NEXT_DAY_PLANNED_VOLUME.alias].MAX_THRESHOLD_PERCENTAGE
                  }% max of Next Day Shift Planned Suggested Volume`
              : type === NEXT_DAY_PLANNED_VOLUME.type
              ? VOLUME_OVERRIDE_LIMIT[NEXT_DAY_PLANNED_VOLUME.alias].MIN_THRESHOLD_PERCENTAGE === 100
                ? `0 to +${
                    VOLUME_OVERRIDE_LIMIT[NEXT_DAY_PLANNED_VOLUME.alias].MAX_THRESHOLD_PERCENTAGE
                  }% max of Next Day Shift Planned Suggested Volume`
                : `-${VOLUME_OVERRIDE_LIMIT[NEXT_DAY_PLANNED_VOLUME.alias].MIN_THRESHOLD_PERCENTAGE}% to +${
                    VOLUME_OVERRIDE_LIMIT[NEXT_DAY_PLANNED_VOLUME.alias].MAX_THRESHOLD_PERCENTAGE
                  }% max of Next Day Shift Planned Volume`
              : type === LAST_DAY_ROLLOVER_VOLUME.type
              ? `0 to ${
                  VOLUME_OVERRIDE_LIMIT[LAST_DAY_ROLLOVER_VOLUME.alias].MAX_THRESHOLD_PERCENTAGE
                }% of Next Day Shift Planned Volume`
              : `0 to ${
                  VOLUME_OVERRIDE_LIMIT[PLANNED_ROLLOVER_VOLUME.alias].MAX_THRESHOLD_PERCENTAGE
                }% of Next Day Shift Planned Volume`}
          </Text>
        ) : null
      ) : null}
    </Column>
  );
};

const CycleVolume = ({
  data,
  index,
  errorCounter,
  updateButtonState,
  initialData,
  dupData,
  isUserAuthorisedForShiftPlanOverrides,
  cycleName,
  isReset
}) => {
  let {
    plannedVolume: suggestedPlannedVolume,
    plannedRolloverVolume: suggestedPlannedRolloverVolume,
    lastDayRolloverVolume: suggestedLastDayRolloverVolume
  } = data.input.shiftPlanSuggestedInput
    ? data.input.shiftPlanSuggestedInput.packageProjectionInput.packageProjectionList[index].volume
    : '';
  let {
    plannedVolume: currentPlannedVolume,
    plannedRolloverVolume: currentPlannedRolloverVolume,
    lastDayRolloverVolume: currentLastDayRolloverVolume
  } = data.input.packageProjectionInput.packageProjectionList[index].volume;
  let {
    plannedVolume: initialPlannedVolume,
    plannedRolloverVolume: initialPlannedRolloverVolume,
    lastDayRolloverVolume: initialLastDayRolloverVolume
  } = JSON.parse(dupData).input.packageProjectionInput.packageProjectionList[index].volume;

  let volumeDistributionOverrides = chainWalk(
    () =>
      data.input.packageProjectionInput.packageProjectionList[index].cycleVolumeDistributionOverride
        .processVolumeDistributionOverrideMap['Induct'],
    null
  );

  const VOLUME_OVERRIDE_LIMIT = getGuardrails(VOLUME_GUARDRAILS, data.nodeType);

  const getAllowedDelta = (volume, limit) => {
    return convertToFixedDP((limit * convertToFixedDP(volume)) / 100);
  };
  const getMinAllowedForVolume = (volume, delta) => {
    return Math.max(convertToFixedDP(volume) - delta, 0);
  };
  const getMaxAllowedForVolume = (volume, delta) => {
    return convertToFixedDP(volume) + delta;
  };

  const planType = planTypeFormat(data.planType);

  const plannedVolumedAllowedDelta = (limit) => {
    return getAllowedDelta(
      !isNumberInvalid(suggestedPlannedVolume) ? suggestedPlannedVolume : initialPlannedVolume,
      limit
    );
  };
  const plannedVolumeMaxAllowed =
    suggestedPlannedVolume === 0
      ? Number.MAX_VALUE
      : convertToFixedDP(!isNumberInvalid(suggestedPlannedVolume) ? suggestedPlannedVolume : initialPlannedVolume) +
        plannedVolumedAllowedDelta(VOLUME_OVERRIDE_LIMIT.PLANNED.MAX_THRESHOLD_PERCENTAGE);

  const plannedVolumeMinAllowed =
    suggestedPlannedVolume === 0
      ? 0
      : convertToFixedDP(!isNumberInvalid(suggestedPlannedVolume) ? suggestedPlannedVolume : initialPlannedVolume) -
        plannedVolumedAllowedDelta(VOLUME_OVERRIDE_LIMIT.PLANNED.MIN_THRESHOLD_PERCENTAGE);

  const isNDPSuggestedVolumeZero = suggestedPlannedVolume !== 0;

  return (
    <Column maxWidth="40%" heights="fit" alignmentHorizontal="stretch" alignmentVertical="top" spacing="medium">
      {volumeDistributionOverrides ? (
        <div>
          <Box spacingInset="0 0 200 0">
            <AddTooltip size={TEXTS.T3}>Volume Split</AddTooltip>
          </Box>
          {volumeDistributionOverrides.map((vdo) => (
            <div>
              <Row>
                <Text color="secondary" type={TEXTS.T3}>
                  {parseTimeStampToDateString(vdo.timeWindow.start)} - {parseTimeStampToDateString(vdo.timeWindow.end)}
                </Text>
                <Text color="secondary" type={TEXTS.T3}>
                  {vdo.percentage * 100}%
                </Text>
              </Row>
            </div>
          ))}
        </div>
      ) : null}
      {[
        {
          type: NEXT_DAY_PLANNED_VOLUME.type,
          title: `${planType} Planned Volume`,
          tooltipText: 'Next Day Planned Volume from Last Mile Capacity Planning',
          currentValue: currentPlannedVolume,
          minAllowed: plannedVolumeMinAllowed,
          maxAllowed: plannedVolumeMaxAllowed,
          initialValue: initialPlannedVolume,
          suggestedValue: suggestedPlannedVolume,
          isNDPSuggestedVolumeZero: isNDPSuggestedVolumeZero
        },
        {
          type: LAST_DAY_ROLLOVER_VOLUME.type,
          title: 'Sideline/RTS',
          tooltipText: 'Total Sidelined Volume and RTS Volume Processed Yesterday',
          currentValue: currentLastDayRolloverVolume,
          prefix: '+',
          minAllowed: getAllowedDelta(suggestedPlannedVolume, VOLUME_OVERRIDE_LIMIT.SIDELINE.MIN_THRESHOLD_PERCENTAGE),
          maxAllowed: Math.max(currentLastDayRolloverVolume, plannedVolumeMaxAllowed),
          initialValue: initialLastDayRolloverVolume,
          suggestedValue: suggestedLastDayRolloverVolume,
          isNDPSuggestedVolumeZero: isNDPSuggestedVolumeZero
        },
        {
          type: PLANNED_ROLLOVER_VOLUME.type,
          title: 'Planned Rollover',
          tooltipText: 'Total volume that will be sidelined today at induct and rolled over to next day',
          currentValue: currentPlannedRolloverVolume,
          prefix: '-',
          minAllowed: getAllowedDelta(suggestedPlannedVolume, VOLUME_OVERRIDE_LIMIT.ROLLOVER.MIN_THRESHOLD_PERCENTAGE),
          maxAllowed: Math.max(currentPlannedRolloverVolume, plannedVolumeMaxAllowed),
          initialValue: initialPlannedRolloverVolume,
          suggestedValue: suggestedPlannedRolloverVolume,
          isNDPSuggestedVolumeZero: isNDPSuggestedVolumeZero
        }
      ].map((content, i) => (
        <VolumeContent
          key={i}
          type={content.type}
          title={content.title}
          tooltipText={content.tooltipText}
          currentValue={content.currentValue}
          prefix={content.prefix}
          data={data}
          index={index}
          updateButtonState={updateButtonState}
          errorCounter={errorCounter}
          minAllowed={content.minAllowed}
          maxAllowed={content.maxAllowed}
          initialValue={content.initialValue}
          suggestedValue={content.suggestedValue}
          isUserAuthorisedForShiftPlanOverrides={isUserAuthorisedForShiftPlanOverrides}
          cycleName={cycleName}
          isNDPSuggestedVolumeZero={isNDPSuggestedVolumeZero}
        />
      ))}
    </Column>
  );
};

export default CycleVolume;
