import React, { Component, useContext } from 'react';

import ErrorPage from './ErrorPage';

import { GlobalStateContext } from '../../global/context/GlobalStateContext';

const ErrorBoundary = ({ children }) => {
  const { asyncError, resetID } = useContext(GlobalStateContext);
  return (
    <ErrorBoundaryClass key={resetID} asyncError={asyncError}>
      {children}
    </ErrorBoundaryClass>
  );
};

class ErrorBoundaryClass extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: '' };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.asyncError !== this.props.asyncError && this.props.asyncError !== '') {
      this.setState({ hasError: true, error: this.props.asyncError });
    }
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage errorTitle="Some error occurred" errorMessage={this.state.error} />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
