import React, { useState, useEffect, useContext } from 'react';
import Table, { TableRow, TableCell } from '@amzn/meridian/table';
import Text from '@amzn/meridian/text';
import Thumbnail from '@amzn/meridian/thumbnail';

export const ManagerViewTable = ({ table }) => {
  return (
    <Table showDividers={false} spacing="small" showStripes={true} className="labor-board-associate-view-table">
      {table.map((associate) => {
        return (
          <TableRow key={associate.alias}>
            <TableCell>
              <Thumbnail
                borderColor="gray"
                size="small"
                source={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?${
                  isNaN(associate.alias) ? `uid` : `employeeid`
                }=${associate.alias}`}
              />
            </TableCell>
            <TableCell>
              <Text transform="capitalize" type="b200">
                {associate.name}
              </Text>
            </TableCell>
            <TableCell alignmentHorizontal="end">
              <Text type="b200">{associate.path}</Text>
            </TableCell>
          </TableRow>
        );
      })}
    </Table>
  );
};
