import React from 'react';
import Loader from '@amzn/meridian/loader';
import Column from '@amzn/meridian/column';

import CustomAlert from '../../commonComponents/CustomAlert';

import './LoadingView.css';

const LoadingView = ({ loadingMsg }) => {
  return (
    <Column className="loaderClass" alignmentHorizontal="center" alignmentVertical="center">
      <CustomAlert title={loadingMsg ? loadingMsg : 'Fetching data... Please wait for few seconds'} width="fit" />
      <Loader type="circular" size="medium" />
    </Column>
  );
};

export default LoadingView;
