import React, { useEffect, useState } from 'react';
import Minibar from '@amzn/meridian/minibar';
import Legend, { LegendProvider } from '@amzn/meridian/legend';
import Row from '@amzn/meridian/row';
import Expander from '@amzn/meridian/expander';
import Table, { TableRow, TableCell } from '@amzn/meridian/table';
import { chainWalk, parseEpochToDateStringWithOptions } from '../utils/helpers';
import { DATE_TIME_FORMAT_WITHOUT_TZ } from '../utils/constants';

const ControlledExpander = (props) => {
  const [open, setOpen] = useState();
  return <Expander open={open} onChange={setOpen} {...props} />;
};

const planStartTime = (plan) => {
  return chainWalk(() => plan.usageTimeWindow.startInSeconds, 0);
};

const dateString = (date) => {
  return parseEpochToDateStringWithOptions(date, DATE_TIME_FORMAT_WITHOUT_TZ).replace('at', '');
};

const ShiftAdherencePlansView = ({ adherenceData }) => {
  const currentTimeInSeconds = Math.floor(Date.now() / 1000);
  const plans = chainWalk(() => adherenceData.planAttrs.adherencePlanInfoList, []);
  const queryEndTimeInSeconds = chainWalk(() => adherenceData.timeWindow.endInSeconds);

  // sort plans by usage start at (ascending)
  plans.sort((plan1, plan2) => planStartTime(plan1) - planStartTime(plan2));

  // data for populating UI Minibar for plans
  const minibarData = [];
  // data for populating plan list table
  const plansViewData = [];

  let adherencePendingPlanExists = false;
  // populate both minibarData and plansViewData using each plan
  plans.forEach((plan, iterator) => {
    // identify plan usage
    const createdAtEpoch = chainWalk(() => plan.createdAtEpochInSecs, null);
    const usageStartEpoch = chainWalk(() => plan.usageTimeWindow.startInSeconds, null);
    let usageEndEpoch = chainWalk(() => plans[iterator + 1].usageTimeWindow.startInSeconds, queryEndTimeInSeconds);
    // in case the adherence of this plan is pending (in future), clip it to current time
    let planAdherenceForThisPlanIncomplete = false;
    if (usageEndEpoch > currentTimeInSeconds) {
      usageEndEpoch = currentTimeInSeconds;
      planAdherenceForThisPlanIncomplete = true;
    }

    const planViewData = {
      id: plan.id,
      serialNo: iterator + 1,
      createdAt: createdAtEpoch ? dateString(createdAtEpoch) : null,
      usageStart: usageStartEpoch ? dateString(usageStartEpoch) : null,
      usageEnd: usageEndEpoch ? dateString(usageEndEpoch) : null
    };
    plansViewData.push(planViewData);

    // timeFrameInSeconds is the metric used for populating the ratio of usage in minibar
    const timeFrameInSeconds = usageEndEpoch - usageStartEpoch;

    // if there is any plan previously for which adherence is incomplete, skip populating all future plans
    if (!adherencePendingPlanExists) {
      minibarData.push({
        key: plan.id,
        value: timeFrameInSeconds,
        label: 'Plan ' + planViewData.serialNo
      });
    }
    if (planAdherenceForThisPlanIncomplete) {
      adherencePendingPlanExists = true;
    }
  });

  // append future window (pending adherence)
  if (queryEndTimeInSeconds > currentTimeInSeconds) {
    minibarData.push({
      key: 'Future',
      value: queryEndTimeInSeconds - currentTimeInSeconds,
      label: 'Future'
    });
  }

  return (
    <LegendProvider data={minibarData}>
      <Row alignmentHorizontal="center">
        <Minibar width="90%" />
      </Row>
      <Row alignmentHorizontal="center" alignmentVertical="top">
        <Row width="90%" widths={['grid-10', 'grid-2']}>
          <ControlledExpander width="80%" widths="fill" title={plansViewData.length + ' plans in use'} type="inline">
            <Table headerRows={1}>
              <TableRow>
                <TableCell>Plan</TableCell>
                <TableCell>ID</TableCell>
                <TableCell>Start</TableCell>
                <TableCell>Created At</TableCell>
              </TableRow>
              {plansViewData.map((plan) => (
                <TableRow highlightOnHover={true}>
                  <TableCell>Plan {plan.serialNo}</TableCell>
                  <TableCell>{plan.id}</TableCell>
                  <TableCell>{plan.usageStart}</TableCell>
                  <TableCell>{plan.createdAt}</TableCell>
                </TableRow>
              ))}
            </Table>
          </ControlledExpander>
          <Legend direction="horizontal" values={() => null} />
        </Row>
      </Row>
    </LegendProvider>
  );
};

export default ShiftAdherencePlansView;
