import Table, { TableActionBar, TableCell, TableRow } from '@amzn/meridian/table';
import React, { useRef, useState, useCallback } from 'react';
import Box from '@amzn/meridian/box';
import Divider from '@amzn/meridian/divider';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import Link from '@amzn/meridian/link';
import Popover from '@amzn/meridian/popover';
import { TEXTS, VTO_VET_RECOMMENDATION_TABLE_TITLE } from '../../../utils/constants';
import { chainWalk, parseTimeStampToDateString } from '../../../utils/helpers';
import TimePicker from '@amzn/meridian/time-picker';
import Column from '@amzn/meridian/column';
import Input from '@amzn/meridian/input';

const getGroupedLabourOpportunities = (labourOpportunities) => {
  if (!labourOpportunities) {
    return [];
  }
  const grouped = labourOpportunities.reduce((acc, curr) => {
    const match = acc.find(
      (lo) =>
        lo.opportunityType === curr.opportunityType &&
        lo.applicability.timeWindow.end === curr.applicability.timeWindow.end &&
        lo.applicability.timeWindow.start === curr.applicability.timeWindow.start
    );
    if (match) {
      match.headcount = (parseInt(match.headcount) || 0) + (parseInt(curr.headcount) || 0);
    } else {
      acc.push({ ...curr });
    }
    return acc;
  }, []);
  return grouped;
};

const HeadcountInput = ({ labourOpportunity }) => {
  const [value, setValue] = useState(labourOpportunity.headcount);
  const onChange = (val) => {
    labourOpportunity.headcount = val;
    setValue(val);
  };
  return (
    <Column spacing="xxsmall">
      <Input value={value} onChange={onChange} type="number" size="small" placeholder="Enter value..." width={120} />
    </Column>
  );
};

const AdditionalRecommendedVTOTable = ({ data, isPendingApproval }) => {
  /*********************
   *                   *
   *      hooks        *
   *                   *
   *********************/
  const { labourOpportunities } = data.output;
  const initialLabourOpportunities = JSON.parse(JSON.stringify(labourOpportunities));
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const groupedLabourOpportunities = getGroupedLabourOpportunities(labourOpportunities);

  /*********************
   *                   *
   *      handler      *
   *                   *
   *********************/

  const onClickEdit = () => {
    setIsEditEnabled(true);
  };

  const onClickCancelEdit = () => {
    data.output.labourOpportunities = initialLabourOpportunities;
    setIsEditEnabled(false);
  };

  const onChangeTimeWindowStart = (newStart, labourOpportunity) => {
    labourOpportunity.applicability.timeWindow.start =
      labourOpportunity.applicability.timeWindow.start.split('T')[0] + `T${newStart}`;
  };

  const onChangeTimeWindowEnd = (newEnd, labourOpportunity) => {
    labourOpportunity.applicability.timeWindow.end =
      labourOpportunity.applicability.timeWindow.end.split('T')[0] + `T${newEnd}`;
  };

  /*********************
   *                   *
   *      renderer     *
   *                   *
   *********************/

  const renderEditOrCancelEditBtn = () => {
    if (!isPendingApproval) return false;
    if (isEditEnabled)
      return (
        <Link type="secondary" onClick={onClickCancelEdit}>
          Cancel
        </Link>
      );
    return (
      <Link type="secondary" onClick={onClickEdit}>
        Edit
      </Link>
    );
  };

  const renderLaborOpportunityTableRow = (labourOpportunity, index) => {
    {
      const buttonRef = useRef();
      const [open, setOpen] = useState(false);
      const openPopover = useCallback(() => setOpen(true), []);
      const closePopover = useCallback(() => setOpen(false), []);
      let workgroupTags = chainWalk(() => labourOpportunity.workgroupTags, null);
      return (
        <TableRow highlightOnHover={true} key={index}>
          <TableCell>{labourOpportunity.opportunityType}</TableCell>
          <TableCell>
            <span onMouseEnter={workgroupTags ? openPopover : null} onMouseLeave={closePopover}>
              <Text ref={buttonRef}>
                {parseTimeStampToDateString(labourOpportunity.applicability.timeWindow.start) +
                  ' - ' +
                  parseTimeStampToDateString(labourOpportunity.applicability.timeWindow.end)}
              </Text>
            </span>
            <Popover anchorNode={buttonRef.current} open={open} position="right" spacingInset="medium">
              {workgroupTags ? (
                <div>
                  <Box spacingInset="0 0 300 0">
                    <Text type={TEXTS.H4} alignment="left">
                      Work Group
                    </Text>
                  </Box>
                  <Divider />
                  {workgroupTags.map((workgroupTag) => (
                    <div>
                      <Row>
                        <Text type={TEXTS.T3}>{workgroupTag}</Text>
                      </Row>
                    </div>
                  ))}
                </div>
              ) : (
                ''
              )}
            </Popover>
          </TableCell>
          <TableCell alignmentHorizontal="right">{labourOpportunity.headcount}</TableCell>
        </TableRow>
      );
    }
  };

  const renderEditLaborOpportunityTableRow = (labourOpportunity, index) => {
    const initialStart = parseTimeStampToDateString(labourOpportunity.applicability.timeWindow.start);
    const initialEnd = parseTimeStampToDateString(labourOpportunity.applicability.timeWindow.end);
    return (
      <TableRow highlightOnHover={true} key={index}>
        <TableCell>{labourOpportunity.opportunityType}</TableCell>
        <TableCell>
          <div className="leftCenter">
            <TimePicker
              onChange={(e) => onChangeTimeWindowStart(e, labourOpportunity)}
              width={120}
              format={{ hourCycle: 'h23', hour: '2-digit', minute: '2-digit' }}
              size="small"
              showIcon={false}
              placeholder={initialStart}
              invalidInputMessage="Invalid time. The correct time format is hour:minute."
            />
            <span style={{ marginLeft: 5, marginRight: 5 }}>-</span>
            <TimePicker
              onChange={(e) => onChangeTimeWindowEnd(e, labourOpportunity)}
              width={120}
              format={{ hourCycle: 'h23', hour: '2-digit', minute: '2-digit' }}
              size="small"
              showIcon={false}
              placeholder={initialEnd}
              invalidInputMessage="Invalid time. The correct time format is hour:minute."
            />
          </div>
        </TableCell>
        <TableCell alignmentHorizontal="right">
          <div className="rightCenter">
            <HeadcountInput labourOpportunity={labourOpportunity} />
          </div>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Table headerRows={1} showDividers={true} showStripes={false} spacing="small">
      {!isPendingApproval ? (
        <TableActionBar>
          <Text type={TEXTS.H4}>{VTO_VET_RECOMMENDATION_TABLE_TITLE}</Text>
        </TableActionBar>
      ) : (
        <div
          style={{
            backgroundColor: '#E6D476',
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 8,
            paddingBottom: 8
          }}
          className="spaceCenter">
          <Text type={TEXTS.H4}>{VTO_VET_RECOMMENDATION_TABLE_TITLE}</Text>
          {renderEditOrCancelEditBtn()}
        </div>
      )}
      <TableRow>
        <TableCell>{}</TableCell>
        <TableCell>Time Frame</TableCell>
        <TableCell alignmentHorizontal="right"># of Opportunities (HC)</TableCell>
      </TableRow>
      {!isEditEnabled &&
        Array.isArray(groupedLabourOpportunities) &&
        groupedLabourOpportunities.map((labourOpportunity, index) =>
          renderLaborOpportunityTableRow(labourOpportunity, index)
        )}
      {isEditEnabled &&
        Array.isArray(labourOpportunities) &&
        labourOpportunities.map((labourOpportunity, index) =>
          renderEditLaborOpportunityTableRow(labourOpportunity, index)
        )}
    </Table>
  );
};

export default AdditionalRecommendedVTOTable;
