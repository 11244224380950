import React, { useState } from 'react';
import Button from '@amzn/meridian/button';
import Row from '@amzn/meridian/row';
import Expander from '@amzn/meridian/expander';
import Text from '@amzn/meridian/text';
import Column from '@amzn/meridian/column';
import Divider from '@amzn/meridian/divider';
import Icon from '@amzn/meridian/icon';
import Tooltip from '@amzn/meridian/tooltip';
import tooltipIcon from '@amzn/meridian-tokens/base/icon/info-knockout';

import ShiftAttendanceMenu from './ShiftAttendanceMenu';
import VTOVETRecommendation from '../viewPlan/VTOVETRecommendation';
import OverrideReason from '../../commonComponents/OverrideReasons';

import {
  chainWalk,
  convertToFixedDP,
  getWindowDurationInMin,
  extractMainWorkGroup,
  parseTimeStampToDateString
} from '../../../utils/helpers';
import { TEXTS } from '../../../utils/constants';
import { enrichShiftGroupAvailabilities } from '../../../utils/shiftPlanHelper';
import { getExperimentMode, getIsTest } from '../../../utils/networkUtil';

const ControlledExpander = (props) => {
  const [open, setOpen] = useState(props.isOpen);
  return (
    <Expander open={open} onChange={setOpen} {...props}>
      {props.children}
    </Expander>
  );
};

const ShiftAttendance = ({
  data,
  updateButtonState,
  errorCounter,
  initialData,
  dupData,
  isUserAuthorisedForShiftPlanOverrides,
  isReset
}) => {
  return data.input.labourAvailability.shiftGroupAvailabilities
    .filter((shiftGroupAvailability) => {
      return (
        shiftGroupAvailability.isUnmatchedAssociate === undefined ||
        shiftGroupAvailability.isUnmatchedAssociate === false
      );
    })
    .map((shiftGroupAvailability, index) => {
      const initialLabourAvailabilityHeadCount =
        initialData.input.labourAvailability.shiftGroupAvailabilities[index].labourAvailabilityHeadCount;

      let maxExpectedHeadCount = 0;
      let maxExpectedShowHours = 0;

      if (
        shiftGroupAvailability.cycleActivityLabourAvailabilityHeadCount === undefined ||
        Object.keys(shiftGroupAvailability.cycleActivityLabourAvailabilityHeadCount).length === 0
      ) {
        const attendancePercentage = Math.round(
          convertToFixedDP(
            chainWalk(() => shiftGroupAvailability.labourAvailabilityHeadCount.attendancePercentage, 0),
            2
          ) * 100
        );
        const rosteredHeadCount = convertToFixedDP(
          chainWalk(() => shiftGroupAvailability.labourAvailabilityHeadCount.rosteredHeadcount, 0)
        );
        const expectedHeadCount = convertToFixedDP(rosteredHeadCount * (attendancePercentage / 100.0), 0);
        const durationInHours = chainWalk(
          () =>
            getWindowDurationInMin(
              shiftGroupAvailability.applicability.timeWindow.end,
              shiftGroupAvailability.applicability.timeWindow.start
            ) / 60.0,
          0
        );
        const unpaidBreaks = convertToFixedDP(chainWalk(() => initialLabourAvailabilityHeadCount.unpaidBreaks, 0));
        const rosteredShowHours = (durationInHours - unpaidBreaks / 60.0) * rosteredHeadCount;
        const expectedShowHours = convertToFixedDP(rosteredShowHours * (attendancePercentage / 100.0));
        maxExpectedHeadCount = expectedHeadCount;
        maxExpectedShowHours = expectedShowHours;
      }

      // Show the maximum "HC" and "Show Hours" across all cycle + activity groups.
      for (const [, activityLabourAvailabilityHeadCount] of Object.entries(
        shiftGroupAvailability.cycleActivityLabourAvailabilityHeadCount
      )) {
        for (const [, labourAvailabilityHeadCount] of Object.entries(activityLabourAvailabilityHeadCount)) {
          const attendancePercentage = Math.round(
            convertToFixedDP(
              chainWalk(() => labourAvailabilityHeadCount.attendancePercentage, 0),
              2
            ) * 100
          );
          const rosteredHeadCount = convertToFixedDP(chainWalk(() => labourAvailabilityHeadCount.rosteredHeadcount, 0));
          const day1NewHireHeadCount = convertToFixedDP(chainWalk(() => labourAvailabilityHeadCount.day1NewHireHeadcount, 0));
          const expectedHeadCount = convertToFixedDP((rosteredHeadCount - day1NewHireHeadCount) * (attendancePercentage / 100.0), 0);
          const durationInHours = chainWalk(
            () =>
              getWindowDurationInMin(
                shiftGroupAvailability.applicability.timeWindow.end,
                shiftGroupAvailability.applicability.timeWindow.start
              ) / 60.0,
            0
          );
          const unpaidBreaks = convertToFixedDP(chainWalk(() => initialLabourAvailabilityHeadCount.unpaidBreaks, 0));
          const expectedShowHours = convertToFixedDP((durationInHours - unpaidBreaks / 60.0) * expectedHeadCount);

          if (expectedHeadCount > maxExpectedHeadCount) {
            maxExpectedHeadCount = expectedHeadCount;
          }
          if (expectedShowHours > maxExpectedShowHours) {
            maxExpectedShowHours = expectedShowHours;
          }
        }
      }

      return (
        <ControlledExpander
          key={index}
          title={
            <Row alignmentHorizontal="justify" alignmentVertical="center">
              <Text alignment="left">{`${parseTimeStampToDateString(
                shiftGroupAvailability.applicability.timeWindow.start
              )} - ${parseTimeStampToDateString(shiftGroupAvailability.applicability.timeWindow.end)}`}</Text>
              <Text alignment="center">{extractMainWorkGroup(shiftGroupAvailability.workGroups)}</Text>
              <Row>
                <Text alignment="right">{`${maxExpectedHeadCount} HC / ${maxExpectedShowHours} Show Hours`}</Text>
                <Tooltip position="right" title="Maximum HC and Show Hours across the group's cycles and activities">
                  <Icon tokens={tooltipIcon} />
                </Tooltip>
              </Row>
            </Row>
          }
          type="section"
          isOpen={index === 0}
        >
          <ShiftAttendanceMenu
            data={data}
            initialData={initialData}
            dupData={dupData}
            index={index}
            shiftGroupAvailability={shiftGroupAvailability}
            updateButtonState={updateButtonState}
            errorCounter={errorCounter}
            isUserAuthorisedForShiftPlanOverrides={isUserAuthorisedForShiftPlanOverrides}
          />
        </ControlledExpander>
      );
    });
};

const verifyRosterError = {
  count: 0
};

const VerifyRoster = ({ data, setData, setStep, dataStorage, dupData, isUserAuthorisedForShiftPlanOverrides }) => {
  const verifyRosterData = data.input.shiftPlanSuggestedInput
    ? JSON.stringify(data.input.shiftPlanSuggestedInput.labourAvailability.shiftGroupAvailabilities)
    : JSON.stringify(JSON.parse(dataStorage).input.labourAvailability.shiftGroupAvailabilities);

  const isDataSameForOverrideEnable = () =>
    JSON.stringify(enrichShiftGroupAvailabilities(data.input.labourAvailability.shiftGroupAvailabilities)) ===
    JSON.stringify(
      enrichShiftGroupAvailabilities(JSON.parse(dataStorage).input.labourAvailability.shiftGroupAvailabilities)
    );

  const isDataSame = () =>
    JSON.stringify(enrichShiftGroupAvailabilities(data.input.labourAvailability.shiftGroupAvailabilities)) ===
    JSON.stringify(enrichShiftGroupAvailabilities(JSON.parse(verifyRosterData)));

  const overrideInitial = data.input.labourAvailability.overrideBasis;
  const overrideOptions = ['Roster Defects', 'Weather', 'Labor Share', 'New Hires', 'Mid-Shift Attrition', 'Other'];
  const overrideExceptions = ['Other'];
  const [disableReset, setDisableReset] = useState(isDataSame());
  const [disableOverride, setDisableOverride] = useState(
    !(overrideInitial && overrideInitial.reason) && (isDataSame() || isDataSameForOverrideEnable())
  );
  const [resetID, setResetID] = useState(Math.random());
  const [isReset, setIsReset] = useState(false);
  const [overrideReason, setOverrideReason] = useState(overrideInitial ? overrideInitial.reason : '');
  const [overrideReasonComment, setOverrideReasonComment] = useState(overrideInitial ? overrideInitial.comment : '');

  const onReset = () => {
    data.input.labourAvailability.shiftGroupAvailabilities = JSON.parse(verifyRosterData);
    data.input.labourAvailability.overrideBasis = null;
    setData(data);
    verifyRosterError.count = 0;
    setResetID(Math.random());
    setDisableReset(true);
    setDisableOverride(true);
    setIsReset(true);
    setOverrideReason('');
    setOverrideReasonComment('');
  };

  const updateOverrideReason = (reason) => {
    setOverrideReason(reason);
    let overrideInitial = data.input.labourAvailability.overrideBasis;
    let comment = overrideInitial ? overrideInitial.comment : undefined;
    data.input.labourAvailability.overrideBasis = {
      reason,
      comment
    };
  };

  const updateOverrideReasonComment = (comment) => {
    setOverrideReasonComment(comment);
    let overrideInitial = data.input.labourAvailability.overrideBasis;
    let reason = overrideInitial ? overrideInitial.reason : undefined;
    data.input.labourAvailability.overrideBasis = {
      reason,
      comment
    };
  };

  const updateButtonState = () => {
    let isSame = isDataSame();
    if (isSame || verifyRosterError.count) {
      data.input.labourAvailability.overrideBasis = null;
      setOverrideReason('');
      setOverrideReasonComment('');
    }
    setDisableReset(!!(isSame && !verifyRosterError.count));
    setDisableOverride(!!(isSame || verifyRosterError.count));
  };

  const isSaveButtonDisabled = () => {
    if (verifyRosterError.count) return true;
    if (!disableOverride) {
      if (overrideReason === '') return true;
      else if (overrideExceptions.includes(overrideReason)) return !overrideReasonComment;
      else return false;
    }
    return false;
  };

  const onSave = () => {
    setData(data);
    setStep(3);
  };

  return (
    <Column
      heights="fit"
      alignmentHorizontal="stretch"
      alignmentVertical="top"
      spacing="medium"
      width="100%"
      spacingInset="400"
    >
      <Text type={TEXTS.H2} alignment="left">
        Rostered HC and Show Hours
      </Text>
      <Text type={TEXTS.T2} alignment="left">
        Verify or update the values below and save.
      </Text>

      <ShiftAttendance
        data={data}
        updateButtonState={updateButtonState}
        errorCounter={verifyRosterError}
        key={resetID}
        initialData={JSON.parse(dataStorage)}
        dupData={JSON.parse(dupData)}
        isUserAuthorisedForShiftPlanOverrides={isUserAuthorisedForShiftPlanOverrides}
        isReset={isReset}
      />

      <VTOVETRecommendation labourOpportunities={data.input.labourAvailability.inputLabourOpportunities} />

      <Column alignmentHorizontal="right">
        <OverrideReason
          overrideReason={overrideReason}
          setOverrideReason={updateOverrideReason}
          overrideReasonComment={overrideReasonComment}
          setOverrideReasonComment={updateOverrideReasonComment}
          options={overrideOptions}
          exceptions={overrideExceptions}
          disable={disableOverride}
        />
      </Column>

      <Divider />

      <Column alignmentHorizontal="right">
        <Row wrap="down" spacingInset="medium none">
          <Button onClick={onReset} disabled={disableReset} type="secondary">
            Reset
          </Button>
          <Button onClick={onSave} disabled={isSaveButtonDisabled()}>
            Save
          </Button>
        </Row>
      </Column>
    </Column>
  );
};

export default VerifyRoster;
