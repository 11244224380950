import { ALL_CYCLES } from './constants';
import {
  chainWalk,
  getWindowDurationInMin,
  isDateStringSubwindow,
  isRegionEU,
  parseTimeStampToDateString
} from './helpers';

const getAllocationsForProcess = (allocations, laborProcess) => {
  let headcount = 0;
  for (let shiftId in allocations) {
    for (let process in allocations[shiftId]) {
      if (process === laborProcess) {
        headcount += allocations[shiftId][process]['headcount'];
      }
    }
  }
  return headcount;
};

const processHeadCountTableData = (data, selectedCycle) => {
  let shiftData = {
    totalVolume: {}
  };
  let timeData = {};
  let availableHeadcounts = {};
  let day1NewHireHeadcounts = {};
  for (let index in data.output.shiftPlan) {
    const val = data.output.shiftPlan[index];

    if (selectedCycle !== ALL_CYCLES && 'cycleName' in val && selectedCycle !== val['cycleName']) {
      continue;
    }

    const availableHeadcountsInTimeWindow = val.activeShiftHeadcounts;
    const day1NewHireHeadcountInTimeWindow = chainWalk(() => val['day1NewHireHeadcount'], 0);
    const timeElement =
      parseTimeStampToDateString(val.timeWindow.start) + '-' + parseTimeStampToDateString(val.timeWindow.end);
    for (let shiftId in val['allocations']) {
      for (let process in val['requiredHeadcounts']) {
        if (!(process in shiftData)) {
          shiftData[process] = {};
        }

        // Skip allocatedHeadcount and durationInMins calculations since they default to 0
        // if the process isn't found in for loop responsible for shiftId.
        if (!(process in val['allocations'][shiftId])) {
          continue;
        }

        timeData[timeElement] = val.timeWindow;
        const durationInMins = chainWalk(() => val['allocations'][shiftId][process]['durationInMins'], 0);
        const requiredDurationInMins = chainWalk(
          () => val['requiredHeadcounts'][process]['requiredDurationInMins'],
          durationInMins
        );
        const allocationsForProcess = getAllocationsForProcess(val['allocations'], process);
        const allocatedHeadcount =
          process in val['allocations'][shiftId] ? val['allocations'][shiftId][process]['headcount'] : 0;
        let vet = val['requiredHeadcounts'][process]['headcount'] - allocationsForProcess;
        if (!vet || vet < 0) {
          vet = 0;
        }
        let windowDurationInMins = getWindowDurationInMin(val.timeWindow.start, val.timeWindow.end);
        if (timeElement in shiftData[process]) {
          shiftData[process][timeElement]['headcount'] += allocatedHeadcount;
          let currentDuration = shiftData[process][timeElement]['durationInMins'];
          currentDuration += durationInMins;
          if (currentDuration < windowDurationInMins) {
            shiftData[process][timeElement]['durationInMins'] = currentDuration;
          }

          // Sum `requiredHeadcount` since `headcount` is already handled.
          shiftData[process][timeElement]['requiredHeadcount'] += val['requiredHeadcounts'][process]['headcount'];
          shiftData[process][timeElement]['hours'] +=
            val['requiredHeadcounts'][process]['headcount'] * (requiredDurationInMins / 60);
          shiftData[process][timeElement]['volume'] += val['requiredHeadcounts'][process]['plannedFph'];
          shiftData[process][timeElement]['rate'].push(val['allocations'][shiftId][process]['plannedPph']);
        } else {
          shiftData[process][timeElement] = {
            headcount: allocatedHeadcount,
            requiredHeadcount: val['requiredHeadcounts'][process]['headcount'],
            hours: val['requiredHeadcounts'][process]['headcount'] * (requiredDurationInMins / 60),
            rate: val['allocations'][shiftId][process] ? [val['allocations'][shiftId][process]['plannedPph']] : [],
            volume: val['requiredHeadcounts'][process]['plannedFph'],
            vet: vet,
            durationInMins: durationInMins < windowDurationInMins ? durationInMins : 0
          };
        }
      }
    }

    // TODO: Rework the double for-loop above after aligning on
    // whether to use `allocations` or `requiredHeadcounts` for
    // duplicate data.
    if ('Training Ambassador' in val['requiredHeadcounts']) {
      if (!('Training Ambassador' in shiftData)) {
        shiftData['Training Ambassador'] = {};
      }

      const trainingAmbassadorData = val['requiredHeadcounts']['Training Ambassador'];

      const durationInMins = chainWalk(() => trainingAmbassadorData['durationInMins'], 0);
      const requiredDurationInMins = chainWalk(() => trainingAmbassadorData['requiredDurationInMins'], durationInMins);
      const windowDurationInMins = getWindowDurationInMin(val.timeWindow.start, val.timeWindow.end);

      shiftData['Training Ambassador'][timeElement] = {
        headcount: trainingAmbassadorData['headcount'],
        requiredHeadcount: trainingAmbassadorData['headcount'],
        hours: trainingAmbassadorData['headcount'] * (requiredDurationInMins / 60),
        rate: [],
        volume: trainingAmbassadorData['plannedFph'],
        durationInMins: durationInMins < windowDurationInMins ? durationInMins : 0
      };
    }

    if (!(timeElement in shiftData['totalVolume'])) {
      shiftData['totalVolume'][timeElement] = {
        sortVolume: 0,
        pickStageVolume: 0
      };
    }

    if ('Stow' in val['requiredHeadcounts'] && val['requiredHeadcounts']['Stow']['plannedFph']) {
      shiftData['totalVolume'][timeElement]['sortVolume'] += val['requiredHeadcounts']['Stow']['plannedFph'];
    }
    if (
      'ADTA Container Building' in val['requiredHeadcounts'] &&
      val['requiredHeadcounts']['ADTA Container Building']['plannedFph']
    ) {
      shiftData['totalVolume'][timeElement]['sortVolume'] +=
        val['requiredHeadcounts']['ADTA Container Building']['plannedFph'];
    }
    if (
      'Pick & Stage - Pick' in val['requiredHeadcounts'] &&
      val['requiredHeadcounts']['Pick & Stage - Pick']['plannedFph']
    ) {
      shiftData['totalVolume'][timeElement]['pickStageVolume'] +=
        val['requiredHeadcounts']['Pick & Stage - Pick']['plannedFph'];
    } else if (
      'Non-Scan Pick & Stage' in val['requiredHeadcounts'] &&
      val['requiredHeadcounts']['Non-Scan Pick & Stage']['plannedFph']
    ) {
      shiftData['totalVolume'][timeElement]['pickStageVolume'] +=
        val['requiredHeadcounts']['Non-Scan Pick & Stage']['plannedFph'];
    }

    availableHeadcounts[timeElement] = availableHeadcountsInTimeWindow;
    day1NewHireHeadcounts[timeElement] = day1NewHireHeadcountInTimeWindow;

    // Extract shift-level break data from input.
    if (isRegionEU()) {
      const shiftGroupBreaks = [];
      data.input.labourAvailability.shiftGroupAvailabilities.forEach((shiftGroupAvailability) => {
        const shiftGroupExpectedHeadcount = shiftGroupAvailability.labourAvailabilityHeadCount.expectedHeadcount;

        shiftGroupAvailability.breaks.forEach((shiftGroupBreak) => {
          if (
            isDateStringSubwindow(
              val.timeWindow.start,
              val.timeWindow.end,
              shiftGroupBreak.timeWindow.start,
              shiftGroupBreak.timeWindow.end
            )
          ) {
            shiftGroupBreaks.push({
              breakHeadcount: shiftGroupExpectedHeadcount,
              breakType: shiftGroupBreak.breakType,
              window: {
                start: shiftGroupBreak.timeWindow.start,
                end: shiftGroupBreak.timeWindow.end
              }
            });
          }
        });
      });

      availableHeadcounts[timeElement] = [...availableHeadcountsInTimeWindow, ...shiftGroupBreaks];
    }
  }
  timeData = Object.keys(timeData).map((key) => ({
    timeElement: key,
    ...timeData[key]
  }));
  timeData.sort((a, b) => {
    let diff = new Date(a.start) - new Date(b.start);
    if (diff === 0) return new Date(a.end) - new Date(b.end);
    return diff;
  });

  const timeDataElements = timeData;
  timeData = timeData.map((val) => val.timeElement);

  shiftData['TimeData'] = timeData;
  for (let process in shiftData) {
    for (let time of timeData) {
      if (!(time in shiftData[process])) {
        shiftData[process][time] = {
          headcount: 0,
          requiredHeadcount: 0,
          hours: 0,
          rate: [],
          volume: 0,
          durationInMins: null
        };
      }
    }
  }
  shiftData.availableHeadcounts = availableHeadcounts;
  shiftData.day1NewHireHeadcounts = day1NewHireHeadcounts;

  // For aggregate cycle view ("All cycles"), combine
  // certain metrics to eliminate nested time windows
  // e.g. "03:00 - 04:00" and "03:20 - 04:00"
  // Determine primary and nested time windows
  // and map them to the primary windows.
  const nestedTimeWindows = new Map();
  for (var i = 0; i < timeDataElements.length; i++) {
    const currTimeDataElement = timeDataElements[i];

    for (var j = i + 1; j < timeDataElements.length - 1; j++) {
      if (currTimeDataElement.end <= timeDataElements[j].start) {
        i = j - 1;
        break;
      }

      if (currTimeDataElement.start < timeDataElements[j].start) {
        nestedTimeWindows.set(timeDataElements[j].timeElement, currTimeDataElement.timeElement);
      }
    }
  }

  // Combine nested windows into their parent windows.
  for (let process in shiftData) {
    if (process !== 'TimeData' && process !== 'availableHeadcounts' && process !== 'day1NewHireHeadcounts') {
      for (let [nestedTimeWindow, primaryTimeWindow] of nestedTimeWindows.entries()) {
        const primaryData = shiftData[process][primaryTimeWindow];
        const nestedData = shiftData[process][nestedTimeWindow];

        for (const dataField in primaryData) {
          if (Array.isArray(primaryData[dataField])) {
            Array.prototype.push.apply(primaryData[dataField], nestedData[dataField]);
          } else {
            primaryData[dataField] += nestedData[dataField];
          }
        }

        delete shiftData[process][nestedTimeWindow];
      }
    }
  }

  // Remove from `TimeData` so we don't render nested windows.
  for (let nestedTimeWindow of nestedTimeWindows.keys()) {
    const nestedTimeWindowIndex = shiftData['TimeData'].indexOf(nestedTimeWindow);
    if (nestedTimeWindowIndex > -1) {
      shiftData['TimeData'].splice(nestedTimeWindowIndex, 1);
    }
  }

  return shiftData;
};

export default processHeadCountTableData;
