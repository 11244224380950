import React, { createContext, useReducer } from 'react';

import LoadingView from '../LoadingView';

import { LoadingReducer, initialState } from '../reducers/LoadingReducer';

export const LoadingContext = createContext();

const LoadingContextProvider = (props) => {
  const [loadingState, dispatchLoader] = useReducer(LoadingReducer, initialState);
  const setLoader = (isLoading, loadingMessage) => {
    dispatchLoader({
      type: 'SET_LOADER',
      data: {
        isLoading,
        loadingMessage: isLoading ? loadingMessage : ''
      }
    });
  };
  return (
    <LoadingContext.Provider value={{ setLoader }}>
      {props.children}
      {loadingState.isLoading && <LoadingView loadingMsg={loadingState.loadingMessage} />}
    </LoadingContext.Provider>
  );
};

export default LoadingContextProvider;
