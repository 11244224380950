import React from 'react';

import Column from '@amzn/meridian/column';
import Divider from '@amzn/meridian/divider';
import Icon from '@amzn/meridian/icon';
import Row from '@amzn/meridian/row';
import Table, { TableCell, TableRow } from '@amzn/meridian/table';
import Text from '@amzn/meridian/text';
import caretUpTokens from '@amzn/meridian-tokens/base/icon/caret-up';
import caretDownTokens from '@amzn/meridian-tokens/base/icon/caret-down';

import { chainWalk, parseEpochToHourMinute } from '../utils/helpers';
import { PROCESS_PATH_TO_ROLES } from './ShiftAdherence';

const CELL_COLOR_UP = '#ebf5ff';
const CELL_COLOR_DOWN = '#ffdbdb';
const CARET_COLOR_UP = '#0085b3';
const CARET_COLOR_DOWN = '#cc0c39';

const ShiftAdherenceTable = ({ adherenceData }) => {
  const stationTimeZone = chainWalk(() => adherenceData['stationAttrs']['timezone'], '');
  const currDate = new Date();

  function createProcessPathRows(processPathName, adherenceData) {
    const processPathRoles = Object.keys(PROCESS_PATH_TO_ROLES[processPathName]);
    const tableCells = {};
    tableCells[processPathName] = [];
    processPathRoles.forEach((processPathRole) => {
      tableCells[processPathRole] = [];
    });

    chainWalk(() => adherenceData['timeWindowHeadcountAdherences'], []).forEach((timeWindowAdherence) => {
      if (chainWalk(() => timeWindowAdherence['breaks'].length > 0, false)) {
        return;
      }
      const isFutureTime =
        chainWalk(() => timeWindowAdherence['timeWindow']['endInSeconds'] * 1000, 0) > currDate.getTime();

      timeWindowAdherence['roleHeadcountAdherences']
        .filter((roleAdherence) =>
          tableCells.hasOwnProperty(
            chainWalk(() => roleAdherence['entityAttrs']['name'].split('__')[2], roleAdherence['entityAttrs']['name'])
          )
        )
        .forEach((roleAdherence) => {
          const roleAdherencePlanned = roleAdherence['planned'];
          const roleAdherenceActual = isFutureTime ? '-' : roleAdherence['actual'];

          let cellColor = 'primary';
          let adherencePrefixElement = <div />;

          if (!isFutureTime) {
            if (roleAdherencePlanned > roleAdherenceActual) {
              cellColor = CELL_COLOR_DOWN;
              adherencePrefixElement = (
                <div style={{ color: CARET_COLOR_DOWN }}>
                  <Icon tokens={caretDownTokens} />
                </div>
              );
            } else if (roleAdherencePlanned < roleAdherenceActual) {
              cellColor = CELL_COLOR_UP;
              adherencePrefixElement = (
                <div style={{ color: CARET_COLOR_UP }}>
                  <Icon tokens={caretUpTokens} />
                </div>
              );
            }
          }

          tableCells[
            chainWalk(() => roleAdherence['entityAttrs']['name'].split('__')[2], roleAdherence['entityAttrs']['name'])
          ].push(
            <TableCell backgroundColor={cellColor}>
              <Row alignmentHorizontal="justify">
                {adherencePrefixElement}
                <Text>
                  {roleAdherencePlanned}/{roleAdherenceActual}
                </Text>
              </Row>
            </TableCell>
          );
        });

      const processPathAdherence = timeWindowAdherence['processPathHeadcountAdherences'].find(
        (processPathAdherence) => processPathAdherence['entityAttrs']['name'] === processPathName
      );
      tableCells[processPathName].push(
        <TableCell alignmentHorizontal="end">
          <Text type="h100">
            {processPathAdherence['planned']}/{isFutureTime ? '-' : processPathAdherence['actual']}
          </Text>
        </TableCell>
      );
    });

    const tableRows = [];
    tableRows.push(
      <TableRow backgroundColor="secondary">
        <TableCell>
          <Text>{processPathName.charAt(0).toUpperCase() + processPathName.slice(1)} Totals</Text>
        </TableCell>
        {tableCells[processPathName]}
      </TableRow>
    );
    processPathRoles.forEach((processPathRole) => {
      tableRows.push(
        <TableRow>
          <TableCell>
            <Text type="h100">{PROCESS_PATH_TO_ROLES[processPathName][processPathRole]}</Text>
          </TableCell>
          {tableCells[processPathRole]}
        </TableRow>
      );
    });

    return tableRows;
  }

  return (
    <Column>
      <Row widths={['fill', '100', 'fill']}>
        <Divider />
        <Text>HC Planned/Actual</Text>
        <Divider />
      </Row>
      <div style={{ overflowX: 'auto', maxWidth: '100%' }}>
        <Table spacing="small" headerRows={1} headerColumns={1} showDividers={true} stickyHeaderColumn={true}>
          <TableRow>
            <TableCell width={200}>
              <Text type="h100">Process Path</Text>
            </TableCell>
            {adherenceData['timeWindowHeadcountAdherences'] &&
              adherenceData['timeWindowHeadcountAdherences']
                .filter((timeWindowAdherence) => chainWalk(() => timeWindowAdherence['breaks'].length === 0, true))
                .map((timeWindowAdherence) => {
                  return (
                    <TableCell alignmentHorizontal="end" width={100}>
                      <Text type="h100">
                        {chainWalk(
                          () =>
                            parseEpochToHourMinute(
                              timeWindowAdherence['timeWindow']['startInSeconds'] * 1000,
                              stationTimeZone
                            ),
                          ''
                        )}
                      </Text>
                    </TableCell>
                  );
                })}
          </TableRow>
          {Object.entries(PROCESS_PATH_TO_ROLES).map(([processPathName, _]) => {
            return createProcessPathRows(processPathName, adherenceData);
          })}
        </Table>
      </div>
    </Column>
  );
};

export default ShiftAdherenceTable;
