import React, { Component } from 'react';
import ShiftAllocation from './ShiftAllocation';

class ShiftAllocationContainer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <ShiftAllocation {...this.props} />;
  }
}

export default ShiftAllocationContainer;
