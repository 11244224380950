import { getStationCode } from '../utils/networkUtil';

const postMessagePromises = {};

function generateGuid() {
  /**
   * @return {string}
   */
  function S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
  return (S4() + S4() + '-' + S4() + '-4' + S4().substr(0, 3) + '-' + S4() + '-' + S4() + S4() + S4()).toLowerCase();
}

function postMessagePromised(targetWindow, message, targetOrigin, timeout) {
  const newGuid = generateGuid();
  message.guid = newGuid;
  postMessagePromises[newGuid] = {};
  postMessagePromises[newGuid]['promise'] = new Promise(function (resolve, reject) {
    postMessagePromises[newGuid]['resolve'] = resolve;
    postMessagePromises[newGuid]['reject'] = reject;
    targetWindow.postMessage(message, targetOrigin);
    if (timeout) {
      setTimeout(() => {
        postMessagePromises[newGuid].resolve('timeout');
      }, timeout);
    }
  });
  return postMessagePromises[newGuid].promise;
}

var setCurrentDarkMode = () => {};
export function setDarkModeStateListener(darkModeStateListener) {
  setCurrentDarkMode = darkModeStateListener;
}

export async function getDarkModeStatus() {
  return await postMessagePromised(
    window.parent,
    {
      method: 'getDarkModeStatus',
      args: {}
    },
    '*'
  );
}

export async function getFeatureStatus(features) {
  return await postMessagePromised(
    window.parent,
    {
      method: 'getFeatureStatus',
      args: { features, stationCode: getStationCode() }
    },
    '*'
  );
}

export async function getAPIGatewayDataFromParent(args) {
  return await postMessagePromised(
    window.parent,
    {
      method: 'getAPIGatewayDataFromParent',
      args: args
    },
    '*'
  );
}

export function publishCustomMetrics(args) {
  window.parent.postMessage(
    {
      method: 'logCustomEventMetrics',
      args: args
    },
    '*'
  );
}

export function publishClientSideMetrics(args) {
  window.parent.postMessage(
    {
      method: 'publishClientSideMetrics',
      args: args
    },
    '*'
  );
}

export async function getTcpRoles() {
  return await postMessagePromised(
    window.parent,
    {
      method: 'getTcpRoles',
      args: {}
    },
    '*',
    200
  );
}

export async function getTCPRolesWithRootStations() {
  return await postMessagePromised(
    window.parent,
    {
      method: 'getTCPRolesWithRootStations',
      args: {}
    },
    '*',
    200
  );
}

export async function getUserLoginFromParent() {
  return await postMessagePromised(
    window.parent,
    {
      method: 'getTcpEmployeeLogin',
      args: {}
    },
    '*',
    200
  );
}

export async function getUserNameFromParent() {
  return await postMessagePromised(
    window.parent,
    {
      method: 'getEmployeeName',
      args: {}
    },
    '*',
    200
  );
}

export async function getNodeCapability() {
  return await postMessagePromised(
    window.parent,
    {
      method: 'getNodeCapability',
      args: {}
    },
    '*'
  );
}

export async function getShiftPlanSettings(args) {
  return await postMessagePromised(
    window.parent,
    {
      method: 'getShiftPlanSettings',
      args: args
    },
    '*'
  );
}

window.addEventListener('message', onNewPostMessageFromParent, false);

function onNewPostMessageFromParent(event) {
  if (event.data.method && subscribeAbleEvents.hasOwnProperty(event.data.method)) {
    subscribeAbleEvents[event.data.method](event.data);
  }
}

const subscribeAbleEvents = {
  sendApiGatewayResponse,
  sendFeatureStatus: sendResponseData,
  sendCapabilityResponse: sendCapabilityResponse,
  sendTcpRolesResponse: sendTcpRolesResponse,
  sendEmployeeNameResponse: sendEmployeeNameResponse,
  sendTcpEmployeeLoginResponse: sendTcpEmployeeLoginResponse,
  sendTcpRolesWithRootStationsResponse: sendTcpRolesWithRootStationsResponse,
  sendGetShiftPlanSettingsResponse: sendGetShiftPlanSettingsResponse,
  sendDarkModeStatus: sendDarkModeStatus
};

function sendDarkModeStatus(data) {
  setCurrentDarkMode(data.childData.capability);
}

function sendApiGatewayResponse(data) {
  if (postMessagePromises[data.guid]) {
    postMessagePromises[data.guid].resolve(data.responseData);
  }
}

function sendResponseData(data) {
  if (postMessagePromises[data.guid]) {
    postMessagePromises[data.guid].resolve(data.responseData);
  }
}

function sendCapabilityResponse(data) {
  if (postMessagePromises[data.guid]) {
    postMessagePromises[data.guid].resolve(data.childData);
  }
}

function sendEmployeeNameResponse(data) {
  if (postMessagePromises[data.guid]) {
    postMessagePromises[data.guid].resolve(data.childData);
  }
}

function sendTcpRolesWithRootStationsResponse(data) {
  if (postMessagePromises[data.guid]) {
    postMessagePromises[data.guid].resolve(data.childData);
  }
}

function sendTcpRolesResponse(data) {
  if (postMessagePromises[data.guid]) {
    postMessagePromises[data.guid].resolve(data.childData);
  }
}

function sendTcpEmployeeLoginResponse(data) {
  if (postMessagePromises[data.guid]) {
    postMessagePromises[data.guid].resolve(data.childData);
  }
}

function sendGetShiftPlanSettingsResponse(data) {
  if (postMessagePromises[data.guid]) {
    postMessagePromises[data.guid].resolve(data.responseData);
  }
}
