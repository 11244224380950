import React, { useRef, useState, useCallback } from 'react';
import Table, { TableRow, TableCell, TableActionBar } from '@amzn/meridian/table';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import Popover, { PopoverHeader } from '@amzn/meridian/popover';
import Heading from '@amzn/meridian/heading';
import Select, { SelectOption } from '@amzn/meridian/select';

import processHeadCountTableData from '../../../utils/processHeadCountTableData';
import { ALL_CYCLES, processMapping, PLANS, TABLE_METRICS, TEXTS } from '../../../utils/constants';
import { chainWalk, convertToFixedDP, getWindowDurationInMin, isRegionEU } from '../../../utils/helpers';

const UncontrolledExpandableRow = ({ children, rowComponents }) => {
  const [open, setOpen] = useState(false);
  return (
    <TableRow open={open} onClick={() => setOpen(!open)} rowComponents={rowComponents} highlightOnHover={true}>
      {children}
    </TableRow>
  );
};

const ExpandableTableMetricRow = ({ shiftData, rowProcess, selectedMetric }) => {
  const metricRows = Object.keys(shiftData).map((process, index) => {
    if (processMapping[rowProcess].includes(process)) {
      return (
        <TableRow key={index} highlightOnHover={true}>
          <TableCell>
            <div style={{ paddingLeft: 30 }}>
              <Row>{process}</Row>
            </div>
          </TableCell>
          {shiftData['TimeData'].map((time, index) => {
            let selectedDuration = shiftData[process][time]['durationInMins'];

            let selectedMetricValue = 0;
            switch (selectedMetric) {
              case 'requiredHeadcount':
                selectedMetricValue = convertToFixedDP(shiftData[process][time]['requiredHeadcount']);
                break;
              case 'hours':
                selectedMetricValue = convertToFixedDP(shiftData[process][time]['hours']);
                selectedDuration = 0;
                break;
              case 'rate':
                const rateValues = shiftData[process][time]['rate'];
                if (rateValues.length > 0) {
                  selectedMetricValue = rateValues.reduce((sum, current) => sum + current) / rateValues.length;
                  selectedMetricValue = Math.round(selectedMetricValue);

                  if (rateValues.length > 1) {
                    selectedDuration = 0;
                  }
                }
                break;
              default:
                selectedMetricValue = Math.round(shiftData[process][time][selectedMetric]);
            }

            // Don't show "(?m)", as part of shift-level breaks requirements.
            if (isRegionEU()) {
              selectedDuration = 0;
            }

            return (
              <TableCell alignmentHorizontal="right" key={index}>
                {selectedMetricValue ? selectedMetricValue + (selectedDuration ? ` (${selectedDuration}m)` : '') : '0'}
              </TableCell>
            );
          })}
        </TableRow>
      );
    } else {
      return null;
    }
  });

  if ('Sort' === rowProcess || 'Pick Stage' === rowProcess) {
    const selectedMetric = 'Sort' === rowProcess ? 'sortVolume' : 'pickStageVolume';
    const sortVolumeRow = (
      <TableRow>
        <TableCell>
          <Row>Volume</Row>
        </TableCell>
        {shiftData['TimeData'].map((time, index) => {
          return (
            <TableCell alignmentHorizontal="right" key={index}>
              {shiftData['totalVolume'][time][selectedMetric]}
            </TableCell>
          );
        })}
      </TableRow>
    );
    metricRows.unshift(sortVolumeRow);
  }

  return metricRows;
};

const TableMetricRow = ({ shiftData, listProcess, selectedMetric }) => {
  const metricValues = {};

  for (let time of shiftData['TimeData']) {
    metricValues[time] = 0;
  }

  for (let process in shiftData) {
    if (listProcess.includes(process)) {
      for (let time of shiftData['TimeData']) {
        if (selectedMetric === 'hours') {
          metricValues[time] += shiftData[process][time]['hours'];
        } else {
          metricValues[time] += shiftData[process][time][selectedMetric];
        }
      }
    }
  }

  return Object.values(metricValues).map((val, index) => (
    <TableCell alignmentHorizontal="right" key={index}>
      {/* TBD: How do we want to show an aggregated rate? e.g. average? max? not at all? */}
      {selectedMetric === 'rate' || selectedMetric === 'volume' ? '' : val ? convertToFixedDP(val) : '0'}
    </TableCell>
  ));
};

const TimeWindowHeader = ({ shiftData, val }) => {
  let availableHeadcountsViews = [];
  let availableHeadcountBreakViews = [];

  let availableHeadcounts = shiftData['availableHeadcounts'] ? shiftData['availableHeadcounts'][val] : [];
  availableHeadcounts = availableHeadcounts ? availableHeadcounts : [];

  for (let availableHeadcount of availableHeadcounts) {
    if ('availableHeadcount' in availableHeadcount) {
      let start = new Date(availableHeadcount.window.start);
      let end = new Date(availableHeadcount.window.end);
      availableHeadcountsViews.push({
        time:
          start.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit'
          }) +
          '-' +
          end.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit'
          }),
        hc: availableHeadcount.availableHeadcount
      });
    } else if ('breakHeadcount' in availableHeadcount) {
      let start = new Date(availableHeadcount.window.start);
      let end = new Date(availableHeadcount.window.end);
      availableHeadcountBreakViews.push({
        time:
          start.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit'
          }) +
          '-' +
          end.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit'
          }),
        duration: getWindowDurationInMin(start, end),
        hc: availableHeadcount.breakHeadcount,
        breakType: availableHeadcount.breakType
      });
    }
  }

  if (availableHeadcountsViews.length > 0) {
    const buttonRef = useRef();
    const [open, setOpen] = useState(false);
    const openPopover = useCallback(() => setOpen(true), []);
    const closePopover = useCallback(() => setOpen(false), []);
    return (
      <React.Fragment>
        <span onMouseEnter={openPopover} onMouseLeave={closePopover}>
          <Text className="menu-text-color" type="h200" ref={buttonRef}>
            {val}
          </Text>
        </span>
        <Popover anchorNode={buttonRef.current} open={open} position="top" spacingInset="medium">
          <PopoverHeader>
            <Heading level={3}>Available Headcounts</Heading>
          </PopoverHeader>
          <Column>
            {availableHeadcountsViews.map((val, index) => (
              <Text key={index}>
                {val.time} - {val.hc}
              </Text>
            ))}
            {availableHeadcountBreakViews.length > 0 ? [<br />, <Heading level={5}>Shift Breaks</Heading>] : null}
            {availableHeadcountBreakViews.map((val, index) => (
              <Text key={index}>
                {val.breakType.charAt(0).toUpperCase() + val.breakType.toLowerCase().slice(1)} break {index + 1}{' '}
                {val.time} - {val.hc}
              </Text>
            ))}
          </Column>
        </Popover>
      </React.Fragment>
    );
  } else {
    return <Text type="h200"> {val} </Text>;
  }
};

const TotalVolumeRow = ({ shiftData }) => {
  return (
    <TableRow>
      <TableCell>
        <Row>Total Volume</Row>
      </TableCell>
      {shiftData['TimeData'].map((time, index) => {
        let volume = 0;
        if ('totalVolume' in shiftData) {
          if ('sortVolume' in shiftData['totalVolume'][time]) {
            volume += shiftData['totalVolume'][time]['sortVolume'];
          }
          if ('pickStageVolume' in shiftData['totalVolume'][time]) {
            volume += shiftData['totalVolume'][time]['pickStageVolume'];
          }
        }
        return (
          <TableCell alignmentHorizontal="right" key={index}>
            {volume}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

const Day1NewHireHeadcountRow = ({ shiftData }) => {
  return (
    <TableRow>
      <TableCell>
        <Row>Day 1 New Hires</Row>
      </TableCell>
      {shiftData['TimeData'].map((time, index) => {
        const day1NewHireHeadcount = chainWalk(() => shiftData.day1NewHireHeadcounts[time], 0);
        return (
          <TableCell alignmentHorizontal="right" key={index}>
            {day1NewHireHeadcount}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

const NextDayPlannedHeadcountTable = ({ data }) => {
  const [selectedCycle, setSelectedCycle] = useState(ALL_CYCLES);
  const [selectedTableMetric, setSelectedTableMetric] = useState(TABLE_METRICS.entries().next().value[0]);
  const isDay1NewHireLaborEnabled = chainWalk(() => data.input.labourAvailability.isDay1NewHireLaborEnabled, false);

  const shiftData = processHeadCountTableData(data, selectedCycle);
  const tableText = data['planType'] === PLANS.PRE_SHIFT ? 'Pre-Shift Planned' : 'Next Day Planned';

  let availableCycles = [];
  const availableCyclesSet = new Set();

  data['output']['shiftPlan'].forEach(function (shiftPlan) {
    if (shiftPlan['cycleName'] !== undefined) {
      availableCyclesSet.add(shiftPlan['cycleName']);
    }
  });

  availableCycles = Array.from(availableCyclesSet);
  availableCycles.sort();

  availableCycles.unshift(ALL_CYCLES);

  return (
    <Column spacing="400" width="100%">
      <TableActionBar alignmentHorizontal="justify">
        <Text type={TEXTS.H2}>{tableText}</Text>

        <Row>
          <Text>Viewing by:</Text>
          <Select
            value={selectedCycle}
            onChange={setSelectedCycle}
            placeholder="Select cycle"
            alignmentHorizontal="right"
          >
            {availableCycles.map((availableCycle) => (
              <SelectOption key={availableCycle} value={availableCycle} label={availableCycle} />
            ))}
          </Select>
          <Select
            value={selectedTableMetric}
            onChange={setSelectedTableMetric}
            width={150}
            placeholder="Select metric"
            alignmentHorizontal="right"
          >
            {Array.from(TABLE_METRICS, ([key, label]) => {
              return <SelectOption key={key} value={key} label={label} />;
            })}
          </Select>
        </Row>
      </TableActionBar>

      <div className="horizontal-scroll">
        <Table
          headerColumns={1}
          headerRows={1}
          showDividers={true}
          rowComponents={[UncontrolledExpandableRow, TableRow]}
          showStripes={true}
          spacing="small"
        >
          <TableRow highlightOnHover={true}>
            <TableCell />
            {shiftData['TimeData'].map((val, index) => (
              <TableCell alignmentHorizontal="right" key={index}>
                <TimeWindowHeader shiftData={shiftData} val={val}></TimeWindowHeader>
              </TableCell>
            ))}
          </TableRow>
          {TotalVolumeRow({ shiftData })}
          {['Sort', 'Pick Stage', 'UTR', 'Site Support'].map((val, index) => (
            <UncontrolledExpandableRow key={index}>
              <TableCell>{val}</TableCell>
              <TableMetricRow
                shiftData={shiftData}
                listProcess={processMapping[val]}
                selectedMetric={selectedTableMetric}
              />
              {ExpandableTableMetricRow({
                shiftData,
                rowProcess: val,
                selectedMetric: selectedTableMetric
              })}
            </UncontrolledExpandableRow>
          ))}
          {isDay1NewHireLaborEnabled && selectedTableMetric === 'requiredHeadcount' && Day1NewHireHeadcountRow({ shiftData })}
        </Table>
      </div>
    </Column>
  );
};

export default NextDayPlannedHeadcountTable;
