import React, { useRef, useState, useCallback } from 'react';
import Box from '@amzn/meridian/box';
import Column from '@amzn/meridian/column';
import Divider from '@amzn/meridian/divider';
import Heading from '@amzn/meridian/heading';
import Popover, { PopoverHeader } from '@amzn/meridian/popover';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import Table, { TableRow, TableCell } from '@amzn/meridian/table';

import { chainWalk, parseTimeStampToDateString } from '../../../utils/helpers';
import { TEXTS } from '../../../utils/constants';

const CycleTable = ({ packageProjectionList }) => {
  return (
    <div>
      <Table headerRows={1} showDividers={true} showStripes={false} spacing="small">
        <TableRow>
          <TableCell>Cycle</TableCell>
          <TableCell alignmentHorizontal="right">Total Volume</TableCell>
          <TableCell alignmentHorizontal="right">Planned Volume</TableCell>
          <TableCell alignmentHorizontal="right">Sideline/RTS</TableCell>
          <TableCell alignmentHorizontal="right">Planned Rollover</TableCell>
        </TableRow>
        {packageProjectionList.map((val, index) => {
          const buttonRef = useRef();
          const [open, setOpen] = useState(false);
          const openPopover = useCallback(() => setOpen(true), []);
          const closePopover = useCallback(() => setOpen(false), []);
          let volumeDistributionOverrides = chainWalk(
            () => val.cycleVolumeDistributionOverride.processVolumeDistributionOverrideMap['Induct'],
            null
          );
          return (
            <TableRow highlightOnHover={true} key={index}>
              <TableCell>
                <span onMouseEnter={volumeDistributionOverrides ? openPopover : null} onMouseLeave={closePopover}>
                  <Text className="menu-text-color" type="h200" ref={buttonRef}>
                    {val.applicability.identifier}
                  </Text>
                </span>
                <Popover anchorNode={buttonRef.current} open={open} position="right" spacingInset="medium">
                  {volumeDistributionOverrides ? (
                    <div>
                      <Box spacingInset="0 0 300 0">
                        <Text type={TEXTS.H4} alignment="left">
                          Volume Split
                        </Text>
                      </Box>
                      <Divider />
                      {volumeDistributionOverrides.map((vdo) => (
                        <div>
                          <Row>
                            <Box spacingInset="200">
                              <Text type={TEXTS.T3}>
                                {parseTimeStampToDateString(vdo.timeWindow.start)} -{' '}
                                {parseTimeStampToDateString(vdo.timeWindow.end)}
                              </Text>
                            </Box>
                            <Text type={TEXTS.T3}>{vdo.percentage * 100}%</Text>
                          </Row>
                        </div>
                      ))}
                    </div>
                  ) : (
                    'No Volume Split Override'
                  )}
                </Popover>
              </TableCell>
              <TableCell alignmentHorizontal="right">
                {val.volume.plannedVolume - val.volume.plannedRolloverVolume + val.volume.lastDayRolloverVolume}
              </TableCell>
              <TableCell alignmentHorizontal="right">{val.volume.plannedVolume}</TableCell>
              <TableCell alignmentHorizontal="right">{val.volume.lastDayRolloverVolume}</TableCell>
              <TableCell alignmentHorizontal="right">{val.volume.plannedRolloverVolume}</TableCell>
            </TableRow>
          );
        })}
      </Table>
    </div>
  );
};

export default CycleTable;
