import React, { Component } from 'react';
import ShiftAllocationContextProvider from './context/ShiftAllocationContext';
import ShiftAllocationAssociatesView from './view/ShiftAllocationAssociatesView';

class ShiftAllocationAssociateViewContainer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <ShiftAllocationAssociatesView {...this.props} />;
  }
}

export default ShiftAllocationAssociateViewContainer;
