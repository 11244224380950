import React from 'react';

import CustomAlert from '../../../commonComponents/CustomAlert';

import { planTypeFormat } from '../../../utils/helpers';

const VerifyShiftPlanAlert = ({ responseData, planId, planType, getPlanAPI }) => {
  if (!responseData && planId) {
    return (
      <CustomAlert
        title={`${planTypeFormat(planType)} Plan has been started, but not submitted please, finish and hit submit.`}
        buttonTitle={`Finish ${planTypeFormat(planType)} Plan`}
        buttonClickHandle={() => {
          getPlanAPI(planType);
        }}
      />
    );
  } else return null;
};

export default VerifyShiftPlanAlert;
