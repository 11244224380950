import React from 'react';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import Box from '@amzn/meridian/box';

import NextDayPlannedHeadcountTable from './NextDayPlannedHeadcountTable';
import CustomAlert from '../../../commonComponents/CustomAlert';
import ShiftTable from './ShiftTable';
import CycleTable from './CycleTable';
import TotalShowHrsTable from './TotalShowHrsTable';
import AdditionalRecommendedVTOTable from './AdditionalRecommendedVTOTable';
import VTOVETRecommendation from './VTOVETRecommendation';

import { chainWalk, compareDates, getTodaysDate, planTypeFormat } from '../../../utils/helpers';
import { PLAN_STATUS } from '../../../utils/constants';
import Button from '@amzn/meridian/button';

const BtnGroup = ({ onClickEditPlan, onClickAcceptAndSubmit }) => {
  return (
    <div className="rightCenter">
      <Button size="small" type="secondary" onClick={onClickEditPlan}>
        Edit Plan
      </Button>
      <div style={{ marginLeft: 10 }}>
        <Button size="small" type="primary" onClick={onClickAcceptAndSubmit}>
          Accept and Submit
        </Button>
      </div>
    </div>
  );
};

const SubmitShiftPlan = ({
  dataStorage,
  setDataStorage,
  planType,
  rerunPlan,
  updatePlanOutputAndStatus,
  getPlanAPI,
  ofdDate
}) => {
  const data = JSON.parse(dataStorage[planType]);

  const onClickAcceptAndSubmit = () => {
    if (Array.isArray(data.output.labourOpportunities)) {
      data.output.labourOpportunities = data.output.labourOpportunities.map((opportunity) => {
        opportunity.headcount = parseInt(opportunity.headcount) || 0;
        return opportunity;
      });
    }
    updatePlanOutputAndStatus(data.planId, planType, data.output);
    data.status = PLAN_STATUS.APPROVED;
    setDataStorage(planType, data);
  };

  const onClickEditPlan = () => {
    getPlanAPI(planType);
  };

  if (data) {
    const isPendingApproval = data.status === PLAN_STATUS.PENDING_APPROVAL;
    let backgroundColor, alertTitle, btnText, disabled;
    if (isPendingApproval) {
      backgroundColor = '#fcf2be';
      alertTitle = `Awaiting Manager Approval. ${planTypeFormat(
        planType
      )} Plan has been submitted and awaiting VTO/VET input from manager`;
      btnText = '';
      disabled = false;
    } else {
      backgroundColor = 'secondary';
      alertTitle = `${planTypeFormat(planType)} Plan has been completed.`;
      btnText = 'Re-run plan';
      disabled = compareDates(ofdDate, getTodaysDate());
    }
    let subtitle = `(Plan ID: ${data.planId})`;
    if (data.input.alpsPlanId != null) {
      subtitle = `(Plan ID: ${data.planId}, ALPS Plan ID: ${data.input.alpsPlanId})`;
    }
    const isDay1NewHireLaborEnabled = chainWalk(() => data.input.labourAvailability.isDay1NewHireLaborEnabled, false);
    return (
      <Column alignmentHorizontal="center" alignmentVertical="center" width="100%" spacing="500">
        <CustomAlert
          backgroundColor={backgroundColor}
          title={alertTitle}
          subtitle={subtitle}
          buttonTitle={btnText}
          buttonClickHandle={() => rerunPlan(planType)}
          disabled={disabled}
        >
          <Row
            height="100%"
            width="100%"
            widths="fill"
            spacing="400"
            wrap="down"
            alignmentHorizontal="justify"
            alignmentVertical="stretch"
          >
            <Column>
              <Box type="outline" spacingInset="400">
                <CycleTable packageProjectionList={data.input.packageProjectionInput.packageProjectionList} />
              </Box>
              <Box type="outline" spacingInset="400">
                <TotalShowHrsTable
                  plannedNodeThroughput={data.output.plannedNodeThroughput}
                  isDay1NewHireLaborEnabled={isDay1NewHireLaborEnabled}
                />
              </Box>
            </Column>
            <Column>
              <Box type="outline" spacingInset="400">
                <ShiftTable
                  shiftGroupAvailabilities={data.input.labourAvailability.shiftGroupAvailabilities}
                  isDay1NewHireLaborEnabled={isDay1NewHireLaborEnabled}
                />
              </Box>
              <Box type="outline" spacingInset="400">
                <VTOVETRecommendation labourOpportunities={data.input.labourAvailability.inputLabourOpportunities} />
              </Box>
              <Box type="outline" spacingInset="400">
                <AdditionalRecommendedVTOTable data={data} isPendingApproval={isPendingApproval} />
              </Box>
            </Column>
          </Row>
          {isPendingApproval && (
            <BtnGroup onClickEditPlan={onClickEditPlan} onClickAcceptAndSubmit={onClickAcceptAndSubmit} />
          )}
        </CustomAlert>
        <NextDayPlannedHeadcountTable data={data} />
      </Column>
    );
  } else {
    return null;
  }
};

export default SubmitShiftPlan;
