import React, { useCallback, useMemo, useState } from 'react';
import Table, { TableRow, TableCell } from '@amzn/meridian/table';
import Thumbnail from '@amzn/meridian/thumbnail';
import Text from '@amzn/meridian/text';
import Link from '@amzn/meridian/link';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import Input from '@amzn/meridian/input';
import { PathCell } from './PathCell';
import { alphabeticalSort } from '../utils/helpers';
import { SelectFilter } from './SelectFilter';
import { RISK_RANK_VALUES, RISK_RANK_OPTIONS, processData } from './utils';
import { RISK_RANK_CLASS } from './styled';
import { cx } from 'emotion';

export const ShiftRotationTable = ({
  data = [],
  recommendations = [],
  cycleResourceAllocationOutputs = [],
  selectedCycle = ''
}) => {
  const [sortColumn, setSortColumn] = useState('name');
  const [sortDirection, setSortDirection] = useState('ascending');
  const onSort = useCallback(({ sortColumn, sortDirection }) => {
    setSortDirection(sortDirection);
    setSortColumn(sortColumn);
  }, []);

  const [nameFilter, setNameFilter] = useState('');
  const [rankFilter, setRankFilter] = useState([]);
  const [disallowedPathFilter, setDisallowedPathFilter] = useState([]);
  const [trainedPathFilter, setTrainedPathFilter] = useState([]);
  const [recommendedPathFilter, setRecommendedPathFilter] = useState([]);

  const processedData = useMemo(() => {
    return processData(data, recommendations, cycleResourceAllocationOutputs, selectedCycle);
  }, [data, recommendations, cycleResourceAllocationOutputs, selectedCycle]);

  const visibleData = useMemo(() => {
    const filteredData = processedData.processedAssociates.filter((d) => {
      return (
        (nameFilter !== '' ? (d.name || d.id).toLowerCase().includes(nameFilter.toLowerCase()) : true) &&
        (rankFilter.length > 0 ? rankFilter.includes(d.riskRanking.toString()) : true) &&
        (disallowedPathFilter.length > 0
          ? d.disallowedPaths.some((path) => disallowedPathFilter.includes(path))
          : true) &&
        (trainedPathFilter.length > 0 ? d.trainedPaths.some((path) => trainedPathFilter.includes(path)) : true) &&
        (recommendedPathFilter.length > 0 ? recommendedPathFilter.includes(d.recommendedPath) : true)
      );
    });

    return filteredData.sort((a, b) => {
      if (['name', 'recommendedPath'].includes(sortColumn)) {
        return alphabeticalSort(sortColumn, sortDirection)(a, b);
      }
      const directionMultiplier = 'ascending' === sortDirection ? 1 : -1;
      if (['riskRanking'].includes(sortColumn)) {
        return directionMultiplier * (a[sortColumn] - b[sortColumn]);
      }

      if (['disallowedPaths', 'trainedPaths'].includes(sortColumn)) {
        const aItems = a[sortColumn];
        const bItems = b[sortColumn];

        const lenghtDifference = aItems.length - bItems.length;

        // if we aren't filtering by any items, then we sort by
        // quantity of disallowed paths sorted alphabetically on
        // the disallowed paths
        if (disallowedPathFilter.length === 0) {
          if (lenghtDifference === 0) {
            return directionMultiplier * (aItems.join(',') > bItems.join(',') ? 1 : -1);
          } else {
            return directionMultiplier * lenghtDifference;
          }
        } else {
          // if we have a filter by disallowedPath, then we are sorting
          // by number of elements selected that match, and then alphabetical
          const aSelectedItems = aItems.filter((item) => disallowedPathFilter.includes(item));
          const bSelectedItems = bItems.filter((item) => disallowedPathFilter.includes(item));

          if (aSelectedItems.length === bSelectedItems.length) {
            return directionMultiplier * (aSelectedItems.join(',') > bSelectedItems.join(',') ? 1 : -1);
          } else {
            return bSelectedItems.length - aSelectedItems.length;
          }
        }
      }
    });
  }, [
    processedData,
    sortColumn,
    sortDirection,
    nameFilter,
    rankFilter,
    disallowedPathFilter,
    trainedPathFilter,
    recommendedPathFilter
  ]);

  return (
    <Column spacingInset="200">
      <Table
        headerRows={1}
        className="shift-rotation-table"
        spacing="small"
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        onSort={onSort}
        showStripes
      >
        <TableRow>
          <TableCell width="20%" sortColumn="name">
            Associate
          </TableCell>
          <TableCell width="8%" sortColumn="riskRanking">
            Rotation Priority
          </TableCell>
          <TableCell width="30%" sortColumn="disallowedPaths">
            Disallowed Paths
          </TableCell>
          <TableCell width="30%" sortColumn="trainedPaths">
            Trained Paths
          </TableCell>
          <TableCell width="12%" sortColumn="recommendedPath">
            Recommended Path
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell alignmentVertical="top">
            <Input id="name-filter" value={nameFilter} onChange={setNameFilter} type="text" size="small" />
          </TableCell>
          <TableCell alignmentVertical="top">
            <SelectFilter
              options={RISK_RANK_VALUES}
              labels={RISK_RANK_OPTIONS}
              value={rankFilter}
              onChange={setRankFilter}
            ></SelectFilter>
          </TableCell>
          <TableCell alignmentVertical="top">
            <SelectFilter
              options={processedData.disallowedPathOptions}
              value={disallowedPathFilter}
              onChange={setDisallowedPathFilter}
            ></SelectFilter>
          </TableCell>
          <TableCell alignmentVertical="top">
            <SelectFilter
              options={processedData.trainedPathOptions}
              value={trainedPathFilter}
              onChange={setTrainedPathFilter}
            ></SelectFilter>
          </TableCell>
          <TableCell alignmentVertical="top">
            <SelectFilter
              options={processedData.recommendedPathOptions}
              value={recommendedPathFilter}
              onChange={setRecommendedPathFilter}
            ></SelectFilter>
          </TableCell>
        </TableRow>
        {visibleData.map((row, idx) => {
          return (
            <TableRow key={idx}>
              <TableCell>
                <Row className="associate-row">
                  <Thumbnail
                    borderColor="gray"
                    size="small"
                    source={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?employeeid=${row.id}`}
                  />
                  <Link
                    target="_parent"
                    href={`https://fclm-portal.amazon.com/employee/ppaTimeDetails?employeeId=${row.id}`}
                    type="secondary"
                  >
                    {row.name || row.id}
                  </Link>
                </Row>
              </TableCell>
              <TableCell>
                <Text className={cx(RISK_RANK_CLASS[row.riskRanking])}>{RISK_RANK_OPTIONS[row.riskRanking]}</Text>
              </TableCell>
              <TableCell>
                <PathCell paths={row.disallowedPaths} highlight={disallowedPathFilter}></PathCell>
              </TableCell>
              <TableCell>
                <PathCell
                  paths={row.trainedPaths}
                  highlight={trainedPathFilter}
                  disallowed={row.disallowedPaths}
                ></PathCell>
              </TableCell>
              <TableCell>
                <Text>{row.recommendedPath}</Text>
              </TableCell>
            </TableRow>
          );
        })}
      </Table>
    </Column>
  );
};

export default ShiftRotationTable;
