import React, { useState, useEffect, useContext, useRef } from 'react';
import Text from '@amzn/meridian/text';
import { TEXTS } from '../../utils/constants';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Button from '@amzn/meridian/button';
import './AssociateViewTable.css';
import { ShiftAllocationContext } from '../context/ShiftAllocationContext';
import { ManagerViewTable } from './ManagerViewTable';
import { PROCESS_PATHS } from '../data';
import AutoScroll from '../../utils/AutoScroll';

function ShiftAllocationAssociatesView(props) {
  // Right now Data is taken from dummy file, pass as arg to getShiftAllocationAssociatesView
  const [associateInfoList, setAssociateInfoList] = useState([]);

  const [tables, setTables] = useState([[]]);
  const [maxRows, setMaxRows] = useState(0);
  const { shiftAllocationModel, shiftAllocationDispatch } = useContext(ShiftAllocationContext);
  const tableContainerRef = useRef(null);

  useEffect(() => {
    //function to map associates to process paths
    function mapAssociatesToProcessPath() {
      const processInfo = shiftAllocationModel['processInfo'];
      const newList = [];

      processInfo
        .filter((boardEntry) => boardEntry.id !== PROCESS_PATHS.BENCH)
        .forEach((boardEntry) => {
          boardEntry.assignments.forEach((assignment) => {
            if (shiftAllocationModel['associateInfo'][assignment.resourceId]) {
              newList.push({
                path: `${boardEntry.title} - ${assignment.allocationAttrs.process}`,
                alias: assignment.resourceId,
                name: shiftAllocationModel['associateInfo'][assignment.resourceId].name || assignment.resourceId
              });
            } else {
              console.log('Associate Info not found', assignment.resourceId);
            }
          });
        });

      // sorting the list alphabetically based on name
      newList.sort((a, b) => {
        if (!isNaN(a.name)) return 1;
        if (!isNaN(b.name)) return -1;
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
      setAssociateInfoList(newList);
    }
    function getMaxRows() {
      const rowHeight = 50; // as thumbnail width is 48px
      const windowHeight = window.innerHeight - 250;
      const rows = Math.floor(windowHeight / rowHeight);
      setMaxRows(rows);
    }
    getMaxRows();
    mapAssociatesToProcessPath();
  }, [shiftAllocationModel]);

  useEffect(() => {
    updateTables();
  }, [maxRows, associateInfoList]);

  const updateTables = () => {
    const rowsPerTable = maxRows;
    const totalRows = associateInfoList.length;

    let newTables = [[]];
    let tableIndex = 0;
    let currentRows = 0;

    for (let i = 0; i < totalRows; i++) {
      if (currentRows === rowsPerTable) {
        // Create a new table if the current table is full
        tableIndex++;
        currentRows = 0;
        newTables.push([]);
      }
      newTables[tableIndex].push(associateInfoList[i]);
      currentRows++;
    }
    setTables(newTables);
  };
  const onClickManagerView = () => {
    // Add call to manager view here
    props.history.replace('/shift-allocation');
  };

  return (
    <Column overflowX="scroll" spacingInset="400" overflowY="hidden">
      <Row alignmentHorizontal="justify">
        <Text type={TEXTS.H1}>Labor Board</Text>
        <Button size="small" type="primary" onClick={onClickManagerView}>
          Manager View
        </Button>
      </Row>
      <Row
        className="labor-board-associate-view-table-row"
        spacing="100"
        overflowX="auto"
        overflowY="hidden"
        alignmentVertical="top"
        widths={'grid-4'}
        ref={tableContainerRef}
      >
        {tables.map((table, index) => (
          <Column
            key={index}
            className="labor-board-associate-view-table-column"
            alignmentHorizontal={'center'}
            spacingInset={'none'}
            overflowX={'scroll'}
            overflowY="hidden"
            type={'outline'}
          >
            <ManagerViewTable table={table}></ManagerViewTable>
          </Column>
        ))}
      </Row>
      <AutoScroll containerRef={tableContainerRef} cadenceInSeconds={1} scrollIncrement={100} />
    </Column>
  );
}

export default ShiftAllocationAssociatesView;
