import React, { useState } from 'react';
import Row from '@amzn/meridian/row';
import { SSD_TIMELINE_KEY } from '../../../../utils/constants';

import Column from '@amzn/meridian/column';
import ButtonGroup, { ButtonOption } from '@amzn/meridian/button-group';
import { displayThrowRate, getBreaks, getShifts, getTimelineForViewPlan, isBreakQuarter } from '../../utils';
import ShiftSortInjectionVolume from './ShiftSortInjectionVolume';
import ShiftOutboundVolume from './ShiftOutboundVolume';
import ShiftHeadcount from './ShiftHeadcount';
import ShiftPlannedThroughput from './ShiftPlannedThroughput';
import ShiftTimeline from './ShiftTimeline';
import ShiftHeadcountRecommendationTable from './ShiftHeadcountRecommendationTable';
import ShiftRisk from './ShiftRisk';
import { chainWalk } from '../../../../utils/helpers';
import { getSinglesEnabled } from '../../common/ssd/SinglesUtils';

const getUniqueQuartersByDepartment = (department, headcountAllocation, quarters, breaks) => {
  let uniqueQuarters = [];
  quarters.forEach((q, index) => {
    if (
      index === 0 ||
      (isBreakQuarter(q, breaks) && !isBreakQuarter(quarters[index - 1], breaks)) ||
      (!isBreakQuarter(q, breaks) && isBreakQuarter(quarters[index - 1], breaks))
    ) {
      uniqueQuarters.push(q);
      return;
    }
    const allRoleHc = headcountAllocation.filter(
      (r) => r.process_path === department.name && r.start_datetime_local === q
    );
    const allPreviousRoleHc = headcountAllocation.filter(
      (r) => r.process_path === department.name && r.start_datetime_local === quarters[index - 1]
    );
    let isDifferent = false;
    allRoleHc.forEach((rHc) => {
      const prevHc = allPreviousRoleHc.find(
        (r) => r.process_path === department.name && r.process_role === rHc.process_role
      );
      if (!prevHc || prevHc.total_hc !== rHc.total_hc) {
        isDifferent = true;
      } else if (department.name === 'injection' && displayThrowRate(prevHc) !== displayThrowRate(rHc)) {
        isDifferent = true;
      }
    });
    if (isDifferent) {
      uniqueQuarters.push(q);
    }
  });
  return uniqueQuarters;
};

const ViewPlanOverview = ({ plan }) => {
  const roles = chainWalk(() => plan.input.ssdShiftPlanInput.roleDetailsList, []);
  let departments = chainWalk(() => plan.input.ssdShiftPlanInput.departments, []);
  const timeline = getTimelineForViewPlan(plan);
  const headcountAllocation = chainWalk(() => JSON.parse(plan.output.serializedOutput).headcountAllocation, []);
  const headcountAllocationPivot = chainWalk(
    () => JSON.parse(plan.output.serializedOutput).headcountAllocationPivot,
    []
  );
  const plannedThroughput = chainWalk(() => JSON.parse(plan.output.serializedOutput).plannedThroughput, []);
  const demand = chainWalk(() => JSON.parse(plan.output.serializedOutput).transformedInputs.demand, []);
  const scienceMetrics = chainWalk(() => JSON.parse(plan.output.serializedOutput).scienceMetrics, []);
  const singlesEnabled = getSinglesEnabled(plan);

  const [selectedShiftName, setSelectedShiftName] = useState(null);
  const shiftList = getShifts(timeline);
  if (selectedShiftName === null) {
    setSelectedShiftName(chainWalk(() => shiftList[0].label, ''));
  }
  const selectedShift = shiftList.find((shift) => shift.label === selectedShiftName);
  const quarters = [];
  departments = departments.map((department) => {
    return {
      ...department,
      quarters: []
    };
  });
  if (selectedShift) {
    const breaks = getBreaks(timeline);
    const shiftHeadcountAllocations = headcountAllocation.filter(({ start_datetime_local }) => {
      return start_datetime_local >= selectedShift.start && start_datetime_local < selectedShift.end;
    });
    shiftHeadcountAllocations.forEach(({ start_datetime_local }) => {
      if (!quarters.includes(start_datetime_local)) {
        quarters.push(start_datetime_local);
      }
    });
    quarters.sort();
    departments.forEach(
      (department) =>
        (department.quarters = getUniqueQuartersByDepartment(department, shiftHeadcountAllocations, quarters, breaks))
    );
  }
  return (
    <Column alignmentHorizontal="center" alignmentVertical="center" width="100%" spacing="500">
      <ButtonGroup size="small" value={selectedShiftName} onChange={setSelectedShiftName}>
        {shiftList.map(({ label }) => (
          <ButtonOption key={label} value={label}>
            {label}
          </ButtonOption>
        ))}
      </ButtonGroup>
      <ShiftTimeline
        timeline={timeline}
        shift={selectedShift}
        skipKeys={[SSD_TIMELINE_KEY.SHIFT_HALF, SSD_TIMELINE_KEY.CET]}
      />
      <Row
        height="100%"
        width="100%"
        widths={['grid-12']}
        spacing="400"
        wrap="down"
        alignmentHorizontal="justify"
        alignmentVertical="stretch"
      >
        <Column>
          <Row width={'100%'} widths={['grid-2', 'grid-3', 'grid-7']}>
            <ShiftHeadcount
              shift={selectedShift}
              headcountAllocation={headcountAllocation}
              headcountAllocationPivot={headcountAllocationPivot}
            />
            <ShiftRisk timeline={timeline} shift={selectedShift} shifts={shiftList} scienceMetrics={scienceMetrics} />
            <ShiftPlannedThroughput
              singlesEnabled={singlesEnabled}
              shift={selectedShift}
              plannedThroughput={plannedThroughput}
            />
          </Row>
          <Row width={'100%'} widths={['grid-7', 'grid-5']}>
            <ShiftSortInjectionVolume shift={selectedShift} demand={demand} />
            <ShiftOutboundVolume shift={selectedShift} demand={demand} />
          </Row>
          <ShiftHeadcountRecommendationTable
            departments={departments}
            roles={roles}
            headcountAllocation={headcountAllocation}
            timeline={timeline}
            shift={selectedShift}
            singlesEnabled={singlesEnabled}
            allQuarters={quarters}
          />
        </Column>
      </Row>
    </Column>
  );
};

export default ViewPlanOverview;
