import React from 'react';
import {
  DYNAMIC_PLAN_CHANGE_MESSAGE,
  DYNAMIC_PLAN_ACCEPT_INFO,
  DYNAMIC_PLAN_OVERRIDE_MESSAGE,
  HOUR_MINUTE_FORMAT,
  PLAN_STATUS,
  M5_LABOR_REASON_CODES,
  TEXTS
} from '../../utils/constants';
import { addFifteenMinutesToEpoch, chainWalk, isDateOld, parseEpochToDateStringWithOptions } from '../../utils/helpers';
import Text from '@amzn/meridian/text';
import Box from '@amzn/meridian/box';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Icon from '@amzn/meridian/icon';
import alertKnockoutTokens from '@amzn/meridian-tokens/base/icon/alert-informational-large';
import CustomAlert from '../../commonComponents/CustomAlert';

const getTitleString = (data, currentTime) => {
  if (!data) {
    return `Dynamic Shift Plan has not been run at ${parseEpochToDateStringWithOptions(currentTime, {
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short',
      hourCycle: 'h23'
    })}.`;
  }
  let title = '';
  let planStatus = data['status'];
  switch (planStatus) {
    case PLAN_STATUS.PENDING_APPROVAL:
      title = `A Dynamic Shift Plan has been generated for ${parseEpochToDateStringWithOptions(
        data['createdAt'],
        HOUR_MINUTE_FORMAT
      )}.`;
      break;
    case PLAN_STATUS.REJECTED:
      title = `A Dynamic Shift Plan has not yet been generated for  ${parseEpochToDateStringWithOptions(
        addFifteenMinutesToEpoch(data['createdAt']),
        HOUR_MINUTE_FORMAT
      )}.`;
      break;
    case PLAN_STATUS.APPROVED:
      title = `Dynamic Shift Plan has been generated and accepted for ${parseEpochToDateStringWithOptions(
        data['createdAt'],
        { hour: '2-digit', minute: '2-digit', timeZoneName: 'short' }
      )}.`;
      break;
    case PLAN_STATUS.CLOSED:
      title = `Dynamic Shift Plan ran at ${parseEpochToDateStringWithOptions(data['createdAt'], HOUR_MINUTE_FORMAT)}.`;
      break;
    case PLAN_STATUS.FAILED:
      title =
        data['planId'] && data['errorMessage'] && data['errorCode']
          ? `Error creating shift plan ${data['planId']} \ Error Message: ${data['errorMessage']} \ Error Code: ${data['errorCode']}`
          : 'Error Message: Failed to create shift plan  Error Code: DYNAMIC_SHIFT_PLANNING_EXCEPTION';
      break;
    default:
      title = `Dynamic Shift Plan was not able to run.`;
      break;
  }
  return title;
};

// For now, we will display the first 2 labor move reasons, if any.
const displayReasonsMessage = (reasons) => {
  if (!reasons) {
    return '';
  }
  let reasonsList = Object.keys(reasons).slice(0, 2);
  let firstReason = reasonsList[0] ? M5_LABOR_REASON_CODES[reasonsList[0]] : '';
  if (!firstReason) {
    return '';
  }
  let message = 'Reason: ' + firstReason;
  let secondReason = reasonsList[1] ? M5_LABOR_REASON_CODES[reasonsList[1]] : '';
  if (!!secondReason) {
    message += ', ' + secondReason;
  }
  return message;
};

const DynamicShiftPlanAlert = ({
  data,
  isPlanPendingApproval,
  isPlanApproved,
  isPlanRejected,
  currentTime,
  laborMoveReasons,
  children,
  runDynamicShiftPlan
}) => {
  const title = !isPlanPendingApproval
    ? isPlanApproved || isPlanRejected
      ? DYNAMIC_PLAN_OVERRIDE_MESSAGE
      : getTitleString(data, currentTime)
    : DYNAMIC_PLAN_CHANGE_MESSAGE;

  const textBeginning =
    isPlanPendingApproval || isPlanApproved || isPlanRejected ? getTitleString(data, currentTime) : '';

  let planIdSubtitle = `(Plan Id: ${data['planId']})`;
  let alpsPlanId = chainWalk(() => data['input']['alpsPlanId'], null);
  if (alpsPlanId != null) {
    planIdSubtitle = `(Plan ID: ${data['planId']}, ALPS Plan ID: ${alpsPlanId})`;
  }

  return data ? (
    <Box type="fill" spacingInset={'400'} width={'100%'} className="alert-border">
      <Column width="100%">
        <Row width="100%" widths={['fit', 'fill']} alignmentHorizontal="justify" alignmentVertical="stretch">
          <Icon tokens={alertKnockoutTokens} />
          <Column>
            {textBeginning != '' ? (
              <Text>
                <b>
                  {textBeginning + ' '}
                  <i>{displayReasonsMessage(laborMoveReasons)}</i>
                </b>
                <Text type={TEXTS.T4}>{<strong>{planIdSubtitle}</strong>}</Text>
                <p>
                  {'This is an'}
                  <b>{' optional'}</b>
                  {' labor recommendation and currently'}
                  <b>{' under testing'}</b>
                  {' to help you manage flow through the shift. '}
                  {DYNAMIC_PLAN_ACCEPT_INFO}
                </p>
              </Text>
            ) : (
              <Text>{title}</Text>
            )}
            {children}
          </Column>
        </Row>
      </Column>
      <div style={{ marginBottom: '1em' }}></div>
    </Box>
  ) : (
    <CustomAlert title={title} buttonTitle={'Run Dynamic Shift Plan'} buttonClickHandle={() => runDynamicShiftPlan()} />
  );
};

export default DynamicShiftPlanAlert;
