import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import { SHIFT_ALLOCATION_ID_PREFIX, SHIFT_ALLOCATION_ID_SUFFIX, TEXTS } from '../../utils/constants';
import Button from '@amzn/meridian/button';
import React, { useEffect, useMemo, useState, useContext, useCallback } from 'react';
import closeLargeTokens from '@amzn/meridian-tokens/base/icon/close-small';
import Icon from '@amzn/meridian/icon';
import Box from '@amzn/meridian/box';
import Select, { SelectOption } from '@amzn/meridian/select';
import SearchField from '@amzn/meridian/search-field';
import Tab, { TabGroup } from '@amzn/meridian/tab';
import Badge from '@amzn/meridian/badge';
import { AVAILABLE_ASSOCIATES_VIEW_CONSTANTS } from '../ShiftAllocationConstants';
import { EmployeeIcon } from './EmployeeIcon';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { ShiftAllocationContext } from '../context/ShiftAllocationContext';
import { getAssociateInfoFromProcessAssignments } from './utils';
import { EMPTY_PROCESS, PROCESS_PATHS } from '../data';

const AvailableAssociates = ({ setOpen }) => {
  //get all process paths here
  const processPaths = Object.values(PROCESS_PATHS)
    .filter((path) => path !== PROCESS_PATHS.BENCH && path !== PROCESS_PATHS.FIVE_S)
    .sort();
  const [selectedDisallowedPath, setSelectedDisallowedPath] = useState([]);
  const [selectedTrainedPath, setSelectedTrainedPath] = useState([]);
  const [selectedSuggestedPath, setSelectedSuggestedPath] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [clockedInCount, setClockedInCount] = useState(0);
  const [clockedOutCount, setClockedOutCount] = useState(0);
  const [currentTab, setCurrentTab] = useState('In');

  const { shiftAllocationModel, shiftAllocationDispatch } = useContext(ShiftAllocationContext);

  const assignments = useMemo(() => {
    if (shiftAllocationModel.processInfo.find((process) => process.id === PROCESS_PATHS.BENCH)) {
      return shiftAllocationModel.processInfo.find((process) => process.id === PROCESS_PATHS.BENCH).assignments;
    } else {
      return [];
    }
  }, [shiftAllocationModel]);

  const visibleData = useMemo(() => {
    return Object.values(
      getAssociateInfoFromProcessAssignments(assignments, shiftAllocationModel['associateInfo'])
    ).filter((associate) => {
      return (
        ((currentTab.includes('In') && associate.clockedInStatus) ||
          (currentTab.includes('Out') && !associate.clockedInStatus)) &&
        (selectedDisallowedPath.length > 0
          ? associate.disallowedPaths.some((path) => selectedDisallowedPath.includes(path))
          : true) &&
        (selectedTrainedPath.length > 0
          ? associate.trainedPaths.some((path) => selectedTrainedPath.includes(path))
          : true) &&
        (selectedSuggestedPath.length > 0
          ? associate.recommendedPaths.some((path) => selectedSuggestedPath.includes(path))
          : true) &&
        (searchQuery.length > 0
          ? (associate.name && associate.name.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase())) ||
            associate.alias.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase())
          : true)
      );
    });
  }, [selectedTrainedPath, selectedSuggestedPath, selectedDisallowedPath, searchQuery, currentTab, assignments]);

  useEffect(() => {
    const clockedIn = [];
    const clockedOut = [];

    Object.values(getAssociateInfoFromProcessAssignments(assignments, shiftAllocationModel['associateInfo'])).forEach(
      (associate) => {
        if (associate.clockedInStatus) {
          clockedIn.push(associate);
        } else {
          clockedOut.push(associate);
        }
      }
    );

    setClockedInCount(clockedIn.length);
    setClockedOutCount(clockedOut.length);
  }, [shiftAllocationModel['associateInfo'], assignments]);

  const onClickCloseButton = () => {
    setOpen(false);
  };

  const onClickClearButton = () => {
    // clear selection of all three filters
    setSelectedDisallowedPath([]);
    setSelectedTrainedPath([]);
    setSelectedSuggestedPath([]);
  };

  const onSubmit = (query) => {
    setSearchQuery(query);
  };

  const TabView = useCallback(() => {
    return (
      <Box>
        <Row alignmentVertical={'top'} alignmentHorizontal={'start'} spacing="xsmall" wrap={'down'}>
          {visibleData.map((associate, index) => {
            return (
              <Draggable
                // adding a key is important!
                key={associate.alias}
                draggableId={associate.alias}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    id={`${SHIFT_ALLOCATION_ID_PREFIX.DRAGGABLE_ASSOCIATE}-${associate.alias}`}
                    style={{
                      ...provided.draggableProps.style,
                      position: snapshot.isDragging ? 'absolute' : 'static'
                    }}
                  >
                    <EmployeeIcon associate={associate} isBenched />
                  </div>
                )}
              </Draggable>
            );
          })}
        </Row>
      </Box>
    );
  }, [visibleData]);

  return (
    <Column spacingInset="300" type={'outline'} height={'100%'}>
      <Row alignmentHorizontal="justify" spacing={'none'}>
        <Text type={TEXTS.H4}>{AVAILABLE_ASSOCIATES_VIEW_CONSTANTS.PAGE_HEADER}</Text>
        <Button type="link" onClick={onClickCloseButton} size="medium">
          <Icon tokens={closeLargeTokens}></Icon>
        </Button>
      </Row>
      <Row spacing={'100'}>
        <Box type="fill" spacingInset="200" width="100%">
          <Row spacingInset="200" alignmentHorizontal={'center'}>
            <Select
              value={selectedDisallowedPath}
              minWidth={'95%'}
              onChange={setSelectedDisallowedPath}
              label={AVAILABLE_ASSOCIATES_VIEW_CONSTANTS.DISALLOWED_PATH}
              size="medium"
            >
              {processPaths.map((disallowedPath) => (
                <SelectOption key={disallowedPath} value={disallowedPath} label={disallowedPath} />
              ))}
            </Select>
          </Row>
          <Row spacingInset="200" alignmentHorizontal={'center'}>
            <Select
              value={selectedTrainedPath}
              minWidth={'95%'}
              onChange={setSelectedTrainedPath}
              label={AVAILABLE_ASSOCIATES_VIEW_CONSTANTS.TRAINED_PATH}
              size="medium"
            >
              {processPaths.map((trainedPath) => (
                <SelectOption key={trainedPath} value={trainedPath} label={trainedPath} />
              ))}
            </Select>
          </Row>
          <Row spacingInset="200" alignmentHorizontal={'center'}>
            <Select
              value={selectedSuggestedPath}
              minWidth={'95%'}
              onChange={setSelectedSuggestedPath}
              label={AVAILABLE_ASSOCIATES_VIEW_CONSTANTS.SUGGESTED_PATH}
              size="medium"
            >
              {processPaths.map((suggestedPath) => (
                <SelectOption key={suggestedPath} value={suggestedPath} label={suggestedPath} />
              ))}
            </Select>
          </Row>
          <Button onClick={onClickClearButton} type="link">
            {AVAILABLE_ASSOCIATES_VIEW_CONSTANTS.CLEAR_ALL_BUTTON}
          </Button>
        </Box>
      </Row>
      <Box width="100%">
        <SearchField
          value={searchQuery}
          onChange={setSearchQuery}
          placeholder={AVAILABLE_ASSOCIATES_VIEW_CONSTANTS.SEARCH_PLACEHOLDER}
          onSubmit={onSubmit}
          size="medium"
          searchButton={true}
          disabled={false}
          clearButton={true}
          minWidth={'95%'}
        />
      </Box>
      <Row spacing="none" alignmentHorizontal={'justify'} alignmentVertical={'top'} wrap={'down'}>
        <TabGroup fill={'tabs'} value={currentTab} onChange={setCurrentTab}>
          <Tab value="In">
            <Text>
              {AVAILABLE_ASSOCIATES_VIEW_CONSTANTS.TAB_IN}
              <Badge value={clockedInCount} type="theme" />
            </Text>
          </Tab>

          <Tab value="Out">
            <Text>
              {AVAILABLE_ASSOCIATES_VIEW_CONSTANTS.TAB_OUT}
              <Badge value={clockedOutCount} type="theme" />
            </Text>
          </Tab>
        </TabGroup>
      </Row>
      <Droppable
        droppableId={`${PROCESS_PATHS.BENCH}-${EMPTY_PROCESS}-${SHIFT_ALLOCATION_ID_SUFFIX.BENCH_DETAIL_CARD}`}
        key={PROCESS_PATHS.BENCH}
        type={'PROCESS_PATH'}
        renderClone={(provided, snapshot, rubric) => (
          <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
            <EmployeeIcon associate={shiftAllocationModel['associateInfo'][rubric.draggableId]} isBenched />
          </div>
        )}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            // some magic numbers but basically that's the width of one of the
            // employeeIcons + margins
            style={{ width: `${65 * 4}px` }}
            {...provided.droppableProps}
            id={PROCESS_PATHS.BENCH}
          >
            {currentTab === 'In' && (
              <div role="tabpanel">
                <TabView />
              </div>
            )}
            {currentTab === 'Out' && (
              <div role="tabpanel">
                <TabView />
              </div>
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </Column>
  );
};

export default AvailableAssociates;
