import { chainWalk, removeSecondsFromTimeStamp } from './helpers';
import { ALL_CYCLES } from './constants';
// Place holder until we migrate to Dynamic V2.
const processDynamicHeadCountTableData = () => {
  return '';
};

const getHeadCountFromShifts = (dataAtTimeAndRole) => {
  const headCounts = dataAtTimeAndRole['assignedHeadCounts'];
  let actualCount = 0;
  if (headCounts) {
    headCounts.forEach((shift) => {
      actualCount += shift['assignedHeadCount'];
    });
  }
  return actualCount;
};

// Calculate headcount for every role at certain time.
export const processDynamicV1HeadCountTableData = (output) => {
  let tableData = {};
  const times = chainWalk(() => output['plannedHeadCount']['headCountPlannerOutput'], []);
  const roles = Object.keys(times).length > 0 ? Object.keys(times[Object.keys(times)[0]]) : [];
  const excessHCTimes = chainWalk(() => output['excessHeadcount']['ExcessHeadcountMap'], []);

  Object.keys(times).map((time) => {
    const dataAtTime = times[time];
    if (!(time in tableData)) {
      tableData[time] = {};
    }
    roles.map((role) => {
      // Getting all the data at a particular timestamp
      const dataAtTimeAndRole = dataAtTime[role];
      // Getting headcount at specific time and role
      tableData[time][role] = getHeadCountFromShifts(dataAtTimeAndRole);
    });
    // Adding headcount for 5S at each time
    const dataAtTime5S = excessHCTimes[time];
    tableData[time]['5S'] = getHeadCountFromShifts(dataAtTime5S);
  });
  return tableData;
};

export const processDynamicV2HeadCountTableData = (output, selectedCycle) => {
  let tableData = {};

  let dataKey = chainWalk(() => removeSecondsFromTimeStamp(output['metadata']['STABLE_PLAN_TIME']), '');
  if (!dataKey) {
    return {};
  }

  tableData[dataKey] = {};
  for (let index in output['headCountPlan']['headCountCycles']) {
    let val = output['headCountPlan']['headCountCycles'][index];
    if (selectedCycle !== ALL_CYCLES && 'cycle' in val && selectedCycle !== val['cycle']) {
      continue;
    }
    let headCountData = chainWalk(() => val['headCountPlannerOutput'][dataKey], []);

    for (let process in headCountData) {
      const dataAtStablePlanTime = headCountData[process];
      if (!tableData[dataKey][process]) {
        tableData[dataKey][process] = getHeadCountFromShifts(dataAtStablePlanTime);
      } else {
        tableData[dataKey][process] += getHeadCountFromShifts(dataAtStablePlanTime);
      }
    }
  }
  return tableData;
};

export default processDynamicV1HeadCountTableData;
