import React, { useState } from 'react';
import Table, { TableRow, TableCell } from '@amzn/meridian/table';
import Input from '@amzn/meridian/input';
import Text from '@amzn/meridian/text';
import Column from '@amzn/meridian/column';
import AddTooltip from '../../../utils/AddTooltip';
import { chainWalk, convertToFixedDP, isNumberInvalid, isNumberNotInRange, isValid } from '../../../utils/helpers';
import { TEXTS, ALPS_PLANNED_ZERO_PPH_WARNING } from '../../../utils/constants';
import { getGuardrails, RATES_GUARDRAILS } from '../../../utils/guardrails';
import Row from '@amzn/meridian/row';
import Tooltip from '@amzn/meridian/tooltip';
import Icon from '@amzn/meridian/icon';
import errWarningIcon from '@amzn/meridian-tokens/base/icon/alert-error-large';
import AddText from '../../../utils/AddText';

const PlannedPPHInput = ({
  data,
  process,
  updateButtonState,
  initialValue,
  currentValue,
  suggestedValue,
  errorCounter,
  isUserAuthorisedForShiftPlanOverrides
}) => {
  const [value, setValue] = useState(currentValue);
  const [isErr, setIsErr] = useState(false);
  const minAllowed = convertToFixedDP(
    convertToFixedDP(isValid(suggestedValue) ? suggestedValue : initialValue) *
      getGuardrails(RATES_GUARDRAILS, data.nodeType).RATES_MIN_THRESHOLD
  );
  const maxAllowed = convertToFixedDP(
    convertToFixedDP(isValid(suggestedValue) ? suggestedValue : initialValue) *
      getGuardrails(RATES_GUARDRAILS, data.nodeType).RATES_MAX_THRESHOLD
  );

  const setText = (val) => {
    if (
      isNumberInvalid(val) ||
      isNumberNotInRange(val, minAllowed, maxAllowed, isUserAuthorisedForShiftPlanOverrides)
    ) {
      if (isErr === false) errorCounter.count++;
      setIsErr(true);
    } else {
      if (isErr === true) errorCounter.count--;
      setIsErr(false);
      data.input.resourceProductivityInput.resourceProductivityList[0].pph[process].plannedPph = convertToFixedDP(val);
    }
    updateButtonState();
    setValue(val);
  };
  return (
    <Column spacing="xxsmall">
      {initialValue === 0 ? (
        <Row>
          <Input value={value} disabled={true} />
          <Tooltip position="top" title={ALPS_PLANNED_ZERO_PPH_WARNING}>
            <Icon tokens={errWarningIcon} />
          </Tooltip>
        </Row>
      ) : (
        <div>
          {isValid(suggestedValue) ? <AddText>{`Suggested value: ${suggestedValue}`}</AddText> : null}
          <Input
            value={value}
            onChange={setText}
            error={isErr}
            focus={isErr ? false : undefined}
            type="number"
            size="small"
            placeholder="Enter value..."
          />
          {isErr && !isUserAuthorisedForShiftPlanOverrides && isValid(suggestedValue) ? (
            <Text color="error" type={TEXTS.T3}>
              Needs to stay within +/- {getGuardrails(RATES_GUARDRAILS, data.nodeType).RATES_THRESHOLD_PERCENTAGE}% of
              suggested value
            </Text>
          ) : isErr && !isUserAuthorisedForShiftPlanOverrides ? (
            <Text color="error" type={TEXTS.T3}>
              Needs to stay within +/- {getGuardrails(RATES_GUARDRAILS, data.nodeType).RATES_THRESHOLD_PERCENTAGE}%
            </Text>
          ) : null}
        </div>
      )}
    </Column>
  );
};

/**
 *
 * @deprecated CycleSortTable should be used.
 * https://code.amazon.com/reviews/CR-88433435
 */
const SortTable = ({
  data,
  validProcesses,
  updateButtonState,
  errorCounter,
  initialData,
  isUserAuthorisedForShiftPlanOverrides
}) => {
  return (
    <Table headerRows={1} showDividers={true} showStripes={true} layout="fixed" spacing="small">
      <TableRow>
        <TableCell>Labour Process</TableCell>
        <TableCell>
          <AddTooltip size={TEXTS.H4}>Historical FCLM Rate</AddTooltip>
        </TableCell>
        <TableCell>
          <AddTooltip size={TEXTS.H4}>Planned PPH</AddTooltip>
        </TableCell>
      </TableRow>
      {validProcesses.map((process, index) => {
        const initialPlannedPph = chainWalk(
          () => initialData.input.resourceProductivityInput.resourceProductivityList[0].pph[process].plannedPph,
          0
        );

        const suggestedPlannedPph =
          data.input.shiftPlanSuggestedInput && data.input.shiftPlanSuggestedInput.resourceProductivityInput
            ? chainWalk(
                () =>
                  data.input.shiftPlanSuggestedInput.resourceProductivityInput.resourceProductivityList[0].pph[process]
                    .plannedPph,
                0
              )
            : undefined;

        const targetPph = chainWalk(
          () => data.input.resourceProductivityInput.resourceProductivityList[0].pph[process].targetPph,
          null
        );
        const plannedPph = chainWalk(
          () => data.input.resourceProductivityInput.resourceProductivityList[0].pph[process].plannedPph,
          0
        );
        const historicPph = chainWalk(
          () => data.input.resourceProductivityInput.resourceProductivityList[0].pph[process].historicPph,
          0
        );
        return (
          <TableRow key={index} highlightOnHover={true}>
            <TableCell>
              <AddTooltip>{process}</AddTooltip>
            </TableCell>
            <TableCell>{historicPph && convertToFixedDP(historicPph)}</TableCell>
            <TableCell>
              <PlannedPPHInput
                data={data}
                process={process}
                updateButtonState={updateButtonState}
                errorCounter={errorCounter}
                initialValue={convertToFixedDP(initialPlannedPph)}
                currentValue={convertToFixedDP(plannedPph)}
                suggestedValue={isValid(suggestedPlannedPph) ? convertToFixedDP(suggestedPlannedPph) : undefined}
                isUserAuthorisedForShiftPlanOverrides={isUserAuthorisedForShiftPlanOverrides}
              />
            </TableCell>
          </TableRow>
        );
      })}
    </Table>
  );
};

export default SortTable;
