import React from 'react';
import Button from '@amzn/meridian/button';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import ViewRates from '../../common/ssd/ViewRates';
import Text from '@amzn/meridian/text';
import infoKnockoutTokens from '@amzn/meridian-tokens/base/icon/info-knockout';
import Icon from '@amzn/meridian/icon';
import Tooltip from '@amzn/meridian/tooltip';
import { MSR_TOOL_TIP } from '../../../../utils/constants';

const VerifyRates = ({ currentUser, plan, setPlan, onGoBack, onGoNext, onCancel }) => {
  return (
    <Column width="100%">
      <div className="header-with-tooltip">
        <Text type={'h400'}>MSR Rates</Text>
        <Tooltip position="end" title={MSR_TOOL_TIP} id="myTooltip">
          <Icon className={'custom-tool-tip'} tokens={infoKnockoutTokens} />
        </Tooltip>
      </div>
      <Text type={'b400'}>Verify or update the values below and submit.</Text>
      <ViewRates currentUser={currentUser} isEditable={true} plan={plan} setPlan={setPlan} />
      <Row alignmentHorizontal="justify">
        <Button onClick={onGoBack} type="secondary">
          Back
        </Button>
        <Row>
          <Button onClick={onCancel} type="tertiary">
            Cancel
          </Button>
          <Button onClick={onGoNext}>Next</Button>
        </Row>
      </Row>
    </Column>
  );
};

export default VerifyRates;
