import { getTodaysDate } from '../../utils/helpers';

export const GlobalStateReducer = (state, action) => {
  switch (action.type) {
    case 'RESTART_APP':
      return { ...state, resetID: Math.random(), asyncError: '' };
    case 'SET_ASYNC_ERROR':
      return { ...state, asyncError: action.data.asyncError };
    case 'SET_DATE':
      return { ...state, date: action.data.date };
    default:
      return state;
  }
};

export const initialState = {
  asyncError: '',
  resetID: Math.random(),
  date: getTodaysDate()
};
