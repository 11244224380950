import React, { useState } from 'react';
import Box from '@amzn/meridian/box';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';
import Input from '@amzn/meridian/input';
import { TEXTS } from '../../utils/constants';
import Button from '@amzn/meridian/button';
import Row from '@amzn/meridian/row';

const DynamicPlanInputOverride = ({ name, inputOverride, setInputOverride, currentInputValue }) => {
  const SCREEN = {
    DEFAULT: 1,
    EDIT: 2
  };
  const [screen, setScreen] = useState(SCREEN.DEFAULT);
  // Change screen of override, if user exits EDIT mode, return value to default current value.
  const changeScreen = () => {
    setScreen(screen === SCREEN.EDIT ? SCREEN.DEFAULT : SCREEN.EDIT);
    if (screen === SCREEN.EDIT) {
      setInputOverride(currentInputValue);
    }
  };

  return (
    <div>
      <Column width="250px" spacing="200">
        <Text tag="label" type={TEXTS.T2} color="primary">
          {name}
        </Text>
        {screen !== SCREEN.DEFAULT ? (
          <div>
            <Row>
              <Box>
                <Input value={inputOverride} onChange={setInputOverride} type="number" size="medium" />
              </Box>
              <Button onClick={changeScreen} type="link">
                Cancel
              </Button>
            </Row>
            <Text type={TEXTS.T2}>{'Current: ' + currentInputValue}</Text>
          </div>
        ) : (
          <Row>
            <Text type={TEXTS.H2}>{currentInputValue}</Text>
            <Button onClick={changeScreen} type="link">
              edit
            </Button>
          </Row>
        )}
      </Column>
    </div>
  );
};

export default DynamicPlanInputOverride;
