export const LoadingReducer = (state, action) => {
  switch (action.type) {
    case 'SET_LOADER':
      return {
        ...state,
        isLoading: action.data.isLoading,
        loadingMessage: action.data.loadingMessage
      };
    default:
      return state;
  }
};

export const initialState = {
  isLoading: false,
  loadingMessage: ''
};
