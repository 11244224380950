import Table, { TableCell, TableRow } from '@amzn/meridian/table';
import React from 'react';

import { chainWalk, convertToFixedDP } from '../../../utils/helpers';

const TotalShowHrsTable = ({ plannedNodeThroughput, isDay1NewHireLaborEnabled }) => {
  // TODO: We can remove this flag check once the Day 1 New Hire hours experience
  // is fully dialed up.
  const isShowTotalDay1NewHire =
    isDay1NewHireLaborEnabled && chainWalk(() => plannedNodeThroughput.totalDay1NewHireShowHours, -1) >= 0;

  return (
    <div>
      <Table headerRows={1} showDividers={true} showStripes={false} spacing="small">
        <TableRow>
          {isShowTotalDay1NewHire ? (
            <TableCell alignmentHorizontal="right">
              Total Show Hrs w/o<br></br> Day 1 Show Hrs
            </TableCell>
          ) : (
            <TableCell alignmentHorizontal="right">Total Show Hrs</TableCell>
          )}
          {isShowTotalDay1NewHire && (
            <TableCell alignmentHorizontal="right">
              Total Day 1<br></br>Show Hrs
            </TableCell>
          )}
          <TableCell alignmentHorizontal="right">Total Needed Hrs</TableCell>
          <TableCell alignmentHorizontal="right">Planned TPH with 5s</TableCell>
          <TableCell alignmentHorizontal="right">Planned TPH without 5s</TableCell>
        </TableRow>
        {plannedNodeThroughput ? (
          <TableRow highlightOnHover={true}>
            <TableCell alignmentHorizontal="right">{convertToFixedDP(plannedNodeThroughput.totalShowHours)}</TableCell>
            {isShowTotalDay1NewHire && (
              <TableCell alignmentHorizontal="right">
                {convertToFixedDP(plannedNodeThroughput.totalDay1NewHireShowHours)}
              </TableCell>
            )}
            <TableCell alignmentHorizontal="right">
              {convertToFixedDP(plannedNodeThroughput.totalNeededHours)}
            </TableCell>
            <TableCell alignmentHorizontal="right">
              {convertToFixedDP(plannedNodeThroughput.plannedTphWith5s)}
            </TableCell>
            <TableCell alignmentHorizontal="right">
              {convertToFixedDP(plannedNodeThroughput.plannedTphWithout5s)}
            </TableCell>
          </TableRow>
        ) : (
          <TableRow highlightOnHover={true}>
            <TableCell alignmentHorizontal="right">0</TableCell>
            <TableCell alignmentHorizontal="right">0</TableCell>
            <TableCell alignmentHorizontal="right">0</TableCell>
            <TableCell alignmentHorizontal="right">0</TableCell>
          </TableRow>
        )}
      </Table>
    </div>
  );
};

export default TotalShowHrsTable;
