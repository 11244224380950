import { MOVE_ASSOCIATE, SET_MODEL, RESOURCE_STATUS_ENRICHMENT, RESET_MODEL } from '../action/ShiftAllocationAction';
import { cloneDeep } from 'lodash';
import { ALLOCATION_TYPE, DEFAULT_SHIFT_ALLOCATION_MODEL, ThumbnailBadge } from '../data';

export const ShiftAllocationReducer = (state, action) => {
  switch (action.type) {
    case MOVE_ASSOCIATE:
      return moveAssociate(state, action);
    case SET_MODEL:
      // do any transformation here.
      return { ...state, ...action.payload };
    case RESOURCE_STATUS_ENRICHMENT:
      return enrichAssociates(state, action);
    case RESET_MODEL:
      return { ...DEFAULT_SHIFT_ALLOCATION_MODEL, ...action.payload };
    default:
      return state;
  }
};

const aisleOrderFn = (assignment1, assignment2) => {
  const {
    allocationAttrs: { aisles: aisles1 },
    resourceId: associateAlias1
  } = assignment1;
  const {
    allocationAttrs: { aisles: aisles2 },
    resourceId: associateAlias2
  } = assignment2;

  if (Number(aisles1[0]) === Number(aisles2[0])) {
    return associateAlias1.localeCompare(associateAlias2);
  }

  return Number(aisles1[0]) - Number(aisles2[0]);
};

const enrichAssociates = (state, action) => {
  const stateCopy = cloneDeep(state);
  const { resourceStatuses } = action.payload;

  resourceStatuses.forEach((resourceStatus) => {
    const { punchedInStatus, resourceId, trackingProcess, productivity } = resourceStatus;
    const associate = stateCopy.associateInfo[resourceId];
    let totalUnitsProcesses = 0;
    if (associate) {
      associate.clockedInStatus = punchedInStatus;
      associate.trackingProcess = trackingProcess;
      if (productivity) {
        Object.values(productivity).forEach(processPath => {
          totalUnitsProcesses += processPath.unitsProcesses;
        });
        if (totalUnitsProcesses === 0 && associate.clockedInStatus) {
          stateCopy.associateInfo[resourceId].thumbnailBadge = new ThumbnailBadge("error", "!")
        }
      }
      stateCopy.associateInfo[resourceId].trackingPath = trackingProcess
    }
  });

  return stateCopy;
};

const moveAssociate = (state, action) => {
  const stateCopy = cloneDeep(state);
  const { associateAlias } = action.data;
  const { boardEntry, processPath } = action.data.to;
  const toBoardEntry = stateCopy.processInfo.find((entry) => {
    return entry.id === boardEntry;
  });
  const assignments = [...toBoardEntry.assignments];
  stateCopy.associateInfo[associateAlias].thumbnailBadge = null;

  const processPathEntry = toBoardEntry.processPathEntries.find((entry) => entry.process === processPath);
  if (toBoardEntry.allocationType === ALLOCATION_TYPE.AISLES) {
    const { minAisle, maxAisle } = action.data.to;
    const clusterId = boardEntry.split('_')[1];
    const newAssignment = {
      resourceId: associateAlias,
      boardEntity: boardEntry,
      allocationAttrs: {
        process: processPath,
        cluster: clusterId,
        type: ALLOCATION_TYPE.AISLES,
        aisles: Array(maxAisle - minAisle + 1)
          .fill()
          .map((_, idx) => `${clusterId}-${minAisle + idx}`)
      }
    };

    if (processPathEntry) {
      newAssignment.allocationAttrs = {
        ...newAssignment.allocationAttrs,
        calmCode: processPathEntry.calmCode,
        pathType: processPathEntry.pathType
      };
    }
    assignments.push(newAssignment);
    assignments.sort(aisleOrderFn);
    toBoardEntry.assignments = assignments;
  } else {
    const assignments = toBoardEntry.assignments;
    const newAssignment = {
      resourceId: associateAlias,
      boardEntity: boardEntry,
      allocationAttrs: {
        process: processPath
      }
    };
    if(processPathEntry) {
      newAssignment.allocationAttrs = {
        ...newAssignment.allocationAttrs,
        calmCode: processPathEntry.calmCode,
        pathType: processPathEntry.pathType
      };
    }
    assignments.push(newAssignment);
    toBoardEntry.assignments = assignments;
  }
  return removeAssociate(stateCopy, action);
};

const removeAssociate = (state, action) => {
  const stateCopy = cloneDeep(state);
  const { associateAlias } = action.data;
  const { boardEntry } = action.data.from;

  const fromBoardEntry = stateCopy.processInfo.find((entry) => entry.id === boardEntry);

  const assignments = fromBoardEntry.assignments;
  const indexOfItemToRemove = assignments.findIndex((assignment) => assignment.resourceId === associateAlias);
  if (indexOfItemToRemove > -1) {
    assignments.splice(indexOfItemToRemove, 1);
  }

  return stateCopy;
};
