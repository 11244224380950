import React from 'react';
import Row from '@amzn/meridian/row';
import Box from '@amzn/meridian/box';
import Heading from '@amzn/meridian/heading';
import Text from '@amzn/meridian/text';
import Tooltip from '@amzn/meridian/tooltip';
import Icon from '@amzn/meridian/icon';
import infoKnockoutTokens from '@amzn/meridian-tokens/base/icon/info-knockout';
import Column from '@amzn/meridian/column';

const MetricView = ({ headerComponent, title, values }) => {
  const gridWidth = parseInt(12 / values.length);
  return (
    <Box type="outline" spacingInset="400">
      <Column>
        {!headerComponent ? (
          <Row>
            <Heading level={2} type="h300" alignment="left">
              <Text>{title}</Text>
            </Heading>
          </Row>
        ) : (
          headerComponent
        )}
        <Row width="100%" widths={`grid-${gridWidth}`}>
          {values.map(({ title, subtext, helperText, tooltipValues }) => {
            return (
              <div key={title}>
                <Text type={'b100'}>{title}</Text>
                {!!helperText && (
                  <Text type={'b100'} color="secondary">
                    {helperText}
                  </Text>
                )}
                <Text type={'d50'}>
                  {subtext}
                  {!!tooltipValues && (
                    <Tooltip
                      position="bottom"
                      title={
                        <Column>
                          {tooltipValues.map((tooltipValue) => (
                            <Text color={'inverted'} key={tooltipValue}>
                              {tooltipValue}
                            </Text>
                          ))}
                        </Column>
                      }
                    >
                      <Icon tokens={infoKnockoutTokens} />
                    </Tooltip>
                  )}
                </Text>
              </div>
            );
          })}
        </Row>
      </Column>
    </Box>
  );
};

export default MetricView;
