import React, { useState, useEffect, useRef } from 'react';

import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Divider from '@amzn/meridian/divider';
import Icon from '@amzn/meridian/icon';
import Loader from '@amzn/meridian/loader';
import Popover from '@amzn/meridian/popover';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import closeSmallTokens from '@amzn/meridian-tokens/base/icon/close-small';
import infoKnockoutTokens from '@amzn/meridian-tokens/base/icon/info-knockout';

import moment from 'moment';

import { getSsdAdminSettings } from '../shiftPlan/ViewPlanSSD';
import { chainWalk, convertEpochToDatetimeLocal, parseEpochToDateStringWithOptions } from '../utils/helpers';

import ShiftAdherenceSelector from './ShiftAdherenceSelector';
import ShiftAdherenceGraph from './ShiftAdherenceGraph';
import ShiftAdherenceTable from './ShiftAdherenceTable';
import ShiftAdherencePlansView from './ShiftAdherencePlansView';

export const PROCESS_PATH_TO_ROLES = {
  outbound: {
    pick2rebinpick: 'Pick2Rebin Pick',
    pick2rebinpack: 'Pick2Rebin Pack',
    vnapick: 'VNA Pick',
    vnapack: 'VNA Pack',
    outboundproblemsolve: 'OB Problem Solve'
  },
  injection: {
    unload: 'Unload'
  },
  sortation: {
    induct: 'Induct',
    buffer: 'Buffer',
    stow: 'Stow',
    stage: 'Stage',
    rts: 'RTS',
    sortationproblemsolve: 'Sort Problem Solve'
  }
};

// Number of plans to show on the UI.
const NUM_PLANS = 5;

const ShiftAdherence = () => {
  const [adherenceData, setAdherenceData] = useState({});
  const [availableShifts, setAvailableShifts] = useState({});
  const [stationTimeZone, setStationTimeZone] = useState('');

  const titleRef = useRef();
  const [isTitlePopoverOpen, setIsTitlePopoverOpen] = useState(false);

  useEffect(() => {
    (async () => {
      // Generate a list of potential shifts.
      const ssdAdminSettings = await getSsdAdminSettings();
      const updatedStationTimeZone = chainWalk(() => ssdAdminSettings['timezone'], '');
      const shifts = chainWalk(() => ssdAdminSettings['shifts'], []);
      if (updatedStationTimeZone === '' || shifts.length === 0) {
        return;
      }

      setStationTimeZone(updatedStationTimeZone);
      const stationTimeZoneOffset = moment.tz(updatedStationTimeZone).format('Z');

      const currDate = new Date();
      const previousDate = new Date(currDate);
      previousDate.setDate(currDate.getDate() - 1);

      const allAvailableShifts = {};
      [currDate, previousDate].forEach((day) => {
        const dateTimeStringLocal = convertEpochToDatetimeLocal(day.getTime() / 1000, updatedStationTimeZone);
        const dateLocal = dateTimeStringLocal.split('T')[0];

        shifts.forEach((shift) => {
          const shiftStartDateUtc = new Date(dateLocal + 'T' + shift['start'] + stationTimeZoneOffset);
          const shiftEndDateUtc = new Date(dateLocal + 'T' + shift['end'] + stationTimeZoneOffset);

          // Adjust shiftEndDateUtc since the shift can cross midnight.
          if (shiftEndDateUtc.getTime() < shiftStartDateUtc.getTime()) {
            shiftEndDateUtc.setDate(shiftEndDateUtc.getDate() + 1);
          }

          // Only show historical shifts.
          if (shiftStartDateUtc.getTime() < currDate.getTime()) {
            const shiftDisplayString = `${shift['label']} (${
              parseEpochToDateStringWithOptions(shiftStartDateUtc.getTime() / 1000, {
                timeZone: updatedStationTimeZone
              }).split(',')[0]
            })`;

            allAvailableShifts[shiftDisplayString] = { start: shiftStartDateUtc, end: shiftEndDateUtc };
          }
        });
      });

      // Sort end times descending.
      const availableEndTimes = [];
      Object.entries(allAvailableShifts).forEach(([_, shiftTimes]) => {
        availableEndTimes.push(shiftTimes['end']);
      });
      availableEndTimes.sort(function (firstDate, secondDate) {
        return secondDate.getTime() - firstDate.getTime();
      });

      // Select latest `NUM_PLANS` plans.
      const filteredEndTimes = availableEndTimes.slice(0, NUM_PLANS);

      // Reconstruct ordered `availableShifts` with latest `NUM_PLANS` plans.
      const updatedAvailableShifts = {};
      filteredEndTimes.forEach((filteredEndTime) => {
        const availableShift = Object.entries(allAvailableShifts).find(([_, shiftTimes]) => {
          return shiftTimes['end'] === filteredEndTime;
        });
        updatedAvailableShifts[availableShift[0]] = availableShift[1];
      });

      setAvailableShifts(updatedAvailableShifts);
    })();
  }, []);

  return (
    <Column spacingInset="400" spacing="500">
      <Row spacing="300">
        <Text type="h500">Headcount Adherence - Shift View</Text>
        <Button
          type="icon"
          onClick={() => {
            setIsTitlePopoverOpen(!isTitlePopoverOpen);
          }}
          ref={titleRef}
        >
          <Icon tokens={infoKnockoutTokens} />
        </Button>
        <Popover
          anchorNode={titleRef.current}
          open={isTitlePopoverOpen}
          onClose={() => {
            setIsTitlePopoverOpen(false);
          }}
          position="bottom"
        >
          <Row alignmentVertical="top" spacingInset="100">
            <Text>
              Displays current active Shift Plan for a given site and time period. The plan leverages various inputs
              (Rates, Volumes, Charge) to predict the headcount required for each role in various process paths to
              complete a specified volume in a given amount of time.
            </Text>
            <Button type="icon" onClick={() => setIsTitlePopoverOpen(false)}>
              <Icon tokens={closeSmallTokens} />
            </Button>
          </Row>
        </Popover>
      </Row>

      {Object.keys(availableShifts).length > 0 && (
        <ShiftAdherenceSelector
          adherenceData={adherenceData}
          setAdherenceData={setAdherenceData}
          availableShifts={availableShifts}
          stationTimeZone={stationTimeZone}
        />
      )}

      {Object.keys(adherenceData).length > 0 ? (
        [
          <ShiftAdherenceGraph adherenceData={adherenceData} />,
          <ShiftAdherencePlansView adherenceData={adherenceData} />,
          <Divider />,
          <ShiftAdherenceTable adherenceData={adherenceData} />,
          <div />
        ]
      ) : (
        <Column alignmentVertical="center" alignmentHorizontal="center" height="50vh">
          <Loader type="circular" size="large" />
        </Column>
      )}
    </Column>
  );
};

export default ShiftAdherence;
