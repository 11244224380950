import React, { useState } from 'react';
import Box from '@amzn/meridian/box';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';
import Input from '@amzn/meridian/input';
import { TEXTS } from '../../utils/constants';
import Button from '@amzn/meridian/button';
import Row from '@amzn/meridian/row';

const DSPVolumeOverride = ({ cycle, volumeOverrides, setVolumeOverrides }) => {
  const [value, setValue] = useState(volumeOverrides[cycle]);
  const onChangeValue = (value) => {
    let updatedData = { ...volumeOverrides };
    updatedData[cycle] = !!value ? parseInt(value) : 0;
    setVolumeOverrides({ ...updatedData });
    setValue(!!value ? parseInt(value) : '');
  };
  return (
    <div>
      <Row widths={'fill'}>
        <Text type={TEXTS.T2}>{cycle + ': '}</Text>
        <Row alignmentHorizontal={'right'}>
          <Input value={value} onChange={onChangeValue} type="number" size="medium" />
        </Row>
      </Row>
    </div>
  );
};

const DynamicPlanVolumeOverride = ({
  name,
  cycles,
  totalVolume,
  volumeValues,
  setVolumeValues,
  volumeValuesBackup
}) => {
  const SCREEN = {
    DEFAULT: 1,
    EDIT: 2
  };
  const [screen, setScreen] = useState(SCREEN.DEFAULT);
  // Change screen of override, if user exits EDIT mode, return value to default current value.
  const changeScreen = () => {
    setScreen(screen === SCREEN.EDIT ? SCREEN.DEFAULT : SCREEN.EDIT);
    if (screen === SCREEN.EDIT) {
      setVolumeValues(JSON.parse(volumeValuesBackup));
    }
  };

  return (
    <div>
      <Column width="250px" spacing="200">
        <Text tag="label" type={TEXTS.T2} color="primary">
          {name}
        </Text>
        {screen !== SCREEN.DEFAULT ? (
          <Column>
            {cycles.map((cycle) => (
              <DSPVolumeOverride cycle={cycle} volumeOverrides={volumeValues} setVolumeOverrides={setVolumeValues} />
            ))}
            <Button onClick={changeScreen} type="link">
              Cancel
            </Button>
          </Column>
        ) : (
          <Row>
            <Text type={TEXTS.H2}>{totalVolume}</Text>
            <Button onClick={changeScreen} type="link">
              edit
            </Button>
          </Row>
        )}
      </Column>
    </div>
  );
};

export default DynamicPlanVolumeOverride;
