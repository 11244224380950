import React, { useState } from 'react';
import Button from '@amzn/meridian/button';
import Row from '@amzn/meridian/row';
import Expander from '@amzn/meridian/expander';
import Text from '@amzn/meridian/text';
import Column from '@amzn/meridian/column';
import Divider from '@amzn/meridian/divider';

import CycleVolume from './CycleVolume';
import OverrideReason from '../../commonComponents/OverrideReasons';

import { TEXTS } from '../../../utils/constants';

const ControlledExpander = ({ section, title, isOpen, ...props }) => {
  const [open, setOpen] = useState(isOpen);
  return (
    <Expander open={open} onChange={setOpen} title={title} type={section}>
      {props.children}
    </Expander>
  );
};

const CycleExpander = ({
  data,
  errorCounter,
  updateButtonState,
  initialData,
  dupData,
  isUserAuthorisedForShiftPlanOverrides
}) => {
  return (
    <div>
      {data.input.packageProjectionInput.packageProjectionList.map((val, index) => {
        return (
          <ControlledExpander
            title={<Text alignment="left">{val.applicability.identifier}</Text>}
            type="section"
            key={index}
            isOpen={index === 0}
          >
            <CycleVolume
              data={data}
              initialData={initialData}
              index={index}
              updateButtonState={updateButtonState}
              errorCounter={errorCounter}
              dupData={dupData}
              isUserAuthorisedForShiftPlanOverrides={isUserAuthorisedForShiftPlanOverrides}
              cycleName={val.applicability.identifier}
            />
          </ControlledExpander>
        );
      })}
    </div>
  );
};

const verifyVolumeError = {
  count: 0
};

const VerifyVolume = ({ data, setData, setStep, dataStorage, dupData, isUserAuthorisedForShiftPlanOverrides }) => {
  const volumeDataStorage = data.input.shiftPlanSuggestedInput
    ? JSON.stringify(data.input.shiftPlanSuggestedInput.packageProjectionInput.packageProjectionList)
    : JSON.stringify(JSON.parse(dataStorage).input.packageProjectionInput.packageProjectionList);

  const isDataSame = () =>
    JSON.stringify(data.input.packageProjectionInput.packageProjectionList) === volumeDataStorage;
  const overrideInitial = data.input.packageProjectionInput.overrideBasis;
  const overrideOptions = ['Weather', 'SEV Events', 'Delayed Line Haul', 'Manifested Not Received', 'Other'];
  const overrideExceptions = ['Other'];

  const [disableReset, setDisableReset] = useState(isDataSame());
  const [disableOverride, setDisableOverride] = useState(isDataSame());
  const [resetID, setResetID] = useState(Math.random());
  const [overrideReason, setOverrideReason] = useState(overrideInitial ? overrideInitial.reason : '');
  const [overrideReasonComment, setOverrideReasonComment] = useState(overrideInitial ? overrideInitial.comment : '');

  const onReset = () => {
    data.input.packageProjectionInput.packageProjectionList = JSON.parse(volumeDataStorage);
    data.input.packageProjectionInput.overrideBasis = null;
    setData(data);
    verifyVolumeError.count = 0;
    setResetID(Math.random());
    setDisableReset(true);
    setDisableOverride(true);
    setOverrideReason('');
    setOverrideReasonComment('');
  };
  const updateOverrideReason = (reason) => {
    setOverrideReason(reason);
    let overrideInitial = data.input.packageProjectionInput.overrideBasis;
    let comment = overrideInitial ? overrideInitial.comment : undefined;
    data.input.packageProjectionInput.overrideBasis = {
      reason,
      comment
    };
  };

  const updateOverrideReasonComment = (comment) => {
    setOverrideReasonComment(comment);
    let overrideInitial = data.input.packageProjectionInput.overrideBasis;
    let reason = overrideInitial ? overrideInitial.reason : undefined;
    data.input.packageProjectionInput.overrideBasis = {
      reason,
      comment
    };
  };

  const updateButtonState = () => {
    let isSame = isDataSame();
    if (isSame || verifyVolumeError.count) {
      data.input.packageProjectionInput.overrideBasis = null;
      setOverrideReason('');
      setOverrideReasonComment('');
    }
    setDisableReset(!!(isSame && !verifyVolumeError.count));
    setDisableOverride(!!(isSame || verifyVolumeError.count));
  };

  const isSaveButtonDisabled = () => {
    if (verifyVolumeError.count) return true;
    if (!disableOverride) {
      if (overrideReason === '') return true;
      else if (overrideExceptions.includes(overrideReason)) return !overrideReasonComment;
      else return false;
    }
    return false;
  };

  const onSave = () => {
    setData(data);
    setStep(2);
  };

  return (
    <Column
      heights="fit"
      alignmentHorizontal="stretch"
      alignmentVertical="top"
      spacing="medium"
      width="100%"
      spacingInset="400"
    >
      <Text type={TEXTS.H2} alignment="left">
        Volume
      </Text>
      <Text type={TEXTS.T2} alignment="left">
        Verify or update the values below and save.
      </Text>

      <CycleExpander
        data={data}
        updateButtonState={updateButtonState}
        errorCounter={verifyVolumeError}
        key={resetID}
        initialData={JSON.parse(dataStorage)}
        dupData={dupData}
        isUserAuthorisedForShiftPlanOverrides={isUserAuthorisedForShiftPlanOverrides}
      />

      <Column alignmentHorizontal="right" key={resetID + 1}>
        <OverrideReason
          overrideReason={overrideReason}
          setOverrideReason={updateOverrideReason}
          overrideReasonComment={overrideReasonComment}
          setOverrideReasonComment={updateOverrideReasonComment}
          options={overrideOptions}
          exceptions={overrideExceptions}
          disable={disableOverride}
        />
      </Column>

      <Divider />

      <Row wrap="down" spacingInset="medium none" alignmentHorizontal="right">
        <Button onClick={onReset} disabled={disableReset} type="secondary">
          Reset
        </Button>
        <Button onClick={onSave} disabled={isSaveButtonDisabled()}>
          Save
        </Button>
      </Row>
    </Column>
  );
};

export default VerifyVolume;
