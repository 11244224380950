import React from 'react';
import { SSD_TIMELINE_KEY } from '../../../../utils/constants';
import ProgressTracker, { ProgressTrackerStep } from '@amzn/meridian/progress-tracker';
import { displayTime, getShiftTimeline } from '../../utils';

const ShiftTimeline = ({ shift, skipKeys = [], timeline }) => {
  const currentTimestamp = Date.now() / 1000;
  const shiftTimeline = getShiftTimeline(shift, timeline);
  return (
    <ProgressTracker direction="row">
      {shiftTimeline
        .filter(({ key }) => !skipKeys.includes(key))
        .map(({ key, label, timestamp, date_time_local }) => (
          <ProgressTrackerStep
            key={label + key}
            type={timestamp <= currentTimestamp ? 'past' : 'future'}
            trackLength={shiftTimeline.length > 6 ? 8 : 12}
            label={`${label} ${key === SSD_TIMELINE_KEY.CPT ? 'CPT' : ''}`}
            showLabel={true}
          >
            {`${displayTime(date_time_local)}`}
          </ProgressTrackerStep>
        ))}
    </ProgressTracker>
  );
};

export default ShiftTimeline;
